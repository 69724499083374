export enum LocalStorageKeys {
  SESSION_TOKEN = "zeal_sessionToken",
  PRIMARY_TOKEN = "zeal_primaryToken",
  SECONDARY_TOKEN = "zeal_secondaryToken",
}

export type ClearLocalStorageOptions = {
  keepPrimary: boolean;
  keepSecondary: boolean;
  keepSession: boolean;
};

export type GetTokenFn = (key: LocalStorageKeys) => string | null;

type TokenRequestHeader = {
  "x-session-token": string;
  "x-primary-token": string;
  "x-secondary-token": string;
};

export const getTokenAuthHeaders = (
  getTokenFn?: GetTokenFn
): Partial<{ [key in keyof TokenRequestHeader]: string }> => {
  if (
    !Object.prototype.hasOwnProperty.call(globalThis, "localStorage") &&
    !getTokenFn
  ) {
    return {};
  }

  const getToken = getTokenFn ?? ((key: string) => localStorage.getItem(key));

  const sessionToken = getToken(LocalStorageKeys.SESSION_TOKEN);
  const primaryToken = getToken(LocalStorageKeys.PRIMARY_TOKEN);
  const secondaryToken = getToken(LocalStorageKeys.SECONDARY_TOKEN);

  return {
    ...(sessionToken ? { "x-session-token": sessionToken } : {}),
    ...(primaryToken ? { "x-primary-token": primaryToken } : {}),
    ...(secondaryToken ? { "x-secondary-token": secondaryToken } : {}),
  };
};

export const clearLocalStorageKeys = (
  options: ClearLocalStorageOptions = {
    keepPrimary: false,
    keepSecondary: false,
    keepSession: false,
  }
) => {
  if (!options.keepPrimary) {
    localStorage.removeItem(LocalStorageKeys.PRIMARY_TOKEN);
  }
  if (!options.keepSecondary) {
    localStorage.removeItem(LocalStorageKeys.SECONDARY_TOKEN);
  }
  if (!options.keepSession) {
    localStorage.removeItem(LocalStorageKeys.SESSION_TOKEN);
  }
};
