import { zSchema } from "../../../schema-validation";

export const companyAddAchSchema = zSchema.object({
  account_class: zSchema.string(),
  account_number: zSchema.string(),
  account_type: zSchema.string(),
  company_id: zSchema.string(),
  company_name: zSchema.string(),
  odo_user_id: zSchema.string(),
  routing_number: zSchema.string(),
});
