import { z } from "zod";

type JsonType = z.ZodUnion<
  [
    z.ZodString,
    z.ZodNumber,
    z.ZodArray<z.ZodLazy<JsonType>, "many">,
    z.ZodRecord<z.ZodString, z.ZodLazy<JsonType>>,
    z.ZodBoolean,
    z.ZodNull,
  ]
>;

const jsonType = (): JsonType =>
  z.union([
    z.string(),
    z.number(),
    z.array(z.lazy(() => jsonType())),
    z.record(
      z.string().min(1),
      z.lazy(() => jsonType())
    ),
    z.boolean(),
    z.null(),
  ]);

export const metadata = () => z.record(z.string().min(1), jsonType());
