import { ICheckDetails, ZealApiResponse } from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";

export const fetchCheckDetails = (axios: IAxiosInstance) => {
  return async (checkIDs: string[]) => {
    const response = await axios.noRetry.post<ZealApiResponse<ICheckDetails[]>>(
      `/api/fetch-check-details`,
      {
        checkIDs,
      }
    );
    const resData = response.data;
    if (resData.success) {
      return resData.data;
    }
    throw new Error("Something went wrong");
  };
};
