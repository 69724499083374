import { AbstractApiClient } from "../AbstractApiClient/AbstractApiClient";
import { postPreviewCheckData } from "./routes/postPreviewCheckData/postPreviewCheckData";
import { preProcessChecks } from "./routes/preProcessChecks/preProcessChecks";
import { preProcessChecksById } from "./routes/preProcessChecksById/preProcessChecksById";
import {
  assertValidPicardSchema,
  PicardSchemaAssertionWithParams,
} from "./validation-types/assertValidPicardSchema";

export class PicardClient extends AbstractApiClient {
  public static AssertValidSchema: PicardSchemaAssertionWithParams =
    assertValidPicardSchema;
  public readonly PostPreviewCheckData = postPreviewCheckData(this.Instance);
  public readonly PreProcessChecks = preProcessChecks(this.Instance);
  public readonly PreProcessChecksById = preProcessChecksById(this.Instance);
}
