import { ISuspendedTax } from "./ISuspendedTax";

export const suspended_taxes: Array<ISuspendedTax> = [
  {
    jurisdiction: "NV",
    codename_regex: "FEE_NV_GENBUSINESS",
  },
  {
    jurisdiction: "NV",
    codename_regex: "FEE_NV_FINANCE",
  },
  {
    jurisdiction: "NH",
    codename_regex: "FEE_NH_BUSINESS_TAX",
  },
  {
    jurisdiction: "WA",
    codename_regex: "FEE_SEATTLE_PAYROLL_EXPENSE",
  },
  {
    jurisdiction: "CA",
    codename_regex: "FEE_CA_SACRAMENTO",
  },
  {
    jurisdiction: "MO",
    codename_regex: "FEE_MO_STLOUIS",
  },
  {
    jurisdiction: "NJ",
    codename_regex: "FEE_NJ_JERSEYCITY",
  },
  {
    jurisdiction: "NJ",
    codename_regex: "FEE_NJ_NEWARK",
  },
];
