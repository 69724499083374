// import { createQueryKeyStore } from "@lukemorales/query-key-factory";
// import { ValidationType } from "@zeal/morn/dist/clients/LaForgeClient/validation-types/ValidationType";
enum LaForgeRoutes {
  UNSAFE__forceExpireCookies = "UNSAFE__forceExpireCookies",
  DeleteMember = "DeleteMember",
  Features = "Features",
  FinishHomeSetup = "FinishHomeSetup",
  GenerateLogoGetUrl = "GenerateLogoGetUrl",
  GetAdminInfo = "GetAdminInfo",
  GetCheckDates = "GetCheckDates",
  GetCheckDatesReportingDetails = "GetCheckDatesReportingDetails",
  GetCompanyTaxRequirements = "GetCompanyTaxRequirements",
  GetContractorInfoByPayload = "GetContractorInfoByPayload",
  GetContractors = "GetContractors",
  GetCurrentUser = "GetCurrentUser",
  GetFirstEmployerCheckInfo = "GetFirstEmployerCheckInfo",
  GetGuestInfo = "GetGuestInfo",
  GetInternalUserInfo = "GetInternalUserInfo",
  GetMembers = "GetMembers",
  GetMyWorkers = "GetMyWorkers",
  GetOnboardingAuthLink = "GetOnboardingAuthLink",
  GetOnboardingLink = "GetOnboardingLink",
  GetReportsStatus = "GetReportsStatus",
  GetSecondaryJWT = "GetSecondaryJWT",
  GetSubEmployeeChecks = "GetSubEmployeeChecks",
  GetUpcomingChecks = "GetUpcomingChecks",
  GetUserInfo = "GetUserInfo",
  GetWLHostedConfig = "GetWLHostedConfig",
  GetWLInfo = "GetWLInfo",
  GetWLInfoById = "GetWLInfoById",
  GetWorkerInfoByPayload = "GetWorkerInfoByPayload",
  InviteMember = "InviteMember",
  Login = "Login",
  Logout = "Logout",
  PostCustomPayrollJournal = "PostCustomPayrollJournal",
  PostPreviewCheckData = "PostPreviewCheckData",
  SendContractorOnboardingLink = "SendContractorOnboardingLink",
  SendOnboardingLink = "SendOnboardingLink",
  Token = "Token",
}

const EMPLOYER_DASH_ROOT_KEY = ["EmployerDashboard"] as const;

export const mergeEmployerDashboardKey = <T = string>(keys: T[]) => [
  ...EMPLOYER_DASH_ROOT_KEY,
  ...keys,
];

export const ReactQueryKeys = {
  EmployerDashboard: {
    root: EMPLOYER_DASH_ROOT_KEY,
    GetUserInfo: {
      root: [...EMPLOYER_DASH_ROOT_KEY, LaForgeRoutes.GetUserInfo] as const,
      // args: (args: ValidationType.IGetUserInfoArgs) =>
      //   [...ReactQueryKeys.EmployerDashboard.GetUserInfo.root, args] as const,
    },
  },
};
