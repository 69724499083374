import { z } from "zod";
import { employeeBaseRequirementSchema, employeeSchema } from "./baseEmployee";

export const i9eVerify = z.literal("i9-eVerify");

export const i9eVerifyEmployeeSchema = employeeBaseRequirementSchema
  .extend({
    kind: i9eVerify,
  })
  .merge(
    employeeSchema
      .pick({
        first_name: true,
        last_name: true,
        ssn: true,
        dob: true,
        address: true,
        city: true,
        state: true,
        zip: true,
        phone_number: true,
        workLocationID: true,
        start_date: true,
      })
      .passthrough()
      .partial()
  );

export const i9eVerifyEmployeeTaskFieldsSchema = z.object({
  kind: z.literal("i9-eVerify"),
  ...Object.fromEntries(
    Object.keys(i9eVerifyEmployeeSchema.omit({ kind: true }).shape).map(
      (key) => [key, z.boolean()]
    )
  ),
});

export type I9eVerifyEmployee = z.infer<typeof i9eVerifyEmployeeSchema>;
export type I9eVerifyEmployeeTaskFields = z.infer<
  typeof i9eVerifyEmployeeTaskFieldsSchema
>;

export type I9eVerifyEmployeeTask = {
  employeeID: string;
  fields: I9eVerifyEmployeeTaskFields;
};
