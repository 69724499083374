import { COMPANY_TRIGGER_MICRODEPOSIT } from "@zeal/common";
import { AxiosError } from "axios";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import {
  ITriggerMicroDepositsRequestBody,
  ITriggerMicroDepositsResponseBody,
} from "./triggerMicroDeposits.jsonschema";

export const triggerCompanyMicroDeposits =
  (axios: IAxiosInstance) => async (body: ITriggerMicroDepositsRequestBody) => {
    try {
      const response =
        await axios.noRetry.post<ITriggerMicroDepositsResponseBody>(
          COMPANY_TRIGGER_MICRODEPOSIT,
          body
        );
      return response;
    } catch (e) {
      const errorMessage =
        (e as AxiosError)?.response?.data ?? "Error triggering micro deposits";
      throw errorMessage;
    }
  };
