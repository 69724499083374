import {
  IGetPaperworkTemplatesRequest,
  IGetPaperworkTemplatesResponse,
  PAPERWORK_ROUTES,
} from "@zeal/common";
import { IAxiosInstance } from "../../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../../AbstractApiClient/IApiResponse";

export const postPaperworkTemplate = (axios: IAxiosInstance) => {
  return async (data: IGetPaperworkTemplatesRequest) => {
    const response = await axios.noRetry.post<
      IApiResponse<IGetPaperworkTemplatesResponse>
    >(PAPERWORK_ROUTES.GET_PAPERWORK_TEMPLATES, data);

    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
