import { zSchema, benefitsClassSchema, EPaySchedule } from "@zeal/common";

export const uploadOneWorkerRequestSchema = zSchema.object({
  companyID: zSchema.string(),
  default_pay_schedule: zSchema.nativeEnum(EPaySchedule).optional(),
  email: zSchema.string().email(),
  first_name: zSchema.string(),
  is_943: zSchema.boolean(),
  is_scheduleH: zSchema.boolean(),
  last_name: zSchema.string(),
  start_date: zSchema.string(),
  title: zSchema.string(),
  workLocationID: zSchema.string(),
  address_line2: zSchema.string().optional(),
  address: zSchema.string().optional(),
  autopilot_on: zSchema.boolean().optional(),
  benefits_class: benefitsClassSchema.optional(),
  city: zSchema.string().optional(),
  dob: zSchema.string().optional(),
  dt_wage_rate: zSchema.number().optional(),
  is_regular: zSchema.boolean().optional(),
  is_salary: zSchema.boolean().optional(),
  ot_wage_rate: zSchema.number().optional(),
  phone_number: zSchema.string().optional(),
  salary_firstDate: zSchema.string().optional(),
  salary: zSchema.number().optional().nullable(),
  ssn: zSchema.string().optional(),
  state: zSchema.string().optional(),
  wage: zSchema.number().optional(),
  zip: zSchema.string().optional(),
});

export type IUploadOneWorkerRequest = Omit<
  zSchema.input<typeof uploadOneWorkerRequestSchema>,
  "companyID" | "dob" | "salary_firstDate" | "start_date"
> & {
  readonly dob?: Date;
  readonly salary_firstDate?: Date;
  readonly start_date?: Date;
}; // createEmployee method converts to date string before sending to API;

export type ICreateEmployeeResponse = {
  readonly success: boolean;
  readonly data: {
    readonly user_type: string;
    readonly default_pay_schedule: string;
    readonly autopilot_on: boolean;
    readonly complete_paperwork: boolean;
    readonly payroll_results: [];
    readonly pto: [];
    readonly vac: [];
    readonly onboarded: boolean;
    readonly employment_status: string;
    readonly is_943: boolean;
    readonly is_scheduleH: boolean;
    readonly onboard_docs_faxed: boolean;
    readonly is_regular: boolean;
    readonly is_salary: boolean;
    readonly _id: string;
    readonly first_name: string;
    readonly last_name: string;
    readonly start_date: string;
    readonly email: string;
    readonly title: string;
    readonly address: string;
    readonly address_line2: string;
    readonly city: string;
    readonly state: string;
    readonly zip: string;
    readonly phone_number: string;
    readonly ssn: string;
    readonly salary: null;
    readonly salary_firstDate: string;
    readonly default_wage: number;
    readonly default_ot_wage: number;
    readonly default_dt_wage: number;
    readonly workLocationID: string;
    readonly working_state: string;
    readonly organization: string;
    readonly companyID: string;
    readonly taxengine_workerID: string;
    readonly createdAt: string;
    readonly updatedAt: string;
    readonly __v: number;
  };
};
