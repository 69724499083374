export enum MediaType {
  Pdf = "pdf",
  Csv = "csv",
  JSON = "json",
  Xml = "xml",
  Txt = "txt",
  Ics = "ICS",
  Xls = "xls",
  Xlsx = "xlsx",
  Zip = "zip",
}

export const MediaTypes = [
  MediaType.Csv,
  MediaType.Ics,
  MediaType.JSON,
  MediaType.Pdf,
  MediaType.Txt,
  MediaType.Xls,
  MediaType.Xlsx,
  MediaType.Xml,
  MediaType.Zip,
] as const;
