import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TCreateEmployeePayCardRequestSchema,
  TCreateEmployeePayCardResponseSchema,
} from "./createEmployeePayCard.schema";

export const createEmployeePayCard = (axios: IAxiosInstance) => {
  return async (data: TCreateEmployeePayCardRequestSchema) => {
    const { employee_id, ...rest } = data;
    const response = await axios.noRetry.post<
      IApiResponse<IApiResponse<TCreateEmployeePayCardResponseSchema>>
    >(`/api/employees/${employee_id}/pay-cards`, rest);

    return response.data;
  };
};
