import { ECitizenshipStatus } from "./ECitizenshipStatus";
import { EWorkerVerificationIDType } from "./EWorkerVerificationIDType";

/**
 * Indicates the type of document that a worker can provide to
 * verify their identity during the i9 e-verify process.
 *
 * The enums are provided by the e-verify web services API, if you have
 * access to the e-verify dashboard, you should be able to find the API
 * spec under "Resources".
 *
 * For the name of the documents:
 * https://www.uscis.gov/i-9-central/form-i-9-acceptable-documents
 */
export enum EDocumentKey {
  CANADIAN_DRIVERS_LICENSE = "CANADIAN_DRIVERS_LICENSE",
  DAY_CARE_RECORD = "DAY_CARE_RECORD",
  DRIVERS_LICENSE = "DRIVERS_LICENSE",
  DS_1350 = "DS_1350", // Department of State Form DS-1350 - Certificate of Report of Birth
  EMPLOYMENT_AUTHORIZATION_DOCUMENT = "EMPLOYMENT_AUTHORIZATION_DOCUMENT",
  FOREIGN_PASSPORT = "FOREIGN_PASSPORT",
  FOREIGN_PASSPORT_WITH_FORM_I94 = "FOREIGN_PASSPORT_WITH_FORM_I94",
  FOREIGN_PASSPORT_WITH_I551_STAMP = "FOREIGN_PASSPORT_WITH_I551_STAMP",
  FORM_I179 = "FORM_I179",
  FORM_I197 = "FORM_I197",
  FORM_I551 = "FORM_I551",
  FORM_I766 = "FORM_I766",
  FORM_I94 = "FORM_I94",
  FORM_I94_RECEIPT = "FORM_I94_RECEIPT",
  FS_240 = "FS_240", // Consular Report of Birth Abroad
  FS_545 = "FS_545", // Certificate of Birth Abroad
  FSM_OR_RMI_PASSPORT_WITH_FORM_I94 = "FSM_OR_RMI_PASSPORT_WITH_FORM_I94",
  GOVERNMENT_ID_CARD = "GOVERNMENT_ID_CARD",
  HOSPITAL_RECORD = "HOSPITAL_RECORD",
  MILITARY_DEPENDENT_ID_CARD = "MILITARY_DEPENDENT_ID_CARD",
  NATIVE_AMERICAN_TRIBAL_DOCUMENT = "NATIVE_AMERICAN_TRIBAL_DOCUMENT",
  SCHOOL_ID_CARD = "SCHOOL_ID_CARD",
  SCHOOL_RECORD = "SCHOOL_RECORD",
  SOCIAL_SECURITY_CARD = "SOCIAL_SECURITY_CARD",
  US_BIRTH_CERTIFICATE = "US_BIRTH_CERTIFICATE",
  US_COAST_GUARD_CARD = "US_COAST_GUARD_CARD",
  US_MILITARY_CARD = "US_MILITARY_CARD",
  US_PASSPORT = "US_PASSPORT",
  VOTER_REGISTRATION_CARD = "VOTER_REGISTRATION_CARD",
}

export enum EDocumentSubType {
  DRIVERS_LICENSE = "DRIVERS_LICENSE",
  STATE_ID_CARD = "STATE_ID_CARD",
}

export const documentKeyOrSubTypeToHumanReadableMap: Record<
  EDocumentKey | EDocumentSubType,
  string
> = {
  [EDocumentKey.CANADIAN_DRIVERS_LICENSE]: "Canadian Driver's License",
  [EDocumentKey.DAY_CARE_RECORD]: "Day Care Record",
  [EDocumentKey.DRIVERS_LICENSE]: "Driver's License",
  [EDocumentKey.DS_1350]: "Form DS-1350",
  [EDocumentKey.EMPLOYMENT_AUTHORIZATION_DOCUMENT]:
    "Employment Authorization Document",
  [EDocumentKey.FOREIGN_PASSPORT]: "Foreign Passport",
  [EDocumentKey.FOREIGN_PASSPORT_WITH_FORM_I94]:
    "Foreign Passport with Form I-94",
  [EDocumentKey.FOREIGN_PASSPORT_WITH_I551_STAMP]:
    "Foreign Passport with I-551 Stamp",
  [EDocumentKey.FORM_I179]: "Form I-179",
  [EDocumentKey.FORM_I197]: "Form I-197",
  [EDocumentKey.FORM_I551]: "Form I-551",
  [EDocumentKey.FORM_I766]: "Form I-766",
  [EDocumentKey.FORM_I94]: "Form I-94",
  [EDocumentKey.FORM_I94_RECEIPT]: "Form I-94 Receipt",
  [EDocumentKey.FS_240]: "FS 240",
  [EDocumentKey.FS_545]: "FS 545",
  [EDocumentKey.FSM_OR_RMI_PASSPORT_WITH_FORM_I94]:
    "FSM or RMI Passport with Form I-94",
  [EDocumentKey.GOVERNMENT_ID_CARD]: "Government ID Card",
  [EDocumentKey.HOSPITAL_RECORD]: "Hospital Record",
  [EDocumentKey.MILITARY_DEPENDENT_ID_CARD]: "Military Dependent ID Card",
  [EDocumentKey.NATIVE_AMERICAN_TRIBAL_DOCUMENT]:
    "Native American Tribal Document",
  [EDocumentKey.SCHOOL_ID_CARD]: "School ID Card",
  [EDocumentKey.SCHOOL_RECORD]: "School Record",
  [EDocumentKey.SOCIAL_SECURITY_CARD]: "Social Security Card",
  [EDocumentKey.US_BIRTH_CERTIFICATE]: "US Birth Certificate",
  [EDocumentKey.US_COAST_GUARD_CARD]: "US Coast Guard Card",
  [EDocumentKey.US_MILITARY_CARD]: "US Military Card",
  [EDocumentKey.US_PASSPORT]: "US Passport",
  [EDocumentKey.VOTER_REGISTRATION_CARD]: "Voter Registration Card",
  [EDocumentSubType.DRIVERS_LICENSE]: "Driver's License",
  [EDocumentSubType.STATE_ID_CARD]: "State ID Card",
};

export enum EDocumentList {
  LIST_A = "LIST_A",
  LIST_B = "LIST_B",
  LIST_C = "LIST_C",
}

export type TDocumentsConfig = Record<
  EDocumentKey,
  {
    list_type: EDocumentList;
    applicable_citizenship_statuses: ECitizenshipStatus[];
    comparableIDVerificationType: EWorkerVerificationIDType;
  }
>;

/**
 * TODO (Sahil, 2024-05-13): Review and update this config
 * Address in ZF1-6351, ZF1-6380
 */
export const documentsConfig: TDocumentsConfig = {
  [EDocumentKey.US_PASSPORT]: {
    list_type: EDocumentList.LIST_A,
    applicable_citizenship_statuses: [ECitizenshipStatus.US_CITIZEN],
    comparableIDVerificationType: EWorkerVerificationIDType.PASSPORT,
  },
  [EDocumentKey.FOREIGN_PASSPORT]: {
    list_type: EDocumentList.LIST_A,
    applicable_citizenship_statuses: [ECitizenshipStatus.NONCITIZEN],
    comparableIDVerificationType: EWorkerVerificationIDType.PASSPORT,
  },
  [EDocumentKey.FOREIGN_PASSPORT_WITH_FORM_I94]: {
    list_type: EDocumentList.LIST_A,
    applicable_citizenship_statuses: [ECitizenshipStatus.NONCITIZEN],
    comparableIDVerificationType: EWorkerVerificationIDType.PASSPORT,
  },
  [EDocumentKey.FSM_OR_RMI_PASSPORT_WITH_FORM_I94]: {
    list_type: EDocumentList.LIST_A,
    applicable_citizenship_statuses: [ECitizenshipStatus.NONCITIZEN],
    comparableIDVerificationType: EWorkerVerificationIDType.PASSPORT,
  },
  [EDocumentKey.FOREIGN_PASSPORT_WITH_I551_STAMP]: {
    list_type: EDocumentList.LIST_A,
    applicable_citizenship_statuses: [ECitizenshipStatus.NONCITIZEN],
    comparableIDVerificationType: EWorkerVerificationIDType.PASSPORT,
  },
  [EDocumentKey.FORM_I94]: {
    list_type: EDocumentList.LIST_A,
    applicable_citizenship_statuses: [ECitizenshipStatus.NONCITIZEN],
    comparableIDVerificationType: EWorkerVerificationIDType.OTHER,
  },
  [EDocumentKey.FORM_I94_RECEIPT]: {
    list_type: EDocumentList.LIST_A,
    applicable_citizenship_statuses: [ECitizenshipStatus.NONCITIZEN],
    comparableIDVerificationType: EWorkerVerificationIDType.OTHER,
  },
  [EDocumentKey.FORM_I766]: {
    list_type: EDocumentList.LIST_A,
    applicable_citizenship_statuses: [ECitizenshipStatus.NONCITIZEN],
    comparableIDVerificationType: EWorkerVerificationIDType.OTHER,
  },
  [EDocumentKey.FORM_I551]: {
    list_type: EDocumentList.LIST_A,
    applicable_citizenship_statuses: [
      ECitizenshipStatus.LAWFUL_PERMANENT_RESIDENT,
    ],
    comparableIDVerificationType: EWorkerVerificationIDType.OTHER,
  },
  [EDocumentKey.CANADIAN_DRIVERS_LICENSE]: {
    list_type: EDocumentList.LIST_B,
    applicable_citizenship_statuses: [ECitizenshipStatus.NONCITIZEN],
    comparableIDVerificationType: EWorkerVerificationIDType.GOVERNMENT_PHOTO_ID,
  },
  [EDocumentKey.DRIVERS_LICENSE]: {
    list_type: EDocumentList.LIST_B,
    applicable_citizenship_statuses: [ECitizenshipStatus.US_CITIZEN],
    comparableIDVerificationType: EWorkerVerificationIDType.GOVERNMENT_PHOTO_ID,
  },
  [EDocumentKey.US_MILITARY_CARD]: {
    list_type: EDocumentList.LIST_B,
    applicable_citizenship_statuses: [ECitizenshipStatus.US_CITIZEN],
    comparableIDVerificationType: EWorkerVerificationIDType.OTHER,
  },
  [EDocumentKey.US_COAST_GUARD_CARD]: {
    list_type: EDocumentList.LIST_B,
    applicable_citizenship_statuses: [ECitizenshipStatus.US_CITIZEN],
    comparableIDVerificationType: EWorkerVerificationIDType.OTHER,
  },
  [EDocumentKey.SCHOOL_ID_CARD]: {
    list_type: EDocumentList.LIST_B,
    applicable_citizenship_statuses: [ECitizenshipStatus.US_CITIZEN],
    comparableIDVerificationType: EWorkerVerificationIDType.OTHER,
  },
  [EDocumentKey.VOTER_REGISTRATION_CARD]: {
    list_type: EDocumentList.LIST_B,
    applicable_citizenship_statuses: [ECitizenshipStatus.US_CITIZEN],
    comparableIDVerificationType: EWorkerVerificationIDType.OTHER,
  },
  [EDocumentKey.MILITARY_DEPENDENT_ID_CARD]: {
    list_type: EDocumentList.LIST_B,
    applicable_citizenship_statuses: [ECitizenshipStatus.US_CITIZEN],
    comparableIDVerificationType: EWorkerVerificationIDType.OTHER,
  },
  [EDocumentKey.GOVERNMENT_ID_CARD]: {
    list_type: EDocumentList.LIST_B,
    applicable_citizenship_statuses: [ECitizenshipStatus.US_CITIZEN],
    comparableIDVerificationType: EWorkerVerificationIDType.GOVERNMENT_PHOTO_ID,
  },
  [EDocumentKey.SCHOOL_RECORD]: {
    list_type: EDocumentList.LIST_B,
    applicable_citizenship_statuses: [ECitizenshipStatus.US_CITIZEN],
    comparableIDVerificationType: EWorkerVerificationIDType.OTHER,
  },
  [EDocumentKey.NATIVE_AMERICAN_TRIBAL_DOCUMENT]: {
    list_type: EDocumentList.LIST_B,
    applicable_citizenship_statuses: [ECitizenshipStatus.US_CITIZEN],
    comparableIDVerificationType: EWorkerVerificationIDType.OTHER,
  },
  [EDocumentKey.HOSPITAL_RECORD]: {
    list_type: EDocumentList.LIST_B,
    applicable_citizenship_statuses: [ECitizenshipStatus.US_CITIZEN],
    comparableIDVerificationType: EWorkerVerificationIDType.OTHER,
  },
  [EDocumentKey.DAY_CARE_RECORD]: {
    list_type: EDocumentList.LIST_B,
    applicable_citizenship_statuses: [ECitizenshipStatus.NONCITIZEN],
    comparableIDVerificationType: EWorkerVerificationIDType.OTHER,
  },
  [EDocumentKey.SOCIAL_SECURITY_CARD]: {
    list_type: EDocumentList.LIST_C,
    applicable_citizenship_statuses: [ECitizenshipStatus.US_CITIZEN],
    comparableIDVerificationType:
      EWorkerVerificationIDType.SOCIAL_SECURITY_CARD,
  },
  [EDocumentKey.EMPLOYMENT_AUTHORIZATION_DOCUMENT]: {
    list_type: EDocumentList.LIST_C,
    applicable_citizenship_statuses: [ECitizenshipStatus.NONCITIZEN],
    comparableIDVerificationType: EWorkerVerificationIDType.OTHER,
  },
  [EDocumentKey.FORM_I197]: {
    list_type: EDocumentList.LIST_C,
    applicable_citizenship_statuses: [
      ECitizenshipStatus.LAWFUL_PERMANENT_RESIDENT,
    ],
    comparableIDVerificationType: EWorkerVerificationIDType.OTHER,
  },
  [EDocumentKey.FORM_I179]: {
    list_type: EDocumentList.LIST_C,
    applicable_citizenship_statuses: [
      ECitizenshipStatus.LAWFUL_PERMANENT_RESIDENT,
    ],
    comparableIDVerificationType: EWorkerVerificationIDType.OTHER,
  },
  [EDocumentKey.FS_545]: {
    list_type: EDocumentList.LIST_C,
    applicable_citizenship_statuses: [
      ECitizenshipStatus.LAWFUL_PERMANENT_RESIDENT,
      ECitizenshipStatus.NONCITIZEN,
    ],
    comparableIDVerificationType: EWorkerVerificationIDType.OTHER,
  },
  [EDocumentKey.FS_240]: {
    list_type: EDocumentList.LIST_C,
    applicable_citizenship_statuses: [
      ECitizenshipStatus.LAWFUL_PERMANENT_RESIDENT,
      ECitizenshipStatus.NONCITIZEN,
    ],
    comparableIDVerificationType: EWorkerVerificationIDType.OTHER,
  },
  [EDocumentKey.US_BIRTH_CERTIFICATE]: {
    list_type: EDocumentList.LIST_C,
    applicable_citizenship_statuses: [ECitizenshipStatus.US_CITIZEN],
    comparableIDVerificationType: EWorkerVerificationIDType.OTHER,
  },
  [EDocumentKey.DS_1350]: {
    list_type: EDocumentList.LIST_C,
    applicable_citizenship_statuses: [ECitizenshipStatus.US_CITIZEN],
    comparableIDVerificationType: EWorkerVerificationIDType.OTHER,
  },
};

/**
 * Maps document keys used in the frontend to keys sent to worf.
 * The frontend requires unique keys to avoid duplicate values used
 * in forms. Some form options can map to the same value stored in worf
 */
export const laforgeToWorfDocumentKeyMap = {
  [EDocumentKey.FSM_OR_RMI_PASSPORT_WITH_FORM_I94]:
    EDocumentKey.FOREIGN_PASSPORT_WITH_FORM_I94,
};
