export enum EContractorType {
  INDIVIDUAL_SOLE_PROPRIETOR = "individual_sole_proprietor",
  C_CORPORATION = "c_corporation",
  S_CORPORATION = "s_corporation",
  PARTNERSHIP = "partnership",
  TRUST_ESTATE = "trust_estate",
  LLC = "llc",
  OTHER = "other",
  // TODO (Sahil, 03/28/24): Rm individual and business once all contractors are updated in production
  INDIVIDUAL = "individual",
  BUSINESS = "business",
}

export type ContractorType = `${EContractorType}`;
export type TContractorType = `${EContractorType}`;

export const businessContractorTypes = [
  EContractorType.C_CORPORATION,
  EContractorType.S_CORPORATION,
  EContractorType.PARTNERSHIP,
  EContractorType.TRUST_ESTATE,
  EContractorType.LLC,
  EContractorType.OTHER,
  // TODO (Sahil, 03/28/24): Rm business once all contractors are updated in production
  EContractorType.BUSINESS,
];

export const individualContractorTypes = [
  EContractorType.INDIVIDUAL_SOLE_PROPRIETOR,
  // TODO (Sahil, 03/28/24): Rm individual once all contractors are updated in production
  EContractorType.INDIVIDUAL,
];

// TODO (Sahil, 03/28/24): Rm once all contractors are updated in production
export const contractorTypes = [
  ...businessContractorTypes,
  ...individualContractorTypes,
];

// TODO (Sahil, 03/28/24): Rename to contractorTypes once all contractors are updated in production
export const strictContractorTypes = [
  EContractorType.INDIVIDUAL_SOLE_PROPRIETOR,
  EContractorType.C_CORPORATION,
  EContractorType.S_CORPORATION,
  EContractorType.PARTNERSHIP,
  EContractorType.TRUST_ESTATE,
  EContractorType.LLC,
  EContractorType.OTHER,
];

export const humanReadableContractorTypeMap: Record<EContractorType, string> = {
  [EContractorType.INDIVIDUAL_SOLE_PROPRIETOR]: "Individual/Sole Proprietor",
  [EContractorType.C_CORPORATION]: "C Corporation",
  [EContractorType.S_CORPORATION]: "S Corporation",
  [EContractorType.PARTNERSHIP]: "Partnership",
  [EContractorType.TRUST_ESTATE]: "Trust/Estate",
  [EContractorType.LLC]: "LLC",
  [EContractorType.OTHER]: "Other",
  // TODO (Sahil, 03/28/24): Rm individual and business once all contractors are updated in production
  [EContractorType.INDIVIDUAL]: "Individual",
  [EContractorType.BUSINESS]: "Business",
};

interface IContractorTypeMenuItem {
  value: EContractorType;
  label: string;
}

export const contractorTypeMenuItems: Array<IContractorTypeMenuItem> = [
  {
    value: EContractorType.INDIVIDUAL_SOLE_PROPRIETOR,
    label: "Individual/Sole proprietor",
  },
  {
    value: EContractorType.LLC,
    label: "LLC",
  },
  {
    value: EContractorType.C_CORPORATION,
    label: "C Corporation",
  },
  {
    value: EContractorType.S_CORPORATION,
    label: "S Corporation",
  },
  {
    value: EContractorType.PARTNERSHIP,
    label: "Partnership",
  },
  {
    value: EContractorType.TRUST_ESTATE,
    label: "Trust/Estate",
  },
  {
    value: EContractorType.OTHER,
    label: "Other",
  },
];
