export enum EApplicableEarningTypes {
  OVERTIME = "overtime",
  HOURLY = "hourly",
  DOUBLETIME = "doubletime",
}

export type TApplicableEarningTypes =
  | EApplicableEarningTypes.OVERTIME
  | EApplicableEarningTypes.HOURLY
  | EApplicableEarningTypes.DOUBLETIME;
