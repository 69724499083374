export const ETermReason = {
  VOLUNTARY: "voluntary",
  INVOLUNTARY: "involuntary",
  DEATH: "death",
  MISCONDUCT: "misconduct",
} as const;

export type ETermReason = (typeof ETermReason)[keyof typeof ETermReason];

export const TERM_REASONS = Object.values(
  ETermReason
) as ReadonlyArray<ETermReason>;

export const EUiTermReason: Record<keyof typeof ETermReason, string> = {
  VOLUNTARY: "Voluntary Termination",
  INVOLUNTARY: "Involuntary Termination",
  DEATH: "Death of Employee",
  MISCONDUCT: "Termination for Gross Misconduct",
} as const;
