import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

export const generateIdGetUrl = (axios: IAxiosInstance) => {
  return async ({
    idType,
    companyId,
    workerId,
    workerType,
  }: ISchema["#/definitions/IGenerateIdGetUrlArgs"]) => {
    const response = await axios.noRetry.get<{
      data: ISchema["#/definitions/IGenerateIdGetUrlResponse"];
    }>(`/generate-id-get-url`, {
      params: {
        id_type: idType,
        company_id: companyId,
        worker_id: workerId,
        worker_type: workerType,
      },
    });
    const resData = response.data?.data;
    return resData;
  };
};
