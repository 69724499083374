import { Unpack, ZealApiErrorResponse } from "@zeal/common";
import { AxiosError } from "axios";

type Error = Unpack<ZealApiErrorResponse["errors"]>;

export class ZealClientError implements Omit<ZealApiErrorResponse, "success"> {
  readonly errors: Error[];
  readonly statusCode: number;
  readonly testMode: boolean;

  /**
   * Returns the first error or a default error if
   * the error list is empty
   */
  public get firstError(): Error {
    if (this.errors.length > 0) {
      return this.errors[0];
    }

    return {
      message: "Something went wrong.",
    };
  }

  /**
   * Transforms an Axios error from a ZealApiErrorResponse into
   * a common structure. If unknown error, creates a default.
   *
   * @param err unknown
   */
  public constructor(err: unknown) {
    // Axios received a response from API with HTTP code other than 200
    if (err instanceof AxiosError && err.response) {
      const statusCode = err.response.status;
      const { testMode, errors } = err.response.data;
      this.errors = errors;
      this.statusCode = statusCode;
      this.testMode = testMode;
    } else {
      this.errors = [
        {
          message: "Something went wrong",
        },
      ];
      this.statusCode = 500;
      this.testMode = false;
    }
  }
}
