import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

export const getEmployeeWatchlistStatus = (axios: IAxiosInstance) => {
  return async ({
    odo_user_id,
    company_id,
  }: {
    odo_user_id: string;
    company_id: string;
  }) => {
    const response = await axios.noRetry.get<
      ISchema["#/definitions/IGetEmployeeWatchlistStatusResponseBody"]
    >(`/api/employee/get-status/watchlist/${odo_user_id}`, {
      data: {
        company_id,
      },
    });
    return response.data;
  };
};
