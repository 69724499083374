import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";
import { IGetCompanyStatusQueryParams } from "./getCompanyStatus.jsonschema";

export const getCompanyStatus = (axios: IAxiosInstance) => {
  return async (query: IGetCompanyStatusQueryParams) => {
    const response = await axios.noRetry.get<
      ISchema["#/definitions/IGetCompanyStatusResponseBody"]
    >(`/api/company/get-status/${query.company_id}`);
    return response;
  };
};
