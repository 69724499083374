import { z } from "zod";
import { RouteMap } from "./customerReporting";

export const workerSummaryFieldsSchema = z.object({
  default_wage: z.boolean().optional(),
  custom_attributes: z.record(z.any()).optional(),
});

export type WorkerSummaryFields = z.infer<typeof workerSummaryFieldsSchema>;

export const workerSummarySchema = z.object({
  companyID: z.string(),
  worker_type: z.enum(["employee", "contractor"]),
  active_workers: z.boolean(),
  limit: z.number().optional(),
  cursor: z.string().nullable(),
  ids: z.array(z.string()),
  custom_attributes: z.record(z.any()).optional(),
});

export type WorkerSummaryRequestParams = z.infer<typeof workerSummarySchema> & {
  has_live_key: boolean;
};

export const workerCountSchema = workerSummarySchema.pick({
  companyID: true,
  active_workers: true,
  worker_type: true,
});

export const workerIDsSchema = workerSummarySchema.omit({
  ids: true,
});

export type WorkerIDsParams = z.infer<typeof workerIDsSchema> & {
  has_live_key: boolean;
};

export const createWorkSummarySchema = workerSummarySchema.omit({
  cursor: true,
});

const workerSchema = z.object({
  employee_id: z.string(),
  employee_status: z.string(),
  job_title: z.string(),
  name: z.string(),
  first_name: z.string(),
  last_name: z.string(),
  working_state: z.string(),
  residency_state: z.string(),
  dob: z.string(),
  ssn: z.string(),
  email: z.string(),
  address: z.string(),
  address_line2: z.string(),
  city: z.string(),
  zip: z.string(),
  phone_number: z.string(),
  default_pay_schedule: z.string(),
  default_wage: z.number(),
  salary: z.number(),
  is_salary: z.boolean(),
  start_date: z.string(),
  external_id: z.string(),
  institution_name: z.string(),
  routing_number: z.string(),
  account_number: z.string(),
  onboarded: z.boolean(),
  custom_attributes: z.record(z.any()).optional(),
});

export type Worker = z.infer<typeof workerSchema>;
export type WorkerType = "All" | "Contractor" | "Employee";
export type WorkerSummaryResponse = {
  kind: RouteMap.worker_summary;
  worker_type: WorkerType;
  count: {
    live_count: number;
    total_count: number;
  };
  workers: (Worker | Contractor)[];
  fields?: WorkerSummaryFields;
};

const contractorSchema = z.object({
  employee_id: z.string(),
  name: z.string(),
  first_name: z.string(),
  job_title: z.null().optional(),
  phone_number: z.null().optional(),
  employee_status: z.null().optional(),
  default_pay_schedule: z.null().optional(),
  default_wage: z.null().optional(),
  last_name: z.string(),
  address: z.string(),
  address_line2: z.string(),
  working_state: z.string(),
  residency_state: z.string(),
  dob: z.string(),
  email: z.string(),
  city: z.string(),
  zip: z.string(),
  start_date: z.string(),
  external_id: z.string(),
  ssn: z.string(),
  onboarded: z.boolean(),
  business_name: z.string(),
  contractor_type: z.string(),
  institution_name: z.string(),
  routing_number: z.string(),
  account_number: z.string(),
  custom_attributes: z.record(z.any()).optional(),
});

export type Contractor = z.infer<typeof contractorSchema>;

export const workerSummaryWorkerTypeSchema = z.discriminatedUnion("kind", [
  z.object({
    kind: z.literal("Employee"),
    data: z.object({
      workers: z.array(workerSchema),
      fields: workerSummaryFieldsSchema,
    }),
  }),
  z.object({
    kind: z.literal("Contractor"),
    data: z.object({
      workers: z.array(contractorSchema),
      fields: workerSummaryFieldsSchema,
    }),
  }),
  z.object({
    kind: z.literal("All"),
    data: z.object({
      workers: z.array(z.union([contractorSchema, workerSchema])),
      fields: workerSummaryFieldsSchema,
    }),
  }),
]);
