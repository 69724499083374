import { TestMode } from "../../@types/general";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

export const getWLInfo = (axios: IAxiosInstance) => {
  return async ({ mode }: ISchema["#/definitions/IGetWLInfoArgs"]) => {
    const response = await axios.noRetry.get<
      ISchema["#/definitions/IGetWLInfoResponse"]
    >(`/api/getWLInfo`, {
      params: { usePrimary: true, test: mode === TestMode.Test },
    });
    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
