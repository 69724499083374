import { zSchema } from "../../../schema-validation";

export const triggerCustomerAccountMicroDepositsRequestSchema = zSchema.object({
  business_name: zSchema.string(),
  odo_user_id: zSchema.string(),
  testMode: zSchema.boolean().optional(),
});

export type TTriggerCustomerAccountMicroDepositsRequestSchema = zSchema.infer<
  typeof triggerCustomerAccountMicroDepositsRequestSchema
>;

export const triggerCustomerAccountMicroDepositsResponseSchema = zSchema.object(
  {
    bank_account_id: zSchema.string(),
    odo_user_id: zSchema.string(),
    user_type: zSchema.literal("customer_account_user"),
    zeal_id: zSchema.string(),
  }
);

export type TTriggerCustomerAccountMicroDepositsResponseSchema = zSchema.infer<
  typeof triggerCustomerAccountMicroDepositsResponseSchema
>;
