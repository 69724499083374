import { IIncomingDebit } from "./IIncomingDebit";
import { ITransactionRecord } from "./ITransactionRecord";

export const ELedgerDirection = {
  CREDIT: "credit",
  DEBIT: "debit",
} as const;

export type ELedgerDirection =
  (typeof ELedgerDirection)[keyof typeof ELedgerDirection];

export const ELedgerStatus = {
  PENDING: "pending",
  SETTLED: "settled",
} as const;

export type ELedgerStatus = (typeof ELedgerStatus)[keyof typeof ELedgerStatus];

export interface ILedgerEntry {
  counterpart_entry: ILedgerEntry;
  readonly account_id: string;
  readonly account_owner: string;
  readonly amount: string;
  readonly direction: ELedgerDirection;
  readonly entry_id: string;
  readonly external_transaction_id: string;
  readonly transaction_record?: string | ITransactionRecord;
  readonly incoming_debit?: string | IIncomingDebit;
  readonly other_party_id: string;
  readonly timestamp: string;
  readonly status: ELedgerStatus;
  readonly discarded: boolean;
  memo: string;
  discardedAt?: string;
}
