import { schemes, zSchema } from "@zeal/common";

export const preProcessChecksRequestSchema = zSchema.object({
  companyFilter: zSchema.object({
    type: zSchema.enum(["single"]),
    companyId: schemes.uuid(),
  }),
  processing_date: schemes.isoDate(),
  test_mode: zSchema.boolean(),
  check_date: schemes.isoDate(),
});

export type TPreProcessChecksRequestSchema = zSchema.infer<
  typeof preProcessChecksRequestSchema
>;

export const preProcessChecksResponseSchema = zSchema.object({
  payrolls: zSchema.array(
    zSchema.object({
      employerCheckID: schemes.uuid(),
      company_id: schemes.uuid(),
      checks: zSchema.array(zSchema.object({ paystub_id: schemes.uuid() })),
    })
  ),
});

export type TPreProcessChecksResponseSchema = zSchema.infer<
  typeof preProcessChecksResponseSchema
>;
