import { z } from "zod";
import { schemes } from "../../schema-validation";

export const employeeAddressSchema = z
  .object({
    address: z.string(),
    address_line2: z.string().nullable(),
    city: z.string(),
    state: schemes.jurisdiction(),
    zip: z.string(),
  })
  .partial();

export type EmployeeAddress = z.infer<typeof employeeAddressSchema>;
