import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "clients/LaForgeClient/validation-types/SchemaDefinition";
import { ZealApiSuccessResponse } from "@zeal/common";
import { ICreateCompanyLocationResponseData } from "./createCompanyLocation.jsonschema";
import { ZealClientError } from "../../../AbstractApiClient/ZealClientError";

type Response = ZealApiSuccessResponse<ICreateCompanyLocationResponseData>;

export const createCompanyLocation = (axios: IAxiosInstance) => {
  return async (body: ISchema["#/definitions/ICreateCompanyLocationArgs"]) => {
    try {
      const response = await axios.noRetry.post<Response>(
        `/api/createCompanyLocation`,
        body
      );

      return response.data.data;
    } catch (e) {
      throw new ZealClientError(e);
    }
  };
};
