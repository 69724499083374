import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

export const postGeneratePaystubFile = (axios: IAxiosInstance) => {
  return async (
    requestBody: ISchema["#/definitions/IPostGeneratePaystubFileBody"],
    signal?: AbortSignal
  ) => {
    const response = await axios.noRetry.post<
      ISchema["#/definitions/IPostGeneratePaystubFileResponse"]
    >("/reports/create/paystub", requestBody, {
      signal,
    });
    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
