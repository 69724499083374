import { schemes, zSchema } from "@zeal/common";

export const fetchWebhookEventsRequest = zSchema.object({
  scope: zSchema.string(),
  entityType: zSchema.string(),
  externalService: zSchema.string(),
  tag: zSchema.string().optional(),
  lastSeenID: zSchema.string().optional(),
});

export type TFetchWebhookEventsRequest = zSchema.input<
  typeof fetchWebhookEventsRequest
>;

export const fetchWebhookEventsResponseData = zSchema.array(
  zSchema.object({
    _id: schemes.mongoObjectId(),
    entityID: zSchema.string(),
    externalService: zSchema.string(),
    entityType: zSchema.string(),
    scope: zSchema.string(),
    event: zSchema.record(zSchema.string(), zSchema.any()),
    tags: zSchema.array(zSchema.any()),
    createdAt: zSchema.string(),
  })
);

export type TFetchWebhookEventsResponseData = zSchema.infer<
  typeof fetchWebhookEventsResponseData
>;
