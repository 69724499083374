import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

export const generateLogoGetUrl = (axios: IAxiosInstance) => {
  return async ({
    key,
    isFavicon = false,
  }: ISchema["#/definitions/IGenerateLogoGetUrlArgs"]) => {
    const response = await axios.noRetry.get<
      ISchema["#/definitions/IGenerateLogoGetUrlResponse"]
    >(`/generate-logo-get-url`, {
      params: {
        key: `${key}-${isFavicon ? "favicon" : "logo"}`,
      },
    });
    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
