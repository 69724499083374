import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

export interface IGetMyWorkerFilterOptions {
  /** Search query term */
  readonly q?: string;

  /** Whether results be scoped down to default pay scheduel */
  readonly default_pay_schedule?: string;

  /** Whether results be scoped down to onboarding status */
  readonly isOnboarded?: boolean;

  /** Whether results be scoped down to payroll type i.e. is_regular */
  readonly is_regular?: boolean;

  /** Whether results be scoped down to employment status */
  readonly employment_status?: string;

  readonly fetchBank?: boolean;
}

export interface IGetMyWorkersOptions {
  readonly query?: IGetMyWorkerFilterOptions;
}

export const getMyWorkers = (axios: IAxiosInstance) => {
  return async (options: IGetMyWorkersOptions) => {
    const { query } = options;
    const response = await axios.noRetry.get<
      ISchema["#/definitions/IGetMyWorkerResponse"]
    >(`/api/getMyWorkers`, {
      params: { ...query },
    });
    return response.data;
  };
};
