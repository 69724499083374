import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";
import { IVoidEmployeeChecksArguments } from "./voidEmployeeChecks.jsonschema";

export const voidEmployeeChecks = (axios: IAxiosInstance) => {
  return async (body: IVoidEmployeeChecksArguments) => {
    const response = await axios.with502Retry.post<
      ISchema["#/definitions/IVoidEmployeeChecksResponseBody"]
    >(`/api/accruals/voidManyChecks`, body);
    return response.data;
  };
};
