import { zSchema } from "@zeal/common";

export const createKybEvaluationRequestSchema = zSchema.object({
  addresses: zSchema.array(
    zSchema.object({
      address_line1: zSchema.string(),
      address_line2: zSchema.string().optional(),
      city: zSchema.string(),
      postal_code: zSchema.string(),
      state: zSchema.string(),
    })
  ),
  external_id: zSchema.string(),
  name: zSchema.string(),
  people: zSchema.array(
    zSchema.object({
      name: zSchema.string(),
    })
  ),
  tin: zSchema.string(),
  unique_external_id: zSchema.string(),
});

export type TCreateKybEvaluationRequestSchema = zSchema.infer<
  typeof createKybEvaluationRequestSchema
>;

export const createKybEvaluationResponseSchema = zSchema.object({});

export type TCreateKybEvaluationResponseSchema = zSchema.infer<
  typeof createKybEvaluationResponseSchema
>;
