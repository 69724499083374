export enum EDocumentField {
  US_PASSPORT_NUMBER = "us_passport_number",
  EXPIRATION_DATE = "expiration_date",
  ISSUING_AUTHORITY = "issuing_authority",
  DOCUMENT_NUMBER = "document_number",
  DOCUMENT_BC_NUMBER = "document_bc_number",
  ALIEN_NUMBER = "alien_number",
  FOREIGN_PASSPORT_NUMBER = "foreign_passport_number",
  COUNTRY_CODE = "country_code",
  I551_NUMBER = "i551_number",
  I766_NUMBER = "i766_number",
  I94_NUMBER = "i94_number",
  US_STATE_CODE = "us_state_code",
  DOCUMENT_SUB_TYPE_CODE = "document_sub_type_code",
  ALIEN_NUMBER_OR_I94_NUMBER = "alien_number_or_i94_number",
  ALIEN_NUMBER_OR_I94_NUMBER_OR_FOREIGN_PASSPORT_NUMBER = "alien_number_or_i94_number_or_foreign_passport_number",
  SEVIS_NUMBER = "sevis_number",
  VISA_NUMBER = "visa_number",
  STATUS = "status",
}
