import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

export const getPoaDocuments = (axios: IAxiosInstance) => {
  return async () => {
    const response = await axios.noRetry.get<
      ISchema["#/definitions/IGetPoaDocumentsResponse"]
    >(`/api/documents/poa/list`);
    return response.data?.documents;
  };
};
