import { EQuarter } from "./enums";

export enum EPaymentType {
  ACH_CREDIT = "ACH_CREDIT",
  ACH_DEBIT = "ACH_DEBIT",
  CHECK = "CHECK",
  CASH = "CASH",
}

export enum EPaymentStatus {
  CREATED = "CREATED",
  SETTLED = "SETTLED",
  CANCELED = "CANCELED",
  RETURNED = "RETURNED",
}

export enum EFileStatus {
  PENDING = "PENDING",
  PROCESSING = "PROCESSING",
  PROCESSED = "PROCESSED",
  REJECTED = "REJECTED",
}

export interface IPaymentStatus {
  readonly submissionNotes: string[];
  readonly status: EPaymentStatus;
  readonly date: string;
  readonly internalUser?: string;
}

export interface IFileStatus {
  readonly submissionNotes: string[];
  readonly status: EFileStatus;
  readonly date: string;
  readonly internalUser?: string;
}

export interface IPaymentSubmissionBase {
  readonly taxType: string;
  readonly quarkCompanyID: string;
  readonly collectorID?: string;
  readonly paymentSubmissionID: string;
  readonly reportingPeriodID: string;
  readonly amountPaid: number;
  readonly submissionDate: string;
  readonly paymentType: EPaymentType;
  readonly paymentStatus: IPaymentStatus[];
  readonly quarter?: EQuarter;
  readonly internalUser: string;
}

export interface IACHCreditPaymentSubmission extends IPaymentSubmissionBase {
  readonly paymentType: EPaymentType.ACH_CREDIT;
  readonly transactionID: string;
}

export interface IACHDebitPaymentSubmission extends IPaymentSubmissionBase {
  readonly paymentType: EPaymentType.ACH_DEBIT;
  readonly confirmationID: string;
}

export interface ICheckPaymentSubmission extends IPaymentSubmissionBase {
  readonly paymentType: EPaymentType.CHECK;
  readonly confirmationID: string;
}

export interface ICashPaymentSubmission extends IPaymentSubmissionBase {
  readonly paymentType: EPaymentType.CASH;
  readonly confirmationID: string;
}

export type IPaymentSubmission =
  | IACHCreditPaymentSubmission
  | IACHDebitPaymentSubmission
  | ICheckPaymentSubmission
  | ICashPaymentSubmission;

export interface IFileSubmission {
  readonly taxType: string;
  readonly quarkCompanyID: string;
  readonly collectorID?: string;
  readonly fileSubmissionID: string;
  readonly reportingPeriodID?: string;
  readonly fileStatus: IFileStatus[];
  readonly internalUser?: string;
  readonly submissionDate: string;
  readonly fileDeadlineID: string;
  readonly generatedFileID: string;
}
