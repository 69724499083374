import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "clients/LaForgeClient/validation-types/SchemaDefinition";

const URL = "/telemetry";
export const postTelemetry = (axios: IAxiosInstance) => {
  return async (requestBody: ISchema["#/definitions/IPostTelemetryBody"]) => {
    await axios.noRetry.post<ISchema["#/definitions/IPostTelemetryBody"]>(
      URL,
      requestBody
    );
  };
};
