import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";
import { IGetCompanyQueryParams } from "./getCompany.jsonschema";

export const getCompany = (axios: IAxiosInstance) => {
  return async (query: IGetCompanyQueryParams) => {
    const response = await axios.noRetry.get<
      ISchema["#/definitions/IGetCompanyResponseBody"]
    >(`/api/company/${query.company_id}`);
    return response.data;
  };
};
