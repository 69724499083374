import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

export const generateDocumentGetUrl = (axios: IAxiosInstance) => {
  return async (
    params: ISchema["#/definitions/IGenerateDocumentGetUrlArgs"]
  ) => {
    const response = await axios.noRetry.get<{
      data: ISchema["#/definitions/IGenerateDocumentGetUrlResponse"];
    }>(`/generate-document-get-url`, {
      params,
    });

    return response.data?.data;
  };
};
