import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import {
  IMigrateOneCompanyRequestBody,
  IMigrateOneCompanyResponseBody,
} from "./migrateOneCompany.jsonschema";

export const migrateOneCompany = (axios: IAxiosInstance) => {
  return async (body: IMigrateOneCompanyRequestBody) => {
    const response = await axios.noRetry.post<IMigrateOneCompanyResponseBody>(
      `/api/company/migrate`,
      body
    );

    return response.data;
  };
};
