const internalError = "An unknown (internal) error has occurred.";

export const networkStatusErrors = {
  400: "A frontend error has occurred.",
  401: "An authorization error has occurred.",
  403: "An authorization error has occurred.",
  404: "An unknown (missing) error has occurred.",
  408: "The connection has timed out.",
  429: "Too many requests sent.",
  500: internalError,
  501: internalError,
  502: internalError,
  503: internalError,
  504: internalError,
  505: internalError,
  508: internalError,
  510: internalError,
  511: internalError,
};
