import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "clients/LaForgeClient/validation-types/SchemaDefinition";

export const inviteMember = (axios: IAxiosInstance) => {
  return async ({
    email,
    role,
    inviter_first_name,
    inviter_last_name,
  }: ISchema["#/definitions/IInviteMemberArgs"]) => {
    const response = await axios.noRetry.post<
      ISchema["#/definitions/IInviteMemberResponse"]
    >(`/api/inviteMember`, {
      email,
      role,
      inviter_first_name,
      inviter_last_name,
    });

    const {
      data: { data, success },
    } = response;
    if (!success) {
      throw new Error(`Failed to invite member`);
    }
    return data;
  };
};
