import { z } from "zod";
import { schemes } from "@zeal/common";

export const downloadEVerifyNoticeSchema = z.object({
  workerID: schemes.mongoObjectId(),
  noticeType: z.union([
    z.literal("FURTHER_ACTION_NOTICE"),
    z.literal("REFERRAL_DATE_CONFIRMATION"),
  ]),
});

export type TDownloadEVerifyNotice = z.infer<
  typeof downloadEVerifyNoticeSchema
>;
