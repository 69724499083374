import {
  TEmbeddingTokenDataRequest,
  TEmbeddingTokenDataResponse,
} from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";

export const getEmbeddingSignedToken = (axios: IAxiosInstance) => {
  return async (data: TEmbeddingTokenDataRequest) => {
    const response = await axios.noRetry.post<
      IApiResponse<TEmbeddingTokenDataResponse>
    >(`/api/getEmbeddingSignedToken`, data);

    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
