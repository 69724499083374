import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { getUsePrimaryUrlParams } from "../../utils/utils";
import { ISchema } from "clients/LaForgeClient/validation-types/SchemaDefinition";

export const updateContractorPaymentWebhook = (axios: IAxiosInstance) => {
  return async ({
    contractorPaymentWebhook,
    userType,
    usePrimary,
    isTestWebhookType,
  }: ISchema["#/definitions/IUpdateContractorPaymentWebhookArgs"]) => {
    const response = await axios.noRetry.post<
      ISchema["#/definitions/IUpdateContractorPaymentWebhookResponse"]
    >(
      `/api/updateContractorPaymentWebhook${getUsePrimaryUrlParams(
        usePrimary
      )}`,
      {
        contractor_payment_webhook: contractorPaymentWebhook,
        user_type: userType,
        is_test_webhook_type: isTestWebhookType,
      }
    );

    const { data: responseData } = response;
    if (!responseData.success) {
      throw new Error(`Something went wrong`);
    }
    return responseData;
  };
};
