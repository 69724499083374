import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";

import { TaxDocument } from "@zeal/common";

export const updateCompanyTaxDocumentById = (axios: IAxiosInstance) => {
  return async (
    company_id: string,
    tax_document_id: string,
    body: FormData
  ) => {
    const response = await axios.noRetry.patch<{
      success: boolean;
      testMode: boolean;
      data: TaxDocument;
    }>(`/api/${company_id}/documents/tax/${tax_document_id}`, body, {
      timeout: 5 * 60 * 1000,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  };
};
