import { AxiosError } from "axios";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TGetCustomerAccountMicroDepositsRequestSchema,
  TGetCustomerAccountMicroDepositsResponseSchema,
} from "./getCustomerAccountMicroDepositsSchema";

export const getCustomerAccountMicroDeposits = (axios: IAxiosInstance) => {
  return async (query: TGetCustomerAccountMicroDepositsRequestSchema) => {
    try {
      const { data } = await axios.noRetry.get<
        IApiResponse<TGetCustomerAccountMicroDepositsResponseSchema>
      >(`/api/customer-accounts/${query.odo_user_id}/micro-deposits`);

      return data.data;
    } catch (e) {
      const errorMessage =
        (e as AxiosError)?.response?.data ?? "Error fetching micro deposits";
      throw errorMessage;
    }
  };
};
