import { EAuthorizedRepresentativeDecision } from "@zeal/common";
import { z } from "zod";

const accessTokenSchema = z.object({
  accessToken: z.string(),
});
const requestChangesSchema = z.object({
  decision: z.literal(EAuthorizedRepresentativeDecision.CHANGES_REQUESTED),
  requested_changes: z.array(z.string()).min(1),
});
const approvedSchema = z.object({
  first_name: z.string().trim().min(1),
  last_name: z.string().trim().min(1),
  decision: z.literal(EAuthorizedRepresentativeDecision.APPROVED),
  signature: z.string(),
});

export const updateI9OnboardingInfoAsAuthorizedRepresentativeSchema =
  z.discriminatedUnion("decision", [
    requestChangesSchema.merge(accessTokenSchema),
    approvedSchema.merge(accessTokenSchema),
  ]);

export const updateI9OnboardingInfoAsAuthorizedRepresentativeUISchema =
  z.discriminatedUnion("decision", [requestChangesSchema, approvedSchema]);

export type TUpdateI9OnboardingInfoAsAuthorizedRepresentativeSchema = z.infer<
  typeof updateI9OnboardingInfoAsAuthorizedRepresentativeSchema
>;
export type TUpdateI9OnboardingInfoAsAuthorizedRepresentativeUISchema = z.infer<
  typeof updateI9OnboardingInfoAsAuthorizedRepresentativeUISchema
>;
