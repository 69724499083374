import {
  TTriggerCustomerAccountMicroDepositsRequestSchema,
  TTriggerCustomerAccountMicroDepositsResponseSchema,
} from "@zeal/common";
import { AxiosError } from "axios";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";

export const triggerCustomerAccountMicroDeposits =
  (axios: IAxiosInstance) =>
  async (body: TTriggerCustomerAccountMicroDepositsRequestSchema) => {
    try {
      const response = await axios.noRetry.post<
        IApiResponse<TTriggerCustomerAccountMicroDepositsResponseSchema>
      >(
        `/api/customer-accounts/${body.odo_user_id}/trigger-micro-deposits`,
        body
      );
      return response;
    } catch (e) {
      const errorMessage =
        (e as AxiosError)?.response?.data ?? "Error triggering micro deposits";
      throw errorMessage;
    }
  };
