import { TPatchCustomerAccountRequestSchema } from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import { TCustomerAccountWithFundingSource } from "../getCustomerAccount/getCustomerAccountSchema";

export const patchCustomerAccount = (axios: IAxiosInstance) => {
  return async (body: TPatchCustomerAccountRequestSchema) => {
    const response = await axios.noRetry.patch<
      IApiResponse<TCustomerAccountWithFundingSource>
    >(`/customer-accounts/${body.customer_account_id}`, body);
    return response.data;
  };
};
