import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

export interface ILogoutOptions {
  readonly keepSession: boolean;
  readonly keepPrimary: boolean;
}

export const logout = (axios: IAxiosInstance) => {
  return async ({ keepPrimary, keepSession }: ILogoutOptions) => {
    const response = await axios.noRetry.post<
      ISchema["#/definitions/ILogoutResponse"]
    >(`/logout`, {
      keepPrimary,
      keepSession,
    });
    const data = response.data;
    return data;
  };
};
