import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import {
  TGetCompanyLocationsRequestSchema,
  TGetCompanyLocationsResponseSchema,
} from "./getCompanyLocationsSchema";

export const getCompanyLocations = (axios: IAxiosInstance) => {
  return async (body: TGetCompanyLocationsRequestSchema) => {
    const response =
      await axios.noRetry.post<TGetCompanyLocationsResponseSchema>(
        `/api/company/getLocations`,
        body
      );

    return response.data;
  };
};
