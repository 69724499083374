import { z } from "zod";
import { RouteMap } from "./customerReporting";

export const customerAccountResponseSchema = z.object({
  employeeName: z.string(),
  employeeID: z.string(),
  customerAccountName: z.string(),
  customerAccountID: z.string(),
  taxType: z.string(),
  quarterlyGrossWages: z.string(),
  quarterlyReportableWages: z.string(),
  quarterlyTaxableWages: z.string(),
  quarterlyWithholding: z.string(),
});

export type CustomerAccountResponse = z.infer<
  typeof customerAccountResponseSchema
>;

export type CustomerAccountResponseShape = {
  rows: Array<z.infer<typeof customerAccountResponseSchema>>;
} & {
  kind: RouteMap.customer_accounts;
};
