import { Jurisdiction, schemes, zSchema } from "@zeal/common";

export const resolveTaxableLocationRequestSchema = zSchema.object({
  address_components: zSchema
    .object({
      street1: zSchema.string().nullish(),
      street2: zSchema.string().nullish(),
      city: zSchema.string().nullish(),
      state: schemes.jurisdiction().nullish(),
      zip: zSchema.string().nullish(),
    })
    .optional(),
  taxable_location_id: schemes.mongoObjectId().optional(),
});

export type TResolveTaxableLocationRequestSchema = zSchema.infer<
  typeof resolveTaxableLocationRequestSchema
>;

export const taxableLocation = zSchema.object({
  city: zSchema.string().optional(),
  is_armed_forces_address: zSchema.boolean(),
  is_deliverable: zSchema.boolean(),
  is_foreign_address: zSchema.boolean(),
  is_po_box: zSchema.boolean(),
  is_suitable_for_taxation: zSchema.boolean(),
  is_state_mismatch: zSchema.boolean(),
  state: zSchema.nativeEnum(Jurisdiction),
  street1: zSchema.string().optional(),
  street2: zSchema.string().optional(),
  taxable_location_id: schemes.mongoObjectId().optional(),
  zip: zSchema.string().optional(),
});

export type TTaxableLocation = zSchema.infer<typeof taxableLocation>;

export const resolveTaxableLocationResponseSchema = taxableLocation;

export type TResolveTaxableLocationResponseSchema = zSchema.infer<
  typeof resolveTaxableLocationResponseSchema
>;
