export const EPaySchedule = {
  DAILY: "daily",
  WEEKLY: "weekly",
  BIWEEKLY: "biweekly",
  SEMIMONTHLY: "semimonthly",
  MONTHLY: "monthly",
  QUARTERLY: "quarterly",
} as const;

export type EPaySchedule = (typeof EPaySchedule)[keyof typeof EPaySchedule];

export const PAY_SCHEDULES = Object.values(
  EPaySchedule
) as ReadonlyArray<EPaySchedule>;
