import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TCreateKybEvaluationRequestSchema,
  TCreateKybEvaluationResponseSchema,
} from "./createKybEvaluation.schema";

export const createKybEvaluation = (axios: IAxiosInstance) => {
  return async (body: TCreateKybEvaluationRequestSchema) => {
    const response = await axios.noRetry.post<
      IApiResponse<TCreateKybEvaluationResponseSchema>
    >("/api/kyb", body);

    return response.data;
  };
};
