import { z } from "zod";

const money = z.preprocess(
  (val) => (val ? Number(val) : val),
  z.number().gte(0)
);

export const employeeWagesSchema = z
  .object({
    default_wage: money.nullable(),
    default_ot_wage: money.nullable(),
    default_dt_wage: money.nullable(),
    salary: money.nullable(),
  })
  .partial();
