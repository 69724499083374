import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TPostPayrollJournalRequestSchema,
  TPostPayrollJournalResponseSchema,
} from "./postPayrollJournalSchema";

const URL = `reports/payroll-journal`;
export const postPayrollJournal = (axios: IAxiosInstance) => {
  return async (requestBody: TPostPayrollJournalRequestSchema) => {
    const response = await axios.noRetry.post<
      IApiResponse<TPostPayrollJournalResponseSchema>
    >(URL, requestBody);
    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
