import { z } from "zod";
import { EEmploymentStatus } from "../../workers-shared/EEmploymentStatus";
import { EKYCStatus } from "../../kyc/EKYCStatus";
import { RouteMap } from "./customerReporting";
import { Contractor, Worker } from "./workerSummary";

export const kycSummarySchema = z.object({
  companyID: z.string(),
  cursor: z.string().nullable(),
  employment_status: z.nativeEnum(EEmploymentStatus).optional(),
  ids: z.array(z.string()),
  kyc_status: z.enum(["all", "approved", "failed"]).optional(),
  limit: z.number().optional(),
});

export type KYCSummaryRequestParams = z.infer<typeof kycSummarySchema> & {
  has_live_key: boolean;
};

export const allWorkerCountSchema = kycSummarySchema.pick({
  companyID: true,
  employment_status: true,
  kyc_status: true,
});

export const allWorkerIDsSchema = kycSummarySchema.omit({
  ids: true,
});

export type AllWorkerIDsParams = z.infer<typeof allWorkerIDsSchema> & {
  has_live_key: boolean;
};

export const createKYCSummarySchema = kycSummarySchema.omit({
  cursor: true,
});

export type KYCSummaryResponse = {
  kind: RouteMap.kyc_summary;
  count: {
    live_count: number;
    total_count: number;
  };
  workers: (Worker | Contractor)[];
};

export const getKYCFilter = (kycStatus: string) => {
  if (kycStatus === "all") {
    return {
      kyc_status: { $exists: true },
    };
  }

  if (kycStatus === "approved") {
    return {
      kyc_status: EKYCStatus.APPROVED,
    };
  }

  if (kycStatus === "failed") {
    return {
      kyc_status: { $exists: true, $ne: EKYCStatus.APPROVED },
    };
  }

  return {};
};
