import { ITransactionEvent, ITransactionRecord } from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";

export type IGetTransactionEventsResponseBody = IApiResponse<
  Array<ITransactionRecord & { transaction_events: ITransactionEvent[] }>
>;

export const getTransactionEvents = (axios: IAxiosInstance) => {
  return async (query: { paymentId: string }) => {
    const response = await axios.noRetry.get<IGetTransactionEventsResponseBody>(
      "/api/transaction/events",
      {
        params: { zealPaymentId: query.paymentId },
      }
    );
    return response.data;
  };
};
