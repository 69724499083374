import { z } from "zod";
import { Jurisdiction } from "../jurisdictions";
import { EKYCStatus } from "../kyc";
import { ELaForgeWorkerEnum } from "../workers-shared";
import { EContractorType } from "./EContractorType";

const getQuickviewContractorInfoResponseData = z.object({
  address: z.string(),
  address_line2: z.string(),
  city: z.string(),
  companyID: z.string(),
  complete_paperwork: z.literal(false),
  createdAt: z.string(),
  email: z.string(),
  employment_status: z.string(),
  first_name: z.string(),
  kyc_status: z.nativeEnum(EKYCStatus).optional(),
  last_name: z.string(),
  middle_name: z.string(),
  odo_user_id: z.string(),
  onboard_docs_faxed: z.literal(false),
  onboarded: z.boolean(),
  onboardedAt: z.string(),
  organization: z.string(),
  state: z.nativeEnum(Jurisdiction),
  type: z.enum([
    EContractorType.INDIVIDUAL_SOLE_PROPRIETOR,
    EContractorType.LLC,
    EContractorType.C_CORPORATION,
    EContractorType.S_CORPORATION,
    EContractorType.PARTNERSHIP,
    EContractorType.TRUST_ESTATE,
    EContractorType.OTHER,
    // TODO (Sahil, 03/28/24): Rm individual and business once all contractors are updated in production
    EContractorType.INDIVIDUAL,
    EContractorType.BUSINESS,
  ]),
  updatedAt: z.string(),
  user_type: z.literal(ELaForgeWorkerEnum.Employee),
  working_state: z.nativeEnum(Jurisdiction),
  zip: z.string(),
  _id: z.string(),
  dob: z.string(),
  bankaccount: z.object({
    _id: z.string(),
    id: z.string(),
    __v: z.number(),
    account_number: z.string(),
    companyID: z.string(),
    createdAt: z.string(),
    institution_name: z.string(),
    routing_number: z.string(),
    type: z.union([z.literal("checking"), z.literal("savings")]),
    updatedAt: z.string(),
  }),
});

export type TGetQuickviewContractorInfoResponseData = z.infer<
  typeof getQuickviewContractorInfoResponseData
>;
