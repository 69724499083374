import Ajv from "ajv";
import betterAjvErrors from "better-ajv-errors";
import { maskData, IMaskOptions } from "@zeal/common";
import { ClientSchemaValidationError } from "../../AbstractApiClient/ClientSchemaValidationError";
import { ISchema, schemas } from "./SchemaDefinition";
import * as schema from "./validation.schema.json";

export const validator = new Ajv({ allErrors: true });
validator.compile(schema);

type SchemaAssertion = <T extends keyof typeof schemas>(
  data: unknown,
  schemaKeyRef: T
) => asserts data is ISchema[T];

export type MiraSchemaAssertionWithParams = <T extends keyof typeof schemas>(
  data: unknown,
  schemaKeyRef: T,
  shouldMask?: boolean,
  options?: IValidationMaskOptions<T>
) => asserts data is ISchema[T];

const assertMatchesSchema: SchemaAssertion = <T extends keyof typeof schemas>(
  data: unknown,
  schemaKeyRef: T
): asserts data is ISchema[T] => {
  validator.validate(schemaKeyRef as string, data);
  if (validator.errors) {
    const detailedMessage = betterAjvErrors(schema, data, validator.errors, {
      format: "js",
    });
    throw new ClientSchemaValidationError(JSON.stringify(detailedMessage));
  }
};

export interface IValidationMaskOptions<T extends keyof typeof schemas> {
  readonly maskOverride?: IMaskOptions;
  /** Assert whether constraints are correct. eg. date is valid, or string fits some regex */
  readonly assertValidRangeAndConstraintsFn?: (payload: ISchema[T]) => void;
}

const defaultMaskOptions: IMaskOptions = {
  shouldMaskTypeErrors: false,
  onMissingProperty: (_e) => {
    // no - op
  },
  onTypeError: (_e) => {
    // no - op
  },
};

/**
 * This method asserts the type is correct and masks out any extra parameters by default.
 * It mutates the original object
 * It will also take in an optional overridable mask options parameter
 * @param data
 * @param schemaKeyRef
 * @param options
 * @returns
 */
export const assertValidMiraSchema: MiraSchemaAssertionWithParams = <
  T extends keyof typeof schemas,
>(
  data: unknown,
  schemaKeyRef: T,
  // eslint-disable-next-line default-param-last
  shouldMask = false,
  options?: IValidationMaskOptions<T>
) => {
  if (shouldMask === false) {
    assertMatchesSchema(data, schemaKeyRef);
    return;
  }
  const maskOptions = options?.maskOverride ?? defaultMaskOptions;

  const maskedPayload = maskData(validator, schemaKeyRef, data, maskOptions);
  assertMatchesSchema(maskedPayload, schemaKeyRef);
  data = maskedPayload;
};
