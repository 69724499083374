import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { getUsePrimaryUrlParams } from "../../utils/utils";
import { ISchema } from "clients/LaForgeClient/validation-types/SchemaDefinition";

export const updateReportWebhook = (axios: IAxiosInstance) => {
  return async ({
    jobWebhook,
    userType,
    usePrimary,
    isTestWebhookType,
  }: ISchema["#/definitions/IUpdateReportWebhookArgs"]) => {
    const response = await axios.noRetry.post<
      ISchema["#/definitions/IUpdateReportWebhookResponse"]
    >(`/api/updateReportWebhook${getUsePrimaryUrlParams(usePrimary)}`, {
      job_webhook: jobWebhook,
      user_type: userType,
      is_test_webhook_type: isTestWebhookType,
    });

    const { data: responseData } = response;
    if (!responseData.success) {
      throw new Error(`Something went wrong`);
    }
    return responseData;
  };
};
