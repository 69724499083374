import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";

export const verifyMicroDeposits = (axios: IAxiosInstance) => {
  return async (params: {
    companyID: string;
    customerAccountID: string;
    deposit_one: string;
    deposit_two: string;
    token: string;
  }) => {
    const response = await axios.noRetry.post<IApiResponse<any>>(
      `/api/customer-accounts/${params.customerAccountID}/verify-micro-deposits`,
      {
        companyID: params.companyID,
        deposits: [params.deposit_one, params.deposit_two],
        access_token: params.token,
      }
    );

    return response.data;
  };
};
