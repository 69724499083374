import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import { TDownloadGarnishmentDocument } from "./downloadGarnishmentDocumentSchema";

export const downloadGarnishmentDocument = (axios: IAxiosInstance) => {
  return async (data: TDownloadGarnishmentDocument) => {
    const response = await axios.noRetry.get<IApiResponse<string>>(
      "/api/downloadGarnishmentDocument",
      { params: data }
    );
    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
