import { ELaForgeWorkerEnum } from "../workers-shared/ELaForgeWorkerEnum";
import { z } from "zod";
import { EWorkerVerificationIDType } from "../accounts";

export const getVeriffIdsSchema = z.object({
  workerID: z.string(),
  workerType: z.enum([
    ELaForgeWorkerEnum.Contractor,
    ELaForgeWorkerEnum.Employee,
  ]),
  testMode: z.boolean(),
});

export type TGetVeriffIdsInput = z.infer<typeof getVeriffIdsSchema>;

export type TGetVeriffIdsParams = Omit<TGetVeriffIdsInput, "testMode">;

export interface IVeriffImageData {
  data: string; // base64 encoded string
  doc_type: EWorkerVerificationIDType;
}

export type TGetVeriffIdsData = Array<IVeriffImageData>;
