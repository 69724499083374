import { TJurisdictionFilter, TJurisdictionPaperworkFilter } from "../bolarus";
import { TParamDefinition } from "../forms";

export const integrationSources = {
  Docuseal: "Docuseal",
  Other: "Other",
} as const;

export const statusTypes = {
  Active: "Active",
  Draft: "Draft",
  Live: "Live",
  Archived: "Archived",
  Ready: "Ready",
} as const;

export const workerType = {
  Employee: "Employee",
  Contractor: "Contractor",
  All: "All",
} as const;

export const jurisdictionType = {
  WorkLocation: "WorkLocation",
  Residency: "Residency",
  All: "All",
} as const;

export const paperWorkTemplateTypes = {
  W4: "W4",
  I9: "I9",
  W2: "W2",
  "1099": "1099",
  W9: "W9",
  CustomPaperwork: "CustomPaperwork",
} as const;

export type EIntegrationSource =
  (typeof integrationSources)[keyof typeof integrationSources];
export type EPaperworkWorkerType = (typeof workerType)[keyof typeof workerType];
export type EJurisdictionType =
  (typeof jurisdictionType)[keyof typeof jurisdictionType];
export type EPaperWorkTemplateTypes =
  (typeof paperWorkTemplateTypes)[keyof typeof paperWorkTemplateTypes];
export type EPaperworkTemplateStatus =
  (typeof statusTypes)[keyof typeof statusTypes];

export interface IGenericTemplate {
  readonly templateID: string;
  readonly companyID?: string;
  readonly form_name: string;
  readonly description?: string;
  readonly paperwork_type: EPaperWorkTemplateTypes;
  readonly worker_type: EPaperworkWorkerType;
  readonly jurisdictions_filter: TJurisdictionPaperworkFilter;
  readonly jurisdiction_type: EJurisdictionType;
  readonly effective_date: string;
  readonly archive_date: string;
  readonly form_fields: Array<
    TParamDefinition & { x: number; y: number; width: number; height: number }
  >;
  readonly attributes_filter?: string;
  readonly urls: string[];
  readonly status: EPaperworkTemplateStatus;
}

export interface IIntegrationTemplate extends IGenericTemplate {
  readonly integrationTemplateID: string;
  readonly integration_source: EIntegrationSource;
  readonly s3_link: string;
}

// ROUTES

export const PAPERWORK_ROUTES = {
  GET_PAPERWORK_TEMPLATES: "/paperwork/templates",
  GET_PAPERWORK_TEMPLATE: "/paperwork/templates/:templateID",
  CREATE_PAPERWORK_TEMPLATE: "/paperwork/templates",
  UPDATE_PAPERWORK_TEMPLATE: "/paperwork/templates/:templateID",
  DELETE_PAPERWORK_TEMPLATE: "/paperwork/templates/:templateID",
} as const;

export type TPaperworkRoutes = keyof typeof PAPERWORK_ROUTES;

// REQUESTS

export interface IGetPaperworkTemplatesRequest {
  readonly worker_type?: EPaperworkWorkerType;
  readonly jurisdictions?: TJurisdictionFilter;
  readonly jurisdiction_type?: EJurisdictionType;
  readonly effective_date?: string;
  readonly archive_date?: string;
  readonly paperwork_type?: EPaperWorkTemplateTypes;
}

export interface IGetPaperworkTemplateRequest {
  readonly templateID: string;
}

export type IPostCreatePaperworkTemplateRequest = Partial<
  Omit<IGenericTemplate, "templateID" | "urls">
> & {
  readonly files: any[];
  readonly form_name: string;
  readonly paperwork_type: EPaperWorkTemplateTypes;
  readonly effective_date: string;
};

export type IPostUpdatePaperworkTemplateRequest = Partial<IGenericTemplate> & {
  readonly templateID: string;
  readonly files?: any[];
};

// RESPONSES

export interface IGetPaperworkTemplatesResponse {
  readonly templates: IGenericTemplate[];
}

export interface IGetPaperworkTemplateResponse {
  readonly template: IGenericTemplate;
}

export interface IPostCreatePaperworkTemplateResponse {
  readonly template: IGenericTemplate;
}

export interface IPostUpdatePaperworkTemplateResponse {
  readonly template: IGenericTemplate;
}
