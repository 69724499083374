import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";
import { IUpdateEmployeeAsCompanyRequest } from "./updateEmployeeAsCompany.jsonschema";

export const updateEmployeeAsCompany = (axios: IAxiosInstance) => {
  return async (body: IUpdateEmployeeAsCompanyRequest) => {
    const response = await axios.noRetry.post<
      ISchema["#/definitions/TUpdateEmployeeAsCompany"]
    >(`/api/updateEmployeeAsCompany`, body);
    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
