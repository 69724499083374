import { Jurisdiction, schemes, zSchema } from "@zeal/common";

export const taxParameterBaseSchema = zSchema.object({
  code: zSchema.string(),
  value: zSchema.string(),
  jurisdiction: zSchema.nativeEnum(Jurisdiction),
  effectiveDate: schemes.isoDate().optional(),
});

export const federalTaxParameterSchema = taxParameterBaseSchema.extend({
  jurisdiction: zSchema.literal(Jurisdiction.US),
});

export type TFederalTaxParameterSchema = zSchema.infer<
  typeof federalTaxParameterSchema
>;

export const stateTaxParameterSchema = taxParameterBaseSchema;

export type TStateTaxParameterSchema = zSchema.infer<
  typeof stateTaxParameterSchema
>;

export const setEmployeeTaxParametersRequestSchema = zSchema.object({
  employeeID: schemes.mongoObjectId(),
  federalParameters: zSchema.array(federalTaxParameterSchema),
  stateParameters: zSchema.array(stateTaxParameterSchema),
  effectiveDate: schemes.isoDate().optional(),
});

export type TSetEmployeeTaxParametersRequestSchema = zSchema.infer<
  typeof setEmployeeTaxParametersRequestSchema
>;

export const setEmployeeTaxParametersResponseSchema = zSchema.object({
  employeeID: schemes.mongoObjectId(),
  federalParameters: zSchema.array(
    federalTaxParameterSchema.extend({
      _id: schemes.mongoObjectId(),
    })
  ),
  stateParameters: zSchema.array(
    stateTaxParameterSchema.extend({
      _id: schemes.mongoObjectId(),
    })
  ),
});

export type TSetEmployeeTaxParametersResponseSchema = zSchema.infer<
  typeof setEmployeeTaxParametersResponseSchema
>;
