import dayjs from "dayjs";
import { zSchema } from "../../schema-validation";
import { ECitizenshipStatus } from "../ECitizenshipStatus";
import { EDocumentField } from "../EDocumentField";
import { EDocumentKey } from "../EDocumentKey";

export type TDocumentTypeZodSchemaMap = Partial<
  Record<
    ECitizenshipStatus,
    Partial<Record<EDocumentKey, zSchema.ZodSchema<any, any>>>
  >
>;

/**
 * Zod schema for a nullable expiration date field.
 * Different from the required expiration date field on purpose.
 * Zod refinements don't work as expected with nullish fields if
 * the refinements are run before the nullish() validator.
 */
const nullableExpirationDateZodSchema = zSchema
  .string()
  .nullish()
  .refine(
    (dateString) => {
      if (!dateString) {
        // Skip validation if the dateString is null or undefined
        return true;
      }

      const date = dayjs(dateString, "YYYY-MM-DD", true);

      // Check if the date is in the future
      return date.isAfter(dayjs());
    },
    {
      message: "Expiration date must be in the future.",
    }
  );

export const documentFieldZodSchemaMap: Record<
  EDocumentField,
  zSchema.ZodSchema<any, any>
> = {
  [EDocumentField.US_PASSPORT_NUMBER]: zSchema
    .string({ required_error: "US passport number is required." })
    .regex(/^[A-Za-z0-9]*$/, "Invalid US passport number.")
    .min(6, {
      message: "US passport number must be at least 6 characters long.",
    })
    .max(9, {
      message: "US passport number must be at most 9 characters long.",
    }),
  [EDocumentField.EXPIRATION_DATE]: zSchema.string().refine(
    (dateString) => {
      const date = dayjs(dateString, "YYYY-MM-DD", true);

      // Check if the date is in the future
      return date.isAfter(dayjs());
    },
    {
      message: "Expiration date must be in the future.",
    }
  ),
  [EDocumentField.ISSUING_AUTHORITY]: zSchema
    .string({
      required_error: "Issuing authority is required.",
    })
    .trim()
    .min(1, "Issuing authority is required."),
  [EDocumentField.DOCUMENT_NUMBER]: zSchema.string().optional(),
  [EDocumentField.DOCUMENT_BC_NUMBER]: zSchema.string({
    required_error: "Drivers license / state ID number is required.",
  }),
  [EDocumentField.ALIEN_NUMBER]: zSchema
    .string({
      required_error: "USCIS or A-number is required.",
    })
    .regex(
      /^A\d{8,9}$/,
      'Invalid USCIS/alien number. Must be the letter "A" followed by 8 or 9 digits.'
    ),
  [EDocumentField.FOREIGN_PASSPORT_NUMBER]: zSchema
    .string({
      required_error: "Foreign passport number is required.",
    })
    .max(12, {
      message: "Foreign passport number must be at most 12 characters long.",
    })
    .regex(/^[A-Za-z0-9]{1,12}$/, "Invalid foreign passport number."),
  [EDocumentField.COUNTRY_CODE]: zSchema.string({
    required_error: "Please select a country.",
  }),
  [EDocumentField.I551_NUMBER]: zSchema.string().regex(
    /^[a-zA-Z]{3}(\d{10}|[*]\d{9})$/,
    // Validation message coming straight from the e-verify web services document
    "I551 Number must be exactly 13 characters long beginning with 3 letters."
  ),
  [EDocumentField.I766_NUMBER]: zSchema
    .string({
      required_error: "I766 number is required.",
    })
    .regex(/^[a-zA-Z]{3}(\d{10}|[*]\d{9})$/, "Invalid I766 number.")
    .length(13, { message: "I766 number must be exactly 13 characters long." }),
  [EDocumentField.I94_NUMBER]: zSchema
    .string({
      required_error: "I94 number is required.",
    })
    .regex(
      /^[0-9A-Za-z]{11}$/,
      "I94 number must be exactly 11 characters long."
    ),
  [EDocumentField.US_STATE_CODE]: zSchema.string({
    required_error: "Please select a state.",
  }),
  [EDocumentField.DOCUMENT_SUB_TYPE_CODE]: zSchema.string({
    required_error:
      "Please specify the type of your drivers license / state ID.",
  }),
  [EDocumentField.ALIEN_NUMBER_OR_I94_NUMBER]: zSchema.enum(
    [EDocumentField.ALIEN_NUMBER, EDocumentField.I94_NUMBER],
    {
      required_error: "Either alien number or I94 number is required.",
    }
  ),
  [EDocumentField.ALIEN_NUMBER_OR_I94_NUMBER_OR_FOREIGN_PASSPORT_NUMBER]:
    zSchema.enum(
      [
        EDocumentField.ALIEN_NUMBER,
        EDocumentField.I94_NUMBER,
        EDocumentField.FOREIGN_PASSPORT_NUMBER,
      ],
      {
        required_error:
          "Either alien number, I94 number, or foreign passport number is required.",
      }
    ),
  [EDocumentField.SEVIS_NUMBER]: zSchema
    .string()
    .length(11, { message: "SEVIS number must be exactly 11 characters long." })
    .regex(
      /^N\d{10}$/,
      "SEVIS number must be the letter N followed by 10 numbers."
    )
    .optional(),
  [EDocumentField.VISA_NUMBER]: zSchema
    .string()
    .length(8, { message: "Visa number must be exactly 8 characters long." })
    .regex(/^[A-Za-z0-9]*$/, "Visa number must be alphanumeric.")
    .optional(),
  [EDocumentField.STATUS]: zSchema.nativeEnum(ECitizenshipStatus, {
    required_error: "Choose a citizenship or immigration status.",
  }),
};

const optionalSchema = Object.keys(documentFieldZodSchemaMap).reduce(
  (acc, key) => {
    // Object.keys() creates a list of strings, so we need to explicitly cast each key to the EDocumentField type
    const typedKey = key as EDocumentField;
    acc[typedKey] = documentFieldZodSchemaMap[typedKey].optional();
    return acc;
  },
  {} as typeof documentFieldZodSchemaMap
);

export const allKeysOptionalI9DocumentZodSchema =
  zSchema.object(optionalSchema);

const alienOrNoncitizenAuthorizedToWorkCommonZodSchemaPairs = {
  [EDocumentField.STATUS]: documentFieldZodSchemaMap[EDocumentField.STATUS],
  [EDocumentField.EXPIRATION_DATE]: nullableExpirationDateZodSchema,
};

export const citizenshipStatusToZodSchemaMap = (
  require_i551_number: boolean
): Partial<Record<ECitizenshipStatus, zSchema.ZodSchema<any, any>>> => ({
  [ECitizenshipStatus.LAWFUL_PERMANENT_RESIDENT]: zSchema.object({
    [EDocumentField.ALIEN_NUMBER]:
      documentFieldZodSchemaMap[EDocumentField.ALIEN_NUMBER],
    [EDocumentField.STATUS]: zSchema.nativeEnum(ECitizenshipStatus, {
      required_error: "Choose a citizenship or immigration status.",
    }),
    ...(require_i551_number && {
      [EDocumentField.I551_NUMBER]:
        documentFieldZodSchemaMap[EDocumentField.I551_NUMBER],
    }),
  }),
  [ECitizenshipStatus.NONCITIZEN_AUTHORIZED_TO_WORK]:
    zSchema.discriminatedUnion(
      EDocumentField.ALIEN_NUMBER_OR_I94_NUMBER_OR_FOREIGN_PASSPORT_NUMBER,
      [
        zSchema.object({
          ...alienOrNoncitizenAuthorizedToWorkCommonZodSchemaPairs,
          [EDocumentField.ALIEN_NUMBER_OR_I94_NUMBER_OR_FOREIGN_PASSPORT_NUMBER]:
            zSchema.enum([EDocumentField.ALIEN_NUMBER]),
          [EDocumentField.ALIEN_NUMBER]:
            documentFieldZodSchemaMap[EDocumentField.ALIEN_NUMBER],
          [EDocumentField.I94_NUMBER]:
            documentFieldZodSchemaMap[EDocumentField.I94_NUMBER].nullish(),
          [EDocumentField.FOREIGN_PASSPORT_NUMBER]:
            documentFieldZodSchemaMap[
              EDocumentField.FOREIGN_PASSPORT_NUMBER
            ].nullish(),
          [EDocumentField.COUNTRY_CODE]:
            documentFieldZodSchemaMap[EDocumentField.COUNTRY_CODE].nullish(),
        }),
        zSchema.object({
          ...alienOrNoncitizenAuthorizedToWorkCommonZodSchemaPairs,
          [EDocumentField.ALIEN_NUMBER_OR_I94_NUMBER_OR_FOREIGN_PASSPORT_NUMBER]:
            zSchema.enum([EDocumentField.I94_NUMBER]),
          [EDocumentField.ALIEN_NUMBER]:
            documentFieldZodSchemaMap[EDocumentField.ALIEN_NUMBER].nullish(),
          [EDocumentField.I94_NUMBER]:
            documentFieldZodSchemaMap[EDocumentField.I94_NUMBER],
          [EDocumentField.FOREIGN_PASSPORT_NUMBER]:
            documentFieldZodSchemaMap[
              EDocumentField.FOREIGN_PASSPORT_NUMBER
            ].nullish(),
          [EDocumentField.COUNTRY_CODE]:
            documentFieldZodSchemaMap[EDocumentField.COUNTRY_CODE].nullish(),
        }),
        zSchema.object({
          ...alienOrNoncitizenAuthorizedToWorkCommonZodSchemaPairs,
          [EDocumentField.ALIEN_NUMBER_OR_I94_NUMBER_OR_FOREIGN_PASSPORT_NUMBER]:
            zSchema.enum([EDocumentField.FOREIGN_PASSPORT_NUMBER]),
          [EDocumentField.ALIEN_NUMBER]:
            documentFieldZodSchemaMap[EDocumentField.ALIEN_NUMBER].nullish(),
          [EDocumentField.I94_NUMBER]:
            documentFieldZodSchemaMap[EDocumentField.I94_NUMBER].nullish(),
          [EDocumentField.FOREIGN_PASSPORT_NUMBER]:
            documentFieldZodSchemaMap[EDocumentField.FOREIGN_PASSPORT_NUMBER],
          [EDocumentField.COUNTRY_CODE]:
            documentFieldZodSchemaMap[EDocumentField.COUNTRY_CODE],
        }),
      ]
    ),
});

export const citizenshipStatusIndependentDocumentTypeZodSchemaMap: Partial<
  Record<EDocumentKey, zSchema.ZodObject<any, any>>
> = {
  [EDocumentKey.DRIVERS_LICENSE]: zSchema.object({
    [EDocumentField.DOCUMENT_SUB_TYPE_CODE]:
      documentFieldZodSchemaMap[EDocumentField.DOCUMENT_SUB_TYPE_CODE],
    [EDocumentField.US_STATE_CODE]:
      documentFieldZodSchemaMap[EDocumentField.US_STATE_CODE],
    [EDocumentField.DOCUMENT_BC_NUMBER]:
      documentFieldZodSchemaMap[EDocumentField.DOCUMENT_BC_NUMBER],
  }),
};

type TCitizenshipStatusWithBaseSchema =
  ECitizenshipStatus.NONCITIZEN_AUTHORIZED_TO_WORK;

type TDocumentKeyWithBaseSchema = EDocumentKey.FOREIGN_PASSPORT_WITH_FORM_I94;

const documentTypeBaseZodSchemaMap: Record<
  TCitizenshipStatusWithBaseSchema,
  Partial<
    Record<
      TDocumentKeyWithBaseSchema,
      Partial<Record<EDocumentField, zSchema.ZodSchema<any, any>>>
    >
  >
> = {
  [ECitizenshipStatus.NONCITIZEN_AUTHORIZED_TO_WORK]: {
    [EDocumentKey.FOREIGN_PASSPORT_WITH_FORM_I94]: {
      [EDocumentField.FOREIGN_PASSPORT_NUMBER]:
        documentFieldZodSchemaMap[EDocumentField.FOREIGN_PASSPORT_NUMBER],
      [EDocumentField.EXPIRATION_DATE]:
        documentFieldZodSchemaMap[EDocumentField.EXPIRATION_DATE],
      [EDocumentField.COUNTRY_CODE]:
        documentFieldZodSchemaMap[EDocumentField.COUNTRY_CODE],
      [EDocumentField.SEVIS_NUMBER]:
        documentFieldZodSchemaMap[EDocumentField.SEVIS_NUMBER],
      [EDocumentField.VISA_NUMBER]:
        documentFieldZodSchemaMap[EDocumentField.VISA_NUMBER],
    },
  },
};

const alienI94NumberConditionalSchema = zSchema.discriminatedUnion(
  EDocumentField.ALIEN_NUMBER_OR_I94_NUMBER,
  [
    zSchema.object({
      [EDocumentField.ALIEN_NUMBER_OR_I94_NUMBER]: zSchema.literal(
        EDocumentField.ALIEN_NUMBER
      ),
      [EDocumentField.ALIEN_NUMBER]:
        documentFieldZodSchemaMap[EDocumentField.ALIEN_NUMBER],
      [EDocumentField.I94_NUMBER]:
        documentFieldZodSchemaMap[EDocumentField.I94_NUMBER].nullish(),
    }),
    zSchema.object({
      [EDocumentField.ALIEN_NUMBER_OR_I94_NUMBER]: zSchema.literal(
        EDocumentField.I94_NUMBER
      ),
      [EDocumentField.ALIEN_NUMBER]:
        documentFieldZodSchemaMap[EDocumentField.ALIEN_NUMBER].nullish(),
      [EDocumentField.I94_NUMBER]:
        documentFieldZodSchemaMap[EDocumentField.I94_NUMBER],
    }),
  ]
);

export const documentTypeZodSchemaMap: TDocumentTypeZodSchemaMap = {
  [ECitizenshipStatus.US_CITIZEN]: {
    [EDocumentKey.US_PASSPORT]: zSchema.object({
      [EDocumentField.US_PASSPORT_NUMBER]:
        documentFieldZodSchemaMap[EDocumentField.US_PASSPORT_NUMBER],
      [EDocumentField.EXPIRATION_DATE]:
        documentFieldZodSchemaMap[EDocumentField.EXPIRATION_DATE],
    }),
  },
  [ECitizenshipStatus.NONCITIZEN]: {
    [EDocumentKey.US_PASSPORT]: zSchema.object({
      [EDocumentField.US_PASSPORT_NUMBER]:
        documentFieldZodSchemaMap[EDocumentField.US_PASSPORT_NUMBER],
      [EDocumentField.EXPIRATION_DATE]:
        documentFieldZodSchemaMap[EDocumentField.EXPIRATION_DATE],
    }),
  },
  [ECitizenshipStatus.LAWFUL_PERMANENT_RESIDENT]: {
    [EDocumentKey.FOREIGN_PASSPORT_WITH_I551_STAMP]: zSchema.object({
      [EDocumentField.FOREIGN_PASSPORT_NUMBER]:
        documentFieldZodSchemaMap[EDocumentField.FOREIGN_PASSPORT_NUMBER],
      [EDocumentField.EXPIRATION_DATE]:
        documentFieldZodSchemaMap[EDocumentField.EXPIRATION_DATE],
      [EDocumentField.COUNTRY_CODE]:
        documentFieldZodSchemaMap[EDocumentField.COUNTRY_CODE],
    }),
    [EDocumentKey.FORM_I94_RECEIPT]: zSchema.object({
      [EDocumentField.EXPIRATION_DATE]:
        documentFieldZodSchemaMap[EDocumentField.EXPIRATION_DATE],
    }),
  },
  [ECitizenshipStatus.NONCITIZEN_AUTHORIZED_TO_WORK]: {
    [EDocumentKey.FOREIGN_PASSPORT_WITH_FORM_I94]: zSchema.discriminatedUnion(
      EDocumentField.ALIEN_NUMBER_OR_I94_NUMBER,
      [
        zSchema.object({
          ...documentTypeBaseZodSchemaMap[
            ECitizenshipStatus.NONCITIZEN_AUTHORIZED_TO_WORK
          ][EDocumentKey.FOREIGN_PASSPORT_WITH_FORM_I94],
          [EDocumentField.ALIEN_NUMBER_OR_I94_NUMBER]: zSchema.literal(
            EDocumentField.ALIEN_NUMBER
          ),
          [EDocumentField.ALIEN_NUMBER]:
            documentFieldZodSchemaMap[EDocumentField.ALIEN_NUMBER],
          [EDocumentField.I94_NUMBER]:
            documentFieldZodSchemaMap[EDocumentField.I94_NUMBER].nullish(),
        }),
        zSchema.object({
          ...documentTypeBaseZodSchemaMap[
            ECitizenshipStatus.NONCITIZEN_AUTHORIZED_TO_WORK
          ][EDocumentKey.FOREIGN_PASSPORT_WITH_FORM_I94],
          [EDocumentField.ALIEN_NUMBER_OR_I94_NUMBER]: zSchema.literal(
            EDocumentField.I94_NUMBER
          ),
          [EDocumentField.ALIEN_NUMBER]:
            documentFieldZodSchemaMap[EDocumentField.ALIEN_NUMBER].nullish(),
          [EDocumentField.I94_NUMBER]:
            documentFieldZodSchemaMap[EDocumentField.I94_NUMBER],
        }),
      ]
    ),
    [EDocumentKey.FORM_I94_RECEIPT]: alienI94NumberConditionalSchema,
    [EDocumentKey.FORM_I766]: zSchema.object({
      [EDocumentField.ALIEN_NUMBER]:
        documentFieldZodSchemaMap[EDocumentField.ALIEN_NUMBER],
      [EDocumentField.I766_NUMBER]:
        documentFieldZodSchemaMap[EDocumentField.I766_NUMBER],
      [EDocumentField.EXPIRATION_DATE]:
        documentFieldZodSchemaMap[EDocumentField.EXPIRATION_DATE],
    }),
  },
};
