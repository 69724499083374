import { EKybStatus, schemes, zSchema } from "@zeal/common";

export const updatePartnerRequestSchema = zSchema.object({
  partner_id: schemes.uuid(),
  kyb_status: zSchema.nativeEnum(EKybStatus).optional(),
});

export type TUpdatePartnerRequestSchema = zSchema.infer<
  typeof updatePartnerRequestSchema
>;

export const updatePartnerResponseSchema = zSchema.object({
  partner_id: schemes.uuid(),
  kyb_status: zSchema.nativeEnum(EKybStatus),
});

export type TUpdatePartnerResponseSchema = zSchema.infer<
  typeof updatePartnerResponseSchema
>;
