import { AccountLoginType } from "@zeal/common/dist/accounts";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";

export type SelectAccountResponse = IApiResponse<{
  user: { firstName: string; lastName: string; email: string; id: string };
  accountType: AccountLoginType;
  token: string;
}>;

export const selectAccount = (axios: IAxiosInstance) => {
  return async (options: {
    accountId: string;
    accountType: string;
    partnerId: string | null;
  }) => {
    const response = await axios.noRetry.post<SelectAccountResponse>(
      `/me/selectAccount`,
      options
    );

    const { data, success } = response.data;
    if (!success) {
      throw new Error("Failed to select account");
    }
    return data;
  };
};
