import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { getUsePrimaryUrlParams } from "../../utils/utils";
import { ISchema } from "clients/LaForgeClient/validation-types/SchemaDefinition";

export const testCheckProcessedWebhook = (axios: IAxiosInstance) => {
  return async ({
    checkProcessedWebhook,
    userType,
    test,
    usePrimary,
  }: ISchema["#/definitions/ITestCheckProcessedWebhookArgs"]) => {
    const response = await axios.noRetry.post<
      ISchema["#/definitions/ITestCheckProcessedWebhookResponse"]
    >(`/api/testCheckProcessedWebhook${getUsePrimaryUrlParams(usePrimary)}`, {
      check_processed_webhook: checkProcessedWebhook,
      user_type: userType,
      test,
    });

    const { data: responseData } = response;
    if (!responseData.success) {
      throw new Error(`Something went wrong`);
    }
    return responseData;
  };
};
