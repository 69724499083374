export interface IPaymentDeadlineTaxLiabilities {
  readonly taxType: string;
  readonly paymentDeadlineID: string;
  readonly group?: string[];
  readonly totalGrossWages: number;
  readonly totalReportableWages: number;
  readonly totalWithholdableWages: number;
  readonly totalWithholdingAmount: number;
  readonly wagesStartDate: string;
  readonly wagesEndDate: string;
  readonly quarkCheckIDs: string[];
  readonly notes?: string[];
}

export const isPaymentDeadlineTaxLiability = (
  x: any
): x is IPaymentDeadlineTaxLiabilities =>
  x.paymentDeadlineID !== undefined && typeof x.paymentDeadlineID === "string";
