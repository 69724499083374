import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { TestMode } from "../../@types/general";
import { ISchema } from "../../validation-types/SchemaDefinition";

export interface IGetOnboardingLinkParams {
  readonly workerID: string;
  readonly profileInfo: boolean;
  readonly employeeAcct: boolean;
  readonly idScan: boolean;
  readonly i9: boolean;
  readonly paperwork: boolean;
  readonly paymentMethod: boolean;
  readonly companyID?: string;
  readonly mode: TestMode;
  readonly userType: string;
}

export const getOnboardingLink = (axios: IAxiosInstance) => {
  return async (params: IGetOnboardingLinkParams) => {
    const {
      profileInfo,
      employeeAcct,
      idScan,
      i9,
      paperwork,
      paymentMethod,
      companyID,
      mode,
      workerID,
      userType,
    } = params;
    const response = await axios.noRetry.get<
      ISchema["#/definitions/IGetOnboardingLinkResponse"]
    >(`/api/getOnboardingLink`, {
      params: {
        test: mode === TestMode.Test,
        userType,
        workerID,
        profile_info: profileInfo,
        employee_acct: employeeAcct,
        id_scan: idScan,
        i9: i9,
        paperwork: paperwork,
        payment_method_required: paymentMethod,
        companyID,
      },
    });
    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
