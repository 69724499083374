import { AxiosResponse } from "axios";
import { IAxiosInstance } from "../../AbstractApiClient/AbstractApiClient";
import { IGetPaymentDeadlinesForCompanyIDRequest } from "@zeal/common/dist/bolarus/payment_deadlines";
import { IGetOustandingPayments } from "@zeal/common/dist/bolarus/IGetOustandingPayments";
import { buildResponseBase, IResponseBase } from "@zeal/common";

export const getDeadlines =
  (axios: IAxiosInstance) =>
  async (obj: IGetPaymentDeadlinesForCompanyIDRequest) => {
    const url = "/private/api/compass/fetchDeadlines";

    const { data } = await axios.noRetry.request<
      IGetOustandingPayments,
      AxiosResponse<IResponseBase<IGetOustandingPayments[]>>,
      IGetPaymentDeadlinesForCompanyIDRequest
    >({
      method: "POST",
      url,
      data: obj,
    });

    return buildResponseBase({ status: data.status, data: data.data });
  };
