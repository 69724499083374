import { CUSTOM_ATTRIBUTE_ROUTES } from "@zeal/common";
import { IAxiosInstance } from "../../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../../AbstractApiClient/IApiResponse";

export const queryAttributes = (axios: IAxiosInstance) => {
  return async (data: { companyID: string; type?: string }) => {
    const response = await axios.noRetry.post<IApiResponse<any>>(
      CUSTOM_ATTRIBUTE_ROUTES.QUERY_ATTRIBUTES,
      data
    );

    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
