import { AxiosResponse } from "axios";
import { IAxiosInstance } from "../../AbstractApiClient/AbstractApiClient";
import {
  buildResponseBase,
  IGetTXPDataFromDeadlinesRequest,
  ITXPMetaResponse,
} from "@zeal/common";

export const getTXPMeta =
  (axios: IAxiosInstance) => async (obj: IGetTXPDataFromDeadlinesRequest) => {
    const url = "/private/api/compass/fetchTXPMeta";

    const res = await axios.noRetry.request<
      ITXPMetaResponse[],
      AxiosResponse<ITXPMetaResponse[]>,
      IGetTXPDataFromDeadlinesRequest
    >({
      method: "POST",
      url,
      data: obj,
    });

    return buildResponseBase({ status: res.status, data: res.data });
  };
