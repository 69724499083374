import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TPostEmployeesRequestSchema,
  TPostEmployeesResponseSchema,
} from "./postEmployeesSchema";

export const postEmployees = (axios: IAxiosInstance) => {
  return async (data: TPostEmployeesRequestSchema) => {
    const response = await axios.noRetry.post<
      IApiResponse<TPostEmployeesResponseSchema>
    >(`/employees`, data);

    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
