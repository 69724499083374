import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

export const getEmployeeCheck = (axios: IAxiosInstance) => {
  return async (employeeCheckID: string, test: boolean) => {
    const response = await axios.noRetry.get<
      ISchema["#/definitions/IGetEmployeeCheckResponse"]
    >(`/api/getEmployeeCheck`, {
      params: {
        employeeCheckID,
        test, // TODO dominic 06/02/2023: remove this and use context
      },
    });
    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
