import { ZealApiResponse } from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IPostResolveFinalNonconfirmation } from "./postResolveEVerifyFinalNonconfirmationSchema";

export const postResolveEVerifyFinalNonConfirmation = (
  axios: IAxiosInstance
) => {
  return async (body: IPostResolveFinalNonconfirmation) => {
    const response = await axios.noRetry.post<ZealApiResponse<boolean>>(
      "/api/postResolveEVerifyFinalNonconfirmation",
      body
    );

    return response.data;
  };
};
