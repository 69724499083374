export enum EKYCStatus {
  APPROVED = "Approved",
  DENIED = "Denied",
  EXCEPTION = "Exception",
  MANUAL_REVIEW = "Manual Review",
  STEP_UP = "Step Up Required",
}

export const EKYCStatusList = [
  EKYCStatus.APPROVED,
  EKYCStatus.DENIED,
  EKYCStatus.EXCEPTION,
  EKYCStatus.MANUAL_REVIEW,
  EKYCStatus.STEP_UP,
] as const;

export const isActionRequired = (status: EKYCStatus | null | undefined) =>
  status === EKYCStatus.DENIED ||
  status === EKYCStatus.EXCEPTION ||
  status === EKYCStatus.MANUAL_REVIEW ||
  status === EKYCStatus.STEP_UP;

export const isApproved = (status: EKYCStatus | null | undefined) =>
  !status || status === EKYCStatus.APPROVED;
