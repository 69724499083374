import { JobStatus, zSchema } from "@zeal/common";

export const getJobsRequestSchema = zSchema.object({
  job_ids: zSchema.array(zSchema.string().optional()),
  status: zSchema.nativeEnum(JobStatus).optional(),
});

export type TGetJobsRequestSchema = zSchema.infer<typeof getJobsRequestSchema>;

export type TGetJobsResponseSchema<Req, Res> = {
  job_id: string;
  payload: {
    data: Res;
  };
  request_body: Req;
  status: JobStatus;
};
