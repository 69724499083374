import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

const URL = `reports/custom-payroll-journal`;
export const postCustomPayrollJournal = (axios: IAxiosInstance) => {
  return async (
    requestBody: ISchema["#/definitions/IPostCustomPayrollJournalRequestBody"]
  ) => {
    const response = await axios.noRetry.post<
      ISchema["#/definitions/IPostCustomPayrollJournalResponseBody"]
    >(URL, requestBody);
    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
