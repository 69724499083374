import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IEmployerCheckProcessedLeanWebhookRequestBody } from "./employerCheckProcessedLeanWebhook.jsonschema";

export const employerCheckProcessedLeanWebhook = (axios: IAxiosInstance) => {
  return async (
    checkProcessedLeanWebhook: string,
    userType: string,
    test: boolean
  ) => {
    const webhookBody: IEmployerCheckProcessedLeanWebhookRequestBody = {
      check_processed_lean_webhook: checkProcessedLeanWebhook,
      user_type: userType,
      test,
    };
    const response = await axios.noRetry.post<unknown>(
      `/api/testCheckProcessedLeanWebhook`,
      webhookBody
    );

    const { data } = response;

    return data;
  };
};
