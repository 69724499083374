import { schemes, zSchema } from "@zeal/common";

export const createEmployeeRequestSchema = zSchema.object({
  companyID: schemes.mongoObjectId(),
  residency_locationID: schemes.mongoObjectId().optional(),
  residency: zSchema
    .object({
      street1: zSchema.string(),
      street2: zSchema.string().optional(),
      city: zSchema.string(),
      state: zSchema.string(),
      zip: zSchema.string(),
    })
    .optional(),
  companyLocationID: schemes.mongoObjectId(),
  companyLocation: zSchema.object({}).optional(),
});

export type TCreateEmployeeRequestSchema = zSchema.infer<
  typeof createEmployeeRequestSchema
>;

export const createEmployeeResponseSchema = zSchema.object({
  employeeID: schemes.mongoObjectId(),
  companyLocationID: schemes.mongoObjectId(),
});

export type TCreateEmployeeResponseSchema = zSchema.infer<
  typeof createEmployeeResponseSchema
>;
