import { EDeductionType } from "../EDeductionType";
import { zSchema } from "../../schema-validation";
import { zAbstractDeductionTemplate } from "./zAbstractDeductionTemplate";
import { zCreateAbstractDeductionTemplate } from "./zCreateAbstractDeductionTemplate";

export const z401kDeductionTemplateSchema = zCreateAbstractDeductionTemplate
  .merge(
    zAbstractDeductionTemplate
      .pick({
        employee_contribution_amount: true,
        employee_contribution_percentage: true,
        employer_contribution_amount: true,
        employer_contribution_percentage: true,
      })
      .partial()
  )
  .extend({
    deduction_type: zSchema.literal(EDeductionType["401K"]),
  });

export const z403bSchema = z401kDeductionTemplateSchema.extend({
  deduction_type: zSchema.literal(EDeductionType["403B"]),
});

export const zDependentCareBenefitSchema = zCreateAbstractDeductionTemplate
  .merge(
    zAbstractDeductionTemplate
      .pick({
        employee_contribution_amount: true,
        employee_contribution_percentage: true,
        employer_contribution_amount: true,
      })
      .partial()
  )
  .extend({
    deduction_type: zSchema.literal(EDeductionType.DEPENDENT_CARE_BENEFIT),
  });

export const zEmployerSponsoredHealthCoverage =
  z401kDeductionTemplateSchema.extend({
    deduction_type: zSchema.literal(
      EDeductionType.EMPLOYER_SPONSORED_HEALTH_COVERAGE
    ),
  });

export const zCreateGarnishmentTemplate = zCreateAbstractDeductionTemplate
  .merge(
    zAbstractDeductionTemplate
      .pick({
        agency_address: true,
        agency_name: true,
        case_id: true,
        order_number: true,
      })
      .required()
  )
  .merge(
    zAbstractDeductionTemplate
      .pick({
        employee_contribution_amount: true,
        employee_contribution_percentage: true,
      })
      .partial()
  )
  .extend({
    deduction_type: zSchema.literal(EDeductionType.GARNISHMENT),
  });

export const zHsaDeductionTemplate = zCreateAbstractDeductionTemplate
  .merge(
    zAbstractDeductionTemplate
      .pick({
        employee_contribution_amount: true,
        hsa_type: true,
      })
      .required()
  )
  .merge(
    zAbstractDeductionTemplate
      .pick({ employer_contribution_amount: true })
      .partial()
  )
  .extend({
    deduction_type: zSchema.literal(EDeductionType.HSA),
  });

export const zMiscellaneousSchema = zCreateAbstractDeductionTemplate
  .merge(
    zAbstractDeductionTemplate
      .pick({
        employee_contribution_amount: true,
        employee_contribution_percentage: true,
        employer_contribution_amount: true,
      })
      .partial()
  )
  .extend({
    deduction_type: zSchema.literal(EDeductionType.MISCELLANEOUS),
  });

export const zRoth401kDeductionTemplate = zCreateAbstractDeductionTemplate
  .merge(
    zAbstractDeductionTemplate
      .pick({
        employee_contribution_amount: true,
        employee_contribution_percentage: true,
        employer_contribution_amount: true,
        employer_contribution_percentage: true,
      })
      .partial()
  )
  .extend({
    deduction_type: zSchema.literal(EDeductionType.ROTH_401K),
  });

export const zRothIraDeductionTemplate = zRoth401kDeductionTemplate.extend({
  deduction_type: zSchema.literal(EDeductionType.ROTH_IRA),
});

export const zSection125DeductionTemplate = zCreateAbstractDeductionTemplate
  .merge(
    zAbstractDeductionTemplate
      .pick({
        employee_contribution_amount: true,
      })
      .required()
  )
  .merge(
    zAbstractDeductionTemplate
      .pick({ employer_contribution_amount: true })
      .partial()
  )
  .extend({
    deduction_type: zSchema.literal(EDeductionType.SECTION_125),
  });

export const zSimpleIraSchema = z401kDeductionTemplateSchema.extend({
  deduction_type: zSchema.literal(EDeductionType.SIMPLE_IRA),
});
