import { IUpdateUserInfoData, IUpdateUserInfoParams } from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";

export const updateUserInfo = (axios: IAxiosInstance) => {
  return async ({
    companyID,
    first_name,
    last_name,
    job_title,
    business_name,
    business_ein,
    business_address,
    business_city,
    business_state,
    business_zip,
    business_phone,
    email,
    isMailingAddressDifferent,
    mail_address,
    mail_city,
    mail_state,
    mail_zip,
    legal_structure,
    onboarding_settings,
    ownership_percentage,
    dob,
    address,
    address_line2,
    city,
    state,
    zip,
  }: IUpdateUserInfoParams) => {
    const response = await axios.noRetry.post<IUpdateUserInfoData>(
      `/api/updateUserInfo`,
      {
        companyID,
        first_name,
        last_name,
        job_title,
        business_name,
        business_ein,
        business_address,
        business_city,
        business_state,
        business_zip,
        business_phone,
        email,
        isMailingAddressDifferent,
        mail_address,
        mail_city,
        mail_state,
        mail_zip,
        legal_structure,
        onboarding_settings,
        ownership_percentage: Number(ownership_percentage),
        dob,
        address,
        address_line2,
        city,
        state,
        zip,
      }
    );

    const { data: responseData } = response;
    if (!responseData.success) {
      throw new Error(`Failed to update user info`);
    }
    return responseData.data;
  };
};
