import { z } from "zod";
import { schemes } from "../../schema-validation";
import { EFaxStatus } from "../../uhura";
import { EEmploymentStatus } from "../../workers-shared/EEmploymentStatus";
import { MediaType } from "../enums";
import { customPayrollJournalFieldsSchema } from "./payrollJournal";

export enum Reports {
  CashRequirements = "cash_requirements",
  CustomPayrollJournal = "custom_payroll_journal",
  DeductionsSummary = "deductions_summary",
  EmployeePaperwork = "employee_paperwork",
  FaxSummary = "fax_summary",
  KYCSummary = "kyc_summary",
  LaborAllocation = "labor_allocation",
  NewHireReport = "nhr",
  PaymentSummary = "payment_summary",
  PayrollJournal = "payroll_journal",
  Paystub = "paystub",
  QuarterToDateCheckAccruals = "qtd",
  ReconciliationReport = "reconciliation_report",
  TaxableWage = "taxable_wage",
  WorkerSummary = "worker_summary",
  YearToDateCheckAccruals = "ytd",
  EmployerCheckRecon = "employer_check_recon",
  CustomerAccounts = "customer_accounts",
  ShiftsYtd = "shifts_ytd",
  I9EVerifySummary = "i9_everify_summary",
}

export enum RouteMap {
  cash_requirements = "cash-requirements",
  custom_payroll_journal = "custom-payroll-journal",
  deductions_summary = "deductions-summary",
  employee_paperwork = "employee-paperwork",
  fax_summary = "fax-summary",
  kyc_summary = "kyc-summary",
  labor_allocation = "labor-allocation",
  payment_summary = "payment-summary",
  payroll_journal = "payroll-journal",
  paystub = "paystub",
  qtd = "qtd",
  reconciliation_report = "reconciliation",
  taxable_wage = "taxable-wage",
  worker_summary = "worker-summary",
  ytd = "ytd",
  employer_check_recon = "employer-check-reconciliation",
  customer_accounts = "customer-accounts",
  shifts_ytd = "shifts-ytd",
  i9_everify_summary = "i9-everify-summary",
}

export const ReportName = {
  [Reports.PayrollJournal]: "Payroll Journal",
  [Reports.CashRequirements]: "Cash Requirements",
  [Reports.DeductionsSummary]: "Deductions Summary",
  [Reports.Paystub]: "Paystub",
  [Reports.PaymentSummary]: "Payment Summary",
  [Reports.CustomPayrollJournal]: "Custom Payroll Journal",
  [Reports.EmployeePaperwork]: "Employee Paperwork",
  [Reports.WorkerSummary]: "Worker Summary",
  [Reports.NewHireReport]: "New Hire Report",
  [Reports.I9EVerifySummary]: "I9 E-Verify Summary",
} as const;

export type Report = keyof typeof ReportName;

export const customerReportingSchema = z.object(
  {
    active_workers: z.boolean().optional(),
    agency: z.string().optional(),
    check_date: z.string().optional(),
    companyID: z.string({
      required_error: "companyID is a required field.",
    }),
    customer_account_ids: z.array(z.string()).optional(),
    direct_deposit: z.string().optional(),
    employeeCheckIDs: z.array(z.string()).optional(),
    employeeID: z
      .union([z.array(z.string()).nonempty(), z.string()])
      .optional(),
    employment_status: z.nativeEnum(EEmploymentStatus).optional(),
    end_date: z.string().optional(),
    fax_type: z.string().optional(),
    fields: customPayrollJournalFieldsSchema.optional(),
    has_custom_flag: z.boolean().optional(),
    has_live_key: z.boolean(),
    include_intl_contractors: z.boolean().optional(),
    include_migrated: z.boolean().optional().default(false),
    job_id: z.string({
      required_error: "Job ID is a required field.",
    }),
    kyc_status: z.enum(["all", "approved", "failed"]).optional(),
    media_type: z.nativeEnum(MediaType).optional(),
    report_format: z.string({
      required_error: "report_format is a required field.",
    }),
    pay_start_date: z.string().optional(),
    pay_end_date: z.string().optional(),
    quarter: z.string().optional(),
    start_date: z.string().optional(),
    status: z
      .enum(["processed", "pending", "pre-processed", "failed"])
      .or(
        z.enum([
          EFaxStatus.PENDING,
          EFaxStatus.QUEUED,
          EFaxStatus.SENDING_FAILED,
          EFaxStatus.RECEIVED,
          EFaxStatus.SENT,
        ])
      )
      .optional(),
    worker_type: z.enum(["employee", "contractor"]).optional(),
    year: schemes.numberish().optional(),
  },
  { required_error: "Missing required fields to run report." }
);

export type MakeCustomerReport = z.infer<typeof customerReportingSchema>;
export type CustomerReportingDetails = Omit<
  MakeCustomerReport,
  "report_format"
> & { report: RouteMap; quarkCompanyID?: string };
