import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import { TGetPayCardsResponseSchema } from "../../../OdoClient/routes/getPayCards/getPayCards.schema";
import { TGetContractorPayCardsRequestSchema } from "./getContractorPayCards.schema";

export const getContractorPayCards = (axios: IAxiosInstance) => {
  return async (data: TGetContractorPayCardsRequestSchema) => {
    const { contractor_id, ...rest } = data;

    const response = await axios.noRetry.get<
      IApiResponse<TGetPayCardsResponseSchema>
    >(`/api/contractors/${contractor_id}/pay-cards`, rest);

    return response.data;
  };
};
