import { z } from "zod";

export interface IEmbeddingTokenDataBase {
  user_email: string; // Email of the owner of the API signing key - admin user email.
  integration_email: string; // Email of the user to create a template for. Example: signer@example.com
  external_id: string; // Your application-specific unique string key to identify this template within your app.
}
export interface IEmbeddingTokenDataByTemplateId
  extends IEmbeddingTokenDataBase {
  type: "byTemplateId";
  template_id: number; // ID of the template to open in the form builder.
}
export interface IEmbeddingTokenDataByDocumentUrls
  extends IEmbeddingTokenDataBase {
  type: "byDocumentUrls";
  document_urls: string[]; // An Array of URLs with PDF files to open in the form builder. Example: ['https://www.irs.gov/pub/irs-pdf/fw9.pdf']
  folder_name: string; // The folder name in which the template should be created.
  name: string; // New template name when creating a template with document_urls specified. Example: Integration W-9 Test Form
}
export type TEmbeddingTokenData =
  | IEmbeddingTokenDataByTemplateId
  | IEmbeddingTokenDataByDocumentUrls;

export const IEmbeddingTokenDataByTemplateIdRequestSchema = z.object({
  integration_email: z.string(),
  external_id: z.string(),
  user_email: z.string(),
  template_id: z.string(),
});

export const IEmbeddingTokenDataByDocumentUrlsRequestSchema = z.object({
  integration_email: z.string(),
  external_id: z.string(),
  user_email: z.string(),
  document_urls: z.array(z.string()),
  folder_name: z.string().optional(),
  name: z.string(),
});

export const IEmbeddingTokenDataRequestSchema = z.union([
  IEmbeddingTokenDataByTemplateIdRequestSchema,
  IEmbeddingTokenDataByDocumentUrlsRequestSchema,
]);

export type TEmbeddingTokenDataRequest = z.infer<
  typeof IEmbeddingTokenDataRequestSchema
>;

export const IEmbeddingTokenDataResponseSchema = z.object({
  token: z.string(),
});

export type TEmbeddingTokenDataResponse = z.infer<
  typeof IEmbeddingTokenDataResponseSchema
>;
