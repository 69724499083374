import config from "./config";
import "./uncaughtExceptionHandler";

const attempt_init = async () => {
  let config_from_riker;
  try {
    config_from_riker = await fetch("/config.js");
    config_from_riker = await config_from_riker.json();
  } catch (error) {
    config_from_riker = {
      server_url: "https://api.zeal.com",
    };
  }
  return config_from_riker;
};

const app_init = async () => {
  const config_from_riker = await attempt_init();
  config(config_from_riker);
  await import("./index");
};

app_init();
