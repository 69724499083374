import { AxiosResponse } from "axios";
import {
  buildResponseBase,
  ICreateJobFileRequest,
  IGeneratedFilesPending,
} from "@zeal/common";
import { IAxiosInstance } from "../../AbstractApiClient/AbstractApiClient";

export const createFileJob =
  (axios: IAxiosInstance) => async (obj: ICreateJobFileRequest) => {
    const url = "/private/api/compass/createFileJob";

    const res = await axios.noRetry.request<
      IGeneratedFilesPending,
      AxiosResponse<IGeneratedFilesPending>,
      ICreateJobFileRequest
    >({
      method: "POST",
      url,
      data: obj,
    });

    return buildResponseBase({ status: res.status, data: res.data });
  };
