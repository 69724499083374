import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TCreateBankAccountRequestSchema,
  TCreateBankAccountResponseSchema,
} from "./createBankAccount.schema";

export const createBankAccount = (axios: IAxiosInstance) => {
  return async (body: TCreateBankAccountRequestSchema) => {
    const { odo_user_id, ...rest } = body;

    const response = await axios.noRetry.post<
      IApiResponse<TCreateBankAccountResponseSchema>
    >(`/api/employees/${odo_user_id}/bank-accounts`, rest);

    return response.data;
  };
};
