export const formatSSN = (ssn: string): string => {
  if (!ssn) {
    return ssn;
  }

  const cleaned = ssn.replace(/-/g, "");

  if (cleaned.length <= 3) {
    return cleaned;
  }

  if (cleaned.length > 3 && cleaned.length <= 5) {
    return `${cleaned.substring(0, 3)}-${cleaned.substring(3)}`;
  }

  if (cleaned.length > 5) {
    return `${cleaned.substring(0, 3)}-${cleaned.substring(
      3,
      5
    )}-${cleaned.substring(5)}`;
  }

  return ssn;
};
