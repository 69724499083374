import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

export const setFaviconStatus = (axios: IAxiosInstance) => {
  return async () => {
    const response = await axios.noRetry.post<
      ISchema["#/definitions/ISetFaviconStatusResponse"]
    >(`/api/setFaviconStatus`, {
      params: {
        usePrimary: true,
      },
    });

    if (response.data?.success) {
      return response.data;
    }

    throw new Error("Something went wrong");
  };
};
