import { II9OnboardingInfo, ZealApiResponse } from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";

export const getI9OnboardingInfo = (axios: IAxiosInstance) => {
  return async ({
    workerID,
    includeDocumentPhotos,
  }: {
    workerID?: string;
    includeDocumentPhotos?: boolean;
  } = {}) => {
    const response = await axios.noRetry.get<
      ZealApiResponse<II9OnboardingInfo>
    >(`/api/getI9OnboardingInfo`, {
      params: { workerID, includeDocumentPhotos },
    });
    const resData = response.data;
    if (resData.success) {
      return resData.data;
    }
    throw new Error("Something went wrong");
  };
};
