import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IJobWebhookRequestBody } from "./jobWebhook.jsonschema";

export const jobWebhook = (axios: IAxiosInstance) => {
  return async (jobWebhook: string, userType: string, test: boolean) => {
    const webhookBody: IJobWebhookRequestBody = {
      job_webhook: jobWebhook,
      user_type: userType,
      test,
    };
    const response = await axios.noRetry.post<unknown>(
      `/api/testReportWebhook`,
      webhookBody
    );

    const { data } = response;
    return data;
  };
};
