import { EEVerifyCaseClosureReasonsAfterFailure, schemes } from "@zeal/common";
import { z } from "zod";

export const postResolveFinalNonConfirmationSchema = z.discriminatedUnion(
  "case_closure_reason_code",
  [
    z.object({
      case_closure_reason_code: z.literal(
        EEVerifyCaseClosureReasonsAfterFailure.EMPLOYEE_CONTINUES_TO_WORK_AFTER_FNC
      ),
      currently_employed_reason_description: z.string().max(140),
      workerID: schemes.mongoObjectId(),
      testMode: z.boolean(),
    }),
    z.object({
      case_closure_reason_code: z.literal(
        EEVerifyCaseClosureReasonsAfterFailure.EMPLOYEE_TERMINATED_FOR_FNC_RESULT
      ),
      workerID: schemes.mongoObjectId(),
      testMode: z.boolean(),
    }),
    z.object({
      case_closure_reason_code: z.literal(
        EEVerifyCaseClosureReasonsAfterFailure.INCORRECT_DATA
      ),
      workerID: schemes.mongoObjectId(),
      testMode: z.boolean(),
    }),
    z.object({
      case_closure_reason_code: z.literal(
        EEVerifyCaseClosureReasonsAfterFailure.EMPLOYER_INSTRUCTED_TO_CLOSE_BY_DHS
      ),
      workerID: schemes.mongoObjectId(),
      testMode: z.boolean(),
    }),
    z.object({
      case_closure_reason_code: z.literal(
        EEVerifyCaseClosureReasonsAfterFailure.EMPLOYER_INSTRUCTED_TO_CLOSE_BY_SSA
      ),
      workerID: schemes.mongoObjectId(),
      testMode: z.boolean(),
    }),
    z.object({
      case_closure_reason_code: z.literal(
        EEVerifyCaseClosureReasonsAfterFailure.OTHER
      ),
      other_reason_description: z.string(),
      workerID: schemes.mongoObjectId(),
      testMode: z.boolean(),
    }),
  ]
);

export type TPostResolveFinalNonConfirmation = z.infer<
  typeof postResolveFinalNonConfirmationSchema
>;
