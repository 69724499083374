import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import { TCustomerAccountWithFundingSource } from "../getCustomerAccount/getCustomerAccountSchema";
import { TGetCustomerAccountsRequestSchema } from "./getCustomerAccountsSchema";

export const getCustomerAccounts = (axios: IAxiosInstance) => {
  return async (params: TGetCustomerAccountsRequestSchema) => {
    const response = await axios.noRetry.get<
      IApiResponse<TCustomerAccountWithFundingSource[]>
    >("/customer-accounts", { params });
    return response.data;
  };
};
