import {
  createEmployeeSchema,
  EEmploymentStatus,
  EPaySchedule,
  schemes,
  zSchema,
} from "@zeal/common";

export type TEmployeeCreateSchema = zSchema.infer<typeof createEmployeeSchema>;

export const postEmployeesRequestSchema = zSchema.object({
  companyID: schemes.companyID(),
  new_employees: zSchema.array(createEmployeeSchema),
});

export type TPostEmployeesRequestSchema = zSchema.infer<
  typeof postEmployeesRequestSchema
>;

export const postEmployeesResponseSchema = zSchema.array(
  zSchema.object({
    address: zSchema.string(),
    address_line2: zSchema.string().nullable(),
    employeeID: schemes.mongoObjectId(),
    city: zSchema.string(),
    companyID: schemes.companyID(),
    default_dt_wage: zSchema.number().nullable(),
    default_pay_schedule: zSchema.nativeEnum(EPaySchedule),
    default_ot_wage: zSchema.number().nullable(),
    default_wage: zSchema.number().nullable(),
    dob: schemes.isoDate().nullable(),
    email: schemes.email(),
    employment_status: zSchema.nativeEnum(EEmploymentStatus),
    first_name: zSchema.string(),
    is_943: zSchema.boolean(),
    is_scheduleH: zSchema.boolean(),
    last_name: zSchema.string(),
    metadata: schemes.metadata(),
    middle_initial: zSchema.string().nullable(),
    onboarded: zSchema.boolean(),
    phone_number: zSchema.string().nullable(),
    start_date: schemes.isoDate().nullable(),
    state: zSchema.string(),
    term_date: schemes.isoDate().nullable(),
    title: zSchema.string(),
    work_week_start: zSchema.string().datetime().nullable(),
    workLocationID: schemes.mongoObjectId(),
    zip: zSchema.string(),
    salary: zSchema.number().nullable(),
    is_salary: zSchema.boolean().nullable(),
    is_regular: zSchema.boolean().nullable(),
  })
);

export type TPostEmployeesResponseSchema = zSchema.infer<
  typeof postEmployeesResponseSchema
>;
