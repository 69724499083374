import { IEmployerCheck } from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";

export const getEmployerCheckById = (axios: IAxiosInstance) => {
  return async (id: string) => {
    const response = await axios.noRetry.get<IApiResponse<IEmployerCheck>>(
      `/api/employerCheck/${id}`
    );
    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
