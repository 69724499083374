import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IEmployerCheckProcessedWebhookRequestBody } from "./employerCheckProcessedWebhook.jsonschema";

export const employerCheckProcessedWebhook = (axios: IAxiosInstance) => {
  return async (
    checkProcessedWebhook: string,
    userType: string,
    test: boolean
  ) => {
    const webhookBody: IEmployerCheckProcessedWebhookRequestBody = {
      check_processed_webhook: checkProcessedWebhook,
      user_type: userType,
      test,
    };
    const response = await axios.noRetry.post<unknown>(
      `/api/testCheckProcessedWebhook`,
      webhookBody
    );

    const { data } = response;

    return data;
  };
};
