import { ReadStream } from "fs";
import { EMediaType } from "@zeal/common/dist/forms";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

export const postFormRenderPDF = (axios: IAxiosInstance) => {
  return async (
    requestBody: ISchema["#/definitions/TPostFormRenderBodyPDF"],
    responseType?: "blob" | "stream"
  ) => {
    switch (responseType) {
      case "stream": {
        return (
          await axios.noRetry.request<ReadStream>({
            method: "POST",
            url: "/forms/render",
            data: {
              ...requestBody,
              media_type: EMediaType.PDF,
            },
            responseType: "stream",
          })
        ).data;
      }
      case "blob":
      default: {
        return (
          await axios.noRetry.request<Blob>({
            method: "POST",
            url: "/forms/render",
            data: {
              ...requestBody,
              media_type: EMediaType.PDF,
            },
            responseType: "blob",
          })
        ).data;
      }
    }
  };
};
