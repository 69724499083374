import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { getUsePrimaryUrlParams } from "../../utils/utils";
import { ISchema } from "clients/LaForgeClient/validation-types/SchemaDefinition";

export const updateEmployeeWebhook = (axios: IAxiosInstance) => {
  return async ({
    employeeWebhook,
    userType,
    usePrimary,
    isTestWebhookType,
  }: ISchema["#/definitions/IUpdateEmployeeWebhookArgs"]) => {
    const response = await axios.noRetry.post<
      ISchema["#/definitions/IUpdateEmployeeWebhookResponse"]
    >(`/api/updateEmployeeWebhook${getUsePrimaryUrlParams(usePrimary)}`, {
      employee_webhook: employeeWebhook,
      user_type: userType,
      is_test_webhook_type: isTestWebhookType,
    });

    const { data: responseData } = response;
    if (!responseData.success) {
      throw new Error(`Something went wrong`);
    }
    return responseData;
  };
};
