import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import {
  TPreProcessChecksRequestSchema,
  TPreProcessChecksResponseSchema,
} from "./preProcessChecks.schema";

export const preProcessChecks = (axios: IAxiosInstance) => {
  return async (body: TPreProcessChecksRequestSchema) => {
    const response = await axios.noRetry.post<TPreProcessChecksResponseSchema>(
      `/api/picard/preprocess`,
      body
    );

    return response.data;
  };
};
