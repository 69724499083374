import { z } from "zod";

import { employeeSchema } from "./baseEmployee";

export const createEmployeeSchema = employeeSchema
  .pick({
    email: true,
    benefits_class: true,
    default_pay_schedule: true,
    doubletime_rate: true,
    external_id: true,
    first_name: true,
    is_943: true,
    is_scheduleH: true,
    last_name: true,
    metadata: true,
    overtime_rate: true,
    phone_number: true,
    ssn: true,
    title: true,
    workLocationID: true,
    is_salary: true,
    is_regular: true,
    task_definition_ids: true,
    employment_status: true,
    address: true,
    address_line2: true,
    city: true,
    state: true,
    zip: true,
    dob: true,
    term_date: true,
    salary_firstDate: true,
    start_date: true,
    work_week_start: true,
    default_wage: true,
    default_ot_wage: true,
    default_dt_wage: true,
    salary: true,
  })
  .partial();

export type CreateEmployee = z.infer<typeof createEmployeeSchema>;
