import { schemes, zSchema } from "@zeal/common";

export const getPayCardTransactionsRequestSchema = zSchema.object({
  pay_card_id: schemes.uuid(),
});

export type TGetPayCardTransactionsRequestSchema = zSchema.infer<
  typeof getPayCardTransactionsRequestSchema
>;

export const getPayCardTransactionsResponseSchema = zSchema.array(
  zSchema.object({
    amount: zSchema.string(),
    date: zSchema.string(),
    description: zSchema.string(),
    external_id: zSchema.string(),
    status: zSchema.enum(["pending", "settled"]),
    type: zSchema.enum(["payment", "refund", "withdrawal"]),
  })
);

export type TGetPayCardTransactionsResponseSchema = zSchema.infer<
  typeof getPayCardTransactionsResponseSchema
>;
