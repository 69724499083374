import { IEmployeeCheck } from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { TGetPendingChecks } from "./getPendingChecksSchema";

export const getPendingChecks = (axios: IAxiosInstance) => {
  return async (query?: TGetPendingChecks) => {
    const response = await axios.noRetry.get<IEmployeeCheck[]>(
      "/api/getPendingChecksDetails",
      {
        params: { ...query },
        withCredentials: true,
      }
    );

    if (response.status === 200) {
      return response.data;
    }

    throw new Error("Something went wrong");
  };
};
