import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";
import { ICreateContractorRequest } from "./createContractor.jsonschema";

export const createContractor = (axios: IAxiosInstance) => {
  return async (formData: ICreateContractorRequest) => {
    const response = await axios.noRetry.post<
      ISchema["#/definitions/ICreateContractorResponse"]
    >("/api/uploadOneContractor", formData);
    const resData = response.data;
    if (resData.success) {
      return resData.success;
    }
    throw new Error("Something went wrong");
  };
};
