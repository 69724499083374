import {
  TGetMyRecentlyOnboardedWorkersQuery,
  TGetMyRecentlyOnboardedWorkersResponseData,
} from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";

export const getMyRecentlyOnboardedWorkers = (axios: IAxiosInstance) => {
  return async (options: TGetMyRecentlyOnboardedWorkersQuery) => {
    const { query } = options;
    const response = await axios.noRetry.get<
      IApiResponse<TGetMyRecentlyOnboardedWorkersResponseData>
    >("/api/workers/recently-onboarded", {
      params: { ...query },
    });
    return response.data;
  };
};
