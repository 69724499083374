import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { getUsePrimaryUrlParams } from "../../utils/utils";
import { ISchema } from "clients/LaForgeClient/validation-types/SchemaDefinition";

export const rollKey = (axios: IAxiosInstance) => {
  return async ({
    keyType,
    userType,
    usePrimary,
  }: ISchema["#/definitions/IRollKeyArgs"]) => {
    const uri = `/api/rollKey${getUsePrimaryUrlParams(usePrimary)}`;
    const response = await axios.noRetry.post<
      ISchema["#/definitions/IRollKeyResponse"]
    >(uri, {
      data: {
        key_type: keyType,
        user_type: userType,
      },
    });

    const { data: responseData } = response;
    if (!responseData.success) {
      throw new Error(`Something went wrong`);
    }
    return responseData;
  };
};
