import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TUpdateBankAccountRequestSchema,
  TUpdateBankAccountResponseSchema,
} from "./updateBankAccount.schema";

export const updateBankAccount = (axios: IAxiosInstance) => {
  return async (body: TUpdateBankAccountRequestSchema) => {
    const { bank_account_id, ...rest } = body;
    try {
      const response = await axios.noRetry.patch<
        IApiResponse<TUpdateBankAccountResponseSchema>
      >(`/api/bank-accounts/${bank_account_id}`, rest);

      const { data } = response.data;

      return data;
    } catch (error: any) {
      throw new Error(
        (error?.response.data.errors &&
          error.response.data.errors[0].message) ||
          "Error updating bank information. Please contact support."
      );
    }
  };
};
