import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import {
  TMigrateEmployeeToCompanyResponse,
  TMigrateEmployeeToCompanyRequest,
} from "./migrateEmployeesToNewCompany.jsonschema";

export const postMigrateEmployeesToNewCompany = (axios: IAxiosInstance) => {
  return async (
    body: TMigrateEmployeeToCompanyRequest
  ): Promise<TMigrateEmployeeToCompanyResponse> => {
    const response =
      await axios.with502Retry.post<TMigrateEmployeeToCompanyResponse>(
        `/api/internal-tools/migrateEmployeeToCompany`,
        body
      );
    return response.data;
  };
};
