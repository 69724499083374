import { z } from "zod";

import { bankAccountSchema } from "../bank-accounts";

import { employeeSchema } from "../employees/schemas/baseEmployee";
import { schemes } from "../schema-validation";
import { userAccountSchema } from "../users";
import { baseEventInput } from "./baseEvent";

export const employeeEvent = baseEventInput.merge(
  z.object({
    source: z.literal("la-forge.workers"),
    employeeId: schemes.mongoObjectId(),
    email: schemes.email(),
    companyId: schemes.uuid(),
    taskDefinitionIds: z.array(schemes.uuid()),
    payload: z.discriminatedUnion("category", [
      z
        .object({ category: z.literal("profileInformation") })
        .merge(employeeSchema.partial()),
      z.object({ category: z.literal("userAccount") }).merge(userAccountSchema),
      z
        .object({ category: z.literal("bankAccount") })
        .merge(bankAccountSchema.partial()),
    ]),
  })
);

export type EmployeeEvent = z.infer<typeof employeeEvent>;
