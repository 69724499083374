import { AxiosResponse } from "axios";
import { IAxiosInstance } from "../../AbstractApiClient/AbstractApiClient";
import {
  IGetOustandingDeadlinesSummaryRequest,
  IGetOustandingDeadlinesSummaryResponse,
} from "@zeal/common/dist/bolarus/payment_deadlines";
import { buildResponseBase, IResponseBase } from "@zeal/common";

export const getSummary =
  (axios: IAxiosInstance) =>
  async (obj: IGetOustandingDeadlinesSummaryRequest) => {
    const url = "/private/api/compass/fetchSummary";

    const { data } = await axios.noRetry.request<
      IGetOustandingDeadlinesSummaryResponse,
      AxiosResponse<IResponseBase<IGetOustandingDeadlinesSummaryResponse[]>>,
      IGetOustandingDeadlinesSummaryRequest
    >({
      method: "POST",
      url,
      data: obj,
    });

    return buildResponseBase({ status: data.status, data: data.data });
  };
