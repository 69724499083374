import { UpdateGroupOperatorRequest, RULE_SET_ROUTES } from "@zeal/common";
import { IAxiosInstance } from "../../../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../../../AbstractApiClient/IApiResponse";

export const updateGroupOperator = (axios: IAxiosInstance) => {
  return async (data: UpdateGroupOperatorRequest) => {
    const response = await axios.noRetry.patch<IApiResponse<any>>(
      `${RULE_SET_ROUTES.UPDATE_GROUP_OPERATOR.replace(
        ":companyID",
        data.companyID
      )
        .replace(":ruleSetID", data.ruleSetID)
        .replace(":groupID", data.groupID)}`,
      data
    );

    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
