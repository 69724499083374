import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

export const setLogoStatus = (axios: IAxiosInstance) => {
  return async () => {
    await axios.noRetry.post<ISchema["#/definitions/ISetLogoStatusResponse"]>(
      `/api/setLogoStatus`,
      {
        params: { usePrimary: true },
      }
    );
  };
};
