import { AxiosError } from "axios";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import {
  ICompanyAddAchRequestBody,
  ICompanyAddAchResponseBody,
} from "./companyAddAch.jsonschema";

export const companyAddAch =
  (axios: IAxiosInstance) => async (body: ICompanyAddAchRequestBody) => {
    try {
      const response = await axios.noRetry.post<ICompanyAddAchResponseBody>(
        `/api/company/add-ach`,
        body
      );
      return response;
    } catch (e) {
      const errorMessage =
        (e as AxiosError)?.response?.data ??
        "Error adding bank account to company";
      throw errorMessage;
    }
  };
