import { EEVerifyCaseClosureReasonsAfterFailure } from "@zeal/common";
import { z } from "zod";

export const postResolveEVerifyFinalNonConfirmationSchema =
  z.discriminatedUnion("case_closure_reason_code", [
    z.object({
      case_closure_reason_code: z.literal(
        EEVerifyCaseClosureReasonsAfterFailure.EMPLOYEE_CONTINUES_TO_WORK_AFTER_FNC
      ),
      currently_employed_reason_description: z.string().max(140),
      workerID: z.string(),
    }),
    z.object({
      case_closure_reason_code: z.literal(
        EEVerifyCaseClosureReasonsAfterFailure.EMPLOYEE_TERMINATED_FOR_FNC_RESULT
      ),
      workerID: z.string(),
    }),
    z.object({
      case_closure_reason_code: z.literal(
        EEVerifyCaseClosureReasonsAfterFailure.INCORRECT_DATA
      ),
      workerID: z.string(),
    }),
    z.object({
      case_closure_reason_code: z.literal(
        EEVerifyCaseClosureReasonsAfterFailure.EMPLOYER_INSTRUCTED_TO_CLOSE_BY_DHS
      ),
      workerID: z.string(),
    }),
    z.object({
      case_closure_reason_code: z.literal(
        EEVerifyCaseClosureReasonsAfterFailure.EMPLOYER_INSTRUCTED_TO_CLOSE_BY_SSA
      ),
      workerID: z.string(),
    }),
    z.object({
      case_closure_reason_code: z.literal(
        EEVerifyCaseClosureReasonsAfterFailure.OTHER
      ),
      other_reason_description: z.string(),
      workerID: z.string(),
    }),
  ]);

export type TPostResolveEVerifyFinalNonConfirmation = z.infer<
  typeof postResolveEVerifyFinalNonConfirmationSchema
>;

export interface IPostResolveFinalNonconfirmation {
  case_closure_reason_code: EEVerifyCaseClosureReasonsAfterFailure;
  currently_employed_reason_description?: string;
  other_reason_description?: string;
  workerID: string;
}
