import { EPermissionActions, EPermissionResources } from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";

export const getPermissions = (axios: IAxiosInstance) => {
  return async () => {
    const response = await axios.noRetry.get<
      IApiResponse<Record<EPermissionResources, EPermissionActions>>
    >(`/api/permissions`, {
      withCredentials: true,
    });
    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
