export interface IPaginatedResult<T extends Array<any>> {
  readonly data: T;
  readonly nextCursor: string | null;
  readonly previousCursor: string | null;
}

export class PaginationResult<T extends Array<any>>
  implements IPaginatedResult<T>
{
  private readonly _data: IPaginatedResult<T>;

  constructor(data: IPaginatedResult<T>) {
    this._data = data;
  }

  get data(): T {
    return this._data.data;
  }

  get nextCursor(): string | null {
    return this._data.nextCursor;
  }

  get previousCursor(): string | null {
    return this._data.previousCursor;
  }

  public map<NewData>(
    map: (data: T[number]) => NewData
  ): PaginationResult<NewData[]> {
    return new PaginationResult({
      data: this.data.map(map),
      nextCursor: this.nextCursor,
      previousCursor: this.previousCursor,
    });
  }
}
