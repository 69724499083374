import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

export const deletePayrollRun = (axios: IAxiosInstance) => {
  return async ({
    payrollRunID,
    companyID,
  }: ISchema["#/definitions/IDeletePayrollRunRequestBody"]) => {
    const response = await axios.noRetry.post<
      ISchema["#/definitions/IDeletePayrollRunResponse"]
    >(`api/deletePayrollRun`, {
      companyID,
      payrollRunID,
    });
    const resData = response.data;

    if (resData.success) {
      return resData;
    }

    throw new Error("Something went wrong");
  };
};
