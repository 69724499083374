import { zSchema } from "../schema-validation";

export type BenefitsClass = zSchema.infer<typeof benefitsClassSchema>;

export const EBenefitsClass = {
  EXECUTIVE: "Executive",
  FULL_TIME: "Full-Time",
  INELIGIBLE: "Ineligible",
  PART_TIME: "Part Time",
} as const;

export const benefitsClassSchema = zSchema.nativeEnum(EBenefitsClass);
