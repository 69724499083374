import { z } from "zod";
import { MediaType } from "../enums";
import { RouteMap } from "./customerReporting";

export const formattedDeductionSummaryItem = z.object({
  check_id: z.string(),
  deduction_name: z.string(),
  deduction_type: z.string(),
  ee_contribution: z.number(),
  employer_check_id: z.string(),
  er_contribution: z.number(),
  first_name: z.string(),
  last_name: z.string(),
  pay_date: z.string(),
  pay_period_gross_pay: z.number(),
  pay_period_hours_worked: z.number(),
  customer_account_id: z.string().optional(),
  customer_account_name: z.string().optional(),
});

export type FormattedDeductionSummaryItem = z.infer<
  typeof formattedDeductionSummaryItem
>;

export type DeductionsSummaryFields = z.infer<
  typeof deductionsSummaryFieldsSchema
>;

export type DeductionsSummaryCsvResponseShape = {
  fields: DeductionsSummaryFields;
  kind: RouteMap.deductions_summary;
  deductions: FormattedDeductionSummaryItem[];
};

export const deductionsSummaryFieldsSchema = z.object({
  customer_account: z.boolean().optional(),
});

export const deductionsSummaryRequestHandlerSchema = z.object({
  companyID: z.string(),
  end_date: z.string().optional(),
  fields: deductionsSummaryFieldsSchema.optional(),
  include_migrated: z.boolean(),
  media_type: z.enum([MediaType.Csv]),
  paystub_ids: z.array(z.string()),
  start_date: z.string().optional(),
});

export const deductionsSummaryParamsSchema = z.object({
  companyID: z.string(),
  customer_account_ids: z.array(z.string()).optional(),
  end_date: z.string().optional(),
  fields: deductionsSummaryFieldsSchema.optional(),
  include_migrated: z.boolean(),
  paystub_ids: z.array(z.string()),
  start_date: z.string().optional(),
});

export type DeductionsSummaryParams = z.infer<
  typeof deductionsSummaryParamsSchema
>;
