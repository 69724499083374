import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

export const getWLHostedConfig = (axios: IAxiosInstance) => {
  return async () => {
    const response = await axios.noRetry.get<
      ISchema["#/definitions/IGetHostedConfig"]
    >(`/api/getWLHostedConfig`);
    const data = response.data.config;
    return data;
  };
};
