import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

export interface IGetReportingPeriodsByFrequencyParams {
  readonly pay_schedule: string;
}

export const getReportingPeriodsByFrequency = (axios: IAxiosInstance) => {
  return async (params: IGetReportingPeriodsByFrequencyParams) => {
    const { pay_schedule } = params;
    const response = await axios.noRetry.get<
      ISchema["#/definitions/IGetReportingPeriodsByFrequencyResponse"]
    >(`/api/getReportingPeriodsByFrequency`, {
      params: {
        pay_schedule,
      },
    });
    const data = response.data;

    if (data.success) {
      return data;
    }

    throw new Error("Something went wrong");
  };
};
