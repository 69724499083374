import { DeleteRuleSetRequest, RULE_SET_ROUTES } from "@zeal/common";
import { IAxiosInstance } from "../../../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../../../AbstractApiClient/IApiResponse";

export const deleteRuleSet = (axios: IAxiosInstance) => {
  return async (data: DeleteRuleSetRequest) => {
    const response = await axios.noRetry.delete<IApiResponse<any>>(
      `${RULE_SET_ROUTES.DELETE_RULE_SET.replace(
        ":companyID",
        data.companyID
      ).replace(":ruleSetID", data.ruleSetID)}`,
      { params: { companyID: data.companyID } }
    );

    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
