import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { getUsePrimaryUrlParams } from "../../utils/utils";
import { ISchema } from "clients/LaForgeClient/validation-types/SchemaDefinition";

export const testShiftWebhook = (axios: IAxiosInstance) => {
  return async ({
    shiftWebhook,
    userType,
    test,
    usePrimary,
  }: ISchema["#/definitions/ITestShiftWebhookArgs"]) => {
    const response = await axios.noRetry.post<
      ISchema["#/definitions/ITestShiftWebhookResponse"]
    >(`/api/testShiftWebhook${getUsePrimaryUrlParams(usePrimary)}`, {
      shift_webhook: shiftWebhook,
      user_type: userType,
      test,
    });

    const { data: responseData } = response;
    if (!responseData.success) {
      throw new Error(`Something went wrong`);
    }
    return responseData;
  };
};
