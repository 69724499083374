import { z } from "zod";
import { Jurisdiction } from "../../jurisdictions/Jurisdiction";
import { schemes } from "../../schema-validation";
import { RouteMap } from "./customerReporting";

const laborAllocationTaxableWageSchema = z.object({
  reportable_wages: z.string().optional(),
  withholdable_wages: z.string().optional(),
});

export type LaborAllocationTaxableWage = z.infer<
  typeof laborAllocationTaxableWageSchema
>;

const laborAllocationTaxTypeRecordSchema = z.record(
  laborAllocationTaxableWageSchema
);
const laborAllocationTransactionRecordSchema = z.record(
  laborAllocationTaxTypeRecordSchema
);

export const laborAllocationEmployeeRecordSchema = z.record(
  laborAllocationTransactionRecordSchema
);

export type LaborAllocationEmployeeTaxableWageRecord = z.infer<
  typeof laborAllocationEmployeeRecordSchema
>;

export const laborAllocationFieldSchema = z
  .object({
    external_id: z.boolean().optional(),
    employer_check_id: z.boolean().optional(),
    workLocationID: z.boolean().optional(),
    resident_state: z.boolean().optional(),
    wcc_code: z.boolean().optional(),
    disbursement_status: z.boolean().optional(),
    hours: z.boolean().optional(),
    wages: z.boolean().optional(),
    employer_name: z.boolean().optional(),
    employer_address: z.boolean().optional(),
    custom_name: z.boolean().optional(),
    customer_account: z.boolean().optional(),
    customer_account_id: z.boolean().optional(),
    customer_account_business_name: z.boolean().optional(),
    customer_account_code: z.boolean().optional(),
  })
  .strict();

export type LaborAllocationFields = z.infer<typeof laborAllocationFieldSchema>;

export const laborAllocationInputSchema = z.object({
  companyID: z.string(),
  include_migrated: z.boolean(),
  paystub_ids: z.array(z.string()),
});

export type CreateLaborAllocationInput = {
  companyID: string;
  include_migrated: boolean;
  paystub_ids: string[];
};

export type LaborAllocationShapeInput = CreateLaborAllocationInput & {
  has_live_key: boolean;
};

const laborAllocationTaxSchema = z.array(
  z.object({
    name: z.string(),
    amount: z.string(),
    reportable_wages: z.string().optional(),
    withholdable_wages: z.string().optional(),
  })
);

export type LaborAllocationTax = z.infer<typeof laborAllocationTaxSchema>;

const laborAllocationShiftSchema = z.object({
  custom_name: z.string(),
  name: z.string(),
  amount: z.string(),
  wcc_code: z.string(),
  workLocationID: z.string(),
  workLocation: z.string(),
  hours: z.string(),
});

export type LaborAllocationShift = z.infer<typeof laborAllocationShiftSchema>;

export const laborAllocationResponseSchema = z
  .object({
    employer_check_id: z.string(),
    employee_check_id: z.string(),
    employee_id: z.string(),
    external_id: z.string().optional(),
    resident_state: z.nativeEnum(Jurisdiction),
    employee_name: z.string(),
    net_pay: z.string(),
    gross_pay: z.string(),
    total_shift_hours: z.string(),
    check_date: z.string(),
    employer_name: z.string(),
    employer_address: z.string(),
    customer_account_id: schemes.uuid().nullish().optional(),
    customer_account_business_name: z.string().optional(),
    customer_account_code: z.string().optional(),
    disbursement_status: z.enum([
      "pending",
      "settled",
      "canceled",
      "returned",
      "net_pay_void",
      "refunded",
    ]),
    deductions: z.array(
      z.object({
        name: z.string(),
        amount: z.string(),
      })
    ),
    shifts: z.array(laborAllocationShiftSchema),
    employee_taxes: laborAllocationTaxSchema,
    employer_taxes: laborAllocationTaxSchema,
  })
  .partial(); // TODO: remove once la-forge is strict-mode enabled

export type LaborAllocationResponse = z.infer<
  typeof laborAllocationResponseSchema
>;

export type LaborAllocationFilterResponse = {
  kind: RouteMap.labor_allocation;
  filter: boolean;
  fields: LaborAllocationFields | null;
  checks: LaborAllocationResponse[];
};
