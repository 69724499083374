import {
  TLFClientVerifyRoutingNumber,
  TVerifyRoutingNumberResponse,
} from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";

export const verifyRoutingNumber = (axios: IAxiosInstance) => {
  return async ({ routing_number }: TLFClientVerifyRoutingNumber) => {
    const response = await axios.noRetry.get<
      IApiResponse<TVerifyRoutingNumberResponse>
    >(`/api/odo/bank/verify`, {
      params: { routing_number },
    });
    const data = response.data;

    if (data.success) {
      return data?.data;
    }

    throw new Error("Something went wrong");
  };
};
