import { EPaymentCreationType, schemes, zSchema } from "@zeal/common";

// @TODO(todd): Create better schemas here instead of all inheriting from base.

export const destinationsAndAmountsSchema = zSchema.object({
  net_amount: zSchema.number(),
  paystub_id: schemes.uuid().or(schemes.mongoObjectId()),
  to_destination_id: schemes.uuid().optional(),
  to_user_id: zSchema.string(),
  zeal_id: zSchema.string().optional(),
});

export const baseTransactionRequestSchema = zSchema.object({
  addenda: zSchema.string().or(zSchema.array(zSchema.string())).optional(),
  amount: zSchema.number().optional(),
  batch_number: zSchema.number().optional(),
  check_id: zSchema.string().optional(),
  company_id: schemes.uuid().optional(),
  company_name: zSchema.string().optional(),
  destinations_and_amounts: zSchema
    .array(destinationsAndAmountsSchema)
    .optional(),
  enable_idempotency: zSchema.boolean().optional(),
  employer_taxes: zSchema.number().optional(),
  extra_meta: zSchema
    .object({
      amounts: zSchema.array(zSchema.number()),
      amount_types: zSchema.array(
        zSchema.enum([
          "TAX",
          "PENALTY",
          "INTEREST",
          "STATE",
          "LOCAL",
          "CITY",
          "FEE",
          "ESCROW",
          "ESTIMATED",
          "ANNUAL",
          "EXTENTION",
          "AUDIT",
          "LOAN",
          "UNDER_PROTEST",
          "RETURN",
          "BILL",
          "PRIOR_OVERPAYMENT",
          "CREDIT_MEMO",
          "DISOUNT",
          "LEVY",
          "GARNISHMENT",
          "1",
          "2",
          "3",
        ])
      ),
      payment_code: zSchema.string(),
      tax_period_end_date: zSchema.string(),
      taxpayer_verification: zSchema.any().optional(),
      tin: zSchema.string(),
    })
    .optional(),
  from_user: zSchema.string(),
  net_amount: zSchema.number().optional(),
  note: zSchema.string().optional(),
  partner_id: schemes.uuid().optional(),
  partner_name: zSchema.string().optional(),
  same_day: zSchema.boolean().optional(),
  to_user: zSchema.string().optional(),
  type_of_payment: zSchema.nativeEnum(EPaymentCreationType).optional(),
  zeal_id: zSchema.string().optional(),
  metadata: schemes.metadata().nullable().optional(),
  test: zSchema.boolean().optional(),
});

export const companyTaxPenaltyTransactionRequestSchema =
  baseTransactionRequestSchema.extend({
    type_of_payment: zSchema.literal(EPaymentCreationType.COMPANY_TAX_PENALTY),
  });

export const fasterPayTransactionRequestSchema =
  baseTransactionRequestSchema.extend({
    type_of_payment: zSchema.literal(EPaymentCreationType.FASTER_PAY),
    destinations_and_amounts: zSchema
      .array(
        destinationsAndAmountsSchema.extend({
          paystub_id: schemes.uuid().optional(),
        })
      )
      .optional(),
  });

export const fundEmployerCustodyTransactionRequestSchema =
  baseTransactionRequestSchema.extend({
    type_of_payment: zSchema.literal(
      EPaymentCreationType.FUND_EMPLOYER_CUSTODY
    ),
  });

export const fundEmployerReserveTransactionRequestSchema =
  baseTransactionRequestSchema.extend({
    type_of_payment: zSchema.literal(
      EPaymentCreationType.FUND_EMPLOYER_RESERVE
    ),
  });

export const fundTaxNodeTransactionRequestSchema =
  baseTransactionRequestSchema.extend({
    type_of_payment: zSchema.literal(EPaymentCreationType.FUND_TAX_NODE),
  });

export const oneDayTransactionRequestSchema =
  baseTransactionRequestSchema.extend({
    type_of_payment: zSchema.literal(EPaymentCreationType.ONE_DAY),
  });

export const oneDayDebitTransactionRequestSchema =
  baseTransactionRequestSchema.extend({
    type_of_payment: zSchema.literal(EPaymentCreationType.ONE_DAY_DEBIT),
  });

export const oneDayTaxTransferTransactionRequestSchema =
  baseTransactionRequestSchema.extend({
    type_of_payment: zSchema.literal(EPaymentCreationType.ONE_DAY_TAX_TRANSFER),
  });

export const payEmployeeTransactionRequestSchema =
  baseTransactionRequestSchema.extend({
    type_of_payment: zSchema.literal(EPaymentCreationType.PAY_EMPLOYEE),
  });

export const payStateTransactionRequestSchema =
  baseTransactionRequestSchema.extend({
    type_of_payment: zSchema.literal(EPaymentCreationType.PAY_STATE),
  });

export const payStateFromCustodyTransactionRequestSchema =
  baseTransactionRequestSchema.extend({
    type_of_payment: zSchema.literal(
      EPaymentCreationType.PAY_STATE_FROM_CUSTODY
    ),
  });

export const prefundedBatchDisbursementTransactionRequestSchema =
  baseTransactionRequestSchema.extend({
    type_of_payment: zSchema.literal(
      EPaymentCreationType.PREFUNDED_BATCH_DISBURSEMENT
    ),
  });

export const prefundedEmployeeDisbursementTransactionRequestSchema =
  baseTransactionRequestSchema.extend({
    type_of_payment: zSchema.literal(
      EPaymentCreationType.PREFUNDED_EMPLOYEE_DISBURSEMENT
    ),
  });

export const pushDeductionsTransactionRequestSchema =
  baseTransactionRequestSchema.extend({
    type_of_payment: zSchema.literal(EPaymentCreationType.PUSH_DEDUCTIONS),
  });

export const refundCustomerAccountPayrollTransactionRequestSchema =
  baseTransactionRequestSchema.extend({
    type_of_payment: zSchema.literal(
      EPaymentCreationType.REFUND_CUSTOMER_ACCOUNT_PAYROLL
    ),
  });

export const refundPayrollTransactionRequestSchema =
  baseTransactionRequestSchema.extend({
    type_of_payment: zSchema.literal(EPaymentCreationType.REFUND_PAYROLL),
  });

export const refundReservePayrollTransactionRequestSchema =
  baseTransactionRequestSchema.extend({
    type_of_payment: zSchema.literal(
      EPaymentCreationType.REFUND_RESERVE_PAYROLL
    ),
  });

export const refundTaxTransactionRequestSchema =
  baseTransactionRequestSchema.extend({
    type_of_payment: zSchema.literal(EPaymentCreationType.REFUND_TAX),
  });

export const taxPenaltyTransactionRequestSchema =
  baseTransactionRequestSchema.extend({
    type_of_payment: zSchema.literal(EPaymentCreationType.TAX_PENALTY),
  });

export const twoDayDeductionsTransferRequestSchema =
  baseTransactionRequestSchema.extend({
    type_of_payment: zSchema.literal(
      EPaymentCreationType.TWO_DAY_DEDUCTIONS_TRANSFER
    ),
  });

export const twoDayTaxTransferRequestSchema =
  baseTransactionRequestSchema.extend({
    type_of_payment: zSchema.literal(EPaymentCreationType.TWO_DAY_TAX_TRANSFER),
  });

export const createTransactionRequestSchema = zSchema.discriminatedUnion(
  "type_of_payment",
  [
    companyTaxPenaltyTransactionRequestSchema,
    fasterPayTransactionRequestSchema,
    fundEmployerCustodyTransactionRequestSchema,
    fundEmployerReserveTransactionRequestSchema,
    fundTaxNodeTransactionRequestSchema,
    oneDayTransactionRequestSchema,
    oneDayDebitTransactionRequestSchema,
    oneDayTaxTransferTransactionRequestSchema,
    payEmployeeTransactionRequestSchema,
    payStateTransactionRequestSchema,
    payStateFromCustodyTransactionRequestSchema,
    prefundedBatchDisbursementTransactionRequestSchema,
    prefundedEmployeeDisbursementTransactionRequestSchema,
    pushDeductionsTransactionRequestSchema,
    refundCustomerAccountPayrollTransactionRequestSchema,
    refundPayrollTransactionRequestSchema,
    refundReservePayrollTransactionRequestSchema,
    refundTaxTransactionRequestSchema,
    taxPenaltyTransactionRequestSchema,
    twoDayDeductionsTransferRequestSchema,
    twoDayTaxTransferRequestSchema,
  ]
);

export type TCreateTransactionRequestSchema = zSchema.infer<
  typeof createTransactionRequestSchema
>;

export const createTransactionResponseSchema = zSchema.object({
  errors: zSchema.object({
    userErrors: zSchema.array(
      zSchema.object({
        input: zSchema.any(),
        error: zSchema.string(),
      })
    ),
    transactionErrors: zSchema.array(
      zSchema.object({
        input: zSchema.any(),
        error: zSchema.string(),
      })
    ),
  }),
  from_user_id: zSchema.string(),
  transactions: zSchema.array(
    zSchema.object({
      input: zSchema.object({
        transaction_group_id: schemes.uuid(),
        transaction_record_id: schemes.uuid(),
      }),
      result: zSchema.object({
        bankPartnerId: zSchema.string(),
      }),
    })
  ),
});

export type TCreateTransactionResponseSchema = zSchema.infer<
  typeof createTransactionResponseSchema
>;
