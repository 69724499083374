import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { getUsePrimaryUrlParams } from "../../utils/utils";
import { ISchema } from "clients/LaForgeClient/validation-types/SchemaDefinition";

export const updateCheckProcessedLeanWebhook = (axios: IAxiosInstance) => {
  return async ({
    checkProcessedLeanWebhook,
    userType,
    usePrimary,
    isTestWebhookType,
  }: ISchema["#/definitions/IUpdateCheckProcessedLeanWebhookArgs"]) => {
    const response = await axios.noRetry.post<
      ISchema["#/definitions/IUpdateCheckProcessedLeanWebhookResponse"]
    >(
      `/api/updateCheckProcessedLeanWebhook${getUsePrimaryUrlParams(
        usePrimary
      )}`,
      {
        check_processed_lean_webhook: checkProcessedLeanWebhook,
        user_type: userType,
        is_test_webhook_type: isTestWebhookType,
      }
    );

    const { data: responseData } = response;
    if (!responseData.success) {
      throw new Error(`Something went wrong`);
    }
    return responseData;
  };
};
