import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { getUsePrimaryUrlParams } from "../../utils/utils";
import { ISchema } from "clients/LaForgeClient/validation-types/SchemaDefinition";

export const testReportWebhook = (axios: IAxiosInstance) => {
  return async ({
    jobWebhook,
    userType,
    test,
    usePrimary,
  }: ISchema["#/definitions/ITestReportWebhookArgs"]) => {
    const response = await axios.noRetry.post<
      ISchema["#/definitions/ITestReportWebhookResponse"]
    >(`/api/testReportWebhook${getUsePrimaryUrlParams(usePrimary)}`, {
      job_webhook: jobWebhook,
      user_type: userType,
      test,
    });

    const { data: responseData } = response;
    if (!responseData.success) {
      throw new Error(`Something went wrong`);
    }
    return responseData;
  };
};
