import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";

export const getUnmigratedWorkers = (axios: IAxiosInstance) => {
  return async (body: string[]) => {
    const response = await axios.noRetry.post<any>(
      `/api/employee/get-unmigrated`,
      { workerIds: body }
    );
    return response.data;
  };
};
