import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";

export const migrateOneWorker = (axios: IAxiosInstance) => {
  return async (body: {
    account_number?: string;
    account_type?: string;
    address: string;
    address_line2: string;
    city: string;
    dob: string;
    email: string;
    first_name: string;
    last_name: string;
    odo_user_id?: string;
    phone_number?: string;
    routing_number?: string;
    tin: string;
    state: string;
    zeal_id: string;
    zip: string;
    enable_idempotency: boolean;
    test: boolean;
    create_person_app: boolean;
  }) => {
    const response = await axios.noRetry.post<any>(
      `/api/employee/migrate`,
      body
    );
    return response.data;
  };
};
