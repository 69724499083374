import {
  TGetWageViolationsRequestBody,
  TGetViolationsResponseData,
} from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";

export const getWageViolations = (axios: IAxiosInstance) => {
  return async (requestBody: TGetWageViolationsRequestBody) => {
    const response = await axios.noRetry.post<
      IApiResponse<TGetViolationsResponseData>
    >(`/api/getWageViolations`, requestBody);
    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
