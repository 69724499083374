import { AxiosResponse } from "axios";
import {
  buildResponseBase,
  ICreateFileSubmissionRequest,
  IFileSubmission,
} from "@zeal/common";
import { IAxiosInstance } from "../../AbstractApiClient/AbstractApiClient";

export const createFileSubmission =
  (axios: IAxiosInstance) => async (obj: ICreateFileSubmissionRequest) => {
    const url = "/private/api/compass/createFileSubmission";

    const res = await axios.noRetry.request<
      IFileSubmission,
      AxiosResponse<IFileSubmission>,
      ICreateFileSubmissionRequest
    >({
      method: "POST",
      url,
      data: obj,
    });

    return buildResponseBase({ status: res.status, data: res.data });
  };
