import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

const URL = `reports/payment-summary`;
export const postPaymentSummary = (axios: IAxiosInstance) => {
  return async (
    requestBody: ISchema["#/definitions/IPostPaymentSummaryRequestBody"]
  ) => {
    const response = await axios.noRetry.post<
      ISchema["#/definitions/IPostPaymentSummaryResponseBody"]
    >(URL, requestBody);
    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
