export enum EDepositSchedule {
  NEXT_DAY = <any>"NEXT_DAY",
  ACCELERATED = <any>"ACCELERATED",
  THREE_DAY = <any>"THREE_DAY",
  FIVE_DAY = <any>"FIVE_DAY",
  WEEKLY = <any>"WEEKLY",
  QUAD_MONTHLY = <any>"QUAD_MONTHLY",
  SEMI_WEEKLY = <any>"SEMI_WEEKLY",
  SEMI_MONTHLY = <any>"SEMI_MONTHLY",
  ACCEL_MONTHLY = <any>"ACCEL_MONTHLY",
  MONTHLY = <any>"MONTHLY",
  QUARTERLY = <any>"QUARTERLY",
  EIGHT_MONTHLY = <any>"EIGHT_MONTHLY",
  SEMI_ANNUALLY = <any>"SEMI_ANNUALLY",
  ANNUALLY = <any>"ANNUALLY",
}
