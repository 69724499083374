import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

export const getActivePayrollRuns = (axios: IAxiosInstance) => {
  return async (params: { filterKey: string }) => {
    const response = await axios.noRetry.get<
      ISchema["#/definitions/IGetActivePayrollRunsResponse"]
    >("/api/active-payroll-runs", { params });
    const resData = response.data;
    if (!resData.success) {
      throw new Error("Something went wrong");
    }
    return resData.data;
  };
};
