import { z } from "zod";

export const checkAccrualsSchema = z.object({
  companyID: z.string(),
  employeeID: z.array(z.string()),
  quarter: z.string().optional(),
  year: z.union([z.number(), z.string()]).optional(),
  has_live_key: z.boolean(),
});

export const checkAccrualsQTDSchema = checkAccrualsSchema.omit({
  year: true,
  has_live_key: true,
});
export const checkAccrualsYTDSchema = checkAccrualsSchema.omit({
  quarter: true,
  has_live_key: true,
});

export type CheckAccruals = z.infer<typeof checkAccrualsSchema>;
export type CheckAccrualsQTD = z.infer<typeof checkAccrualsQTDSchema>;
export type CheckAccrualsYTD = z.infer<typeof checkAccrualsYTDSchema>;

export type Withholdings = {
  amount: number;
  taxType: string;
  name: string;
  payer: "EE" | "ER";
};

export type CheckTotals = {
  gross_pay: number;
  net_pay: number;
  num_checks: number;
  employee_taxes: number;
  employer_taxes: number;
  deductions: number;
  taxes_by_type: {
    [k: string]: Withholdings;
  };
  earnings_by_type: {
    [k: string]: number;
  };
  deductions_by_type: {
    [k: string]: number;
  };
};

export type CheckTotalsResponse = {
  gross_pay: string;
  net_pay: string;
  num_checks: number;
  employee_taxes: string;
  employer_taxes: string;
  deductions: string;
  taxes_by_type: (Omit<Withholdings, "amount"> & { amount: string })[];
  earnings_by_type: {
    [k: string]: string;
  }[];
  deductions_by_type: {
    [k: string]: string;
  }[];
};

export interface EmployeesCheckTotal {
  [k: string]: CheckTotals;
}

export type CheckAccrualsShape = {
  totals: CheckTotals;
  employees: EmployeesCheckTotal;
};

export type CheckAccrualsShapeResponse = {
  totals: CheckTotalsResponse;
  employees: (CheckTotalsResponse & { employeeID: string })[];
};
