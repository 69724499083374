import { z } from "zod";
import { employeeBaseRequirementSchema, employeeSchema } from "./baseEmployee";

export const informationCollection = z.literal("information-collection");

export const informationCollectionEmployeeSchema = employeeBaseRequirementSchema
  .extend({
    kind: z.literal("information-collection"),
  })
  .merge(
    employeeSchema
      .pick({
        first_name: true,
        last_name: true,
      })
      .passthrough()
      .partial()
  );

export const informationCollectionEmployeeTaskFieldsSchema = z.object({
  kind: informationCollection,
  ...Object.fromEntries(
    Object.keys(
      informationCollectionEmployeeSchema.omit({ kind: true }).shape
    ).map((key) => [key, z.boolean()])
  ),
});

export type InformationCollectionEmployee = z.infer<
  typeof informationCollectionEmployeeSchema
>;
export type InformationCollectionEmployeeTaskFields = z.infer<
  typeof informationCollectionEmployeeSchema
>;

export type InformationCollectionEmployeeTask = {
  employeeID: string;
  fields: InformationCollectionEmployeeTaskFields;
};
