import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TEVerifyWebhookBody,
  TEVerifyWebhookRequest,
} from "./testEVerifyWebhookSchema";

export const testEVerifyWebhook = (axios: IAxiosInstance) => {
  return async ({ testMode, url }: TEVerifyWebhookRequest) => {
    const response = await axios.noRetry.post<
      IApiResponse<TEVerifyWebhookBody>
    >("/api/testEVerifyWebhook", {
      url,
      testMode,
    });

    const { data: responseData } = response;
    if (!responseData.success) {
      throw new Error(`Something went wrong`);
    }
    return responseData;
  };
};
