import { z } from "zod";

const getMyRecentlyOnboardedWorkersQuerySchema = z.object({
  query: z.object({
    limit: z.number().optional(),
  }),
});

export type TGetMyRecentlyOnboardedWorkersQuery = z.infer<
  typeof getMyRecentlyOnboardedWorkersQuerySchema
>;

const getMyRecentlyOnboardedWorkersResponseData = z.object({
  _id: z.string(),
  first_name: z.string(),
  last_name: z.string(),
  title: z.string(),
  working_state: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
});

export type TGetMyRecentlyOnboardedWorkersResponseData = Array<
  z.infer<typeof getMyRecentlyOnboardedWorkersResponseData>
>;
