export enum EParamType {
  signature = "base64",
  string = "string",
  float = "float",
  percentage = "percentage",
  integer = "integer",
  enum = "enum",
  boolean = "boolean",
  dollars = "dollars",
  base64 = "base64",
}
