export type EDisbursementMethod =
  (typeof EDisbursementMethod)[keyof typeof EDisbursementMethod];

export type EEmployeeDisbursementMethod = Exclude<
  EDisbursementMethod,
  "mail_check"
>;

export type EContractorDisbursementMethod = Exclude<
  EDisbursementMethod,
  "download_check" | "mail_check"
>;

export const EDisbursementMethod = {
  DIRECT_DEPOSIT: "direct_deposit",
  DOWNLOAD_CHECK: "download_check",
  PREPAID: "prepaid",
  MAIL_CHECK: "mail_check", // maintained for legacy reasons, but doesn't seem to be used
  PAYCARD: "paycard",
} as const;

export const DISBURSEMENT_METHODS: ReadonlyArray<EDisbursementMethod> = [
  EDisbursementMethod.DIRECT_DEPOSIT,
  EDisbursementMethod.DOWNLOAD_CHECK,
  EDisbursementMethod.PAYCARD,
  EDisbursementMethod.PREPAID,
];

export const EMPLOYEE_DISBURSEMENT_METHODS: ReadonlyArray<EEmployeeDisbursementMethod> =
  [
    EDisbursementMethod.DIRECT_DEPOSIT,
    EDisbursementMethod.DOWNLOAD_CHECK,
    EDisbursementMethod.PAYCARD,
    EDisbursementMethod.PREPAID,
  ];

export const CONTRACTOR_DISBURSEMENT_METHODS: ReadonlyArray<EContractorDisbursementMethod> =
  [
    EDisbursementMethod.DIRECT_DEPOSIT,
    EDisbursementMethod.PAYCARD,
    EDisbursementMethod.PREPAID,
  ];
