import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

export const token = (axios: IAxiosInstance) => {
  return async ({
    accountId,
    password,
  }: ISchema["#/definitions/ITokenBody"]) => {
    const response = await axios.noRetry.post<
      ISchema["#/definitions/TokenResponse"]
    >(`/token`, { account_id: accountId, password });
    const resData = response.data;

    return resData;
  };
};
