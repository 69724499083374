import { AxiosError } from "axios";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";

export const resetCompanyMicroDeposits =
  (axios: IAxiosInstance) => async (query: { micro_deposit_id: string }) => {
    try {
      const response = await axios.noRetry.patch(
        `/api/company/${query.micro_deposit_id}/micro-deposits`
      );
      return response.data;
    } catch (e) {
      return (e as AxiosError)?.response?.data;
    }
  };
