import { MoveRuleRequest, RULE_SET_ROUTES } from "@zeal/common";
import { IAxiosInstance } from "../../../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../../../AbstractApiClient/IApiResponse";

export const moveRule = (axios: IAxiosInstance) => {
  return async (data: MoveRuleRequest) => {
    const response = await axios.noRetry.patch<IApiResponse<any>>(
      `${RULE_SET_ROUTES.MOVE_RULE.replace(":companyID", data.companyID)
        .replace(":ruleSetID", data.ruleSetID)
        .replace(":ruleID", data.ruleID)}`,
      data
    );

    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
