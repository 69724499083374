import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";

export const getContractorPaycardDisclosures = (axios: IAxiosInstance) => {
  return async ({
    workerId,
    document_keys,
  }: {
    readonly workerId: string;
    readonly document_keys: string[];
  }) => {
    const response = await axios.noRetry.get(
      `/api/contractors/${workerId}/paycard-disclosures`,
      {
        params: {
          document_keys,
        },
      }
    );

    return response.data;
  };
};
