export enum ETransactionStatus {
  CREATED_BY_ZEAL = "created_by_zeal",
  CREATED_BY_BANK = "created_by_bank",
  PENDING = "pending",
  CANCELED = "canceled",
  PROCESSING = "processing",
  ERROR = "error",
  SENT = "sent",
  SETTLED = "settled",
  RETURNED = "returned",
}

export const EReturnCodes = {
  R01: "Insufficient funds",
  R02: "Account closed",
  R03: "No account or unable to locate account",
  R04: "Invalid account number structure",
  R05: "Unauthorized debit to consumer account using a corporate SEC code",
  R06: "Returned per ODFI's request",
  R07: "Authorization revoked by customer",
  R08: "Payment stopped",
  R09: "Uncollected funds",
  R10: "Customer advises Originator is Not Known to Receiver and/or Originator is Not Authorized by Receiver to Debit Receiver's Account",
  R11: "Customer Advises Entry Not in Accordance with the Terms of Authorization",
  R12: "Branch sold to another DFI",
  R13: "Invalid ACH routing number",
  R14: "Representment payee deceased or unable to continue in that capacity",
  R15: "Beneficiary of account holder deceased",
  R16: "Account Frozen/Entry Returned Per OFAC Instruction",
  R17: "File record edit criteria/Entry with invalid account number initiated under questionable circumstances",
  R18: "Improper effective entry date",
  R19: "Amount field error",
  R20: "Non-transaction account",
  R21: "Invalid company identification",
  R22: "Invalid individual ID number",
  R23: "Credit entry refused by receiver",
  R24: "Duplicate entry",
  R25: "Addenda error",
  R26: "Mandatory field error",
  R27: "Trace number error",
  R28: "Routing number check digit error",
  R29: "Corporate customer advises not authorized",
  R30: "RDFI not participant in check truncation program",
  R31: "Permissible return entry",
  R32: "RDFI nonsettlement",
} as const;

export type TReturnCodes = keyof typeof EReturnCodes;

export interface ITransactionEvent {
  readonly transaction_record_id: string;
  readonly transaction_event_id: string;
  readonly status: ETransactionStatus;
  readonly external_created_at?: string;
  readonly error_code?: TReturnCodes;
  readonly createdAt: string;
  readonly updatedAt: string;
}
