import { EMediaType } from "@zeal/common/dist/forms";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

export const getFormTemplate = (axios: IAxiosInstance) => {
  return async (params: ISchema["#/definitions/TGetFormTemplateQuery"]) => {
    switch (params.media_type) {
      case EMediaType.JSON: {
        return (
          await axios.noRetry.request<
            ISchema["#/definitions/TGetFormTemplateResponseJSON"]
          >({
            method: "GET",
            url: "/forms/templates",
            params,
            responseType: "json",
          })
        ).data.data;
      }
      case EMediaType.PDF: {
        return (
          await axios.noRetry.request<
            ISchema["#/definitions/TGetFormTemplateResponseStream"]
          >({
            method: "GET",
            url: "/forms/templates",
            params,
            responseType: "stream",
          })
        ).data;
      }
      default: {
        throw new Error("Invalid media type");
      }
    }
  };
};
