export enum EPaymentUserTypes {
  ADMIN_USER = "admin_user",
  COMPANY_USER = "company_user",
  CUSTOMER_ACCOUNT_USER = "customer_account_user",
  EMPLOYEE_USER = "employee_user",
  STATE_TAX_USER = "state_tax_user",
}

export interface IOdoEmployee {
  kyc_evaluation_id?: string;
  migrated: boolean;
  person_app_id?: string;
  bank_account_id?: string;
  zeal_id?: string;
  odo_user_id: string;
  user_type: EPaymentUserTypes.EMPLOYEE_USER;
}

export interface IOdoEmployeeOnboardRequest {
  address: string;
  address_line2?: string;
  city: string;
  zip: string;
  state: string;
  phone_number: string;
  birth_date_base: string;
  first_name: string;
  last_name: string;
  contact_email: string;
  uuid: string;
  ssn?: string;
  ein?: string;
  id_front?: string;
  id_back?: string;
  company_id?: string;
  partner_id?: string;
}

export interface IOdoEmployeeOnboardResponse {
  success: boolean;
  employee?: IOdoEmployee;
  data?: any; // FIXME any
  error?: string;
}
