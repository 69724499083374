import { ZealApiResponse } from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { TUpdateI9OnboardingInfoAsAuthorizedRepresentativeSchema } from "./updateI9OnboardingInfoAsAuthorizedRepresentativeSchema";

export const updateI9OnboardingInfoAsAuthorizedRepresentative = (
  axios: IAxiosInstance
) => {
  return async (
    data: TUpdateI9OnboardingInfoAsAuthorizedRepresentativeSchema
  ) => {
    const { accessToken, ...rest } = data;
    const response = await axios.noRetry.patch<ZealApiResponse<null>>(
      `/api/updateI9OnboardingInfoAsAuthorizedRepresentative`,
      rest,
      {
        params: { accessToken },
      }
    );
    const resData = response.data;
    if (resData.success) {
      return resData.data;
    }
    throw new Error("Something went wrong");
  };
};
