import { z } from "zod";
import { Jurisdictions as supportedJurisdictions } from "../../jurisdictions";

export const jurisdiction = (errmsg?: string) =>
  z.string().refine((val) => {
    const jurisdiction = val.toUpperCase();

    if (
      supportedJurisdictions[
        jurisdiction as keyof typeof supportedJurisdictions
      ]
    ) {
      return true;
    }

    return false;
  }, errmsg || "State does not represent a state in the US");
