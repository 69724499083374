export enum Jurisdiction {
  AK = "AK", // Alaska",
  AL = "AL", // Alabama",
  AR = "AR", // Arkansas",
  AZ = "AZ", // Arizona",
  CA = "CA", // California",
  CO = "CO", // Colorado",
  CT = "CT", // Connecticut",
  DC = "DC", // District of Columbia",
  DE = "DE", // Delaware",
  FL = "FL", // Florida",
  GA = "GA", // Georgia",
  HI = "HI", // Hawaii",
  IA = "IA", // Iowa",
  ID = "ID", // Idaho",
  IL = "IL", // Illinois",
  IN = "IN", // Indiana",
  KS = "KS", // Kansas",
  KY = "KY", // Kentucky",
  LA = "LA", // Louisiana",
  MA = "MA", // Massachusetts",
  MD = "MD", // Maryland",
  ME = "ME", // Maine",
  MI = "MI", // Michigan",
  MN = "MN", // Minnesota",
  MO = "MO", // Missouri",
  MS = "MS", // Mississippi",
  MT = "MT", // Montana",
  NC = "NC", // North Carolina",
  ND = "ND", // North Dakota",
  NE = "NE", // Nebraska",
  NH = "NH", // New Hampshire",
  NJ = "NJ", // New Jersey",
  NM = "NM", // New Mexico",
  NV = "NV", // Nevada",
  NY = "NY", // New York",
  OH = "OH", // Ohio",
  OK = "OK", // Oklahoma",
  OR = "OR", // Oregon",
  PA = "PA", // Pennsylvania",
  RI = "RI", // Rhode Island",
  SC = "SC", // South Carolina",
  SD = "SD", // South Dakota",
  TN = "TN", // Tennessee",
  TX = "TX", // Texas",
  US = "US", // Federal",
  UT = "UT", // Utah",
  VA = "VA", // Virginia",
  VT = "VT", // Vermont",
  WA = "WA", // Washington",
  WI = "WI", // Wisconsin",
  WV = "WV", // West Virginia",
  WY = "WY", // Wyoming"
}

/* Jurisdictions not currently supported
  AS = "AS", // American Samoa",
  GU = "GU", // Guam",
  FM = "FM", // Federated States Of Micronesia",
  MH = "MH", // Marshall Islands",
  MP = "MP", // Northern Mariana Islands",
  PR = "PR", // Puerto Rico",
  PW = "PW", // Palau",
  VI = "VI", // Virgin Islands",
*/

export const Jurisdictions = {
  AK: "AK", // Alaska",
  AL: "AL", // Alabama",
  AR: "AR", // Arkansas",
  AZ: "AZ", // Arizona",
  CA: "CA", // California",
  CO: "CO", // Colorado",
  CT: "CT", // Connecticut",
  DC: "DC", // District of Columbia",
  DE: "DE", // Delaware",
  FL: "FL", // Florida",
  GA: "GA", // Georgia",
  HI: "HI", // Hawaii",
  IA: "IA", // Iowa",
  ID: "ID", // Idaho",
  IL: "IL", // Illinois",
  IN: "IN", // Indiana",
  KS: "KS", // Kansas",
  KY: "KY", // Kentucky",
  LA: "LA", // Louisiana",
  MA: "MA", // Massachusetts",
  MD: "MD", // Maryland",
  ME: "ME", // Maine",
  MI: "MI", // Michigan",
  MN: "MN", // Minnesota",
  MO: "MO", // Missouri",
  MS: "MS", // Mississippi",
  MT: "MT", // Montana",
  NC: "NC", // North Carolina",
  ND: "ND", // North Dakota",
  NE: "NE", // Nebraska",
  NH: "NH", // New Hampshire",
  NJ: "NJ", // New Jersey",
  NM: "NM", // New Mexico",
  NV: "NV", // Nevada",
  NY: "NY", // New York",
  OH: "OH", // Ohio",
  OK: "OK", // Oklahoma",
  OR: "OR", // Oregon",
  PA: "PA", // Pennsylvania",
  RI: "RI", // Rhode Island",
  SC: "SC", // South Carolina",
  SD: "SD", // South Dakota",
  TN: "TN", // Tennessee",
  TX: "TX", // Texas",
  US: "US", // Federal",
  UT: "UT", // Utah",
  VA: "VA", // Virginia",
  VT: "VT", // Vermont",
  WA: "WA", // Washington",
  WI: "WI", // Wisconsin",
  WV: "WV", // West Virginia",
  WY: "WY", // Wyoming"
} as const;

// Contractor model has inconsistent data within working_state key -- this is a necessary map to translate the inconsistent data
// TODO: once the root fix has been implemented and data has been migrated, this can be removed
export const JurisdictionNameMap = {
  Alaska: Jurisdiction.AK,
  Alabama: Jurisdiction.AL,
  Arkansas: Jurisdiction.AR,
  Arizona: Jurisdiction.AZ,
  California: Jurisdiction.CA,
  Colorado: Jurisdiction.CO,
  Connecticut: Jurisdiction.CT,
  "District of Columbia": Jurisdiction.DC,
  Delaware: Jurisdiction.DE,
  Florida: Jurisdiction.FL,
  Georgia: Jurisdiction.GA,
  Hawaii: Jurisdiction.HI,
  Iowa: Jurisdiction.IA,
  Idaho: Jurisdiction.ID,
  Illinois: Jurisdiction.IL,
  Indiana: Jurisdiction.IN,
  Kansas: Jurisdiction.KS,
  Kentucky: Jurisdiction.KY,
  Louisiana: Jurisdiction.LA,
  Massachusetts: Jurisdiction.MA,
  Maryland: Jurisdiction.MD,
  Maine: Jurisdiction.ME,
  Michigan: Jurisdiction.MI,
  Minnesota: Jurisdiction.MN,
  Montana: Jurisdiction.MT,
  Missouri: Jurisdiction.MO,
  Mississippi: Jurisdiction.MS,
  "North Carolina": Jurisdiction.NC,
  "North Dakota": Jurisdiction.ND,
  Nebraska: Jurisdiction.NE,
  "New Hampshire": Jurisdiction.NH,
  "New Jersey": Jurisdiction.NJ,
  "New Mexico": Jurisdiction.NM,
  Nevada: Jurisdiction.NV,
  "New York": Jurisdiction.NY,
  Ohio: Jurisdiction.OH,
  Oklahoma: Jurisdiction.OK,
  Oregon: Jurisdiction.OR,
  Pennsylvania: Jurisdiction.PA,
  "Rhode Island": Jurisdiction.RI,
  "South Carolina": Jurisdiction.SC,
  "South Dakota": Jurisdiction.SD,
  Tennessee: Jurisdiction.TN,
  Texas: Jurisdiction.TX,
  Utah: Jurisdiction.UT,
  Virginia: Jurisdiction.VA,
  Vermont: Jurisdiction.VT,
  Washington: Jurisdiction.WA,
  Wisconsin: Jurisdiction.WI,
  "West Virginia": Jurisdiction.WV,
  Wyoming: Jurisdiction.WY,
} as const;

export const JurisdictionAbbrevToNameMap = {
  [Jurisdiction.AK]: "Alaska",
  [Jurisdiction.AL]: "Alabama",
  [Jurisdiction.AR]: "Arkansas",
  [Jurisdiction.AZ]: "Arizona",
  [Jurisdiction.CA]: "California",
  [Jurisdiction.CO]: "Colorado",
  [Jurisdiction.CT]: "Connecticut",
  [Jurisdiction.DC]: "District of Columbia",
  [Jurisdiction.DE]: "Delaware",
  [Jurisdiction.FL]: "Florida",
  [Jurisdiction.GA]: "Georgia",
  [Jurisdiction.HI]: "Hawaii",
  [Jurisdiction.IA]: "Iowa",
  [Jurisdiction.ID]: "Idaho",
  [Jurisdiction.IL]: "Illinois",
  [Jurisdiction.IN]: "Indiana",
  [Jurisdiction.KS]: "Kansas",
  [Jurisdiction.KY]: "Kentucky",
  [Jurisdiction.LA]: "Louisiana",
  [Jurisdiction.MA]: "Massachusetts",
  [Jurisdiction.MD]: "Maryland",
  [Jurisdiction.ME]: "Maine",
  [Jurisdiction.MI]: "Michigan",
  [Jurisdiction.MN]: "Minnesota",
  [Jurisdiction.MT]: "Montana",
  [Jurisdiction.MO]: "Missouri",
  [Jurisdiction.MS]: "Mississippi",
  [Jurisdiction.NC]: "North Carolina",
  [Jurisdiction.ND]: "North Dakota",
  [Jurisdiction.NE]: "Nebraska",
  [Jurisdiction.NH]: "New Hampshire",
  [Jurisdiction.NJ]: "New Jersey",
  [Jurisdiction.NM]: "New Mexico",
  [Jurisdiction.NV]: "Nevada",
  [Jurisdiction.NY]: "New York",
  [Jurisdiction.OH]: "Ohio",
  [Jurisdiction.OK]: "Oklahoma",
  [Jurisdiction.OR]: "Oregon",
  [Jurisdiction.PA]: "Pennsylvania",
  [Jurisdiction.RI]: "Rhode Island",
  [Jurisdiction.SC]: "South Carolina",
  [Jurisdiction.SD]: "South Dakota",
  [Jurisdiction.TN]: "Tennessee",
  [Jurisdiction.TX]: "Texas",
  [Jurisdiction.UT]: "Utah",
  [Jurisdiction.VA]: "Virginia",
  [Jurisdiction.VT]: "Vermont",
  [Jurisdiction.WA]: "Washington",
  [Jurisdiction.WI]: "Wisconsin",
  [Jurisdiction.WV]: "West Virginia",
  [Jurisdiction.WY]: "Wyoming",
} as const;

export enum JurisdictionCodes {
  AL = "01",
  AK = "02",
  AZ = "04",
  AR = "05",
  CA = "06",
  CO = "08",
  CT = "09",
  DE = "10",
  DC = "11",
  FL = "12",
  GA = "13",
  HI = "15",
  ID = "16",
  IL = "17",
  IN = "18",
  IA = "19",
  KS = "20",
  KY = "21",
  LA = "22",
  ME = "23",
  MD = "24",
  MA = "25",
  MI = "26",
  MN = "27",
  MS = "28",
  MO = "29",
  MT = "30",
  NE = "31",
  NV = "32",
  NH = "33",
  NJ = "34",
  NM = "35",
  NY = "36",
  NC = "37",
  ND = "38",
  OH = "39",
  OK = "40",
  OR = "41",
  PA = "42",
  RI = "44",
  SC = "45",
  SD = "46",
  TN = "47",
  TX = "48",
  UT = "49",
  VT = "50",
  VA = "51",
  WA = "53",
  WV = "54",
  WI = "55",
  WY = "56",
}

export const stateCode = {
  [Jurisdiction.AL]: JurisdictionCodes.AL,
  [Jurisdiction.AK]: JurisdictionCodes.AK,
  [Jurisdiction.AZ]: JurisdictionCodes.AZ,
  [Jurisdiction.AR]: JurisdictionCodes.AR,
  [Jurisdiction.CA]: JurisdictionCodes.CA,
  [Jurisdiction.CO]: JurisdictionCodes.CO,
  [Jurisdiction.CT]: JurisdictionCodes.CT,
  [Jurisdiction.DE]: JurisdictionCodes.DE,
  [Jurisdiction.DC]: JurisdictionCodes.DC,
  [Jurisdiction.FL]: JurisdictionCodes.FL,
  [Jurisdiction.GA]: JurisdictionCodes.GA,
  [Jurisdiction.HI]: JurisdictionCodes.HI,
  [Jurisdiction.IA]: JurisdictionCodes.IA,
  [Jurisdiction.ID]: JurisdictionCodes.ID,
  [Jurisdiction.IL]: JurisdictionCodes.IL,
  [Jurisdiction.IN]: JurisdictionCodes.IN,
  [Jurisdiction.KS]: JurisdictionCodes.KS,
  [Jurisdiction.KY]: JurisdictionCodes.KY,
  [Jurisdiction.LA]: JurisdictionCodes.LA,
  [Jurisdiction.ME]: JurisdictionCodes.ME,
  [Jurisdiction.MD]: JurisdictionCodes.MD,
  [Jurisdiction.MA]: JurisdictionCodes.MA,
  [Jurisdiction.MI]: JurisdictionCodes.MI,
  [Jurisdiction.MN]: JurisdictionCodes.MN,
  [Jurisdiction.MS]: JurisdictionCodes.MS,
  [Jurisdiction.MO]: JurisdictionCodes.MO,
  [Jurisdiction.MT]: JurisdictionCodes.MT,
  [Jurisdiction.NE]: JurisdictionCodes.NE,
  [Jurisdiction.NV]: JurisdictionCodes.NV,
  [Jurisdiction.NH]: JurisdictionCodes.NH,
  [Jurisdiction.NJ]: JurisdictionCodes.NJ,
  [Jurisdiction.NM]: JurisdictionCodes.NM,
  [Jurisdiction.NY]: JurisdictionCodes.NY,
  [Jurisdiction.NC]: JurisdictionCodes.NC,
  [Jurisdiction.ND]: JurisdictionCodes.ND,
  [Jurisdiction.OH]: JurisdictionCodes.OH,
  [Jurisdiction.OK]: JurisdictionCodes.OK,
  [Jurisdiction.OR]: JurisdictionCodes.OR,
  [Jurisdiction.PA]: JurisdictionCodes.PA,
  [Jurisdiction.RI]: JurisdictionCodes.RI,
  [Jurisdiction.SC]: JurisdictionCodes.SC,
  [Jurisdiction.SD]: JurisdictionCodes.SD,
  [Jurisdiction.TN]: JurisdictionCodes.TN,
  [Jurisdiction.UT]: JurisdictionCodes.UT,
  [Jurisdiction.VT]: JurisdictionCodes.VT,
  [Jurisdiction.VA]: JurisdictionCodes.VA,
  [Jurisdiction.WA]: JurisdictionCodes.WA,
  [Jurisdiction.WV]: JurisdictionCodes.WV,
  [Jurisdiction.WI]: JurisdictionCodes.WI,
  [Jurisdiction.WY]: JurisdictionCodes.WY,
} as const;

export const LocalJurisdictionCodesByTaxType = {
  LIT_IN_ADAMS: "01",
  LIT_IN_ALLEN: "02",
  LIT_IN_BARTHOLOMEW: "03",
  LIT_IN_BENTON: "04",
  LIT_IN_BLACKFORD: "05",
  LIT_IN_BOONE: "06",
  LIT_IN_BROWN: "07",
  LIT_IN_CARROLL: "08",
  LIT_IN_CASS: "09",
  LIT_IN_CLARK: "10",
  LIT_IN_CLAY: "11",
  LIT_IN_CLINTON: "12",
  LIT_IN_CRAWFORD: "13",
  LIT_IN_DAVIESS: "14",
  LIT_IN_DEARBORN: "15",
  LIT_IN_DECATUR: "16",
  LIT_IN_DEKALB: "17",
  LIT_IN_DELAWARE: "18",
  LIT_IN_DUBOIS: "19",
  LIT_IN_ELKHART: "20",
  LIT_IN_FAYETTE: "21",
  LIT_IN_FLOYD: "22",
  LIT_IN_FOUNTAIN: "23",
  LIT_IN_FRANKLIN: "24",
  LIT_IN_FULTON: "25",
  LIT_IN_GIBSON: "26",
  LIT_IN_GRANT: "27",
  LIT_IN_GREENE: "28",
  LIT_IN_HAMILTON: "29",
  LIT_IN_HANCOCK: "30",
  LIT_IN_HARRISON: "31",
  LIT_IN_HENDRICKS: "32",
  LIT_IN_HENRY: "33",
  LIT_IN_HOWARD: "34",
  LIT_IN_HUNTINGTON: "35",
  LIT_IN_JACKSON: "36",
  LIT_IN_JASPER: "37",
  LIT_IN_JAY: "38",
  LIT_IN_JEFFERSON: "39",
  LIT_IN_JENNINGS: "40",
  LIT_IN_JOHNSON: "41",
  LIT_IN_KNOX: "42",
  LIT_IN_KOSCIUSKO: "43",
  LIT_IN_LAGRANGE: "44",
  LIT_IN_LAKE: "45",
  LIT_IN_LAPORTE: "46",
  LIT_IN_LAWRENCE: "47",
  LIT_IN_MADISON: "48",
  LIT_IN_MARION: "49",
  LIT_IN_MARSHALL: "50",
  LIT_IN_MARTIN: "51",
  LIT_IN_MIAMI: "52",
  LIT_IN_MONROE: "53",
  LIT_IN_MONTGOMERY: "54",
  LIT_IN_MORGAN: "55",
  LIT_IN_NEWTON: "56",
  LIT_IN_NOBLE: "57",
  LIT_IN_OHIO: "58",
  LIT_IN_ORANGE: "59",
  LIT_IN_OWEN9: "60",
  LIT_IN_PARKE: "61",
  LIT_IN_PERRY: "62",
  LIT_IN_PIKE: "63",
  LIT_IN_PORTER: "64",
  LIT_IN_POSEY: "65",
  LIT_IN_PULASKI: "66",
  LIT_IN_PUTNAM: "67",
  LIT_IN_RANDOLPH: "68",
  LIT_IN_RIPLEY: "69",
  LIT_IN_RUSH: "70",
  LIT_IN_SCOTT: "71",
  LIT_IN_SHELBY: "72",
  LIT_IN_SPENCER: "73",
  LIT_IN_ST_JOSEPH: "74",
  LIT_IN_STARKE: "75",
  LIT_IN_STEUBEN: "76",
  LIT_IN_SULLIVAN: "77",
  LIT_IN_SWITZERLAND: "78",
  LIT_IN_TIPPECANOE: "79",
  LIT_IN_TIPTON: "80",
  LIT_IN_UNION: "81",
  LIT_IN_VANDERBURGH: "82",
  LIT_IN_VERMILLION: "83",
  LIT_IN_VIGO: "84",
  LIT_IN_WABASH: "85",
  LIT_IN_WARREN: "86",
  LIT_IN_WARRICK: "87",
  LIT_IN_WASHINGTON: "88",
  LIT_IN_WAYNE: "89",
  LIT_IN_WELLS: "90",
  LIT_IN_WHITE: "91",
  LIT_IN_WHITLEY: "92",
};
