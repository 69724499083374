export enum Disbursement {
  direct_deposit = "Direct Deposit",
  mail_check = "Mail Check",
  download_check = "Download Check",
  prepaid = "Pre-Paid",
}

export enum DisbursementStatus {
  returned = "Returned",
  canceled = "Canceled",
  pending = "Pending",
  settled = "Settled",
  net_pay_void = "Net Pay Void",
  refunded = "Refunded",
}
