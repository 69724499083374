import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

export interface IGetPayrollRunsParams {
  readonly companyID?: string;
}

export const getPayrollRuns = (axios: IAxiosInstance) => {
  return async (params: IGetPayrollRunsParams) => {
    const { companyID } = params;
    const response = await axios.noRetry.get<
      ISchema["#/definitions/IGetPayrollRunsResponse"]
    >(`/api/getPayrollRuns`, {
      params: {
        companyID,
      },
    });
    const resData = response.data;

    if (resData.success) {
      return resData;
    }

    throw new Error("Something went wrong");
  };
};
