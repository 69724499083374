import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IContractorWebhookRequestBody } from "./contractorWebhook.jsonschema";

export const contractorWebhook = (axios: IAxiosInstance) => {
  return async (contractorWebhook: string, userType: string, test: boolean) => {
    const webhookBody: IContractorWebhookRequestBody = {
      contractor_webhook: contractorWebhook,
      user_type: userType,
      test,
    };
    const response = await axios.noRetry.post<unknown>(
      `/api/testContractorWebhook`,
      webhookBody
    );

    const { data } = response;
    return data;
  };
};
