import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { TPostGarnishmentDocumentRequest } from "./postGarnishmentDocumentSchema";
import { AxiosRequestConfig } from "axios";

export const postGarnishmentDocument = (axios: IAxiosInstance) => {
  return async (body: TPostGarnishmentDocumentRequest) => {
    const { file, ...rest } = body;
    const formData = new FormData();
    formData.append("file", file);

    Object.entries(rest).forEach(([key, value]) => {
      formData.append(key, value as string);
    });

    const config: AxiosRequestConfig = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    return axios.noRetry.request<any>({
      method: "POST",
      url: "/api/post-garnishment-document",
      data: formData,
      ...config,
    });
  };
};
