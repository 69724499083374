import { z } from "zod";
import { DisbursementStatus } from "../enums";
import { IContractorPaymentSummary } from "../../contractor-payments/IContractorPayment";
import { RouteMap } from "./customerReporting";
import { EContractorType } from "../../contractors";

export type ContractorPaymentReporting = Pick<
  IContractorPaymentSummary,
  | "pay_date"
  | "companyID"
  | "contractorID"
  | "first_name"
  | "last_name"
  | "amount"
  | "full_name"
  | "business_name"
  | "type"
  | "payment_type"
> & {
  disbursement_status: string;
  zeal_id?: string;
  net_amount?: string;
  _id: string;
};

export const paymentSummarySchema = z.object({
  companyID: z.string(),
  pay_date: z.string(),
  amount: z.string(),
  first_name: z.string(),
  last_name: z.string(),
  contractor_id: z.string(),
  unformatted_amount: z.number(),
  disbursement_status: z.nativeEnum(DisbursementStatus),
  zeal_id: z.string(),
  type: z.enum([
    EContractorType.INDIVIDUAL_SOLE_PROPRIETOR,
    EContractorType.C_CORPORATION,
    EContractorType.S_CORPORATION,
    EContractorType.PARTNERSHIP,
    EContractorType.TRUST_ESTATE,
    EContractorType.LLC,
    EContractorType.OTHER,
    // TODO (Sahil, 03/28/24): Rm individual and business once all contractors are updated in production
    EContractorType.INDIVIDUAL,
    EContractorType.BUSINESS,
  ]),
});

export type PaymentSummary = z.infer<typeof paymentSummarySchema>;

export type ContractorPaymentsByPayDate<T = string> = {
  pay_date: string;
  payments_total: T;
  payments: ContractorPaymentReporting[];
};

export type PaymentSummaryPDFShape = {
  kind: RouteMap.payment_summary;
  include_intl_contractors?: boolean;
  pay_start_date: string;
  pay_end_date: string;
  payments: ContractorPaymentsByPayDate[];
};

export type PaymentSummaryCSVShape = {
  kind: RouteMap.payment_summary;
  include_intl_contractors?: boolean;
  payments: ContractorPaymentReporting[];
};
