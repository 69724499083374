import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import { TGetOnboardingInfoResponseSchema } from "./getOnboardingInfo.schema";

export const getOnboardingInfo = (axios: IAxiosInstance) => {
  return async () => {
    const response = await axios.noRetry.get<
      IApiResponse<TGetOnboardingInfoResponseSchema>
    >(`/api/getOnboardingInfo`);

    return response.data;
  };
};
