import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IAuthenticationClaims } from "@zeal/common/dist/accounts";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";

export type MeResponse = IApiResponse<IAuthenticationClaims | null>;

export const whoami = (axios: IAxiosInstance) => {
  return async () => {
    const response = await axios.noRetry.get<MeResponse>(`/me`);
    const {
      data: { data, success },
    } = response;
    if (!success) {
      throw new Error(`Failed to get /me`);
    }
    return data;
  };
};
