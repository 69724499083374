import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IContractorPaymentWebhookRequestBody } from "./contractorPaymentWebhook.jsonschema";

export const contractorPaymentWebhook = (axios: IAxiosInstance) => {
  return async (
    contractorPaymentWebhook: string,
    userType: string,
    test: boolean
  ) => {
    const webhookBody: IContractorPaymentWebhookRequestBody = {
      contractor_payment_webhook: contractorPaymentWebhook,
      user_type: userType,
      test,
    };
    const response = await axios.noRetry.post<unknown>(
      `/api/testContractorPaymentWebhook`,
      webhookBody
    );

    const { data } = response;
    return data;
  };
};
