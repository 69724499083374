import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TPostKycSummaryRequestSchema,
  TPostKycSummaryResponseSchema,
} from "./postKycSummarySchema";

const URL = `reports/kyc-summary`;
export const postKycSummary = (axios: IAxiosInstance) => {
  return async (requestBody: TPostKycSummaryRequestSchema) => {
    const response = await axios.noRetry.post<
      IApiResponse<TPostKycSummaryResponseSchema>
    >(URL, requestBody);
    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
