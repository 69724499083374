import {
  TVerifyCustomerAccountMicroDepositsRequestSchema,
  TVerifyCustomerAccountMicroDepositsResponseSchema,
} from "@zeal/common";
import { AxiosError } from "axios";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";

export const verifyCustomerAccountMicroDeposits =
  (axios: IAxiosInstance) =>
  async (body: TVerifyCustomerAccountMicroDepositsRequestSchema) => {
    try {
      const response = await axios.noRetry.post<
        IApiResponse<TVerifyCustomerAccountMicroDepositsResponseSchema>
      >(
        `/api/customer-accounts/${body.odo_user_id}/verify-micro-deposits`,
        body
      );
      return response;
    } catch (e) {
      const errorMessage =
        (e as AxiosError)?.response?.data ?? "Error verifying micro deposits";
      throw errorMessage;
    }
  };
