import validator from "validator";
import { z } from "zod";

function validateEmail(email: any) {
  try {
    return validator.isEmail(email);
  } catch (e) {
    return false;
  }
}

// clean input and then check its valid
export const email = (errmsg?: string) =>
  z
    .string()
    .transform((val) => val.toLowerCase().trim())
    .refine(validateEmail, errmsg || "Invalid email");
