import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

export const getEmployeeCheckDetails = (axios: IAxiosInstance) => {
  return async ({
    employee_check_id,
    includePayrollRun,
  }: ISchema["#/definitions/IGetEmployeeCheckDetailsQueryParams"]) => {
    const response = await axios.noRetry.get<
      ISchema["#/definitions/IGetEmployeeCheckDetailsResponse"]
    >(`/api/getEmployeeCheckDetails`, {
      params: { employee_check_id, includePayrollRun },
    });
    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
