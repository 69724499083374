import { AbstractApiClient } from "../AbstractApiClient/AbstractApiClient";
import { getCustomerAccount } from "./routes/getCustomerAccount/getCustomerAccount";
import { getCustomerAccounts } from "./routes/getCustomerAccounts/getCustomerAccounts";
import { patchCustomerAccount } from "./routes/patchCustomerAccount/patchCustomerAccount";
import { postCustomerAccount } from "./routes/postCustomerAccount/postCustomerAccount";
import { postFundingSource } from "./routes/postFundingSource/postFundingSource";
import { updateCustomerAccount } from "./routes/updateCustomerAccount/updateCustomerAccount";
import {
  assertValidMiraSchema,
  MiraSchemaAssertionWithParams,
} from "./validation-types/assertValidMiraSchema";

export class MiraClient extends AbstractApiClient {
  public static AssertValidSchema: MiraSchemaAssertionWithParams =
    assertValidMiraSchema;

  public readonly GetCustomerAccount = getCustomerAccount(this.Instance);
  public readonly GetCustomerAccounts = getCustomerAccounts(this.Instance);
  public readonly PatchCustomerAccount = patchCustomerAccount(this.Instance);
  public readonly PostCustomerAccount = postCustomerAccount(this.Instance);
  public readonly PostFundingSource = postFundingSource(this.Instance);
  public readonly UpdateCustomerAccount = updateCustomerAccount(this.Instance);
}
