import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "clients/LaForgeClient/validation-types/SchemaDefinition";
import { ZealApiSuccessResponse } from "@zeal/common";
import { IUpdateCompanyLocationResponseData } from "./updateCompanyLocation.jsonschema";
import { ZealClientError } from "../../../AbstractApiClient/ZealClientError";

type Response = ZealApiSuccessResponse<IUpdateCompanyLocationResponseData>;

export const updateCompanyLocation = (axios: IAxiosInstance) => {
  return async (body: ISchema["#/definitions/IUpdateCompanyLocationArgs"]) => {
    try {
      const response = await axios.noRetry.patch<Response>(
        `/api/updateCompanyLocation`,
        body
      );
      return response?.data?.data;
    } catch (e) {
      throw new ZealClientError(e);
    }
  };
};
