import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "clients/LaForgeClient/validation-types/SchemaDefinition";
import { ZealClientError } from "../../../AbstractApiClient/ZealClientError";
import { IGetCompanyLocationsResponseData } from "./getCompanyLocations.jsonschema";
import { ZealApiSuccessResponse } from "@zeal/common";

type SuccessResponse = ZealApiSuccessResponse<IGetCompanyLocationsResponseData>;

export const getCompanyLocations = (axios: IAxiosInstance) => {
  return async (params: ISchema["#/definitions/IGetCompanyLocationsArgs"]) => {
    try {
      const response = await axios.noRetry.get<SuccessResponse>(
        `/api/getCompanyLocations`,
        {
          params,
        }
      );
      return response?.data?.data;
    } catch (e) {
      throw new ZealClientError(e);
    }
  };
};
