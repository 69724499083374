import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";

const URL = "/api/approveCheck";
export const approveCheck = (axios: IAxiosInstance) => {
  return async (employeeCheckID: string) => {
    const response = await axios.noRetry.post(URL, { employeeCheckID });
    const resData = response.data;

    if (resData.success !== true) {
      throw new Error("Something went wrong");
    }
    return resData.data;
  };
};
