import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

export const deleteMember = (axios: IAxiosInstance) => {
  return async ({ role, id }: ISchema["#/definitions/IDeleteMemberArgs"]) => {
    const response = await axios.noRetry.post<
      ISchema["#/definitions/IDeleteMemberResponse"]
    >(`/api/deleteMember`, { role, id });

    const {
      data: { data, success },
    } = response;
    if (!success) {
      throw new Error(`Failed to delete member`);
    }
    return data;
  };
};
