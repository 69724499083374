import { z } from "zod";
export const statelessMinWageSchema = z.object({
  effectiveDate: z.string().optional(),
  features: z.array(z.string()).optional(),
});

export const statefulMinWageSchema = z.object({
  workLocationID: z.string(),
  effectiveDate: z.string().optional(),
});

export type TGetMinWageRulesRequestBody = z.infer<typeof statefulMinWageSchema>;
