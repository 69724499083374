import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TGetEmployeeEmployeeCheckDisbursementEventsRequestSchema,
  TGetEmployeeEmployeeCheckDisbursementEventsResponseSchema,
} from "./getEmployeeCheckDisbursementEvents.schema";

export const getEmployeeCheckDisbursementEvents = (axios: IAxiosInstance) => {
  return async (
    data: TGetEmployeeEmployeeCheckDisbursementEventsRequestSchema
  ) => {
    const { employee_check_id, ...rest } = data;

    const response = await axios.noRetry.get<
      IApiResponse<TGetEmployeeEmployeeCheckDisbursementEventsResponseSchema>
    >(`/api/employee-checks/${employee_check_id}/disbursement-events`, rest);

    return response.data;
  };
};
