import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";

export type SubmitEmailConfirmationResponse = IApiResponse<null>;

export const submitEmailConfirmation = (axios: IAxiosInstance) => {
  return async (options: { token: string }) => {
    const response = await axios.noRetry.post<SubmitEmailConfirmationResponse>(
      `/confirm-email`,
      options
    );

    const { data, success } = response.data;
    if (!success) {
      throw new Error("Failed to select account");
    }
    return data;
  };
};
