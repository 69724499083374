export type TFilter = "all" | "single" | "multiple" | "exclude";

export enum EQueryFilter {
  all = "all",
  single = "single",
  multiple = "multiple",
  exclude = "exclude",
  regex = "regex",
  include = "include",
}

export enum ECompareFilter {
  eq = "eq",
  gte = "gte",
  lte = "lte",
  gt = "gt",
  lt = "lt",
}

export type TFilterField<T, Key extends string> = {
  [K in Key]: T;
};

// QUERY FILTERS

export type TAllFilter = {
  type: EQueryFilter.all;
};
export type TSingleFilter<T, K extends string> = {
  type: EQueryFilter.single;
} & TFilterField<T, K>;

export type TMultipleFilter<T, K extends string> = {
  type: EQueryFilter.multiple;
} & TFilterField<T, K>;

export type TExcludeFilter<T, K extends string> = {
  type: EQueryFilter.exclude;
} & TFilterField<T, K>;

export type TRegexFilter<T, K extends string> = {
  type: EQueryFilter.regex;
} & TFilterField<T, K>;

export type TIncludeFilter<T, K extends string> = {
  type: EQueryFilter.include;
} & TFilterField<T, K>;

// COMPARE FILTERS

export type TEqualsFilter<T, K extends string> = {
  type: ECompareFilter.eq;
} & TFilterField<T, K>;

export type TGreaterThanOrEqualFilter<T, K extends string> = {
  type: ECompareFilter.gte;
} & TFilterField<T, K>;

export type TLessThanOrEqualFilter<T, K extends string> = {
  type: ECompareFilter.lte;
} & TFilterField<T, K>;

export type TLessThanFilter<T, K extends string> = {
  type: ECompareFilter.lt;
} & TFilterField<T, K>;

export type TGreaterThanFilter<T, K extends string> = {
  type: ECompareFilter.gt;
} & TFilterField<T, K>;
