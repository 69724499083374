import { AbstractApiClient } from "../AbstractApiClient/AbstractApiClient";
import { contractorPaymentWebhook } from "./routes/contractorPaymentWebhook/contractorPaymentWebhook";
import { contractorWebhook } from "./routes/contractorWebhook/contractorWebhook";
import { createEmployeeWebhook } from "./routes/createEmployeeWebhook/createEmployeeWebhook";
import { employeeCheckWebhook } from "./routes/employeeCheckWebhook/employeeCheckWebhook";
import { employeeOnboardingWebhook } from "./routes/employeeOnboardingWebhook/employeeOnboardingWebhook";
import { employerCheckCreatedWebhook } from "./routes/employerCheckCreatedWebhook/employerCheckCreatedWebhook";
import { employerCheckProcessedLeanWebhook } from "./routes/employerCheckProcessedLeanWebhook/employerCheckProcessedLeanWebhook";
import { employerCheckProcessedWebhook } from "./routes/employerCheckProcessedWebhook/employerCheckProcessedWebhook";
import { employerWebhook } from "./routes/employerWebhook/employerWebhook";
import { jobWebhook } from "./routes/jobWebhook/jobWebhook";
import { shiftWebhook } from "./routes/shiftWebhook/shiftWebhook";
import { updateEmployeeWebhook } from "./routes/updateEmployeeWebhook/updateEmployeeWebhook";
import {
  assertValidWebhookSchema,
  WebhookSchemaAssertionWithParams,
} from "./validation-types/assertValidWebhookSchema";

export class WebhookClient extends AbstractApiClient {
  public static readonly AssertValidSchema: WebhookSchemaAssertionWithParams =
    assertValidWebhookSchema;

  public readonly ContractorWebhook = contractorWebhook(this.Instance);
  public readonly ContractorPaymentWebhook = contractorPaymentWebhook(
    this.Instance
  );
  public readonly CreateEmployeeWebhook = createEmployeeWebhook(this.Instance);
  public readonly UpdateEmployeeWebhook = updateEmployeeWebhook(this.Instance);
  public readonly EmployeeOnboardingWebhook = employeeOnboardingWebhook(
    this.Instance
  );
  public readonly EmployeeCheckWebhook = employeeCheckWebhook(this.Instance);
  public readonly EmployerWebhook = employerWebhook(this.Instance);
  public readonly EmployerCheckCreatedWebhook = employerCheckCreatedWebhook(
    this.Instance
  );
  public readonly EmployerCheckProcessedWebhook = employerCheckProcessedWebhook(
    this.Instance
  );
  public readonly EmployerCheckProcessedLeanWebhook =
    employerCheckProcessedLeanWebhook(this.Instance);
  public readonly ShiftWebhook = shiftWebhook(this.Instance);
  public readonly JobWebhook = jobWebhook(this.Instance);
}
