import { z } from "zod";
import { employeeBaseRequirementSchema, employeeSchema } from "./baseEmployee";

import { bankAccountSchema } from "../../bank-accounts";
import { userAccountSchema } from "../../users";

export const payroll = z.literal("payroll");
export const payrollEmployeeProfilePayload = z
  .object({
    category: z.literal("profileInformation"),
  })
  .merge(
    employeeSchema
      .pick({
        email: true,
        first_name: true,
        last_name: true,
        middle_initial: true,
        phone_number: true,
        dob: true,
        ssn: true,
        address: true,
        address_line2: true,
        city: true,
        state: true,
        zip: true,
        title: true,
        start_date: true,
        default_pay_schedule: true,
        employment_status: true,
        working_state: true,
        workLocationID: true,
        onboarded: true,
        organization: true,
        is_salary: true,
        is_regular: true,
        is_943: true,
        is_scheduleH: true,
        default_wage: true,
        default_ot_wage: true,
        default_dt_wage: true,
        external_id: true,
        task_definition_ids: true,
      })
      .partial()
  );

export const payrollEmployeeSchema = employeeBaseRequirementSchema
  .extend({
    kind: payroll,
  })
  .merge(
    employeeSchema
      .pick({
        first_name: true,
        last_name: true,
        ssn: true,
        dob: true,
        phone_number: true,
        address: true,
        city: true,
        state: true,
        zip: true,
        title: true,
        workLocationID: true,
        default_pay_schedule: true,
        start_date: true,
        salary: true,
        is_salary: true,
        default_wage: true,
        default_ot_wage: true,
        default_dt_wage: true,
      })
      .passthrough()
      .partial()
  );

export const payrollEmployeeTaskFieldsSchema = z.object({
  kind: payroll,
  profileInformation: z.object({
    ...Object.fromEntries(
      Object.keys(payrollEmployeeSchema.omit({ kind: true }).shape).map(
        (key) => [key, z.boolean().optional()]
      )
    ),
  }),
  userAccount: z.object({
    ...Object.fromEntries(
      Object.keys(userAccountSchema.shape).map((key) => [
        key,
        z.boolean().optional(),
      ])
    ),
  }),
  bankAccount: z.object({
    ...Object.fromEntries(
      Object.keys(bankAccountSchema.shape).map((key) => [
        key,
        z.boolean().optional(),
      ])
    ),
  }),
});

export type PayrollEmployee = z.infer<typeof payrollEmployeeSchema>;
export type PayrollEmployeeTaskFields = z.infer<
  typeof payrollEmployeeTaskFieldsSchema
>;
export type PayrollEmployeeTask = {
  employeeID: string;
  fields: PayrollEmployeeTaskFields;
};
