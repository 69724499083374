import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ICreateEmployeeWebhookRequestBody } from "./createEmployeeWebhook.jsonschema";

export const createEmployeeWebhook = (axios: IAxiosInstance) => {
  return async (
    createEmployeeWebhook: string,
    userType: string,
    test: boolean
  ) => {
    const webhookBody: ICreateEmployeeWebhookRequestBody = {
      created_webhook: createEmployeeWebhook,
      user_type: userType,
      test,
    };
    const response = await axios.noRetry.post<unknown>(
      `/api/testCreationWebhook`,
      webhookBody
    );

    const { data } = response;

    return data;
  };
};
