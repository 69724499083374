import { z } from "zod";

export const reportingPeriodSchema = z.object({
  _id: z.string(),
  start_date: z.string(),
  end_date: z.string(),
  pay_schedule: z.string(),
});

export type ReportingPeriod = z.infer<typeof reportingPeriodSchema>;
