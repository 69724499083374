import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

export const getWLAlerts = (axios: IAxiosInstance) => {
  return async ({
    companyIDs,
    whiteLabelID,
  }: ISchema["#/definitions/IGetWLAlertsArgs"]) => {
    const params = new URLSearchParams({
      whiteLabelID,
      companyIDs: companyIDs.join(","),
    } as unknown as Record<string, string>);
    const uri = `/api/getWLAlerts?${params.toString()}`;

    const response =
      await axios.noRetry.get<ISchema["#/definitions/IGetWLAlertsResponse"]>(
        uri
      );
    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
