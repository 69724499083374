import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IDeleteCheckRequestBody } from "./deleteCheck.schema";

export const deleteCheck = (axios: IAxiosInstance) => {
  return async ({
    employeeCheckID,
    keepEmptyRun = false,
  }: IDeleteCheckRequestBody) => {
    const isBulkDelete =
      Array.isArray(employeeCheckID) && employeeCheckID.length > 1;
    const endpoint = isBulkDelete
      ? "/api/bulkDeleteChecks"
      : "/api/deleteCheck";

    const response = await axios.noRetry.post(endpoint, {
      ...(isBulkDelete
        ? { employeeCheckIDs: employeeCheckID }
        : {
            employeeCheckID: Array.isArray(employeeCheckID)
              ? employeeCheckID[0]
              : employeeCheckID,
          }),
      keepEmptyRun,
    });

    const {
      data: { success },
    } = response;

    if (!success) {
      throw new Error(`Failed to delete check`);
    }

    return {};
  };
};
