import { z } from "zod";
import { RouteMap } from "./customerReporting";

const reconciliationResponseschema = z.object({
  companyID: z.string(),
  zeal_id: z.string(),
  internal_total: z.number(),
  external_total: z.number(),
  odo_id: z.string(),
  type: z.string(),
  destination_user: z.string(),
  disbursement_status: z.string(),
  disbursement_method: z.string(),
  note: z.string(),
  bank_code: z.string(),
});

export type ReconciliationResponse = z.infer<
  typeof reconciliationResponseschema
>;

export type ReconciliationResponseShape = {
  kind: RouteMap.reconciliation_report;
  rows: ReconciliationResponse[];
};
