import { TRANSACTION_EXTERNAL_GET_PARAM } from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { TGetExternalStatusTransactionRequest } from "./getTransactionStatus.jsonschema";

// TODO remove this or update it for TP Shiraz March 8, 2024
export const getTransactionStatus = (axios: IAxiosInstance) => {
  return async (query: TGetExternalStatusTransactionRequest) => {
    const response = await axios.noRetry.get(
      `${TRANSACTION_EXTERNAL_GET_PARAM}${query.external_id}`
    );
    return response;
  };
};
