import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TGetCompanyDebitsRequestSchema,
  TGetCompanyDebitsResponseSchema,
} from "./getCompanyDebits.schema";

export const getCompanyDebits = (axios: IAxiosInstance) => {
  return async (params: TGetCompanyDebitsRequestSchema) => {
    const response = await axios.noRetry.get<
      IApiResponse<TGetCompanyDebitsResponseSchema>
    >(`/api/companyDebits`, {
      params,
    });

    if (!response?.data?.success) {
      throw new Error("Something went wrong");
    }

    return response?.data?.data;
  };
};
