import { schemes, zSchema } from "@zeal/common";

export const getCustomerAccountMicroDepositsRequestSchema = zSchema.object({
  odo_user_id: zSchema.string(),
});

export type TGetCustomerAccountMicroDepositsRequestSchema = zSchema.infer<
  typeof getCustomerAccountMicroDepositsRequestSchema
>;

export const getCustomerAccountMicroDepositsResponseSchema = zSchema.array(
  zSchema.object({
    amount_1: zSchema.string(),
    amount_2: zSchema.string(),
    attempts: zSchema.array(
      zSchema.object({
        amount_1: zSchema.string(),
        amount_2: zSchema.string(),
        ignored: zSchema.boolean(),
        odo_user_id: zSchema.string(),
        micro_deposit_attempt_id: schemes.uuid(),
        micro_deposit_id: schemes.uuid(),
      })
    ),
    odo_user_id: zSchema.string(),
    micro_deposit_id: schemes.uuid(),
    verified: zSchema.boolean(),
  })
);

export type TGetCustomerAccountMicroDepositsResponseSchema = zSchema.infer<
  typeof getCustomerAccountMicroDepositsResponseSchema
>;
