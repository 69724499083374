import { EKybStatus } from "./EKybStatus";
import { EMiddeskKybStatus } from "./EMiddeskKybStatus";

export const middeskKybStatusToEKybStatus = (
  middeskKybStatus: EMiddeskKybStatus
): EKybStatus => {
  switch (middeskKybStatus) {
    case EMiddeskKybStatus.Approved:
      return EKybStatus.Approved;
    case EMiddeskKybStatus.InReview:
      return EKybStatus.NeedsReview;
    default:
      return EKybStatus.NotStarted;
  }
};
