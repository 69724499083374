import { LaForgeClient } from "@zeal/morn";
import config from "./config";

window.addEventListener("error", async (event: ErrorEvent) => {
  const errorData = {
    message: event.message,
    filename: event.filename,
    lineno: event.lineno,
    colno: event.colno,
  };
  const client = new LaForgeClient({
    auth: { type: "localStorage" },
    baseURL: config().server_url,
  });

  await client.PostTelemetry({
    event: "app/uncaught-exception",
    data: errorData,
  });
});

// Escape hatch for --isolatedModules flag
export {};
