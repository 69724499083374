import { ZealApiErrorResponse } from "@zeal/common";
import { AxiosError } from "axios";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import {
  ICompanyKybOnboardRequestBody,
  ICompanyKybOnboardResponseBody,
} from "./companyKybOnboard.jsonschema";

export const companyKybOnboard =
  (axios: IAxiosInstance) => async (body: ICompanyKybOnboardRequestBody) => {
    try {
      const response = await axios.noRetry.post<ICompanyKybOnboardResponseBody>(
        `/api/company/onboard/kyb`,
        body
      );
      return response;
    } catch (e) {
      const errorMessage =
        (e as AxiosError)?.response?.data ??
        (e as ZealApiErrorResponse)?.errors?.[0]?.message ??
        "Error onboarding company";
      throw errorMessage;
    }
  };
