import { CreateRuleSetRequest, RULE_SET_ROUTES } from "@zeal/common";
import { IAxiosInstance } from "../../../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../../../AbstractApiClient/IApiResponse";

export const createRuleSet = (axios: IAxiosInstance) => {
  return async (data: CreateRuleSetRequest) => {
    const response = await axios.noRetry.post<IApiResponse<any>>(
      RULE_SET_ROUTES.CREATE_RULE_SET,
      data
    );

    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
