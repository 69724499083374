import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

export const company = (axios: IAxiosInstance) => {
  return async (params: ISchema["#/definitions/ICompanyQueryParameters"]) => {
    const response = await axios.noRetry.get<
      ISchema["#/definitions/ICompanyResponse"]
    >(`/api/company`, { params });
    const resData = response.data;

    return resData;
  };
};
