import {
  EEVerifyCaseClosureReasonsAfterCloseOnTNC,
  EPendingReferralDecision,
} from "@zeal/common";
import { z } from "zod";

export const postResolveEVerifyPendingReferralSchema = z.discriminatedUnion(
  "decision",
  [
    z.object({
      case_closure_reason_code: z.nativeEnum(
        EEVerifyCaseClosureReasonsAfterCloseOnTNC
      ),
      decision: z.literal(EPendingReferralDecision.CLOSE_CASE),
      workerID: z.string(),
    }),
    z.object({
      decision: z.literal(EPendingReferralDecision.NO_ACTION),
      employee_notified: z.literal(true),
      workerID: z.string(),
    }),
    z.object({
      decision: z.literal(EPendingReferralDecision.REFER),
      employee_notified: z.literal(true),
      workerID: z.string(),
    }),
  ]
);

export type TPostResolveEVerifyPendingReferral = z.infer<
  typeof postResolveEVerifyPendingReferralSchema
>;

export interface IPostResolvePendingReferral {
  decision: EPendingReferralDecision;
  case_closure_reason_code?: EEVerifyCaseClosureReasonsAfterCloseOnTNC;
  employee_notified?: boolean;
  workerID: string;
}
