import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TGetEmployeeChecksRequestSchema,
  TGetEmployeeChecksResponseSchema,
} from "./getEmployeeChecks.schema";

export const getEmployeeChecks = (axios: IAxiosInstance) => {
  return async (params: TGetEmployeeChecksRequestSchema) => {
    const response = await axios.noRetry.get<
      IApiResponse<TGetEmployeeChecksResponseSchema>
    >(`/api/employee-checks`, { params });

    return response.data;
  };
};
