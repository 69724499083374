/* eslint-disable @typescript-eslint/no-explicit-any */
import { isEqual } from "lodash";

type DocType<T> = T & Record<string, any>;

export function findDiff<T, K>(doc: T, update?: K, excludes?: string[]) {
  const excluded = new Set(excludes ?? []);

  return Object.keys(update ?? {})
    .filter((k) => !excluded.has(k))
    .reduce(
      (acc, key) => {
        const value = update != null ? (update as DocType<K>)[key] : null;
        if (!isEqual(value, (doc as DocType<T>)[key])) {
          return {
            ...acc,
            [key]: value,
          };
        }
        return acc;
      },
      {} as Record<string, any>
    );
}
