import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";
import { ICreatePayrollChecksRequest } from "./createPayrollChecks.jsonschema";

export const createPayrollChecks = (axios: IAxiosInstance) => {
  return async (checkInfo: ICreatePayrollChecksRequest) => {
    const response = await axios.noRetry.post<
      ISchema["#/definitions/ICreatePayrollChecksResponse"]
    >(`/api/createPayrollChecks`, checkInfo);
    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }
    throw new Error("Something went wrong");
  };
};
