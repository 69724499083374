import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TCreateContractorPayCardRequestSchema,
  TCreateContractorPayCardResponseSchema,
} from "./createContractorPayCard.schema";

export const createContractorPayCard = (axios: IAxiosInstance) => {
  return async (data: TCreateContractorPayCardRequestSchema) => {
    const { contractor_id, ...rest } = data;
    const response = await axios.noRetry.post<
      IApiResponse<IApiResponse<TCreateContractorPayCardResponseSchema>>
    >(`/api/contractors/${contractor_id}/pay-cards`, rest);

    return response.data;
  };
};
