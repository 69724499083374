import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IUpdateEmployeeWebhookRequestBody } from "./updateEmployeeWebhook.jsonschema";

export const updateEmployeeWebhook = (axios: IAxiosInstance) => {
  return async (employeeWebhook: string, userType: string, test: boolean) => {
    const webhookBody: IUpdateEmployeeWebhookRequestBody = {
      employee_webhook: employeeWebhook,
      user_type: userType,
      test,
    };
    const response = await axios.noRetry.post<unknown>(
      `/api/testEmployeeWebhook`,
      webhookBody
    );

    const { data } = response;

    return data;
  };
};
