import { COMPANY_VERIFY_ACH } from "@zeal/common";
import { AxiosError } from "axios";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import {
  IVerifyCompanyMicroDepositsRequestBody,
  IVerifyCompanyMicroDepositsResponseBody,
} from "./verifyMicroDeposits.jsonschema";

export const verifyCompanyMicroDeposits =
  (axios: IAxiosInstance) =>
  async (body: IVerifyCompanyMicroDepositsRequestBody) => {
    try {
      const response =
        await axios.noRetry.post<IVerifyCompanyMicroDepositsResponseBody>(
          COMPANY_VERIFY_ACH,
          body
        );
      return response;
    } catch (e) {
      const errorMessage =
        (e as AxiosError)?.response?.data ?? "Error verifying micro deposits";
      throw errorMessage;
    }
  };
