import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TUpdateCustomerAccountRequestSchema,
  TUpdateCustomerAccountResponseSchema,
} from "./updateCustomerAccount.schema";

export const updateCustomerAccount = (axios: IAxiosInstance) => {
  return async (data: TUpdateCustomerAccountRequestSchema) => {
    const { customer_account_id, ...rest } = data;

    const response = await axios.noRetry.patch<
      IApiResponse<TUpdateCustomerAccountResponseSchema>
    >(`/api/customer-accounts/${customer_account_id}`, rest);

    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
