import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import { TI9WebhookBody, TI9WebhookRequest } from "./testI9WebhookSchema";

export const testI9Webhook = (axios: IAxiosInstance) => {
  return async ({ testMode, url }: TI9WebhookRequest) => {
    const response = await axios.noRetry.post<IApiResponse<TI9WebhookBody>>(
      "/api/testI9Webhook",
      {
        url,
        testMode,
      }
    );

    const { data: responseData } = response;
    if (!responseData.success) {
      throw new Error(`Something went wrong`);
    }
    return responseData;
  };
};
