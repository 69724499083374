import { EContractorPaymentType } from "../contractor-payments";
import { ETxnSpeed } from "../misc-taxation";
import { schemes, zSchema } from "../schema-validation";
import { EDisbursementMethod } from "../workers-shared";

export const createContractorPaymentSchema = zSchema.object({
  amount: zSchema.string(),
  approval_required: zSchema.boolean().default(false),
  companyID: schemes.companyID(),
  contractorID: schemes.mongoObjectId(),
  disbursement: zSchema
    .object({
      method: zSchema.enum([
        EDisbursementMethod.DIRECT_DEPOSIT,
        EDisbursementMethod.PAYCARD,
        EDisbursementMethod.PREPAID,
      ] as const),
    })
    .optional(),
  pay_date: schemes.isoDate(),
  speed: zSchema.nativeEnum(ETxnSpeed).default(ETxnSpeed.TWO_DAY),
  type: zSchema
    .enum([
      EContractorPaymentType.REGULAR,
      EContractorPaymentType.REIMBURSEMENT,
    ] as const)
    .default(EContractorPaymentType.REGULAR),
});
