import { ReadonlySet } from "../data-structures";

export const ELocationStatus = {
  SHARED: "location_shared",
  DENIED: "permission_denied",
  UNAVAILABLE: "position_unavailable",
  TIMEOUT: "timeout",
} as const;

export type ELocationStatus =
  (typeof ELocationStatus)[keyof typeof ELocationStatus];

export const LOCATION_STATUSES = ReadonlySet.fromObjectValues(ELocationStatus);
