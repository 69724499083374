export enum EContactType {
  EMAIL = "EMAIL",
  FAX = "FAX",
  PHONE = "PHONE",
}

export type ContactType = `${EContactType}`;

export interface IWorkLocationContact {
  readonly kind: ContactType;
  readonly value: string;
}
