import { zSchema } from "../../../schema-validation";

export const migrateEmployeesSchema = zSchema.object({
  account_number: zSchema.string().optional(),
  account_type: zSchema.string().optional(),
  address: zSchema.string(),
  address_line2: zSchema.string().optional(),
  city: zSchema.string(),
  dob: zSchema.string(),
  email: zSchema.string(),
  first_name: zSchema.string(),
  last_name: zSchema.string(),
  odo_user_id: zSchema.string().optional(),
  phone_number: zSchema.string().optional(),
  routing_number: zSchema.string().optional(),
  tin: zSchema.string(),
  state: zSchema.string(),
  zeal_id: zSchema.string(),
  zip: zSchema.string(),
  enable_idempotency: zSchema.boolean().optional(),
  create_person_app: zSchema.boolean(),
});
