import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { TestMode } from "../../@types/general";
import { ISchema } from "../../validation-types/SchemaDefinition";

export const getUpcomingChecks = (axios: IAxiosInstance) => {
  return async (mode: TestMode) => {
    const response = await axios.noRetry.get<
      ISchema["#/definitions/IGetUpcomingCheckObject"]
    >(`/api/getUpcomingChecks`, {
      params: { test: mode === TestMode.Test },
    });
    return response.data;
  };
};
