import { StateCode } from "../jurisdictions";

export const statesRequiringEVerify = [
  "AL",
  "AZ",
  "FL",
  "GA",
  "LA",
  "MS",
  "NC",
  "SC",
  "TN",
  "UT",
] as const;

export const getAllStatesRequiringEVerifyForCompany = (
  companyEVerifyStates: StateCode[] | undefined = []
): StateCode[] =>
  Array.from(new Set([...companyEVerifyStates, ...statesRequiringEVerify]));

/**
 * If either the worker's residence state or work location state is in the list of states requiring E-Verify,
 * then an e-verify case must be created for the worker.
 */
export const getWhetherWorkerRequiresEVerify = ({
  companyEVerifyStates,
  workerResidenceState,
  workerWorkLocationState,
}: {
  companyEVerifyStates: StateCode[] | undefined;
  workerResidenceState: StateCode;
  workerWorkLocationState: StateCode;
}): boolean =>
  [workerResidenceState, workerWorkLocationState].some((state) =>
    getAllStatesRequiringEVerifyForCompany(companyEVerifyStates).includes(state)
  );
