import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { STATE_AGENCY_TRANSACTION_BULK_GET } from "@zeal/common";
import { ISchema } from "../../validation-types/SchemaDefinition";
import { TGetStateAgencyTransactionsBulkRequestBody } from "./getStateAgencyTransactionsBulk.jsonschema";

export const getStateAgencyTransactionsBulk = (axios: IAxiosInstance) => {
  return async (body: TGetStateAgencyTransactionsBulkRequestBody) => {
    const response = await axios.noRetry.post<
      ISchema["#/definitions/TGetStateAgencyTransactionsBulkResponseBody"]
    >(STATE_AGENCY_TRANSACTION_BULK_GET, body);
    return response.data;
  };
};
