import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

export const getTaxSetupInfo = (axios: IAxiosInstance) => {
  return async (company_id: string, jurisdiction?: string) => {
    const response = await axios.noRetry.get<
      ISchema["#/definitions/IGetTaxSetupInfoResponse"]
    >(`/api/taxSetupInfo`, {
      params: { company_id, jurisdiction },
    });
    return response.data;
  };
};
