import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { TestMode } from "../../@types/general";
import { ISchema } from "../../validation-types/SchemaDefinition";

export const getCompanyTaxRequirements = (axios: IAxiosInstance) => {
  return async (
    params: ISchema["#/definitions/IGetCompanyTaxRequirementsParamsArgs"]
  ) => {
    const { mode, companyID } = params;
    const response = await axios.noRetry.post<
      ISchema["#/definitions/IGetCompanyTaxRequirementsResponse"]
    >(`getCompanyTaxRequirements?test=${mode === TestMode.Test}`, {
      companyID,
    });
    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
