import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import {
  TGetEmployeeTaxParameterSummaryRequestSchema,
  TGetEmployeeTaxParameterSummaryResponseSchema,
} from "./getEmployeeTaxParameterSummarySchema";

export const getEmployeeTaxParameterSummary = (axios: IAxiosInstance) => {
  return async (body: TGetEmployeeTaxParameterSummaryRequestSchema) => {
    const response =
      await axios.noRetry.post<TGetEmployeeTaxParameterSummaryResponseSchema>(
        `/api/employee/getTaxParameterSummary`,
        body
      );

    return response.data;
  };
};
