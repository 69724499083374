export enum ECompanyStatus {
  CONTRACT_PENDING = "contract_pending",
  INITIAL_ONBOARDING = "initial_onboarding",
  LIVE = "live",
  MIGRATION_PENDING = "migration_pending",
  NEEDS_MORE_INFO = "needs_more_info",
  OFFBOARDED = "offboarded",
  PAYROLL_ACCESS_NEEDED = "payroll_access_needed",
  PAYMENT_PERMISSION_PENDING = "payment_permission_pending",
}
