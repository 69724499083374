import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

export const updatePayrollRun = (axios: IAxiosInstance) => {
  return async (
    requestBody: ISchema["#/definitions/IUpdatePayrollRunRequest"]
  ) => {
    const response = await axios.noRetry.post<
      ISchema["#/definitions/IUpdatePayrollRunResponse"]
    >(`/api/updatePayrollRun`, requestBody);
    const resData = response.data;

    if (resData.success) {
      return resData;
    }

    throw new Error("Something went wrong");
  };
};
