import { AccountLoginType } from "@zeal/common/dist/accounts";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";

export type GetCurrentUserAccountsResponse = IApiResponse<{
  user: { firstName: string; lastName: string; email: string; id: string };
  accounts: {
    created: Date;
    accountId: string;
    accountType: AccountLoginType;
    partnerId: string | null;
    partnerName: string | null;
    isTestAccount?: boolean;
    companies:
      | {
          companyId: string;
          companyName: string;
        }[]
      | null;
  }[];
}>;

export const getCurrentUserAccounts = (axios: IAxiosInstance) => {
  return async () => {
    const response =
      await axios.noRetry.get<GetCurrentUserAccountsResponse>(`/me/accounts`);

    const { data, success } = response.data;
    if (!success) {
      throw new Error("Failed to get current user accounts");
    }
    return data;
  };
};
