import { COMPANY_RUN_KYB } from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import {
  IRunCompanyKYBRequestBody,
  IRunCompanyKYBResponseBody,
} from "./runCompanyKYB.jsonschema";

export const runCompanyKYB = (axios: IAxiosInstance) => {
  return async (body: IRunCompanyKYBRequestBody) => {
    const response = await axios.noRetry.post<IRunCompanyKYBResponseBody>(
      COMPANY_RUN_KYB,
      body
    );

    return response.data;
  };
};
