import { z } from "zod";

const getMyRecentlyOnboardedContractorsQuerySchema = z.object({
  query: z.object({
    limit: z.number().optional(),
  }),
});

export type TGetMyRecentlyOnboardedContractorsQuery = z.infer<
  typeof getMyRecentlyOnboardedContractorsQuerySchema
>;

const getMyRecentlyOnboardedContractorsResponseData = z.object({
  _id: z.string(),
  first_name: z.string(),
  last_name: z.string(),
  state: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
});

export type TGetMyRecentlyOnboardedContractorsResponseData = Array<
  z.infer<typeof getMyRecentlyOnboardedContractorsResponseData>
>;
