export const GeneratedFileStatus = {
  PENDING: "PENDING",
  CREATED: "CREATED",
} as const;

export type FileStatus =
  (typeof GeneratedFileStatus)[keyof typeof GeneratedFileStatus];
export interface IGeneratedFilesBase {
  generatedFileID: string;
  deadlineID: string;
  jobID: string;
  status: string;
}

export interface IGeneratedFilesPending extends IGeneratedFilesBase {
  status: typeof GeneratedFileStatus.PENDING;
}

export interface IGeneratedFilesCreated extends IGeneratedFilesBase {
  status: typeof GeneratedFileStatus.CREATED;
  jobToken: string;
  correlationID: string;
  fileURI: string;
}

export type IGeneratedFiles = IGeneratedFilesPending | IGeneratedFilesCreated;

export const StateFilingConfKeys = {
  "AL-A6": "AL-A6",
  "AL-A1": "AL-A1",
  "CA-DE9": "CA-DE9",
  "MA-SUTA": "MA-SUTA",
  "MA-PFML": "MA-PFML",
  "CO-Quarterly-Wage-Report": "CO-Quarterly-Wage-Report",
  "TN-SUTA": "TN-SUTA",
  "DC-SUTA": "DC-SUTA",
  "TX-SUTA": "TX-SUTA",
  "VA-SUTA": "VA-SUTA",
  "IN-SUTA": "IN-SUTA",
  "FL-SUTA": "FL-SUTA",
  "GA-G7": "GA-G7",
  "GA-SUTA": "GA-SUTA",
  "UT-SUTA": "UT-SUTA",
  "ME-SUTA": "ME-SUTA",
  "ME-SIT": "ME-SIT",
  "MN-SUTA": "MN-SUTA",
  "ID-SUTA": "ID-SUTA",
  "AL-SUTA": "AL-SUTA",
  "IA-SUTA": "IA-SUTA",
  "KS-SUTA": "KS-SUTA",
  "AR-SUTA": "AR-SUTA",
  "KY-SUTA": "KY-SUTA",
  "LA-SUTA": "LA-SUTA",
  "CT-SUTA": "CT-SUTA",
  "MD-SUTA": "MD-SUTA",
  "MS-UIWAGE": "MS-UIWAGE",
  "SC-SUTA": "SC-SUTA",
  "MO-SUTA": "MO-SUTA",
  "NC-SUTA": "NC-SUTA",
  "MT-SUTA": "MT-SUTA",
  "ND-SUTA": "ND-SUTA",
  "OK-SUTA": "OK-SUTA",
  "NM-SUTA": "NM-SUTA",
  "NV-SUTA": "NV-SUTA",
  "WI-SUTA-CONTRIBUTION": "WI-SUTA-CONTRIBUTION",
  "WI-SUTA-WAGE": "WI-SUTA-WAGE",
  "NE-SUTA": "NE-SUTA",
  "MI-SUTA": "MI-SUTA",
  "CA-SUTA": "CA-SUTA",
  "OH-SUTA": "OH-SUTA",
  "PA-SUTA": "PA-SUTA",
  "WA-SUTA": "WA-SUTA",
  "CT-PFML": "CT-PFML",
  "IL-SUTA_MONTHLY": "IL-SUTA_MONTHLY",
  "IL-SUTA_QUARTERLY": "IL-SUTA_QUARTERLY",
  "NY-SUTA": "NY-SUTA",
  "HI-SUTA": "HI-SUTA",
  "OR-132": "OR-132",
  "OR-OQ": "OR-OQ",
  "OR-SCHEDULE-B": "OR-SCHEDULE-B",
  "IL-SIT": "IL-SIT",
  "NJ-WR303629": "NJ-WR303629",
  "SD-SUTA": "SD-SUTA",
  "WY-SUTA": "WY-SUTA",
  "AK-SUTA-HEADER": "AK-SUTA-HEADER",
  "AK-SUTA-WAGE": "AK-SUTA-WAGE",
  "WV-SUTA-HEADER": "WV-SUTA-HEADER",
  "WV-SUTA-WAGE": "WV-SUTA-WAGE",
  "CO-DENVER-OPT": "CO-DENVER-OPT",
  "WA-PFML": "WA-PFML",
} as const;

export type StateFilingConfKey = keyof typeof StateFilingConfKeys;
