import { z } from "zod";

type TParams = Parameters<typeof z.string>[0] & {
  readonly invalid_pattern_error?: string;
};

export const uuid = (customErrors: TParams = {}) => {
  const { invalid_pattern_error = "Invalid UUID", ...zStringParams } =
    customErrors;
  return z.string(zStringParams).regex(/^[0-9a-f]{32}$/, invalid_pattern_error);
};
