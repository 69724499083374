export const EProductType = {
  PAYROLL: "payroll",
  ONBOARDING_ONLY: "onboarding_only",
} as const;

export type EProductType = (typeof EProductType)[keyof typeof EProductType];

export const ProductTypes = [
  EProductType.PAYROLL.toString(),
  EProductType.ONBOARDING_ONLY.toString(),
] as const;

export const humanReadableProductTypeMap: Record<EProductType, string> = {
  [EProductType.PAYROLL]: "Payroll",
  [EProductType.ONBOARDING_ONLY]: "Onboarding only",
};
