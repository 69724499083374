import {
  BANK_VERIFY,
  TVerifyRoutingNumber,
  TVerifyRoutingNumberResponse,
} from "@zeal/common";
import { AxiosError } from "axios";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";

export const verifyRoutingNumber =
  (axios: IAxiosInstance) => async (query: TVerifyRoutingNumber) => {
    try {
      const response = await axios.noRetry.get<
        IApiResponse<TVerifyRoutingNumberResponse>
      >(BANK_VERIFY, {
        params: { routing_number: query.routingNumber },
      });
      return response.data?.data;
    } catch (e) {
      const errorMessage =
        (e as AxiosError)?.response?.data ?? "Error validating routing number";
      throw errorMessage;
    }
  };
