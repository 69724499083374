import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { TSaveCompanySignatureResponse } from "./saveCompanySignature.jsonschema";

export const saveCompanySignature = (axios: IAxiosInstance) => {
  return async (companyID: string, signature: string) => {
    const response = await axios.noRetry.post<TSaveCompanySignatureResponse>(
      `/api/saveCompanySignature`,
      {
        companyID,
        signature,
      }
    );
    const resData = response.data;
    if (!resData.success) {
      throw new Error("Something went wrong");
    }
    return resData.data;
  };
};
