import { AxiosResponse } from "axios";
import { IAxiosInstance } from "../../AbstractApiClient/AbstractApiClient";
import {
  IPaymentSubmission,
  IPostTXPPayment,
  TApiResponse,
} from "@zeal/common";

export const postTXPPayment =
  (axios: IAxiosInstance) => async (obj: IPostTXPPayment[]) => {
    const url = "/private/api/compass/addTXPPayment";

    const res = await axios.noRetry.request<
      TApiResponse<IPaymentSubmission[], "payments">,
      AxiosResponse<TApiResponse<IPaymentSubmission[], "payments">>,
      IPostTXPPayment[]
    >({
      method: "POST",
      url,
      data: obj,
    });

    return res.data;
  };
