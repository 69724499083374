import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

export const generateFileGetUrl = (axios: IAxiosInstance) => {
  return async ({
    key,
    bucket,
  }: ISchema["#/definitions/IGenerateFileGetUrlArgs"]) => {
    const response = await axios.noRetry.get<
      ISchema["#/definitions/IGenerateFileGetUrlResponse"]
    >(`/generate-file-get-url`, {
      params: {
        key,
        bucket,
      },
    });
    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
