import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import {
  TPreProcessChecksByIdRequestSchema,
  TPreProcessChecksByIdResponseSchema,
} from "./preProcessChecksById.schema";

export const preProcessChecksById = (axios: IAxiosInstance) => {
  return async (body: TPreProcessChecksByIdRequestSchema) => {
    const response =
      await axios.noRetry.post<TPreProcessChecksByIdResponseSchema>(
        `/api/picard/preprocessChecks`,
        body
      );

    return response.data;
  };
};
