export enum RoundingRule {
  // Full directed rules, they don't have a bias for the "nearest" integer.
  RoundAwayFromZero, // 0.5 -> 1, 0.3 -> 1, -0.5 -> -1, -0.3 -> -1
  RoundTowardsZero, // aka truncate, 0.5 -> 1, 0.3 -> 1, -0.5 -> -1, -0.3 -> -1
  RoundTowardsPositiveInfinity, // aka ceil, 0.5 -> 1, 0.3 -> 1, -0.5 -> 0, -0.3 -> 0
  RoundTowardsNegativeInfinity, // aka floor 0.5 -> 0, 0.3 -> 0, -0.5 -> -1, -0.3 -> -1

  // Biased towards nearest integer, directed 0.5 behavior
  RoundHalfTowardsPositiveInfinity, // aka Math.round(), 0.5 -> 1, -0.5 -> 0
  RoundHalfTowardsNegativeInfinity, // 0.5 -> 0, -0.5 -> -1
  RoundHalfTowardsZero, // 0.5 -> 0, -0.5 -> 0
  RoundHalfAwayFromZero, // aka common rounding, 0.5 -> 1, -0.5 -> -1
  RoundHalfToEven, // aka bankers rounding
  RoundHalfToOdd,
}

export class Round {
  static round(
    rule: RoundingRule,
    amount: number,
    decimalPlaces: number = 2
  ): number {
    if (rule == RoundingRule.RoundHalfTowardsPositiveInfinity) {
      amount = Round.roundHalfTowardsPositiveInfinity(amount, decimalPlaces);
    } else if (rule == RoundingRule.RoundHalfToEven) {
      amount = Round.bankersRound(amount, decimalPlaces);
    } else if (rule == RoundingRule.RoundAwayFromZero) {
      amount = Round.roundAwayFromZero(amount, decimalPlaces);
    } else if (rule == RoundingRule.RoundHalfAwayFromZero) {
      amount = Round.roundHalfAwayFromZero(amount, decimalPlaces);
    } else {
      throw new RangeError("unsupported rounding rule");
    }

    return amount;
  }

  private static bankersRound(n: number, d = 2) {
    const x = n * 10 ** d;
    const r = Math.round(x);
    const br = Math.abs(x) % 1 === 0.5 ? (r % 2 === 0 ? r : r - 1) : r;
    return br / 10 ** d;
  }

  private static roundHalfTowardsPositiveInfinity(n: number, d = 2) {
    const x = n * 10 ** d;
    const r = Math.round(x);
    return r / 10 ** d;
  }

  private static roundTowardsPositiveInfinity(n: number, d = 2) {
    const x = n * 10 ** d;
    const r = Math.ceil(x);
    return r / 10 ** d;
  }

  private static roundTowardsNegativeInfinity(n: number, d = 2) {
    const x = n * 10 ** d;
    const r = Math.floor(x);
    return r / 10 ** d;
  }

  private static roundHalfAwayFromZero(n: number, d = 2) {
    if (n >= 0) {
      return Round.roundHalfTowardsPositiveInfinity(n, d);
    }
    return -1 * Round.roundHalfTowardsPositiveInfinity(-1 * n, d);
  }

  private static roundAwayFromZero(n: number, d = 2) {
    if (n >= 0) {
      return Round.roundTowardsPositiveInfinity(n, d);
    }
    return Round.roundTowardsNegativeInfinity(n, d);
  }
}
