export enum ETxnSpeed {
  ZERO_DAY = "zero_day",
  ONE_DAY = "one_day",
  ONE_DAY_NO_RESERVE = "one_day_no_reserve",
  TWO_DAY = "two_day",
}

export const EAcclTxnSpeed = [
  ETxnSpeed.ZERO_DAY,
  ETxnSpeed.ONE_DAY,
  ETxnSpeed.ONE_DAY_NO_RESERVE,
];
