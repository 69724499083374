import { z } from "zod";

export const cacheMinimumWageViolationsSchema = z.object({
  testMode: z.boolean(),
  dryRun: z.boolean(),
});

export type TCacheMinimumWageViolationsRequestBody = z.infer<
  typeof cacheMinimumWageViolationsSchema
>;

export interface ICacheMinimumWageSuccess {
  updatedCount: number;
  pendingCount: number;
  durationMs: number;
  companyID: string;
}

export interface ICacheMinimumWageError {
  companyID: string;
  error: any;
}

export type TCacheMinimumWageViolationsResponse = Array<
  ICacheMinimumWageSuccess | ICacheMinimumWageError
>;
