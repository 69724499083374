import { z } from "zod";
import { schemes } from "../../schema-validation";
import { RouteMap } from "./customerReporting";

// base shifts used during reporting
export const shiftSchema = z.object({
  employeeCheckID: schemes.uuid(),
  shiftID: schemes.mongoObjectId(),
  custom_name: z.string().nullish(),
  customerAccountID: z.string().nullish(),
  wcc_code: z.string().nullish(),
  name: z.string(),
  label: z.string(),
  amount: z.string(),
  description: z.string(),
  shift_date: z.string(),
  unformatted_amount: z.number(),
  workLocationID: z.string().optional(),
  workLocation: z.string().optional(),
  hours: z.string().nullish(),
});

// shifts organized by some key -- work locationID, employeeID etc.
const shiftsByKeySchema = z.record(z.string(), z.array(shiftSchema));

// corrections to be displayed
const shiftCorrectionSchema = z.object({
  type: z.string(),
  rate: z.string(),
  earned: z.string(),
});

export type Shift = z.infer<typeof shiftSchema>;
export type ShiftsByKey = z.infer<typeof shiftsByKeySchema>;
export type ShiftCorrections = z.infer<typeof shiftCorrectionSchema>;

export const shiftsYtdResponseSchema = z.object({
  hourlyWage: z.string(),
  otHours: z.string(),
  sickHours: z.string(),
  ytdHours: z.string(),
  ytdGross: z.string(),
  avgHours: z.string(),
  accrualBalance: z.string(),
  accruedSickTime: z.string(),
  employeeID: z.string(),
  customerAccount: z.string(),
  fullName: z.string(),
  title: z.string(),
  workState: z.string(),
});

export type ShiftsYtdResponse = z.infer<typeof shiftsYtdResponseSchema>;
export type ShiftsYtdResponseShape = {
  rows: Array<z.infer<typeof shiftsYtdResponseSchema>>;
} & {
  kind: RouteMap.shifts_ytd;
};
