import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";
import { ICreateCustodyNodeRequestBody } from "./createCustodyNode.jsonschema";

export const createCustodyNode = (axios: IAxiosInstance) => {
  return async (body: ICreateCustodyNodeRequestBody) => {
    const response = await axios.noRetry.post<
      ISchema["#/definitions/ICreateCustodyNodeResponseBody"]
    >(`/api/company/add-custody`, body);
    return response.data;
  };
};
