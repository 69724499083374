import { EDocumentKey } from "../i9-everify";

export const EVeriffDocumentType = {
  DRIVERS_LICENSE: "DRIVERS_LICENSE",
  PASSPORT: "PASSPORT",
  ID_CARD: "ID_CARD",
  RESIDENCE_PERMIT: "RESIDENCE_PERMIT",
  VISA: "VISA",
  OTHER: "OTHER",
} as const;

export type EVeriffDocumentType = keyof typeof EVeriffDocumentType;

export const i9ToVeriffDocumentTypeMap: Partial<
  Record<EDocumentKey, EVeriffDocumentType>
> = {
  [EDocumentKey.US_PASSPORT]: EVeriffDocumentType.PASSPORT,
  [EDocumentKey.FOREIGN_PASSPORT]: EVeriffDocumentType.PASSPORT,
  [EDocumentKey.FOREIGN_PASSPORT_WITH_FORM_I94]: EVeriffDocumentType.PASSPORT,
  [EDocumentKey.FSM_OR_RMI_PASSPORT_WITH_FORM_I94]:
    EVeriffDocumentType.PASSPORT,
  [EDocumentKey.FOREIGN_PASSPORT_WITH_I551_STAMP]: EVeriffDocumentType.PASSPORT,
  [EDocumentKey.DRIVERS_LICENSE]: EVeriffDocumentType.DRIVERS_LICENSE,
  [EDocumentKey.CANADIAN_DRIVERS_LICENSE]: EVeriffDocumentType.DRIVERS_LICENSE,
  [EDocumentKey.GOVERNMENT_ID_CARD]: EVeriffDocumentType.ID_CARD,
  [EDocumentKey.US_MILITARY_CARD]: EVeriffDocumentType.ID_CARD,
  [EDocumentKey.US_COAST_GUARD_CARD]: EVeriffDocumentType.ID_CARD,
  [EDocumentKey.MILITARY_DEPENDENT_ID_CARD]: EVeriffDocumentType.ID_CARD,
  [EDocumentKey.EMPLOYMENT_AUTHORIZATION_DOCUMENT]: EVeriffDocumentType.ID_CARD,
  [EDocumentKey.NATIVE_AMERICAN_TRIBAL_DOCUMENT]: EVeriffDocumentType.ID_CARD,
  [EDocumentKey.FORM_I551]: EVeriffDocumentType.ID_CARD,
};
