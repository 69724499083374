import { EKybStatus, schemes, zSchema } from "@zeal/common";

export const updateCompanyRequestSchema = zSchema.object({
  company_id: schemes.uuid(),
  kyb_status: zSchema.nativeEnum(EKybStatus).optional(),
});

export type TUpdateCompanyRequestSchema = zSchema.infer<
  typeof updateCompanyRequestSchema
>;

export const updateCompanyResponseSchema = zSchema.object({
  company_id: schemes.uuid(),
  kyb_status: zSchema.nativeEnum(EKybStatus),
});

export type TUpdateCompanyResponseSchema = zSchema.infer<
  typeof updateCompanyResponseSchema
>;
