import { Jurisdiction } from "../jurisdictions/Jurisdiction";
import { EDeadlineType } from "./deadline_types";
import { EDeadlineStatusAlias } from "./EDeadlineStatusAlias";
import { IPaymentSchedulerResponse } from "./IPaymentSchedulerResponse";
import { TCompanyFilter } from "./pipeline/filters/company_filter";
import { TDateFilter, EDateView } from "./pipeline/filters/date_filter";
import { TJurisdictionFilter } from "./pipeline/filters/jurisdiction_filter";
import { TTaxTypeFilter } from "./pipeline/filters/taxType_filter";
export interface IGetPaymentDeadlinesForCompanyIDRequest {
  readonly quark_company_id?: string;
  readonly quark_company_ids?: string[];
  readonly company_filter?: TCompanyFilter;
  readonly jurisdiction_filter?: TJurisdictionFilter;
  readonly tax_type_filter?: TTaxTypeFilter;
  readonly date_filter?: TDateFilter;
  readonly start_date?: string;
  readonly end_date?: string;
  readonly dry_run?: boolean;
  readonly deadline_type?: EDeadlineType;
  readonly response_type?: EResponseType;
}

export enum EResponseType {
  CSV = "CSV",
  JSON = "JSON",
}

export interface IGetOustandingDeadlinesSummaryRequest {
  readonly deadline_type?: EDeadlineType;
  readonly date_filter?: TDateFilter | EDateView;
  readonly company_filter?: TCompanyFilter;
  readonly jurisdiction_filter?: TJurisdictionFilter;
  readonly tax_type_filter?: TTaxTypeFilter;
  readonly use_laforge_ids?: boolean;
}
export interface IGetOustandingDeadlinesSummaryResponse {
  readonly jurisdiction: Jurisdiction;
  readonly tax_type_code: string;
  readonly deadline: string;
  readonly deadline_type: EDeadlineType;
  readonly status?: EDeadlineStatusAlias;
  readonly companyIDs: string[];
}

export interface IGetPaymentDeadlinesForCompanyIDForDateRangeRequest {
  readonly quark_company_id: string;
  readonly start_date: string;
  readonly end_date: string;
  readonly dry_run?: boolean;
}

export interface IGetPaymentDeadlinesRequest {
  readonly check_date: string;
}

export interface IGetPaymentDeadlinesForCompanyIDResponse {
  readonly deadlines: IPaymentSchedulerResponse[] | string;
}
