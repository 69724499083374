import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TUpdatePartnerRequestSchema,
  TUpdatePartnerResponseSchema,
} from "./updatePartner.schema";

export const updatePartner = (axios: IAxiosInstance) => {
  return async (data: TUpdatePartnerRequestSchema) => {
    const { partner_id, ...rest } = data;

    const response = await axios.noRetry.patch<
      IApiResponse<TUpdatePartnerResponseSchema>
    >(`/api/partners/${partner_id}`, rest);

    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
