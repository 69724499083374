import { zSchema } from "@zeal/common";

export const postLaborAllocationRequestSchema = zSchema.object({
  companyID: zSchema.string(),
  customer_account_ids: zSchema.array(zSchema.string()),
  media_type: zSchema.string(),
  start_date: zSchema.string(),
  end_date: zSchema.string(),
  include_migrated: zSchema.boolean().default(false),
});

export type TPostLaborAllocationRequestSchema = zSchema.TypeOf<
  typeof postLaborAllocationRequestSchema
>;

export const postLaborAllocationResponseSchema = zSchema.object({
  created_at: zSchema.string(),
  job_id: zSchema.string(),
  request_body: postLaborAllocationRequestSchema,
  status: zSchema.string(),
});

export type TPostLaborAllocationResponseSchema = zSchema.TypeOf<
  typeof postLaborAllocationResponseSchema
>;
