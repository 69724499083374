import { Jurisdiction } from "../jurisdictions/Jurisdiction";
import { EDepositSchedule } from "./EDepositSchedule";

export enum EDeadlineType {
  PAYMENT = "PAYMENT",
  FILE = "FILE",
}

export enum EFileDeadlineType {
  REGULAR = "REGULAR",
  ZERO_WAGE = "ZERO_WAGE",
}
export interface IDeadline {
  readonly deadlineID: string;
  readonly deadlineType: EDeadlineType;
  readonly quarkCompanyID: string;
  readonly reportingPeriodID: string;
  readonly deadline: string; // YYYY-MM-DD
  readonly taxType: string;
  readonly depositSchedule: EDepositSchedule;
  readonly isAccelerated: boolean;
  readonly jurisdiction: Jurisdiction;
  readonly deadlineNotes: string[];
  readonly isUserProvided: boolean;
}
export interface IPaymentDeadline extends IDeadline {
  readonly deadlineType: EDeadlineType.PAYMENT;
  readonly paymentFrequency: EDepositSchedule;
}

export interface IFileDeadline extends IDeadline {
  readonly deadlineType: EDeadlineType.FILE;
  readonly filingFrequency: EDepositSchedule;
  readonly form_name: string;
  readonly fileType: EFileDeadlineType;
}

export const isDeadline = (x: any): x is IDeadline =>
  x.deadlineID !== undefined &&
  typeof x.deadlineID === "string" &&
  x.reportingPeriodID !== undefined &&
  typeof x.reportingPeriodID === "string";

export const isPaymentDeadline = (x: any): x is IPaymentDeadline =>
  isDeadline(x) && x.deadlineType === EDeadlineType.PAYMENT;

export const isFileDeadline = (x: any): x is IFileDeadline =>
  isDeadline(x) && x.deadlineType === EDeadlineType.FILE;
