import { z } from "zod";
import { EFaxStatus } from "../../uhura";
import { RouteMap } from "./customerReporting";

export const faxSummaryOptionsSchema = z.object({
  companyID: z.string(),
  start_date: z.string({
    required_error: "start date is a required field.",
  }),
  end_date: z.string({ required_error: "end date is a required field." }),
  status: z.enum([
    EFaxStatus.PENDING,
    EFaxStatus.QUEUED,
    EFaxStatus.SENDING_FAILED,
    EFaxStatus.RECEIVED,
    EFaxStatus.SENT,
  ]),
  agency: z.string(),
  fax_type: z.string(),
});

export const faxSummaryResponseSchema = z.object({
  url: z.string(),
  contact_name: z.string(),
  contact_number: z.string(),
  fax_type: z.string(),
  status: z.enum([
    EFaxStatus.PENDING,
    EFaxStatus.QUEUED,
    EFaxStatus.SENDING_FAILED,
    EFaxStatus.RECEIVED,
    EFaxStatus.SENT,
  ]),
  time_sent: z.string(),
  workerID: z.string(),
  companyID: z.string(),
  max_retries_met: z.string().optional(),
});

export type FaxSummaryOptions = z.infer<typeof faxSummaryOptionsSchema>;
export type FaxSummaryResponse = z.infer<typeof faxSummaryResponseSchema>;
export type FaxSummaryResponseShape = {
  kind: RouteMap.fax_summary;
  rows: FaxSummaryResponse[];
};
