import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import { TCustomerAccountWithFundingSource } from "../../../MiraClient/schemas";

export const getCustomerAccounts = (axios: IAxiosInstance) => {
  return async (params: { companyID: string }) => {
    const response = await axios.noRetry.get<
      IApiResponse<TCustomerAccountWithFundingSource[]>
    >(`/customer-accounts`, {
      params,
    });

    return response.data;
  };
};
