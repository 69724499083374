import { zSchema } from "../../../schema-validation";

export const reconcileBankAccountsSchema = zSchema.object({
  bankAccounts: zSchema.array(
    zSchema.object({
      id: zSchema.string(),
      account_number: zSchema.string().optional(),
      routing_number: zSchema.string().optional(),
    })
  ),
});
