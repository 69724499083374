import { ZealApiResponse } from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import {
  TGetBulkEVerifyPublicCaseStatusesRequest,
  TGetBulkEVerifyPublicCaseStatusesResponse,
} from "./getBulkEVerifyPublicCaseStatusesSchema";

export const getBulkEVerifyPublicCaseStatuses = (axios: IAxiosInstance) => {
  return async (data: TGetBulkEVerifyPublicCaseStatusesRequest) => {
    const response = await axios.noRetry.post<
      ZealApiResponse<TGetBulkEVerifyPublicCaseStatusesResponse>
    >("/api/getBulkEVerifyPublicCaseStatuses", { ...data });
    const resData = response.data;
    if (resData.success) {
      return resData.data;
    }
    throw new Error("Something went wrong");
  };
};
