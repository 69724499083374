import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { getUsePrimaryUrlParams } from "../../utils/utils";
import { ISchema } from "clients/LaForgeClient/validation-types/SchemaDefinition";

export const testCheckCreatedWebhook = (axios: IAxiosInstance) => {
  return async ({
    checkCreatedWebhook,
    userType,
    test,
    usePrimary,
  }: ISchema["#/definitions/ITestCheckCreatedWebhookArgs"]) => {
    const response = await axios.noRetry.post<
      ISchema["#/definitions/ITestCheckCreatedWebhookResponse"]
    >(`/api/testCheckCreatedWebhook${getUsePrimaryUrlParams(usePrimary)}`, {
      check_created_webhook: checkCreatedWebhook,
      user_type: userType,
      test,
    });

    const { data: responseData } = response;
    if (!responseData.success) {
      throw new Error(`Something went wrong`);
    }
    return responseData;
  };
};
