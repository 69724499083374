/**
 * Duplicate of EWorkerVerificationIDType from "../accounts"
 * Importing it from "../accounts" causes a circular dependency
 */
export enum EWorkerVerificationIDType {
  I9_REQUIREMENTS = "i9_requirements",
  GOVERNMENT_PHOTO_ID = "government_photo_id",
  PASSPORT = "passport",
  DRIVERS_LICENSE = "drivers_license", // equivalent to GOVERNMENT_PHOTO_ID with type drivers_license
  SOCIAL_SECURITY_CARD = "social_security_card",
  SOCIAL_SECURITY_CARD_BACK = "social_security_card_back",
  OTHER = "other",
  OTHER_BACK = "other_back",
}
