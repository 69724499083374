import { ZealApiErrorResponse } from "@zeal/common";
import { AxiosError } from "axios";

const createZealApiErrorResponse = (message: string): ZealApiErrorResponse => ({
  success: false,
  testMode: false,
  errors: [
    {
      message,
    },
  ],
});

export function returnZealApiErrorResponse(err: unknown): ZealApiErrorResponse {
  const isUnknownError = !(err instanceof AxiosError);
  if (isUnknownError)
    return createZealApiErrorResponse(
      "Something went wrong: Received unknown error type."
    );

  // Axios received a response from API with HTTP code other than 200
  if (err.response) {
    return err.response.data;
  }

  return createZealApiErrorResponse(`Unexpected Axios error: ${err.message}.`);
}
