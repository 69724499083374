import { AxiosError } from "axios";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TGetCompanyAccountsRequestSchema,
  TGetCompanyAccountsResponseSchema,
} from "./getCompanyAccountsSchema";

export const getCompanyAccounts =
  (axios: IAxiosInstance) =>
  async (params: TGetCompanyAccountsRequestSchema) => {
    try {
      const response = await axios.noRetry.get<
        IApiResponse<TGetCompanyAccountsResponseSchema>
      >(`/api/companies/${params.odo_user_id}/accounts`, { params });
      return response.data;
    } catch (e) {
      const errorMessage =
        (e as AxiosError)?.response?.data ??
        "Error retrieving company accounts";
      throw errorMessage;
    }
  };
