import {
  ECompanyStatus,
  EContractorPaymentStatus,
  EDeductionType,
  EDisbursementMethod,
  EEmployeeCheckStatus,
  EEmployerCheckStatus,
  ETxnSpeed,
  schemes,
  zSchema,
} from "@zeal/common";

export const getCompanyDebitsRequestSchema = zSchema.object({
  check_date: schemes.isoDate(),
  only_pending: schemes.booleanString(),
  test: schemes.booleanString(),
});

export type TGetCompanyDebitsRequestSchema = zSchema.infer<
  typeof getCompanyDebitsRequestSchema
>;

export const getCompanyDebitsObjectSchema = zSchema.object({
  business_name: zSchema.string(),
  checks: zSchema.array(
    zSchema.object({
      approval_required: zSchema.boolean(),
      approved: zSchema.boolean(),
      check_date: schemes.isoDate(),
      customer_account_totals: zSchema.record(
        zSchema.object({
          company_cash_requirement: zSchema.number(),
          company_debit: zSchema.number(),
        })
      ),
      deductions: zSchema.array(
        zSchema.object({
          deduction_type: zSchema.nativeEnum(EDeductionType),
        })
      ),
      disbursement: zSchema.object({
        method: zSchema.nativeEnum(EDisbursementMethod),
      }),
      employerCheckID: schemes.mongoObjectId().optional(),
      paystub_id: schemes.uuid(),
      speed: zSchema.nativeEnum(ETxnSpeed),
      status: zSchema.nativeEnum(EEmployeeCheckStatus),
      status_detail: zSchema.string().optional(),
    })
  ),
  company_id: schemes.uuid(),
  company_status: zSchema.nativeEnum(ECompanyStatus),
  employer_check: zSchema
    .object({
      _id: schemes.mongoObjectId(),
      customer_account_totals: zSchema.record(
        zSchema.object({
          company_cash_requirement: zSchema.number(),
          company_debit: zSchema.number(),
        })
      ),
      status: zSchema.nativeEnum(EEmployerCheckStatus),
      totals: zSchema.object({
        company_debit: zSchema.number(),
      }),
    })
    .nullable(),
  expected_transactions: zSchema.number(),
  id: schemes.uuid(),
  is_sandbox: zSchema.boolean(),
  odo_user_id: zSchema.string(),
  partner_name: zSchema.string(),
  payments: zSchema.array(
    zSchema.object({
      approval_required: zSchema.boolean(),
      approved: zSchema.boolean(),
      disbursement: zSchema.object({
        method: zSchema.nativeEnum(EDisbursementMethod),
      }),
      status: zSchema.nativeEnum(EContractorPaymentStatus),
    })
  ),
  quark_company_id: schemes.mongoObjectId(),
  speed: zSchema.nativeEnum(ETxnSpeed),
});

export type TGetCompanyDebitsObjectSchema = zSchema.infer<
  typeof getCompanyDebitsObjectSchema
>;

export const failedChecksSchema = zSchema.object({
  business_name: zSchema.string(),
  check_date: schemes.isoDate(),
  company_id: schemes.uuid(),
  failed_reason: zSchema.string(),
  paystub_id: schemes.uuid(),
  speed: zSchema.nativeEnum(ETxnSpeed),
});

export type TFailedChecksSchema = zSchema.infer<typeof failedChecksSchema>;

export const getCompanyDebitsResponseSchema = zSchema.object({
  failed_checks: zSchema.array(failedChecksSchema),
  non_live_checks: zSchema.array(getCompanyDebitsObjectSchema),
  pending_approval_checks: zSchema.array(getCompanyDebitsObjectSchema),
  valid_checks: zSchema.array(getCompanyDebitsObjectSchema),
});

export type TGetCompanyDebitsResponseSchema = zSchema.infer<
  typeof getCompanyDebitsResponseSchema
>;
