import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

export const deleteShift = (axios: IAxiosInstance) => {
  return async (requestBody: { shift: { shiftID: string } }) => {
    const { shift } = requestBody;
    const response = await axios.noRetry.post<
      ISchema["#/definitions/IDeleteShiftResponse"]
    >(`api/deleteShift`, {
      shift,
    });
    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
