import { AxiosError } from "axios";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";

export const getCompanyMicroDeposits = (axios: IAxiosInstance) => {
  return async (query: { id: string }) => {
    try {
      const response = await axios.noRetry.get<any>(
        `/api/company/${query.id}/micro-deposits`
      );
      if (response.data?.success) {
        return response.data.data;
      }
    } catch (e) {
      const errorMessage =
        (e as AxiosError)?.response?.data ?? "Error fetching micro deposits";
      throw errorMessage;
    }
  };
};
