import { TRANSACTION_CREATE } from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TCreateTransactionRequestSchema,
  TCreateTransactionResponseSchema,
} from "./createTransaction.schema";

export const createTransaction = (axios: IAxiosInstance) => {
  return async (body: TCreateTransactionRequestSchema) => {
    const response = await axios.noRetry.post<
      IApiResponse<TCreateTransactionResponseSchema>
    >(TRANSACTION_CREATE, body);
    return response.data;
  };
};
