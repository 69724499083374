import { zSchema } from "../schema-validation";

export const veriffResponseListingSchema = zSchema.object({
  sourceName: zSchema.string(),
  sourceUrl: zSchema.string().optional(),
  date: zSchema.string().optional(),
});

export const veriffResponseHitsSchema = zSchema.object({
  countries: zSchema.array(zSchema.string()),
  aka: zSchema.array(zSchema.string()),
  matchedName: zSchema.string(),
  matchTypes: zSchema.array(zSchema.string()),
  listingsRelatedToMatch: zSchema.object({
    adverseMedia: zSchema.array(veriffResponseListingSchema).optional(),
    fitnessProbity: zSchema.array(zSchema.unknown()).optional(),
    pep: zSchema.array(veriffResponseListingSchema).optional(),
    sanctions: zSchema.array(veriffResponseListingSchema).optional(),
    warnings: zSchema.array(veriffResponseListingSchema).optional(),
  }),
  associates: zSchema.array(zSchema.string()).optional(),
});

export type TVeriffResponseHits = zSchema.infer<
  typeof veriffResponseHitsSchema
>;
