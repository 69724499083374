import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

export const getStateTaxConfig = (axios: IAxiosInstance) => {
  return async ({
    companyID,
    jurisdiction,
    scope,
    pageNumber,
    filter,
    q,
  }: ISchema["#/definitions/IGetStateTaxConfigQueryParams"]) => {
    const response = await axios.noRetry.get<
      ISchema["#/definitions/IGetStateTaxConfigResponse"]
    >("/taxconfig/local", {
      params: { companyID, jurisdiction, scope, pageNumber, filter, q },
    });
    return response.data;
  };
};
