import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

const URL = `reports/cash-requirements`;
export const postCashRequirements = (axios: IAxiosInstance) => {
  return async (
    requestBody: ISchema["#/definitions/IPostCashRequirementsRequestBody"]
  ) => {
    const response = await axios.noRetry.post<
      ISchema["#/definitions/IPostCashRequirementsResponseBody"]
    >(URL, requestBody);
    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
