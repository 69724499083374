import { z } from "zod";

/**
 * IMPORTANT: DO NOT USE FOR NEW ROUTES - ONLY USED AS A PRECAUTION WHEN CONVERTING TO FED-SDK
 *
 * `numberish` checks if the input is either a number or a string.
 * If the input is a string, it attempts to convert it into a number.
 *
 * Note: To include further zod constraints, you'll need to use pipe or refine.
 *
 * @example
 * numberish().parse("123"); // returns 123
 * numberish().parse(123); // returns 123
 * numberish().parse("abc"); // throws an zod error
 * numberish().pipe(z.number().positive()).parse(-10); // throws an zod error
 * numberish().refine((val) => val > 0).parse(-10); // throws an zod error
 */

export const numberish = () =>
  z.number().or(z.string().trim().min(1)).pipe(z.coerce.number());
