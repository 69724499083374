import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

export interface ILoginArgs {
  readonly username: string;
  readonly password: string;
}

export const login = (axios: IAxiosInstance) => {
  return async ({ username, password }: ILoginArgs) => {
    const response = await axios.noRetry.post<
      | ISchema["#/definitions/LoginObjectResponse"]
      | ISchema["#/definitions/LoginObjectResponse2"]
    >(`/login`, { username, password });
    const resData = response.data;

    return resData;
  };
};
