export enum ELLCTaxClassification {
  C_CORPORATION = "c_corporation",
  S_CORPORATION = "s_corporation",
  PARTNERSHIP = "partnership",
}

export const llcTaxClassifications: ELLCTaxClassification[] = [
  ELLCTaxClassification.C_CORPORATION,
  ELLCTaxClassification.S_CORPORATION,
  ELLCTaxClassification.PARTNERSHIP,
];

interface ILLCTaxClassificationMenuItems {
  value: ELLCTaxClassification;
  label: string;
}

export const humanReadableLLCTaxClassificationMap: Record<
  ELLCTaxClassification,
  string
> = {
  [ELLCTaxClassification.C_CORPORATION]: "C Corporation",
  [ELLCTaxClassification.S_CORPORATION]: "S Corporation",
  [ELLCTaxClassification.PARTNERSHIP]: "Partnership",
};

export const llcTaxClassificationMenuItems: Array<ILLCTaxClassificationMenuItems> =
  [
    {
      value: ELLCTaxClassification.C_CORPORATION,
      label: "C Corporation",
    },
    {
      value: ELLCTaxClassification.S_CORPORATION,
      label: "S Corporation",
    },
    {
      value: ELLCTaxClassification.PARTNERSHIP,
      label: "Partnership",
    },
  ];
