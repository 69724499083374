import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import {
  TCreateEmployeeRequestSchema,
  TCreateEmployeeResponseSchema,
} from "./createEmployeeSchema";

export const createEmployee = (axios: IAxiosInstance) => {
  return async (body: TCreateEmployeeRequestSchema) => {
    const response = await axios.noRetry.post<TCreateEmployeeResponseSchema>(
      `/api/employee/createEmployee`,
      body
    );

    return response.data;
  };
};
