import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TGetJobsRequestSchema,
  TGetJobsResponseSchema,
} from "./getJobs.schema";

export const getJobs = (axios: IAxiosInstance) => {
  return async <Req, Res>(data: TGetJobsRequestSchema) => {
    const { job_ids, status } = data;

    const response = await axios.noRetry.get<
      IApiResponse<Array<TGetJobsResponseSchema<Req, Res>>>
    >(`/api/jobs`, { params: { job_ids, status } });

    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
