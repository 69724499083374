import { z } from "zod";

function validateRegex(pattern: any) {
  try {
    new RegExp(pattern);
    return true;
  } catch (e) {
    return false;
  }
}

// clean input and then check its valid
export const regex = () => z.string().refine(validateRegex, "Invalid regex");
