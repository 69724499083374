import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";
import { ISendContractorOnboardingLinkArgs } from "./sendContractorOnboardingLink.jsonschema";

export const sendContractorOnboardingLink = (axios: IAxiosInstance) => {
  return async (params: ISendContractorOnboardingLinkArgs) => {
    const response = await axios.noRetry.post<
      ISchema["#/definitions/ISendContractorOnboardingLinkResponse"]
    >(`/api/sendContractorOnboardingLink`, params);
    const data = response.data;

    if (data.success) {
      return data;
    }

    throw new Error("Something went wrong");
  };
};
