import { LEGAL_STRUCTURES } from "../odo/IOdoCompany";
import { EOwnerType, ICustomerAccount } from "./ICustomerAccount";
import { IFundingSource } from "../funding-sources/IFundingSource";
import { zSchema, schemes } from "../schema-validation";

export const updateCustomerAccountRequestSchema = zSchema.object({
  customer_account_id: zSchema.string(),
  partner_id: zSchema.string(),
  business_name: zSchema.string(),
  ein: zSchema.string().length(9, "EIN must be 9 characters"),
  legal_structure: zSchema.enum(LEGAL_STRUCTURES),
  phone: zSchema.string(),
  email: schemes.email(),
  business_address: zSchema.string(),
  business_city: zSchema.string(),
  business_state: zSchema.string(),
  business_zip: zSchema.string(),
  company_id: zSchema.string(),
  business_owner: zSchema.object({
    first_name: zSchema.string(),
    last_name: zSchema.string(),
    email: schemes.email(),
    ssn: schemes.ssn(),
    dob: zSchema.string(),
    title: zSchema.string(),
    ownership_percentage: zSchema.coerce.number().min(0).max(100),
    owner_type: zSchema.nativeEnum(EOwnerType),
    address: zSchema.string(),
    address_line2: zSchema.string().optional(),
    city: zSchema.string(),
    state: zSchema.string(),
    zip: zSchema.string(),
  }),
});

export type TUpdateCustomerAccountRequestSchema = zSchema.infer<
  typeof updateCustomerAccountRequestSchema
>;

export const getCustomerAccountRequestSchema = zSchema.object({
  company_id: zSchema.string(),
  customer_account_id: zSchema.string(),
});

export type TGetCustomerAccountRequestSchema = zSchema.infer<
  typeof getCustomerAccountRequestSchema
>;

export type TCustomerAccountWithFundingSource = ICustomerAccount & {
  funding_source: IFundingSource;
};

export type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends object ? DeepPartial<T[P]> : T[P];
};
