import { EKybStatus, schemes, zSchema } from "@zeal/common";

export const updateCustomerAccountRequestSchema = zSchema.object({
  customer_account_id: schemes.uuid(),
  kyb_status: zSchema.nativeEnum(EKybStatus).optional(),
});

export type TUpdateCustomerAccountRequestSchema = zSchema.infer<
  typeof updateCustomerAccountRequestSchema
>;

export const updateCustomerAccountResponseSchema = zSchema.object({
  customer_account_id: schemes.uuid(),
  kyb_status: zSchema.nativeEnum(EKybStatus),
});

export type TUpdateCustomerAccountResponseSchema = zSchema.infer<
  typeof updateCustomerAccountResponseSchema
>;
