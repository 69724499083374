import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

export interface IGetClosestCheckDateParams {
  readonly startDate?: string;
  readonly payrollSchedule?: string;
  readonly firstPayPeriodID?: string;
}

export const getClosestCheckDate = (axios: IAxiosInstance) => {
  return async (params: IGetClosestCheckDateParams) => {
    const { startDate, payrollSchedule, firstPayPeriodID } = params;
    const response = await axios.noRetry.get<
      ISchema["#/definitions/IGetClosestCheckDateResponse"]
    >(`/api/getClosestCheckDate`, {
      params: {
        startDate,
        payrollSchedule,
        firstPayPeriodID,
      },
    });
    const resData = response.data;

    if (resData.success) {
      return resData;
    }

    throw new Error("Something went wrong");
  };
};
