import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

export const getGuestInfo = (axios: IAxiosInstance) => {
  return async ({
    usePrimaryCookie = true,
  }: ISchema["#/definitions/IGetGuestInfoArgs"]) => {
    const response = await axios.noRetry.get<
      ISchema["#/definitions/IGetGuestInfoResponse"]
    >(`/api/getGuestInfo`, {
      params: { usePrimary: usePrimaryCookie },
    });
    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
