import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

export const patchTaxSetupInfo = (axios: IAxiosInstance) => {
  return async (
    requestBody: ISchema["#/definitions/IPatchTaxSetupInfoRequestBody"]
  ) => {
    const response = await axios.noRetry.patch<
      ISchema["#/definitions/IPatchTaxSetupInfoResponse"]
    >(`/api/taxSetupInfo`, requestBody);
    return response.data;
  };
};
