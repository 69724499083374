import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

export interface IGetPayrollRunByIdParams {
  readonly payrollRunID?: string;
  readonly regularOnly?: boolean;
  readonly paySchedule?: string;
}

export const getPayrollRunEmployees = (axios: IAxiosInstance) => {
  return async (params: IGetPayrollRunByIdParams) => {
    const response = await axios.noRetry.get<
      ISchema["#/definitions/IGetPayrollRunEmployeesResponse"]
    >(`/api/payroll-run-employees`, {
      params,
    });
    const resData = response.data;
    if (resData.success) {
      return resData.data;
    }
    throw new Error("Something went wrong");
  };
};
