import { RUN_KYC } from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IRunKYCRequestBody, IRunKYCResponseBody } from "./runKYC.jsonschema";

export const runKYC = (axios: IAxiosInstance) => {
  return async (body: IRunKYCRequestBody) => {
    const response = await axios.noRetry.post<IRunKYCResponseBody>(
      RUN_KYC,
      body
    );

    return response.data;
  };
};
