import { schemes, zSchema } from "@zeal/common";

export const getCompanyLocationsRequestSchema = zSchema.object({
  companyID: schemes.companyID(),
  companyLocationID: schemes.mongoObjectId().optional(),
});

export type TGetCompanyLocationsRequestSchema = zSchema.infer<
  typeof getCompanyLocationsRequestSchema
>;

export const companyLocation = zSchema.object({
  companyID: schemes.mongoObjectId(),
  id: schemes.mongoObjectId(),
  locationID: schemes.mongoObjectId(),
  name: zSchema.string(),
});

export type TCompanyLocation = zSchema.infer<typeof companyLocation>;

export const getCompanyLocationsResponseSchema = zSchema.array(companyLocation);

export type TGetCompanyLocationsResponseSchema = zSchema.infer<
  typeof getCompanyLocationsResponseSchema
>;
