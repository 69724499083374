import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { getUsePrimaryUrlParams } from "../../utils/utils";
import { ISchema } from "clients/LaForgeClient/validation-types/SchemaDefinition";

export const testCheckProcessedLeanWebhook = (axios: IAxiosInstance) => {
  return async ({
    checkProcessedLeanWebhook,
    userType,
    test,
    usePrimary,
  }: ISchema["#/definitions/ITestCheckProcessedLeanWebhookArgs"]) => {
    const response = await axios.noRetry.post<
      ISchema["#/definitions/ITestCheckProcessedLeanWebhookResponse"]
    >(
      `/api/testCheckProcessedLeanWebhook${getUsePrimaryUrlParams(usePrimary)}`,
      {
        check_processed_lean_webhook: checkProcessedLeanWebhook,
        user_type: userType,
        test,
      }
    );

    const { data: responseData } = response;
    if (!responseData.success) {
      throw new Error(`Something went wrong`);
    }
    return responseData;
  };
};
