import {
  TCustomerAccountWithFundingSource,
  TUpdateCustomerAccountRequestSchema,
} from "@zeal/common";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";

export const updateCustomerAccountInfo = (axios: IAxiosInstance) => {
  return async (body: TUpdateCustomerAccountRequestSchema) => {
    const response = await axios.noRetry.patch<
      IApiResponse<TCustomerAccountWithFundingSource>
    >(`/api/customer-accounts`, body);
    return response.data;
  };
};
