import { zSchema } from "../../../schema-validation";

export enum EntityType {
  business = "business",
  person = "person",
}

export const runAllKycSchema = zSchema.object({
  entity_type: zSchema.nativeEnum(EntityType),
});
