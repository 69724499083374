import { schemes, zSchema } from "@zeal/common";

export const postDeductionsSummaryRequestSchema = zSchema.object({
  companyID: schemes.companyID(),
  customer_account_ids: zSchema.array(schemes.uuid()).optional(),
  end_date: zSchema.string(),
  media_type: zSchema.enum(["csv"]),
  start_date: zSchema.string(),
});

export type TPostDeductionsSummaryRequestSchema = zSchema.infer<
  typeof postDeductionsSummaryRequestSchema
>;

export const postDeductionsSummaryResponseSchema = zSchema.object({
  created_at: zSchema.string(),
  job_id: zSchema.string(),
  status: zSchema.enum(["pending", "completed"]),
});

export type TPostDeductionsSummaryResponseSchema = zSchema.infer<
  typeof postDeductionsSummaryResponseSchema
>;
