import { z } from "zod";
import { ZealErrorAlias } from "../../errors/ZealErrors";
import { schemes } from "../../schema-validation";
import { employeeSchema } from "./baseEmployee";

export const updateEmployeeSchemaBase = z
  .object(
    {
      companyID: schemes.companyID(),
      testMode: z.boolean(),
      employeeID: schemes.mongoObjectId(
        "employeeID",
        ZealErrorAlias.EMPLOYEE_ID_NOT_OBJECTID.message
      ),
    },
    { required_error: "No employee object found in the body of request" }
  )
  .merge(
    employeeSchema
      .pick({
        first_name: true,
        middle_initial: true,
        last_name: true,
        employment_status: true,
        term_reason: true,
        email: true,
        title: true,
        phone_number: true,
        default_pay_schedule: true,
        ssn: true,
        workLocationID: true,
        external_id: true,
        metadata: true,
        is_943: true,
        is_scheduleH: true,
        is_regular: true,
        paperless_w2: true,
        benefits_class: true,
        is_salary: true,
        everify_er_shared_referral_date_confirmation_at: true,
        task_definition_ids: true,
        term_date: true,
        start_date: true,
        salary_firstDate: true,
        work_week_start: true,
        dob: true,
        address: true,
        address_line2: true,
        city: true,
        state: true,
        zip: true,
        default_wage: true,
        default_ot_wage: true,
        default_dt_wage: true,
        salary: true,
      })
      .partial()
  );

export const updateEmployeeSchema = updateEmployeeSchemaBase.omit({
  testMode: true,
  companyID: true,
});

export type UpdateEmployee = z.infer<typeof updateEmployeeSchema>;
