import { z } from "zod";
import { Jurisdiction } from "../jurisdictions/Jurisdiction";
import { EKYCStatus } from "../kyc/EKYCStatus";
import { EEmploymentStatus } from "../workers-shared";
import { ELaForgeWorkerEnum } from "../workers-shared/ELaForgeWorkerEnum";

// (incomplete) but intended to mimic the shape of a contractor in la-forge db
export const ZContractor = z.object({
  email: z.string().toLowerCase(),
  first_name: z.string().optional(),
  last_name: z.string().optional(),
  middle_name: z.string().optional(),
  address: z.string().optional(),
  address_line2: z.string().optional(),
  city: z.string().optional(),
  state: z.nativeEnum(Jurisdiction),
  zip: z.string().optional(),
  ssn: z.string().optional(),
  dob: z.string().optional(),
  ein: z.string().optional(),
  business_name: z.string().optional(),
  companyID: z.string().optional(),
  complete_paperwork: z.boolean().default(false),
  employment_status: z
    .nativeEnum(EEmploymentStatus)
    .default(EEmploymentStatus.LIVE),
  kyc_status: z.nativeEnum(EKYCStatus).optional(),
  onboard_docs_faxed: z.boolean().default(false),
  onboarded: z.boolean().default(false),
  organization: z.string().optional(),
  type: z.literal("individual").or(z.literal("business")),
  user_type: z
    .nativeEnum(ELaForgeWorkerEnum)
    .default(ELaForgeWorkerEnum.Contractor),
  working_state: z.nativeEnum(Jurisdiction).optional(),
});
