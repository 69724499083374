import { II9OnboardingInfo, ZealApiResponse } from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";

export const getInfoForAuthorizedRepresentative = (axios: IAxiosInstance) => {
  return async ({ accessToken }: { accessToken: string }) => {
    const response = await axios.noRetry.get<
      ZealApiResponse<II9OnboardingInfo>
    >(`/api/getInfoForAuthorizedRepresentative`, {
      params: { accessToken },
    });
    const resData = response.data;
    if (resData.success) {
      return { ...resData.data, testMode: response.data.testMode };
    }
    throw new Error("Something went wrong");
  };
};
