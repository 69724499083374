import {
  IGetCheckPDFRequestBody,
  IGetCheckPDFResponseData,
} from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";

export const getCheckPDF = (axios: IAxiosInstance) => {
  return async (requestBody: IGetCheckPDFRequestBody) => {
    const response = await axios.noRetry.post<
      IApiResponse<IGetCheckPDFResponseData>
    >(`api/getCheckPDF`, requestBody);
    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
