import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

export const generateFilePutUrl = (axios: IAxiosInstance) => {
  return async ({
    whiteLabelID,
    contentType,
    usePrimary = true,
  }: ISchema["#/definitions/IGenerateFilePutUrlArgs"]) => {
    const response = await axios.noRetry.get<{
      data: ISchema["#/definitions/IGenerateFilePutUrlResponse"];
    }>(`/generate-file-put-url`, {
      params: {
        key: `${whiteLabelID}-File`,
        contentType: contentType,
        usePrimary,
        whiteLabelID,
      },
      headers: {
        "Content-Type": contentType,
      },
    });

    const resData = response.data?.data;

    if (resData) {
      return resData;
    }

    throw new Error("Something went wrong");
  };
};
