export const ETreasuryPrimeKycResult = {
  ok: "ok",
  error: "error",
  pending: "pending",
} as const;

export type ETreasuryPrimeKycResult =
  (typeof ETreasuryPrimeKycResult)[keyof typeof ETreasuryPrimeKycResult];

export const TREASURY_PRIME_RESULTS = Object.values(
  ETreasuryPrimeKycResult
) as ReadonlyArray<ETreasuryPrimeKycResult>;
