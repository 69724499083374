import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

export const downloadPoaDocument = (axios: IAxiosInstance) => {
  return async (key: string) => {
    const response = await axios.noRetry.get<
      ISchema["#/definitions/IDownloadPoaDocumentResponse"]
    >(`/api/documents/poa/download`, {
      params: { prefix: key },
    });
    return response.data;
  };
};
