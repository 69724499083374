import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
export const createFundingSource = (axios: IAxiosInstance) => {
  return async (params: {
    companyID: string;
    customerAccountID: string;
    access_token?: string;
    accountNumber?: string;
    routingNumber?: string;
    accountType?: "checking" | "savings";
  }) => {
    const response = await axios.noRetry.post<IApiResponse<any>>(
      `/api/customer-accounts/${params.customerAccountID}/funding-sources`,
      {
        companyID: params.companyID,
        access_token: params.access_token,
        account_number: params.accountNumber,
        routing_number: params.routingNumber,
        account_type: params.accountType,
      }
    );

    return response.data;
  };
};
