import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { getUsePrimaryUrlParams } from "../../utils/utils";
import { ISchema } from "clients/LaForgeClient/validation-types/SchemaDefinition";

export const testEmployeeCheckWebhook = (axios: IAxiosInstance) => {
  return async ({
    employeeCheckWebhook,
    userType,
    test,
    usePrimary,
  }: ISchema["#/definitions/ITestEmployeeCheckWebhookArgs"]) => {
    const response = await axios.noRetry.post<
      ISchema["#/definitions/ITestEmployeeCheckWebhookResponse"]
    >(`/api/testEmployeeCheckWebhook${getUsePrimaryUrlParams(usePrimary)}`, {
      employee_check_webhook: employeeCheckWebhook,
      user_type: userType,
      test,
    });

    const { data: responseData } = response;
    if (!responseData.success) {
      throw new Error(`Something went wrong`);
    }
    return responseData;
  };
};
