import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { TestMode } from "../../@types/general";
import { ISchema } from "../../validation-types/SchemaDefinition";

export const getEmployeeReportingPeriods = (axios: IAxiosInstance) => {
  return async ({
    employeeID,
    mode,
  }: ISchema["#/definitions/IGetEmployeeReportingPeriodsRequestBody"]) => {
    const response = await axios.noRetry.post<
      ISchema["#/definitions/IGetEmployeeReportingPeriodsResponse"]
    >(`/api/getEmployeeReportingPeriods`, {
      employeeID,
      test: mode === TestMode.Test,
    });
    const data = response.data;

    if (data.success) {
      return data;
    }

    throw new Error("Something went wrong");
  };
};
