import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { TGetVeriffIdsParams, TGetVeriffIdsData } from "@zeal/common";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";

type TGetVeriffIdsResponse = IApiResponse<TGetVeriffIdsData>;

export const getVeriffIds = (axios: IAxiosInstance) => {
  return async (params: TGetVeriffIdsParams) => {
    const response = await axios.noRetry.get<TGetVeriffIdsResponse>(
      `/api/getVeriffIds`,
      {
        params,
      }
    );
    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
