import { z } from "zod";
import { getViolationsSchema as getViolationsSchemaRizzo } from "../rizzo";

export const getWageViolationsSchema = getViolationsSchemaRizzo.omit({
  defaultComplianceParameters: true,
  submissionTime: true,
  persistEvents: true,
});

export type TGetWageViolationsRequestBody = z.infer<
  typeof getWageViolationsSchema
>;
