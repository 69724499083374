import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";
import { IPostPreviewCheckDataRequestBody } from "./postPreviewCheckData.jsonschema";

export const postPreviewCheckData = (axios: IAxiosInstance) => {
  return async (body: IPostPreviewCheckDataRequestBody) => {
    const response = await axios.with502Retry.post<
      ISchema["#/definitions/IPreviewCheckDataResponseBody"]
    >(`/api/picard/preview-checkData`, body, {});
    return response.data;
  };
};
