import { z } from "zod";
import { schemes } from "../../../schema-validation";

export const verifyRoutingNumberSchema = z
  .object({ routingNumber: schemes.routingNumber() })
  .required();

export type TVerifyRoutingNumberResponse = boolean;
export type TVerifyRoutingNumber = z.infer<typeof verifyRoutingNumberSchema>;
export type TLFClientVerifyRoutingNumber = {
  routing_number: TVerifyRoutingNumber["routingNumber"];
};
