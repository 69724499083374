import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";

export const getCheckCsvTemplate = (axios: IAxiosInstance) => {
  return async () => {
    const response = await axios.noRetry.get("/api/check-csv-template");
    const resData = response.data;

    if (resData) {
      return resData;
    }

    throw new Error("Something went wrong");
  };
};
