import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import { TGetPayCardArtResponseSchema } from "./getPayCardArt.schema";

export const getPayCardArt = (axios: IAxiosInstance) => {
  return async () => {
    const response =
      await axios.noRetry.get<IApiResponse<TGetPayCardArtResponseSchema>>(
        `/api/pay-cards/art`
      );

    return response.data;
  };
};
