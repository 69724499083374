import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

export interface IGetChecksExistAlertParams {
  readonly companyID?: string;
  readonly employeeIDs?: string[];
  readonly checkDate?: string;
  readonly checkID?: string;
}

export const getChecksExistAlert = (axios: IAxiosInstance) => {
  return async (params: IGetChecksExistAlertParams) => {
    const { companyID, employeeIDs, checkDate, checkID } = params;
    const response = await axios.noRetry.get<
      ISchema["#/definitions/IGetChecksExistAlertResponse"]
    >(`/api/getChecksExistAlert`, {
      params: {
        companyID,
        employeeIDs,
        checkDate,
        checkID,
      },
    });
    const resData = response.data;

    if (resData.success) {
      return resData;
    }

    throw new Error("Something went wrong");
  };
};
