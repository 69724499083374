import { ILaForgeCompany, ZealApiResponse } from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";

export const getCompanyVeriffToken = (axios: IAxiosInstance) => {
  return async (companyId: string) => {
    const response = await axios.noRetry.post<ZealApiResponse<ILaForgeCompany>>(
      `/api/getCompanyVeriffToken`,
      {
        companyId: companyId,
      }
    );
    const resData = response.data;
    if (resData.success) {
      return resData.data;
    }
    throw new Error("Something went wrong");
  };
};
