import { z, type ZodSchema } from "zod";
import { EFieldName, type TFieldName } from "./allFields";

export const schemas: Record<TFieldName, ZodSchema> = {
  [EFieldName.first_name]: z.string(),
  [EFieldName.middle_initial]: z.string(),
  [EFieldName.last_name]: z.string(),
  [EFieldName.dob]: z.date(),
  [EFieldName.ssn]: z.string(),
  [EFieldName.phone_number]: z.string(),
  [EFieldName.address]: z.string(),
  [EFieldName.address_line2]: z.string().nullish(),
  [EFieldName.city]: z.string(),
  [EFieldName.state]: z.string(),
  [EFieldName.zip]: z.string(),
};
