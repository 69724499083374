import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TUpdateCompanyRequestSchema,
  TUpdateCompanyResponseSchema,
} from "./updateCompany.schema";

export const updateCompany = (axios: IAxiosInstance) => {
  return async (data: TUpdateCompanyRequestSchema) => {
    const { company_id, ...rest } = data;

    const response = await axios.noRetry.patch<
      IApiResponse<TUpdateCompanyResponseSchema>
    >(`/api/companies/${company_id}`, rest);

    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
