import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TGetPayCardTokenRequestSchema,
  TGetPayCardTokenResponseSchema,
} from "./getPayCardToken.schema";

export const getPayCardToken = (axios: IAxiosInstance) => {
  return async (params: TGetPayCardTokenRequestSchema) => {
    const { pay_card_id, ...rest } = params;

    const response = await axios.noRetry.get<
      IApiResponse<TGetPayCardTokenResponseSchema>
    >(`/api/pay-cards/${pay_card_id}/token`, { params: rest });

    return response.data;
  };
};
