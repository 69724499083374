import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IEmployeeOnboardingWebhookRequestBody } from "./employeeOnboardingWebhook.jsonschema";

export const employeeOnboardingWebhook = (axios: IAxiosInstance) => {
  return async (
    employeeOnboardingWebhook: string,
    userType: string,
    test: boolean
  ) => {
    const webhookBody: IEmployeeOnboardingWebhookRequestBody = {
      onboarding_webhook: employeeOnboardingWebhook,
      user_type: userType,
      test,
    };
    const response = await axios.noRetry.post<unknown>(
      `/api/testOnboardingWebhook`,
      webhookBody
    );

    const { data } = response;

    return data;
  };
};
