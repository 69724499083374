import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import {
  TSetEmployeeTaxParametersRequestSchema,
  TSetEmployeeTaxParametersResponseSchema,
} from "./setEmployeeTaxParametersSchema";

export const setEmployeeTaxParameters = (axios: IAxiosInstance) => {
  return async (body: TSetEmployeeTaxParametersRequestSchema) => {
    const response =
      await axios.noRetry.post<TSetEmployeeTaxParametersResponseSchema>(
        `/api/employee/setTaxParameters`,
        body
      );

    return response.data;
  };
};
