import { ZealApiResponse, II9OnboardingInfo } from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { TReplaceI9WorkerLocation } from "./replaceI9Location.schema";

export const replaceI9WorkerLocation = (axios: IAxiosInstance) => {
  return async (data: TReplaceI9WorkerLocation) => {
    const response = await axios.noRetry.put<
      ZealApiResponse<II9OnboardingInfo>
    >("/api/i9-onboarding-info/worker-location", data);
    const resData = response.data;
    if (resData.success) {
      return resData.data;
    }
    throw new Error("Something went wrong");
  };
};
