import {
  TCustomerAccountWithFundingSource,
  TGetCustomerAccountRequestSchema,
} from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";

export const getCustomerAccountOnboardingInfo = (axios: IAxiosInstance) => {
  return async (params: TGetCustomerAccountRequestSchema) => {
    const response = await axios.noRetry.get<
      IApiResponse<TCustomerAccountWithFundingSource>
    >(`/api/customer-accounts/${params.customer_account_id}`, { params });
    return response.data;
  };
};
