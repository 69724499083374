import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import { TGetPayCardTransactionsResponseSchema } from "../../../OdoClient/schemas";
import { TGetPayCardTransactionsRequestSchema } from "./getPayCardTransactions.schema";

export const getPayCardTransactions = (axios: IAxiosInstance) => {
  return async (data: TGetPayCardTransactionsRequestSchema) => {
    const { pay_card_id } = data;

    const response = await axios.noRetry.get<
      IApiResponse<TGetPayCardTransactionsResponseSchema>
    >(`/api/pay-cards/${pay_card_id}/transactions`);

    return response.data;
  };
};
