import { RUN_ALL_KYC } from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import {
  IRunAllKYCRequestBody,
  IRunAllKYCResponseBody,
} from "./runAllKYC.jsonschema";

export const runAllKYC = (axios: IAxiosInstance) => {
  return async (body: IRunAllKYCRequestBody) => {
    const response = await axios.noRetry.post<IRunAllKYCResponseBody>(
      RUN_ALL_KYC,
      body
    );

    return response.data;
  };
};
