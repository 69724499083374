import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";
import { ICreateEmployeeCheckRequest } from "./createEmployeeCheck.jsonschema";

export const createEmployeeCheck = (axios: IAxiosInstance) => {
  return async (checkInfo: ICreateEmployeeCheckRequest) => {
    const response = await axios.noRetry.post<
      ISchema["#/definitions/ICreateEmployeeCheckResponse"]
    >(`/api/createEmployeeCheck`, checkInfo);
    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }
    throw new Error("Something went wrong");
  };
};
