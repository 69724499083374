export enum ECompanyType {
  ALL = "all",
  CONTRACTOR_ONLY = "contractor_only",
}

export const CompanyTypes = [
  ECompanyType.ALL.toString(),
  ECompanyType.CONTRACTOR_ONLY.toString(),
] as const;

export const humanReadableCompanyTypeMap: Record<ECompanyType, string> = {
  [ECompanyType.ALL]: "Contractors and employees",
  [ECompanyType.CONTRACTOR_ONLY]: "Contractors only",
};
