import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TGetEmployerEmployerCheckDisbursementEventsRequestSchema,
  TGetEmployerEmployerCheckDisbursementEventsResponseSchema,
} from "./getEmployerCheckDisbursementEvents.schema";

export const getEmployerCheckDisbursementEvents = (axios: IAxiosInstance) => {
  return async (
    data: TGetEmployerEmployerCheckDisbursementEventsRequestSchema
  ) => {
    const { employer_check_id, ...rest } = data;

    const response = await axios.noRetry.get<
      IApiResponse<TGetEmployerEmployerCheckDisbursementEventsResponseSchema>
    >(`/api/employer-checks/${employer_check_id}/disbursement-events`, rest);

    return response.data;
  };
};
