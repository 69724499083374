import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import {
  TGenerateFilesPutUrlArgs,
  TGenerateFilesPutUrlResponse,
} from "./generateFilesPutUrl.schema";

export const generateFilesPutUrl = (axios: IAxiosInstance) => {
  return async ({
    key,
    contentType,
    bucket,
    usePrimary = true,
  }: TGenerateFilesPutUrlArgs) => {
    const response = await axios.noRetry.get<{
      data: TGenerateFilesPutUrlResponse;
    }>(`/generate-files-put-url`, {
      params: {
        key,
        contentType,
        bucket,
        usePrimary,
      },
      headers: {
        "Content-Type": contentType,
      },
    });

    const resData = response.data?.data;

    if (resData) {
      return resData;
    }

    throw new Error("Something went wrong");
  };
};
