import { IV2_Deduction } from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";

const URL = `/api/bulk-manage-deductions`;

export const postManageBulkDeductions = (axios: IAxiosInstance) => {
  return async (requestBody: {
    toUpdate?: { deduction: Partial<IV2_Deduction>; deductionID: string }[];
    toDelete: { deductionID: string }[];
    employeeCheckID: string;
  }) => {
    const response = await axios.noRetry.post(URL, requestBody);
    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
