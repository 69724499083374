import {
  TPostFundingSourceRequestSchema,
  TPostFundingSourceResponseSchema,
} from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";

export const postFundingSource = (axios: IAxiosInstance) => {
  return async (body: TPostFundingSourceRequestSchema) => {
    const response = await axios.noRetry.post<
      IApiResponse<TPostFundingSourceResponseSchema>
    >(`/customer-accounts/${body.customer_account_id}/funding-sources`, body);
    return response.data;
  };
};
