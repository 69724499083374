import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

export const getCheckPreview = (axios: IAxiosInstance) => {
  return async (params: ISchema["#/definitions/IGetCheckPreviewRequest"]) => {
    const { checkIDs, companyID } = params;
    const response = await axios.noRetry.get(`/api/getCheckPreview`, {
      params: {
        checkIDs,
        companyID,
      },
    });
    const resData = response.data;

    if (resData.success) {
      return resData;
    }

    throw new Error("Something went wrong");
  };
};
