import { EEVerifyPublicCaseStatus, schemes } from "@zeal/common";
import { z } from "zod";

export const getBulkEVerifyPublicCaseStatusesRequestZodSchema = z.object({
  workerIDs: z.array(schemes.mongoObjectId()),
  testMode: z.boolean(),
});

export type TGetBulkEVerifyPublicCaseStatusesRequest = z.infer<
  typeof getBulkEVerifyPublicCaseStatusesRequestZodSchema
>;

export const getBulkEVerifyPublicCaseStatusesResponseZodSchema = z.object({
  case_statuses: z.array(
    z.object({
      everify_status: z.nativeEnum(EEVerifyPublicCaseStatus),
      workerID: schemes.mongoObjectId(),
    })
  ),
});

export type TGetBulkEVerifyPublicCaseStatusesResponse = z.infer<
  typeof getBulkEVerifyPublicCaseStatusesResponseZodSchema
>;
