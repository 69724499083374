import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";

export const getPendingEVerifyActionByAdmin = (axios: IAxiosInstance) => {
  return async () => {
    const response = await axios.noRetry.get<IApiResponse<{ data: boolean }>>(
      "/api/getPendingEVerifyActionByAdmin"
    );
    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
