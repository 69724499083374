import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import {
  ISaveEmployerOnboardingTermsRequestBody,
  ISaveEmployerOnboardingTermsResponse,
} from "./saveEmployerOnboardingTerms.jsonschema";

export const saveEmployerOnboardingTerms = (axios: IAxiosInstance) => {
  return async (body: ISaveEmployerOnboardingTermsRequestBody) => {
    const response =
      await axios.noRetry.post<ISaveEmployerOnboardingTermsResponse>(
        `/api/saveEmployerOnboardingTerms`,
        body
      );

    const resData = response.data;

    if (!resData.success) {
      throw new Error("Something went wrong");
    }

    return resData.data;
  };
};
