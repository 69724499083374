import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import { TGetUserInfoResponseSchema } from "./getUserInfoSchema";

export const getUserInfo = (axios: IAxiosInstance) => {
  return async () => {
    const response =
      await axios.noRetry.get<IApiResponse<TGetUserInfoResponseSchema>>(
        `/api/getUserInfo`
      );
    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
