import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { getUsePrimaryUrlParams } from "../../utils/utils";
import { ISchema } from "clients/LaForgeClient/validation-types/SchemaDefinition";

export const updateShiftWebhook = (axios: IAxiosInstance) => {
  return async ({
    workerShiftWebhook,
    userType,
    usePrimary,
    isTestWebhookType,
  }: ISchema["#/definitions/IUpdateShiftWebhookArgs"]) => {
    const response = await axios.noRetry.post<
      ISchema["#/definitions/IUpdateShiftWebhookResponse"]
    >(`/api/updateShiftWebhook${getUsePrimaryUrlParams(usePrimary)}`, {
      worker_shift_webhook: workerShiftWebhook,
      user_type: userType,
      is_test_webhook_type: isTestWebhookType,
    });

    const { data: responseData } = response;
    if (!responseData.success) {
      throw new Error(`Something went wrong`);
    }
    return responseData;
  };
};
