import { z } from "zod";
import { schemes } from "../../schema-validation";
import { RouteMap } from "./customerReporting";

const employerCheckReconciliationReqSchema = z.object({
  companyID: z.string(),
  start_date: schemes.isoDate(),
  end_date: schemes.isoDate(),
});

const employerCheckReconciliationSchema = z.object({
  employerCheckID: z.string(),
  createdAt: schemes.isoDate(),
  check_date: schemes.isoDate(),
  status: z.string(),
  gross_pay: z.string(),
  company_debit: z.string(),
  total_employee_taxes: z.string(),
  total_employer_taxes: z.string(),
});

export type EmployerCheckReconResponse = z.infer<
  typeof employerCheckReconciliationSchema
>;

export type EmployerCheckReconciliationRequest = z.infer<
  typeof employerCheckReconciliationReqSchema
>;

export type EmployerCheckReconResponseShape = {
  kind: RouteMap.employer_check_recon;
  rows: EmployerCheckReconResponse[];
};
