import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

export const postForm = (axios: IAxiosInstance) => {
  return async (requestBody: ISchema["#/definitions/TPostFormBody"]) => {
    const response = await axios.noRetry.post<
      ISchema["#/definitions/TPostFormResponse"]
    >(`/forms`, requestBody);
    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
