import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { getUsePrimaryUrlParams } from "../../utils/utils";
import { ISchema } from "clients/LaForgeClient/validation-types/SchemaDefinition";

export const testContractorWebhook = (axios: IAxiosInstance) => {
  return async ({
    contractorWebhook,
    userType,
    test,
    usePrimary,
  }: ISchema["#/definitions/ITestContractorWebhookArgs"]) => {
    const response = await axios.noRetry.post<
      ISchema["#/definitions/ITestContractorWebhookResponse"]
    >(`/api/testContractorWebhook${getUsePrimaryUrlParams(usePrimary)}`, {
      contractor_webhook: contractorWebhook,
      user_type: userType,
      test,
    });

    const { data: responseData } = response;
    if (!responseData.success) {
      throw new Error(`Something went wrong`);
    }
    return responseData;
  };
};
