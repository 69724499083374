import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

export const getSecondaryJWT = (axios: IAxiosInstance) => {
  return async ({
    companyID,
  }: ISchema["#/definitions/IGetSecondaryJWTArgs"]) => {
    const response = await axios.noRetry.get<
      ISchema["#/definitions/IGetSecondaryJWTResponse"]
    >(`/api/getSecondaryJWT`, {
      params: { usePrimary: true, companyID },
    });
    const resData = response.data;

    return resData;
  };
};
