import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  IGetLedgerForUserQueryParams,
  IGetLedgerForUserResponseBody,
} from "./getLedgerForUser.jsonschema";

export const getLedgerForUser = (axios: IAxiosInstance) => {
  return async (params: IGetLedgerForUserQueryParams) => {
    const response = await axios.noRetry.get<
      IApiResponse<IGetLedgerForUserResponseBody>
    >(`/api/ledger`, { params });
    return response.data;
  };
};
