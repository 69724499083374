import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "clients/LaForgeClient/validation-types/SchemaDefinition";

export const updateAdminInfo = (axios: IAxiosInstance) => {
  return async ({
    role,
    firstName,
    lastName,
  }: ISchema["#/definitions/IUpdateAdminInfoArgs"]) => {
    const response = await axios.noRetry.post<
      ISchema["#/definitions/IUpdateAdminInfoResponse"]
    >(`/api/updateAdminInfo`, {
      role,
      first_name: firstName,
      last_name: lastName,
    });

    const { data: responseData } = response;
    if (!responseData.success) {
      throw new Error(`Failed to update admin info`);
    }
    return responseData.data;
  };
};
