import { ZealApiResponse } from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { TGetI9ReviewProgressResponseSchema } from "./getI9ReviewProgressSchema";

export const getI9ReviewProgress = (axios: IAxiosInstance) => {
  return async () => {
    const response = await axios.noRetry.get<
      ZealApiResponse<TGetI9ReviewProgressResponseSchema>
    >("/api/getI9ReviewProgress");
    const resData = response.data;
    if (resData.success) {
      return resData.data;
    }
    throw new Error("Something went wrong");
  };
};
