import { z } from "zod";
import { RouteMap } from "./customerReporting";

export const cashRequirementsSchema = z.object({
  refunded: z.string(),
  total_company_debit: z.string(),
  garnishment: z.string(),
  direct_deposit: z.string(),
  employee_taxes: z.string(),
  employer_taxes: z.string(),
  total_plus_refunds: z.string(),
  total_extra_cash: z.string(),
  total_cash_requirements: z.string(),
  check_date: z.string(),
});

export type CashRequirementsShape = z.infer<typeof cashRequirementsSchema> & {
  kind: RouteMap.cash_requirements;
  extra_contributions: {
    [x: string]: string;
  };
};
