export enum ZealEmailAddress {
  Admin = "smoke-tester+admin@zeal.com",
  Backend = "backend@zeal.com",
  Company = "company@zeal.com",
  Contractor = "smoke-tester+contractor@zeal.com",
  DefaultZeal = "hello@payrollcomms.com",
  Guest = "smoke-tester+guest@zeal.com",
  Internal = "smoke-tester+internal@zeal.com",
  KYC = "kyc@zeal.com",
  Partner = "partner@zeal.com",
  Sit = "sit@zeal.com",
  Support = "support@zeal.com",
  System = "system@zeal.com",
  TaxIDS = "taxids@zeal.com",
  TaxIDS2 = "taxids2@zeal.com",
  Whitelabel = "smoke-tester+wlroot@zeal.com",
  WhitelabelAdmin = "smoke-tester+wladmin@zeal.com",
  Worker = "smoke-tester+worker@zeal.com",

  Kirti = "kirti@zeal.com",
  Pranab = "pranab@zeal.com",
}
