export enum Months {
  JAN = "january",
  FEB = "february",
  MAR = "march",
  APR = "april",
  MAY = "may",
  JUN = "june",
  JUL = "july",
  AUG = "august",
  SEP = "september",
  OCT = "october",
  NOV = "november",
  DEC = "december",
}
