import { TGetQuickviewWorkerInfoResponseData } from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";

export const getQuickviewWorkerInfo = (axios: IAxiosInstance) => {
  return async (employeeID: string) => {
    const response = await axios.noRetry.get<
      IApiResponse<TGetQuickviewWorkerInfoResponseData>
    >(`/api/getQuickviewWorkerInfo`, {
      params: { employeeID },
      withCredentials: true,
    });
    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
