import { zSchema } from "../../../schema-validation";
import { LEGAL_STRUCTURES } from "../../IOdoCompany";

export const companyOnboardSchema = zSchema.object({
  address: zSchema.string(),
  business_name: zSchema.string(),
  business_phone: zSchema.string(),
  city: zSchema.string(),
  contact_email: zSchema.string(),
  ein: zSchema.string(),
  first_name: zSchema.string(),
  last_name: zSchema.string(),
  legal_structure: zSchema.enum(LEGAL_STRUCTURES),
  ownership_percentage: zSchema.number(),
  skip_account_creation: zSchema.boolean().optional(),
  ssn: zSchema.string(),
  state: zSchema.string(),
  title: zSchema.string(),
  uuid: zSchema.string(),
  zip: zSchema.string(),
});
