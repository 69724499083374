import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import { TEVerifyWebhookRequest } from "../testEVerifyWebhook/testEVerifyWebhookSchema";
import { TUpdateEVerifyWebhookResponse } from "./updateEVerifyWebhookSchema";

export const updateEVerifyWebhook = (axios: IAxiosInstance) => {
  return async ({ testMode, url }: TEVerifyWebhookRequest) => {
    const response = await axios.noRetry.post<
      IApiResponse<TUpdateEVerifyWebhookResponse>
    >("/api/updateEVerifyWebhook", {
      testMode,
      url,
    });

    const { data: responseData } = response;
    if (!responseData.success) {
      throw new Error(`Something went wrong`);
    }
    return responseData;
  };
};
