import { IGeneratedFiles, IGeneratedFilesPending } from "./generated_files";
import { IFileRequestConfig } from "./IFileRequestConfig";

export interface ICreateFileSubmissionRequestBase {
  readonly quarkCompanyID: string;
  readonly collectorID?: string;
  readonly submissionDate: string;
  readonly submissionNotes: string[];
  readonly internalUser: string;
  readonly generatedFileID: string;
}

export interface ICreateFileSubmissionRequestByDeadlineID
  extends ICreateFileSubmissionRequestBase {
  readonly deadlineID: string;
  readonly kind: "deadlineID";
}

export interface ICreateFileSubmissionRequestByReportingPeriodID
  extends ICreateFileSubmissionRequestBase {
  readonly reportingPeriodID: string;
  readonly kind: "reportingPeriodID";
}

export interface ICreateFileSubmissionRequestByNone
  extends ICreateFileSubmissionRequestBase {
  readonly kind: "none";
}

export type ICreateFileSubmissionRequest =
  | ICreateFileSubmissionRequestByDeadlineID
  | ICreateFileSubmissionRequestByReportingPeriodID
  | ICreateFileSubmissionRequestByNone;

export const isCreateFileSubmissionRequestByDeadlineID = (
  x: ICreateFileSubmissionRequest
): x is ICreateFileSubmissionRequestByDeadlineID => x.kind === "deadlineID";

export const isCreateFileSubmissionRequestByReportingPeriodID = (
  x: ICreateFileSubmissionRequest
): x is ICreateFileSubmissionRequestByReportingPeriodID =>
  x.kind === "reportingPeriodID";

export const isCreateFileSubmissionRequestByNone = (
  x: ICreateFileSubmissionRequest
): x is ICreateFileSubmissionRequestByNone => x.kind === "none";

export interface ICreateJobFileRequest {
  deadlineID: string;
  config: IFileRequestConfig;
  quarkCompanyID: string;
  start_date: string;
  end_date: string;
}

export type ICreateJobFileResponse = IGeneratedFilesPending;

export interface IGetJobStatusRequest {
  generatedFileID: string;
}

export type IGetJobStatusResponse = IGeneratedFiles;
