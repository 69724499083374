import { z } from "zod";

import { schemes } from "../schema-validation";

export const webhookEvent = z.object({
  entityID: z.string(),
  externalService: z.enum(["treasury-prime"]),
  entityType: z.enum(["ach", "transaction"]),
  scope: z.enum(["create", "update"]),
  event: z.any(),
  tags: z.array(z.enum(["transactionUpdate"])),
  _id: schemes.mongoObjectId(),
  createdAt: z.string(),
  updatedAt: z.string(),
});

export type WebhookEvent = z.infer<typeof webhookEvent>;
