/**
 * Indicates the citizenship status of the worker during the I9 e-verify process.
 * Used to determine which documents the worker can provide to verify their identity.
 */
export enum ECitizenshipStatus {
  US_CITIZEN = "US_CITIZEN",
  NONCITIZEN = "NONCITIZEN",
  LAWFUL_PERMANENT_RESIDENT = "LAWFUL_PERMANENT_RESIDENT",
  NONCITIZEN_AUTHORIZED_TO_WORK = "NONCITIZEN_AUTHORIZED_TO_WORK",
}
