import { EI9AdminReviewStatus, schemes } from "@zeal/common";
import { z } from "zod";

export const updateI9AdminReviewRequestSchema = z.discriminatedUnion("status", [
  z.object({
    companyID: schemes.companyID(),
    employeeID: schemes.mongoObjectId(),
    status: z.literal(EI9AdminReviewStatus.CHANGES_REQUESTED),
    requestedChanges: z.string().min(1).max(500),
  }),
  z.object({
    companyID: schemes.companyID(),
    employeeID: schemes.mongoObjectId(),
    status: z.literal(EI9AdminReviewStatus.APPROVED),
  }),
]);

export type TUpdateI9AdminReviewRequest = {
  companyID: string;
  employeeID: string;
  status: EI9AdminReviewStatus;
  requestedChanges?: string;
};
