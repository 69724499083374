import {
  IGetPaperworkTemplateRequest,
  IGetPaperworkTemplateResponse,
  PAPERWORK_ROUTES,
} from "@zeal/common";
import { IAxiosInstance } from "clients/AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "clients/AbstractApiClient/IApiResponse";

/* eslint-disable */
export const fetchCustomPaperwork =
  (axios: IAxiosInstance) => async (data: IGetPaperworkTemplateRequest) => {
    const response = await axios.noRetry.get<
      IApiResponse<IGetPaperworkTemplateResponse>
    >(
      `${PAPERWORK_ROUTES.GET_PAPERWORK_TEMPLATE.replace("/:templateID", "")}/${
        data.templateID
      }`
    );

    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
