import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "clients/LaForgeClient/validation-types/SchemaDefinition";

export const createBankAccount = (axios: IAxiosInstance) => {
  return async ({
    account_type,
    bank_name,
    bank_account_number,
    bank_routing_number,
    companyID,
    employeeID,
    test,
    isOnboarding,
  }: ISchema["#/definitions/ICreateBankAccountArgs"]) => {
    const response = await axios.noRetry.post(
      `/api/forms/employee/${companyID}/sign/US-direct_deposit/${employeeID}`,
      {
        account_type,
        bank_name,
        bank_account_number,
        bank_routing_number,
        companyID,
        employeeID,
        test,
        isOnboarding,
      }
    );

    if (!response?.data?.success) {
      throw new Error("Something went wrong");
    }
    return response?.data?.data;
  };
};
