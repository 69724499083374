import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { PayrollRunTypes } from "../../@types/general";
import { ISchema } from "../../validation-types/SchemaDefinition";

export interface IGetPayrollRunByIdParams {
  readonly type: PayrollRunTypes;
  readonly reportingPeriodID?: string;
  readonly payDate?: string;
  readonly payFrequency?: string;
}

export const postCreatePayrollRun = (axios: IAxiosInstance) => {
  return async (body: IGetPayrollRunByIdParams) => {
    const response = await axios.noRetry.post<
      ISchema["#/definitions/IPostCreatePayrollRunResponse"]
    >(`/api/payroll-run/create-regular`, body);
    const resData = response.data;
    if (resData.success) {
      return resData.data;
    }
    throw new Error("Something went wrong");
  };
};
