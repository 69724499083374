import { schemes, zSchema } from "../../../schema-validation";
import { EntityType } from "./runAllKyc";

export const runKycSchema = zSchema.object({
  entity_type: zSchema.nativeEnum(EntityType),
  zeal_id: zSchema.string(),
  kyc_product_id: zSchema.string().optional(),
  first_name: zSchema.string().optional(),
  last_name: zSchema.string().optional(),
  dob: schemes.isoDate().optional(),
});
