import { schemes, zSchema } from "@zeal/common";

export const updateCompanyNodeRequestSchema = zSchema.object({
  account_num: zSchema.string(),
  account_type: zSchema.enum(["checking", "savings"]).default("checking"),
  business_name: zSchema.string(),
  company_id: schemes.uuid(),
  odo_user_id: zSchema.string(),
  partner_business_name: zSchema.string(),
  routing_num: zSchema.string(),
  test: zSchema.boolean(),
  trigger_micro_deposits: zSchema.boolean().optional(),
});

export type TUpdateCompanyNodeRequestSchema = zSchema.infer<
  typeof updateCompanyNodeRequestSchema
>;
