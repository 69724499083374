import { z } from "zod";
import { schemes } from "@zeal/common";

export const postI9EVerifySummaryRequestSchema = z.object({
  active_workers: z.boolean().default(true),
  companyID: schemes.companyID(),
  media_type: z.literal("csv"),
  start_date: z.string(),
  end_date: z.string(),
});

export type TPostI9EVerifySummaryRequestBody = z.infer<
  typeof postI9EVerifySummaryRequestSchema
>;

interface IPostI9EVerifySummaryResponseRequestBody
  extends TPostI9EVerifySummaryRequestBody {
  readonly job_type: string;
  readonly report_format: string;
}

export interface IPostI9EVerifySummaryResponseData {
  readonly created_at: string;
  readonly job_id: string;
  readonly request_body: IPostI9EVerifySummaryResponseRequestBody;
  readonly status: string;
}
