import { STATE_AGENCY_GET_PARAM } from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";
import { TGetStateAgencyInfoRequestBody } from "./getStateAgencyInfo.jsonschema";

export const getStateAgencyInfo = (axios: IAxiosInstance) => {
  return async (query: TGetStateAgencyInfoRequestBody) => {
    const response = await axios.noRetry.get<
      ISchema["#/definitions/TGetStateAgencyInfoResponseBody"]
    >(`${STATE_AGENCY_GET_PARAM}${query.zeal_id}`);
    return response.data;
  };
};
