import { Method } from "axios";
import { ZodSchema } from "zod";
import { Jurisdiction } from "../jurisdictions";
import { schemes, zSchema } from "../schema-validation";
import {
  EEVerifyDuplicateContinueReasonCodes,
  EEVerifyCaseClosureReasons,
  EEVerifyPhotoMatchResults,
} from "./everify-case-statuses";
import { ECitizenshipStatus } from "./ECitizenshipStatus";
import { EDocumentKey } from "./EDocumentKey";
import { EReasonForDelay } from "./EReasonForDelay";

export enum EEVerifyRoutes {
  // Health
  HEALTH = "/health",

  // Authentication
  RESET_PASSWORD = "/authentication/reset_password",
  MODIFY_PASSWORD = "/authentication/modify_password",
  LOGIN = "/authentication/login",
  TOKEN_REFRESH = "/authentication/refresh",
  TOKEN_VALIDATE = "/authentication/validate",

  // Case Checks
  DUPLICATE_CHECK = "/duplicate_check",
  CASE_FIELDS = "/checks/case_fields",

  // Case Workflow
  CASE_GET = "/cases/:case_number",
  CASE_SUBMIT = "/cases/:case_number/submit",
  CASE_RETRIEVE_PHOTO = "/cases/:case_number/retrieve_photo",
  CASE_PHOTO_MATCH = "/cases/:case_number/photo_match",
  CASE_SCAN_AND_UPLOAD = "/cases/:case_number/scan_and_upload",
  CASE_FURTHER_ACTION_NOTICE = "/cases/:case_number/further_action_notice",
  CASE_REFERRAL_DATE_CONFIRMATION = "/cases/:case_number/referral_date_confirmation",
  CASE_NO_ACTION = "/cases/:case_number/no_action",
  CASE_CREATE = "/cases",
  CASE_CONFIRM_DETAILS_FIELDS = "/cases/:case_number/confirm_details_fields",
  CASE_CONFIRM_DETAILS = "/cases/:case_number/confirm_details",
  CASE_REFER = "/cases/:case_number/refer",
  CASE_CLOSE = "/cases/:case_number/close",
  CASE_CLOSURE_REASON = "/cases/:case_number/closure_reason",
  CASE_SEARCH = "/cases/search",

  // Case Synchronization
  CASE_UPDATED = "/cases/updated",
  CASE_CONFIRM_UPDATED = "/cases/confirm_updated",
  CASE_ALERT_COUNTS = "/cases/alert_counts",
}

export const EVerifyRoutesRequiringAuthorization = new Set([
  EEVerifyRoutes.MODIFY_PASSWORD,
  EEVerifyRoutes.TOKEN_REFRESH,
  EEVerifyRoutes.TOKEN_VALIDATE,
  EEVerifyRoutes.DUPLICATE_CHECK,
  EEVerifyRoutes.CASE_FIELDS,
  EEVerifyRoutes.CASE_GET,
  EEVerifyRoutes.CASE_SUBMIT,
  EEVerifyRoutes.CASE_RETRIEVE_PHOTO,
  EEVerifyRoutes.CASE_PHOTO_MATCH,
  EEVerifyRoutes.CASE_SCAN_AND_UPLOAD,
  EEVerifyRoutes.CASE_FURTHER_ACTION_NOTICE,
  EEVerifyRoutes.CASE_REFERRAL_DATE_CONFIRMATION,
  EEVerifyRoutes.CASE_NO_ACTION,
  EEVerifyRoutes.CASE_CREATE,
  EEVerifyRoutes.CASE_CONFIRM_DETAILS_FIELDS,
  EEVerifyRoutes.CASE_CONFIRM_DETAILS,
  EEVerifyRoutes.CASE_REFER,
  EEVerifyRoutes.CASE_CLOSE,
  EEVerifyRoutes.CASE_CLOSURE_REASON,
  EEVerifyRoutes.CASE_SEARCH,
  EEVerifyRoutes.CASE_UPDATED,
  EEVerifyRoutes.CASE_CONFIRM_UPDATED,
  EEVerifyRoutes.CASE_ALERT_COUNTS,
]);

export const EVerifyRoutesMethods: Record<EEVerifyRoutes, Method> = {
  [EEVerifyRoutes.HEALTH]: "GET",
  [EEVerifyRoutes.RESET_PASSWORD]: "POST",
  [EEVerifyRoutes.MODIFY_PASSWORD]: "POST",
  [EEVerifyRoutes.LOGIN]: "POST",
  [EEVerifyRoutes.TOKEN_REFRESH]: "POST",
  [EEVerifyRoutes.TOKEN_VALIDATE]: "POST",
  [EEVerifyRoutes.DUPLICATE_CHECK]: "POST",
  [EEVerifyRoutes.CASE_FIELDS]: "POST",
  [EEVerifyRoutes.CASE_GET]: "GET",
  [EEVerifyRoutes.CASE_SUBMIT]: "POST",
  [EEVerifyRoutes.CASE_RETRIEVE_PHOTO]: "GET",
  [EEVerifyRoutes.CASE_PHOTO_MATCH]: "POST",
  [EEVerifyRoutes.CASE_SCAN_AND_UPLOAD]: "POST",
  [EEVerifyRoutes.CASE_FURTHER_ACTION_NOTICE]: "GET",
  [EEVerifyRoutes.CASE_REFERRAL_DATE_CONFIRMATION]: "GET",
  [EEVerifyRoutes.CASE_NO_ACTION]: "POST",
  [EEVerifyRoutes.CASE_CREATE]: "POST",
  [EEVerifyRoutes.CASE_CONFIRM_DETAILS_FIELDS]: "GET",
  [EEVerifyRoutes.CASE_CONFIRM_DETAILS]: "POST",
  [EEVerifyRoutes.CASE_REFER]: "POST",
  [EEVerifyRoutes.CASE_CLOSE]: "POST",
  [EEVerifyRoutes.CASE_CLOSURE_REASON]: "GET",
  [EEVerifyRoutes.CASE_SEARCH]: "POST",
  [EEVerifyRoutes.CASE_UPDATED]: "GET",
  [EEVerifyRoutes.CASE_CONFIRM_UPDATED]: "POST",
  [EEVerifyRoutes.CASE_ALERT_COUNTS]: "GET",
};

export const caseNumberZodSchema = zSchema
  .string()
  .regex(
    /^\d{13}[A-Z]{2}$/,
    "case_number must be 13 numbers followed by 2 uppercase letters."
  );

export const languageZodSchema = zSchema
  .union([zSchema.literal("english"), zSchema.literal("spanish")])
  .optional();

export const EVerifyRoutesSchemas: Record<EEVerifyRoutes, ZodSchema> = {
  [EEVerifyRoutes.HEALTH]: zSchema.any(),
  [EEVerifyRoutes.RESET_PASSWORD]: zSchema.object({
    logon_id: zSchema.string(),
  }),
  [EEVerifyRoutes.MODIFY_PASSWORD]: zSchema.object({
    old_password: zSchema.string(),
    new_password: zSchema.string(),
  }),
  [EEVerifyRoutes.LOGIN]: zSchema.object({
    username: zSchema.string({ required_error: "username is required." }),
    password: zSchema.string({ required_error: "password is required." }),
    testMode: zSchema.boolean(),
  }),
  [EEVerifyRoutes.TOKEN_REFRESH]: zSchema.object({
    testMode: zSchema.boolean(),
  }),
  [EEVerifyRoutes.TOKEN_VALIDATE]: zSchema.object({
    testMode: zSchema.boolean(),
  }),
  [EEVerifyRoutes.DUPLICATE_CHECK]: zSchema.object({
    ssn: zSchema.string(),
    client_company_id: zSchema.number().optional(),
    sort_field: zSchema
      .enum(["last_name", "case_status", "case_number", "hire_date"])
      .optional(),
    sort_direction: zSchema.enum(["ASC", "DESC"]).optional(),
    page_number: zSchema.number().min(1).optional(),
    page_size: zSchema.number().min(1).max(120).optional(),
    testMode: zSchema.boolean(),
  }),
  [EEVerifyRoutes.CASE_FIELDS]: zSchema.object({
    ssn: zSchema.string(),
    citizenship_status_code: zSchema.nativeEnum(ECitizenshipStatus),
    client_company_id: zSchema.number(),
    document_a_type_code: zSchema.nativeEnum(EDocumentKey).optional(),
    document_b_type_code: zSchema.nativeEnum(EDocumentKey).optional(),
    document_c_type_code: zSchema.nativeEnum(EDocumentKey).optional(),
    testMode: zSchema.boolean(),
  }),
  [EEVerifyRoutes.CASE_GET]: zSchema.any(),
  [EEVerifyRoutes.CASE_SUBMIT]: zSchema.object({
    case_number: caseNumberZodSchema,
    testMode: zSchema.boolean(),
  }),
  [EEVerifyRoutes.CASE_RETRIEVE_PHOTO]: zSchema.any(),
  [EEVerifyRoutes.CASE_PHOTO_MATCH]: zSchema.object({
    photo_match: zSchema.nativeEnum(EEVerifyPhotoMatchResults),
  }),
  [EEVerifyRoutes.CASE_SCAN_AND_UPLOAD]: zSchema.object({
    // sent as form-data, not json
    front_photo: zSchema.string(),
    back_photo: zSchema.string(),
  }),
  [EEVerifyRoutes.CASE_FURTHER_ACTION_NOTICE]: zSchema.object({
    case_number: caseNumberZodSchema,
    language: languageZodSchema,
    testMode: zSchema.boolean(),
  }),
  [EEVerifyRoutes.CASE_REFERRAL_DATE_CONFIRMATION]: zSchema.object({
    language: languageZodSchema,
    testMode: zSchema.boolean(),
  }),
  [EEVerifyRoutes.CASE_NO_ACTION]: zSchema.object({
    employee_notified: zSchema.boolean(),
  }),
  [EEVerifyRoutes.CASE_CREATE]: zSchema.object({
    // REQUIRED
    companyID: schemes.companyID(),
    workerID: schemes.mongoObjectId(),
    citizenship_status_code: zSchema.nativeEnum(ECitizenshipStatus),
    client_company_id: zSchema.number(),
    date_of_birth: schemes.isoDate(),
    date_of_hire: schemes.isoDate(),
    first_name: zSchema.string().min(1).max(25),
    last_name: zSchema.string().min(1).max(40),
    ssn: zSchema.string(),
    testMode: zSchema.boolean(),

    // DOC A
    document_a_type_code: zSchema.nativeEnum(EDocumentKey).optional(),

    // DOC B AND C
    document_b_type_code: zSchema.nativeEnum(EDocumentKey).optional(),
    document_bc_number: zSchema.string().optional(),
    document_c_type_code: zSchema.nativeEnum(EDocumentKey).optional(),
    document_sub_type_code: zSchema.string().optional(),

    // CONDITIONALLY REQUIRED DEPENDING ON DOCS
    alien_number: zSchema
      .string()
      .regex(
        /^A\d{8,9}$/,
        'Invalid alien number. Must be the letter "A" followed by 8 or 9 digits.'
      )
      .optional(),
    country_code: zSchema.string().optional(),
    expiration_date: zSchema.string().optional(),
    i551_number: zSchema.string().length(13).optional(),
    i766_number: zSchema.string().length(13).optional(),
    i94_number: zSchema.string().length(11).optional(),
    no_expiration_date: zSchema.boolean().optional(),
    sevis_number: zSchema.string().length(11).optional(),
    us_passport_number: zSchema.string().min(6).max(9).optional(),
    us_state_code: zSchema.nativeEnum(Jurisdiction).optional(),
    visa_number: zSchema.string().length(8).optional(),

    // DUPLICATE CASE
    duplicate_continue_reason: zSchema.string().min(1).max(255).optional(),
    duplicate_continue_reason_code: zSchema
      .nativeEnum(EEVerifyDuplicateContinueReasonCodes)
      .optional(),

    // OPTIONAL
    employee_email_address: zSchema.string().trim().email().optional(),
    employer_case_id: zSchema.string().min(1).max(40).optional(),
    foreign_passport_number: zSchema.string().min(6).max(12).optional(),
    middle_initial: zSchema.string().length(1).optional(),
    other_last_names_used: zSchema.array(zSchema.string()).optional(),
    phone_number: zSchema.string().length(10).optional(),
    reason_for_delay_code: zSchema.nativeEnum(EReasonForDelay).optional(),
    reason_for_delay_description: zSchema.string().min(1).max(240).optional(),
  }),
  [EEVerifyRoutes.CASE_CONFIRM_DETAILS_FIELDS]: zSchema.object({
    case_number: caseNumberZodSchema,
    testMode: zSchema.boolean(),
  }),
  [EEVerifyRoutes.CASE_CONFIRM_DETAILS]: zSchema.object({
    case_number: caseNumberZodSchema,
    case_fields: zSchema.array(
      zSchema.object({
        field_name: zSchema.string(),
        value: zSchema.string(),
      })
    ),
    testMode: zSchema.boolean(),
  }),
  [EEVerifyRoutes.CASE_REFER]: zSchema.object({
    case_number: caseNumberZodSchema,
    employee_notified: zSchema.boolean(),
    testMode: zSchema.boolean(),
  }),
  [EEVerifyRoutes.CASE_CLOSE]: zSchema.object({
    case_number: caseNumberZodSchema.optional(),
    case_closure_reason_code: zSchema.nativeEnum(EEVerifyCaseClosureReasons),
    other_reason_description: zSchema.string().max(256).optional(),
    currently_employed_reason_description: zSchema.string().max(256).optional(),
    testMode: zSchema.boolean(),
  }),
  [EEVerifyRoutes.CASE_CLOSURE_REASON]: zSchema.any(),
  [EEVerifyRoutes.CASE_SEARCH]: zSchema.object({
    case_fields: zSchema
      .object({
        alien_number: zSchema
          .string()
          .regex(
            /^A\d{8,9}$/,
            'Invalid alien number. Must be the letter "A" followed by 8 or 9 digits.'
          )
          .optional(),
        case_number: caseNumberZodSchema.optional(),
        drivers_license_number: zSchema.string().optional(),
        first_name: zSchema.string().optional(),
        foreign_passport_number: zSchema.string().min(1).max(12).optional(),
        i94_number: zSchema.string().min(1).max(11).optional(),
        last_name: zSchema.string().optional(),
        passport_number: zSchema.string().min(1).max(9).optional(),
        ssn: zSchema.string().min(1).max(11).optional(),
      })
      .optional(),
    sort_field: zSchema
      .enum(["last_name", "case_status", "case_number", "hire_date"])
      .optional(),
    sort_direction: zSchema.enum(["ASC", "DESC"]).optional(),
    page_number: zSchema.number().min(1).optional(),
    page_size: zSchema.number().min(1).max(120).optional(),
    date_from: zSchema.string().optional(), // MM/DD/YYYY
    date_to: zSchema.string().optional(), // MM/DD/YYYY
    client_company_id: zSchema.number().optional(),
    filter: zSchema.string(),
  }),
  [EEVerifyRoutes.CASE_UPDATED]: zSchema.object({
    number_of_records: zSchema.string().optional(), // 1-500, defaults to 500
  }),
  [EEVerifyRoutes.CASE_CONFIRM_UPDATED]: zSchema.object({
    case_numbers: zSchema.array(zSchema.string().length(15)),
  }),
  [EEVerifyRoutes.CASE_ALERT_COUNTS]: zSchema.object({
    filters: zSchema
      .array(
        zSchema.enum([
          "CASES_TO_BE_CLOSED",
          "WORK_DOCS_EXPIRING",
          "CASES_WITH_NEW_UPDATES",
        ])
      )
      .optional(),
  }),
};
