import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

export const getMembers = (axios: IAxiosInstance) => {
  return async () => {
    const response =
      await axios.noRetry.get<ISchema["#/definitions/IGetMembersResponseData"]>(
        `/api/getMembers`
      );

    const {
      status,
      data,
      data: { success },
    } = response;

    if (!success || status !== 200) {
      throw new Error(`Failed to get members`);
    }

    return data;
  };
};
