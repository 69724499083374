import { UpdateFilterRuleRequest, RULE_SET_ROUTES } from "@zeal/common";
import { IAxiosInstance } from "../../../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../../../AbstractApiClient/IApiResponse";

export const updateFilterRule = (axios: IAxiosInstance) => {
  return async (data: UpdateFilterRuleRequest) => {
    const response = await axios.noRetry.patch<IApiResponse<any>>(
      `${RULE_SET_ROUTES.UPDATE_FILTER_RULE.replace(
        ":companyID",
        data.companyID
      )
        .replace(":ruleSetID", data.ruleSetID)
        .replace(":filterID", data.filterID)}`,
      data
    );

    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
