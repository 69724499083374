import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { getUsePrimaryUrlParams } from "../../utils/utils";
import { ISchema } from "clients/LaForgeClient/validation-types/SchemaDefinition";

export const testContractorPaymentWebhook = (axios: IAxiosInstance) => {
  return async ({
    contractorPaymentWebhook,
    userType,
    test,
    usePrimary,
  }: ISchema["#/definitions/ITestContractorPaymentWebhookArgs"]) => {
    const response = await axios.noRetry.post<
      ISchema["#/definitions/ITestContractorPaymentWebhookResponse"]
    >(
      `/api/testContractorPaymentWebhook${getUsePrimaryUrlParams(usePrimary)}`,
      {
        contractor_payment_webhook: contractorPaymentWebhook,
        user_type: userType,
        test,
      }
    );

    const { data: responseData } = response;
    if (!responseData.success) {
      throw new Error(`Something went wrong`);
    }
    return responseData;
  };
};
