import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

export const uploadIDVerification = (axios: IAxiosInstance) => {
  return async (
    body:
      | ISchema["#/definitions/IUploadIDVerificationContractorArgs"]
      | ISchema["#/definitions/IUploadIDVerificationEmployeeArgs"]
  ) => {
    const response = await axios.noRetry.post<
      ISchema["#/definitions/IUploadIDVerificationResponse"]
    >(`/api/${body.worker_type}s/id`, body);
    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
