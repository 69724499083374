import { z } from "zod";

import { AuthenticationMethod } from "../accounts/IAuthenticationMethods";
import { schemes } from "../schema-validation";

export const baseRequest = z.object({
  status: z.string(),
  method: z.string(),
  path: z.string(),
  headers: z.any(),
  params: z.any(),
  query: z.any(),
  requestBody: z.any(),
  responseBody: z.any(),
  errors: z.any().optional(),
  userId: schemes.mongoObjectId(),
  whitelabelId: z.string().optional(),
  companyId: schemes.uuid().optional(),
  correlationId: z.string(),
  idempotencyKey: z.string(),
  sessionId: z.string().optional(),
  testMode: z.boolean(),
  email: schemes.email().optional(),
  environment: z.string(),
  authenticationMethod: z.nativeEnum(AuthenticationMethod).optional(),
});
