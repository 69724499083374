import {
  TCustomerAccountAddAchRequestSchema,
  TCustomerAccountAddAchResponseSchema,
} from "@zeal/common";
import { AxiosError } from "axios";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";

export const customerAccountAddAch =
  (axios: IAxiosInstance) =>
  async (body: TCustomerAccountAddAchRequestSchema) => {
    try {
      const response = await axios.noRetry.post<
        IApiResponse<TCustomerAccountAddAchResponseSchema>
      >(`/api/customer-accounts/${body.odo_user_id}/bank`, body);
      return response;
    } catch (e) {
      const errorMessage =
        (e as AxiosError)?.response?.data ??
        "Error onboarding customer account";
      throw errorMessage;
    }
  };
