import { ICheckDetails, ZealApiResponse } from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IPayrollRun } from "../common/IPayrollRun";

export interface IGetFetchPayrollRunParams {
  readonly payrollRunID?: string;
  readonly searchQuery?: string;
  readonly pageNumber?: number;
}

export const getPayrollRunById = (axios: IAxiosInstance) => {
  return async (params: IGetFetchPayrollRunParams) => {
    const response = await axios.noRetry.get<
      ZealApiResponse<
        IPayrollRun & {
          checkDetails: ICheckDetails[];
          hasChecksWithNoShifts: boolean;
        }
      >
    >(`/api/fetch-payroll-run`, {
      params,
    });
    const resData = response.data;
    if (resData.success) {
      return resData.data;
    }
    throw new Error("Something went wrong");
  };
};
