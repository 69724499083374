import { RoundingRule, Round } from "./Rounding";

export type UnboundedDollars = null;
export type Cents = number;
export const UnboundedDollars = <UnboundedDollars>null;

export class Dollars {
  readonly cents: Cents;

  static unbounded = <UnboundedDollars>null;

  static zero: Dollars = new Dollars(0);

  constructor(
    amount: number,
    roundingRule: RoundingRule = RoundingRule.RoundHalfTowardsPositiveInfinity,
    roundToDollar: boolean = false
  ) {
    amount = Round.round(roundingRule, amount, roundToDollar ? 0 : 2);

    this.cents = +parseFloat((amount * 100).toString()).toFixed(0);
  }

  get value(): number {
    return this.amount();
  }

  static fromCents(amount: Cents): Dollars {
    const cents = +parseFloat(amount.toString()).toFixed(0) as Cents;
    return new Dollars(cents / 100);
  }

  amount(): number {
    return this.cents / 100;
  }

  amountString(): string {
    return `${this.cents.toString().slice(0, -2)}.${this.cents
      .toString()
      .slice(-2)}`;
  }

  paddedAmountString(): string {
    let dollars = this.cents.toString().slice(0, -2);
    if (dollars === "") {
      dollars = "0";
    }
    const cents = this.cents.toString().padStart(2, "0").slice(-2);
    return `${dollars}.${cents}`;
  }

  roundToDollar(
    rule: RoundingRule = RoundingRule.RoundHalfAwayFromZero
  ): Dollars {
    return new Dollars(Round.round(rule, this.amount(), 0));
  }

  toString(): string {
    return this.amount().toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  }
}
