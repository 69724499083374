import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TCustomerAccountWithFundingSource,
  TGetCustomerAccountRequestSchema,
} from "./getCustomerAccountSchema";

export const getCustomerAccount = (axios: IAxiosInstance) => {
  return async (params: TGetCustomerAccountRequestSchema) => {
    const response = await axios.noRetry.get<
      IApiResponse<TCustomerAccountWithFundingSource>
    >(`/customer-accounts/${params.customer_account_id}`, { params });
    return response.data;
  };
};
