import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

export const getContractorPayments = (axios: IAxiosInstance) => {
  return async (contractorID: string) => {
    const response = await axios.noRetry.get<
      ISchema["#/definitions/IGetContractorPaymentsResponse"]
    >(`/api/getContractorPayments`, {
      params: { contractorID },
    });
    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
