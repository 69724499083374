import {
  ContractorPayment,
  createContractorPaymentSchema,
  zSchema,
} from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";

export type TCreateContractorPaymentRequest = zSchema.infer<
  typeof createContractorPaymentSchema
>;

export const createContractorPayment = (axios: IAxiosInstance) => {
  return async (requestBody: TCreateContractorPaymentRequest) => {
    const response = await axios.noRetry.post<IApiResponse<ContractorPayment>>(
      "api/contractorPayment",
      requestBody
    );
    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
