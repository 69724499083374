import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { getUsePrimaryUrlParams } from "../../utils/utils";
import { ISchema } from "clients/LaForgeClient/validation-types/SchemaDefinition";

export const updateEmployerOnboardingWebhook = (axios: IAxiosInstance) => {
  return async ({
    employerOnboardingWebhook,
    userType,
    isTestWebhookType,
    usePrimary,
  }: ISchema["#/definitions/IUpdateEmployerOnboardingWebhookArgs"]) => {
    const response = await axios.noRetry.post<
      ISchema["#/definitions/IUpdateEmployerOnboardingWebhookResponse"]
    >(
      `/api/updateEmployerOnboardingWebhook${getUsePrimaryUrlParams(
        usePrimary
      )}`,
      {
        employer_onboarding_webhook: employerOnboardingWebhook,
        user_type: userType,
        is_test_webhook_type: isTestWebhookType,
      }
    );

    const { data: responseData } = response;
    if (!responseData.success) {
      throw new Error(`Something went wrong`);
    }
    return responseData;
  };
};
