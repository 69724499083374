import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

export const getContractors = (axios: IAxiosInstance) => {
  return async () => {
    const response = await axios.noRetry.get<
      ISchema["#/definitions/IGetMyContractorsResponse"]
    >(`/api/getMyContractors`, {});
    const data = response.data;

    return data;
  };
};
