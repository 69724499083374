/* 
  To add another unit of length, find the conversion factor 
  between the new unit and the base unit (meters) 
  and add it to the conversionFactors object.

  You can also alias it as you see fit in the object and the Length class.
 */
const conversionFactors = {
  mm: 1e-3, // 1 mm = 0.001 m
  cm: 1e-2, // 1 cm = 0.01 m
  m: 1, // 1 m = 1 m
  km: 1e3, // 1 km = 1000 m
  in: 0.0254, // 1 in = 0.0254 m
  ft: 0.3048, // 1 ft = 0.3048 m
  yd: 0.9144, // 1 yd = 0.9144 m
  mi: 1609.344, // 1 mi = 1609.344 m

  // full name aliases
  get millimeters() {
    return this.mm;
  },
  get centimeters() {
    return this.cm;
  },
  get meters() {
    return this.m;
  },
  get kilometers() {
    return this.km;
  },
  get inches() {
    return this.in;
  },
  get feet() {
    return this.ft;
  },
  get yards() {
    return this.yd;
  },
  get miles() {
    return this.mi;
  },
} as const;

type Unit = keyof typeof conversionFactors;

const convert = (value: number, from: Unit, to: Unit) => {
  if (!conversionFactors[from] || !conversionFactors[to]) {
    throw new Error("Invalid length units");
  }

  const conversionFactor = conversionFactors[to] / conversionFactors[from];

  return value / conversionFactor;
};

export class Length {
  private readonly _m: number;

  private constructor(meters: number) {
    this._m = meters;
  }

  static from(amount: number, unit: Unit) {
    return new Length(convert(amount, unit, "m"));
  }

  public to(unit: Unit) {
    return convert(this._m, "m", unit);
  }

  private rounded() {
    return Math.round((this._m + Number.EPSILON) * 100) / 100;
  }

  // compare to another Length instance
  public lt(l2: Length) {
    return this.rounded() < l2.rounded();
  }

  public lte(l2: Length) {
    return this.rounded() <= l2.rounded();
  }

  public gt(l2: Length) {
    return this.rounded() > l2.rounded();
  }

  public gte(l2: Length) {
    return this.rounded() >= l2.rounded();
  }

  public eq(l2: Length) {
    return this.rounded() === l2.rounded();
  }

  // semantic getters
  public get millimeters() {
    return this.to("mm");
  }
  public get centimeters() {
    return this.to("cm");
  }
  public get meters() {
    return this.to("m");
  }
  public get kilometers() {
    return this.to("km");
  }
  public get inches() {
    return this.to("in");
  }
  public get feet() {
    return this.to("ft");
  }
  public get yards() {
    return this.to("yd");
  }
  public get miles() {
    return this.to("mi");
  }
}
