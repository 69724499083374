import { z } from "zod";
import { uuid } from "../../schema-validation/schemes";

export const taskStatus = {
  Pending: "pending",
  InProgress: "in-progress",
  Complete: "complete",
  Disabled: "disabled",
} as const;

export type TaskStatus = (typeof taskStatus)[keyof typeof taskStatus];

export const taskSchema = z.object({
  correlationId: z.string(),
  taskId: uuid(),
  taskDefinitionId: uuid(),
  taskDefinitionTemplateId: uuid(),
  productDefinitionId: uuid(),
  companyID: uuid(),
  status: z.nativeEnum(taskStatus).default(taskStatus.Pending),
  fields: z.object({}).default({}),
});

export type ZTask = z.infer<typeof taskSchema>;
