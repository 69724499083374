import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";
import { IUploadTaxParameterRequest } from "./updateTaxParameters.jsonschema";

export const uploadTaxParameters = (axios: IAxiosInstance) => {
  return async (body: IUploadTaxParameterRequest) => {
    const response = await axios.noRetry.post<
      ISchema["#/definitions/updateTaxParameters"]
    >(`/api/updateTaxParameters`, body);
    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
