import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";
import { IGetEmployerCheckInternalQueryParams } from "./getEmployerCheckInternal.jsonschema";

export const getEmployerCheckInternal = (axios: IAxiosInstance) => {
  return async (params: IGetEmployerCheckInternalQueryParams) => {
    const response = await axios.noRetry.get<
      ISchema["#/definitions/IGetEmployerCheckInternalResponse"]
    >(`/api/employerCheck`, {
      params,
    });
    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
