import { IResponseBase } from "./IResponseBase";

export const buildResponseBase = <T>(res: {
  status: number;
  data: T;
}): IResponseBase<T> => {
  return {
    status: res.status,
    success: res.status === 200 ? true : false,
    data: res.data,
  };
};
