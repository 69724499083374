/**
 * Indicates the reason for when a case is submitted 3 federal business days after the date of hire
 */
export enum EReasonForDelay {
  AWAITING_SOCIAL_SECURITY_NUMBER = "AWAITING_SOCIAL_SECURITY_NUMBER",
  TECHNICAL_PROBLEMS = "TECHNICAL_PROBLEMS",
  AUDIT_REVEALED_THAT_NEW_HIRE_WAS_NOT_RUN = "AUDIT_REVEALED_THAT_NEW_HIRE_WAS_NOT_RUN",
  AWAITING_RESPONSE_ON_CASE_WITH_INCORRECT_INFO = "AWAITING_RESPONSE_ON_CASE_WITH_INCORRECT_INFO",
  FEDERAL_CONTRACTOR = "FEDERAL_CONTRACTOR",
  OTHER = "OTHER",
}
