import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import { TGetPayCardsResponseSchema } from "../../../OdoClient/routes/getPayCards/getPayCards.schema";
import { TGetEmployeePayCardsRequestSchema } from "./getEmployeePayCards.schema";

export const getEmployeePayCards = (axios: IAxiosInstance) => {
  return async (data: TGetEmployeePayCardsRequestSchema) => {
    const { employee_id, ...rest } = data;

    const response = await axios.noRetry.get<
      IApiResponse<TGetPayCardsResponseSchema>
    >(`/api/employees/${employee_id}/pay-cards`, rest);

    return response.data;
  };
};
