import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";
import { ICalcTaxArguments } from "./calcTax.jsonschema";

export const calcTax = (axios: IAxiosInstance) => {
  return async (body: ICalcTaxArguments) => {
    const response = await axios.with502Retry.post<
      ISchema["#/definitions/ICalcTaxResponseBody"]
    >(`/api/calculator/calcTax`, body);
    return response.data;
  };
};
