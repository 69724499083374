import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";

export interface IDisplayTransactionEvent {
  status: string;
  display_status: string;
  timestamp: string;
}

type TGetTransactionEventsResponse = IApiResponse<IDisplayTransactionEvent>;

export const getTransactionEvents = (axios: IAxiosInstance) => {
  return async ({ checkId }: { checkId: string }) => {
    const response = await axios.noRetry.get<TGetTransactionEventsResponse>(
      `/api/transactionEvents`,
      {
        params: { checkId },
      }
    );
    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
