import { ReadonlySet } from "../data-structures";

export const EDeductionType = {
  "401K": "401k",
  "403B": "403b",
  DEPENDENT_CARE_BENEFIT: "dependent_care_benefit",
  EMPLOYER_SPONSORED_HEALTH_COVERAGE: "employer_sponsored_health_coverage",
  GARNISHMENT: "garnishment",
  HSA: "hsa",
  MISCELLANEOUS: "miscellaneous",
  ROTH_401K: "roth_401k",
  ROTH_IRA: "roth_ira",
  SECTION_125: "section_125",
  SIMPLE_IRA: "simple_ira",
} as const;

export type EDeductionType =
  (typeof EDeductionType)[keyof typeof EDeductionType];

export const DeductionTypeLabel: Record<EDeductionType, string> = {
  "401k": "401K",
  "403b": "403B",
  dependent_care_benefit: "Dependent Care Benefit",
  employer_sponsored_health_coverage: "Employer Sponsored Health Coverage",
  garnishment: "Garnishment",
  hsa: "HSA",
  miscellaneous: "Miscellaneous",
  roth_401k: "Roth 401K",
  roth_ira: "Roth IRA",
  section_125: "Section 125",
  simple_ira: "Simple IRA",
} as const;

export const DEDUCTION_TYPES = new ReadonlySet(Object.values(EDeductionType));
