import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

export const updateEmployeeAsSelf = (axios: IAxiosInstance) => {
  return async ({
    workerData,
    employeeID,
    companyID,
  }: ISchema["#/definitions/IUpdateEmployeeAsSelfRequest"]) => {
    const response = await axios.noRetry.post<
      ISchema["#/definitions/IUpdateEmployeeAsSelfResponse"]
    >(`/api/updateEmployeeAsSelf`, {
      oldData: {
        _id: employeeID,
      },
      newData: workerData,
      companyID,
    });
    const resData = response.data;

    if (resData.success) {
      return resData;
    }

    throw new Error("Something went wrong");
  };
};
