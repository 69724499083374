import { z } from "zod";

import { ZContractor } from "../contractors";
import { schemes } from "../schema-validation";
import { baseEventInput } from "./baseEvent";

export const contractorEvent = baseEventInput.merge(
  z.object({
    source: z.literal("la-forge.contractors"),
    contractorId: schemes.mongoObjectId(),
    companyId: schemes.uuid(),
    payload: ZContractor.partial(),
  })
);

export type ContractorEvent = z.infer<typeof contractorEvent>;
