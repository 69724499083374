import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "clients/LaForgeClient/validation-types/SchemaDefinition";

export const testEmployeeRequirementCreatedWebhook = (
  axios: IAxiosInstance
) => {
  return async ({
    url,
    testMode,
  }: ISchema["#/definitions/ITestEmployeeRequirementCreatedWebhookBody"]) => {
    const response = await axios.noRetry.post<
      ISchema["#/definitions/ITestEmployeeRequirementCreatedWebhookResponse"]
    >(`/api/testEmployeeRequirementCreatedWebhook`, {
      url,
      testMode,
    });

    const { data: responseData } = response;
    if (!responseData.success) {
      throw new Error(`Something went wrong`);
    }
    return responseData;
  };
};
