import {
  IPostCreatePaperworkTemplateResponse,
  PAPERWORK_ROUTES,
} from "@zeal/common";
import { IAxiosInstance } from "clients/AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "clients/AbstractApiClient/IApiResponse";

export const createCustomPaperwork = (axios: IAxiosInstance) => async () => {
  const response = await axios.noRetry.post<
    IApiResponse<IPostCreatePaperworkTemplateResponse>
  >(PAPERWORK_ROUTES.GET_PAPERWORK_TEMPLATES);

  const resData = response.data;

  if (resData.success) {
    return resData.data;
  }

  throw new Error("Something went wrong");
};
