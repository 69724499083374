import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import { TestMode } from "../../@types/general";

export const getKYC = (axios: IAxiosInstance) => {
  return async (mode: TestMode) => {
    const response = await axios.noRetry.get<
      IApiResponse<{ hasReviews: boolean }>
    >(`/api/kyc`, {
      params: { test: mode === TestMode.Test },
    });
    return response.data;
  };
};
