import { TPostCustomerAccountRequestSchema } from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import { TCustomerAccountWithFundingSource } from "../getCustomerAccount/getCustomerAccountSchema";

export const postCustomerAccount = (axios: IAxiosInstance) => {
  return async (body: TPostCustomerAccountRequestSchema) => {
    const response = await axios.noRetry.post<
      IApiResponse<TCustomerAccountWithFundingSource>
    >("/customer-accounts", body);
    return response.data;
  };
};
