import { z, type AnyZodObject } from "zod";
import {
  allFields,
  type IControlledInputField,
  type TFieldName,
} from "./allFields";
import { schemas } from "./schemas";

interface IGenerateFormArgs {
  readonly fieldNames: TFieldName[];
}

export interface IGenerateFormReturn {
  readonly formFields: IControlledInputField[];
  readonly formSchema: AnyZodObject;
}

/**
 * Generates form fields and schema based on the provided field names.
 *
 * @param {IGenerateFormArgs} args - The arguments for generating the form.
 * @param {TFieldName[]} args.fieldNames - An array of field names to include in the form.
 * @returns {IGenerateFormReturn} An object containing the form fields and the form schema.
 * @throws Will throw an error if no form fields or schemas can be generated for the provided field names.
 */
export const generateForm = ({
  fieldNames,
}: IGenerateFormArgs): IGenerateFormReturn => {
  const formFields = fieldNames
    .map((fieldName) => allFields[fieldName])
    .filter(Boolean) as IControlledInputField[];

  if (formFields.length === 0) {
    throw new Error(
      `Form fields could not be generated for this prerequisites form generator request. Requested fields: ${fieldNames.join(
        ", "
      )}.`
    );
  }

  const formSchemas = fieldNames
    .map((fieldName) => schemas[fieldName])
    .filter(Boolean) as AnyZodObject[];

  if (formSchemas.length === 0) {
    throw new Error(
      `Form schemas could not be generated for this prerequisites form generator request. Requested fields: ${fieldNames.join(
        ", "
      )}.`
    );
  }

  return {
    formFields,
    formSchema: z.object(Object.assign({}, ...formSchemas)),
  };
};
