import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { getUsePrimaryUrlParams } from "../../utils/utils";
import { ISchema } from "clients/LaForgeClient/validation-types/SchemaDefinition";

export const testCreationWebhook = (axios: IAxiosInstance) => {
  return async ({
    createdWebhook,
    userType,
    test,
    usePrimary,
  }: ISchema["#/definitions/ITestCreationWebhookArgs"]) => {
    const response = await axios.noRetry.post<
      ISchema["#/definitions/ITestCreationWebhookResponse"]
    >(`/api/testCreationWebhook${getUsePrimaryUrlParams(usePrimary)}`, {
      created_webhook: createdWebhook,
      user_type: userType,
      test,
    });

    const { data: responseData } = response;
    if (!responseData.success) {
      throw new Error(`Something went wrong`);
    }
    return responseData;
  };
};
