import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TPostDeductionsSummaryRequestSchema,
  TPostDeductionsSummaryResponseSchema,
} from "./postDeductionsSummarySchema";

const URL = `reports/deductions-summary`;
export const postDeductionsSummary = (axios: IAxiosInstance) => {
  return async (requestBody: TPostDeductionsSummaryRequestSchema) => {
    const response = await axios.noRetry.post<
      IApiResponse<TPostDeductionsSummaryResponseSchema>
    >(URL, requestBody);
    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
