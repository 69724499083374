import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

export const features = (axios: IAxiosInstance) => {
  return async (companyID?: string) => {
    const response = await axios.noRetry.get<
      ISchema["#/definitions/IFeaturesResponse"]
    >(`/api/features`, {
      params: {
        companyID,
      },
    });
    const resData = response.data;

    return resData;
  };
};
