import { zSchema } from "../schema-validation";

export const customerAccountOnboardRequestSchema = zSchema.object({
  business_name: zSchema.string(),
  zeal_id: zSchema.string(),
});

export type TCustomerAccountOnboardRequestSchema = zSchema.infer<
  typeof customerAccountOnboardRequestSchema
>;

export const customerAccountOnboardResponseSchema = zSchema.object({
  business_name: zSchema.string(),
  odo_user_id: zSchema.string(),
  user_type: zSchema.literal("customer_account_user"),
  zeal_id: zSchema.string(),
});

export type TCustomerAccountOnboardResponseSchema = zSchema.infer<
  typeof customerAccountOnboardResponseSchema
>;
