import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import { TGetPayCardTokenResponseSchema } from "../../../OdoClient/routes/getPayCardToken/getPayCardToken.schema";
import { TGetPayCardTokenRequestSchema } from "./getPayCardToken.schema";

export const getPayCardToken = (axios: IAxiosInstance) => {
  return async (data: TGetPayCardTokenRequestSchema) => {
    const { pay_card_id, ...rest } = data;

    const response = await axios.noRetry.get<
      IApiResponse<TGetPayCardTokenResponseSchema>
    >(`/api/pay-cards/${pay_card_id}/token`, { params: rest });

    return response.data;
  };
};
