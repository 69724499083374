import { zSchema, ELocationStatus } from "@zeal/common";

export const replaceI9WorkerLocationSchema = zSchema.object({
  location: zSchema
    .object({
      latitude: zSchema.number().optional(),
      longitude: zSchema.number().optional(),
      accuracy: zSchema.number().optional(),
      timestamp: zSchema.number().optional(),
      status: zSchema.nativeEnum(ELocationStatus),
    })
    .superRefine((value, ctx) => {
      if (value.status === ELocationStatus.SHARED) {
        (["latitude", "longitude", "accuracy", "timestamp"] as const).forEach(
          (key) => {
            if (value[key] == null) {
              ctx.addIssue({
                code: zSchema.ZodIssueCode.custom,
                message:
                  "Location fields are required when status is location_shared",
                path: [key],
              });
              return zSchema.never;
            }
          }
        );
      }
    }),
});

export const replaceI9AuthRepLocationSchema =
  replaceI9WorkerLocationSchema.extend({
    accessToken: zSchema.string(),
  });

export type TReplaceI9WorkerLocation = zSchema.input<
  typeof replaceI9WorkerLocationSchema
>;

export type TReplaceI9AuthRepLocation = zSchema.input<
  typeof replaceI9AuthRepLocationSchema
>;
