import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { getUsePrimaryUrlParams } from "../../utils/utils";
import { ISchema } from "clients/LaForgeClient/validation-types/SchemaDefinition";

export const testEmployerOnboardingWebhook = (axios: IAxiosInstance) => {
  return async ({
    employerOnboardingWebhook,
    userType,
    test,
    usePrimary,
  }: ISchema["#/definitions/ITestEmployerOnboardingWebhookArgs"]) => {
    const response = await axios.noRetry.post<
      ISchema["#/definitions/ITestEmployerOnboardingWebhookResponse"]
    >(
      `/api/testEmployerOnboardingWebhook${getUsePrimaryUrlParams(usePrimary)}`,
      {
        employer_onboarding_webhook: employerOnboardingWebhook,
        user_type: userType,
        test,
      }
    );

    const { data: responseData } = response;
    if (!responseData.success) {
      throw new Error(`Something went wrong`);
    }
    return responseData;
  };
};
