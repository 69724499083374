import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IEmployerWebhookRequestBody } from "./employerWebhook.jsonschema";

export const employerWebhook = (axios: IAxiosInstance) => {
  return async (
    employerOnboardingWebhook: string,
    userType: string,
    test: boolean
  ) => {
    const webhookBody: IEmployerWebhookRequestBody = {
      employer_onboarding_webhook: employerOnboardingWebhook,
      user_type: userType,
      test,
    };
    const response = await axios.noRetry.post<unknown>(
      `/api/testEmployerOnboardingWebhook`,
      webhookBody
    );

    const { data } = response;
    return data;
  };
};
