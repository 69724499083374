import { IV2_Deduction, ZealApiResponse } from "@zeal/common";
import { IAxiosInstance } from "clients/AbstractApiClient/AbstractApiClient";

const URL = "/api/employee-check-deductions";
export const getEmployeeCheckDeductions =
  (axios: IAxiosInstance) => async (employeeCheckID: string) => {
    const response = await axios.noRetry.get<
      ZealApiResponse<(IV2_Deduction & { id: string })[]>
    >(URL, {
      params: { employeeCheckID },
    });
    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
