import { zSchema } from "../../../schema-validation";

export const employeeAddAchSchema = zSchema.object({
  first_name: zSchema.string(),
  last_name: zSchema.string(),
  odo_user_id: zSchema.string(),
  account_type: zSchema.string(), // TODO enum
  account_number: zSchema.string(),
  routing_number: zSchema.string(),
});
