import { z } from "zod";
import { RouteMap } from "./customerReporting";

const taxableWageResponse = z.object({
  employees: z.array(z.record(z.string(), z.string())),
  headers: z.array(z.string()),
});

export type TaxableWageResponse = {
  kind: RouteMap.taxable_wage;
} & z.infer<typeof taxableWageResponse>;
