import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
export const migrateEmployeeTaxParamsData = (axios: IAxiosInstance) => {
  return async (body: any) => {
    const response = await axios.noRetry.post<IApiResponse<any>>(
      `/api/migrate-employee-tax-params`,
      body
    );
    return response.data;
  };
};
