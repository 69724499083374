import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import { TI9WebhookRequest } from "../testI9Webhook/testI9WebhookSchema";
import { TUpdateI9WebhookResponse } from "./updateI9WebhookSchema";

export const updateI9Webhook = (axios: IAxiosInstance) => {
  return async ({ testMode, url }: TI9WebhookRequest) => {
    const response = await axios.noRetry.post<
      IApiResponse<TUpdateI9WebhookResponse>
    >("/api/updateI9Webhook", {
      testMode,
      url,
    });

    const { data: responseData } = response;
    if (!responseData.success) {
      throw new Error(`Something went wrong`);
    }
    return responseData;
  };
};
