import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import {
  CompleteEmployerOnboardingFlowResponse,
  ICompleteEmployerOnboardingFlowRequestBody,
} from "./completeEmployerOnboardingFlow.jsonschema";

export const completeEmployerOnboardingFlow = (axios: IAxiosInstance) => {
  return async (body: ICompleteEmployerOnboardingFlowRequestBody) => {
    const response =
      await axios.noRetry.post<CompleteEmployerOnboardingFlowResponse>(
        `/api/completeEmployerOnboardingFlow`,
        body
      );

    const { data } = response.data;
    return data;
  };
};
