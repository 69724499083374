import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { TestMode } from "../../@types/general";
import { ISchema } from "../../validation-types/SchemaDefinition";

export const getPendingShifts = (axios: IAxiosInstance) => {
  return async ({
    employeeCheckID,
    mode,
  }: ISchema["#/definitions/IGetPendingShiftsQueryParams"]) => {
    const response = await axios.noRetry.get<
      ISchema["#/definitions/IGetPendingShiftsResponse"]
    >(`/api/getPendingShifts`, {
      params: { employeeCheckID, test: mode === TestMode.Test },
    });
    const data = response.data;

    if (data.success) {
      return data;
    }

    throw new Error("Something went wrong");
  };
};
