import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "clients/LaForgeClient/validation-types/SchemaDefinition";

export const updateGuestInfo = (axios: IAxiosInstance) => {
  return async ({
    firstName,
    lastName,
    businessName,
  }: ISchema["#/definitions/IUpdateGuestInfoArgs"]) => {
    const response = await axios.noRetry.post<
      ISchema["#/definitions/IUpdateGuestInfoResponse"]
    >(`/api/updateGuestInfo`, {
      business_name: businessName,
      first_name: firstName,
      last_name: lastName,
    });

    const { data: responseData } = response;
    if (!responseData.success) {
      throw new Error(`Failed to update guest info`);
    }
    return responseData.data;
  };
};
