import { ZealApiResponse, II9OnboardingInfo } from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { TReplaceI9AuthRepLocation } from "./replaceI9Location.schema";

export const replaceI9AuthRepLocation = (axios: IAxiosInstance) => {
  return async (data: TReplaceI9AuthRepLocation) => {
    const { accessToken, ...location } = data;
    const response = await axios.noRetry.put<
      ZealApiResponse<II9OnboardingInfo>
    >("/api/i9-onboarding-info/auth-rep-location", location, {
      params: { accessToken },
    });
    const resData = response.data;
    if (resData.success) {
      return resData.data;
    }
    throw new Error("Something went wrong");
  };
};
