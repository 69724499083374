import {
  PAPERWORK_ROUTES,
  IPostCreatePaperworkTemplateRequest,
  IPostCreatePaperworkTemplateResponse,
} from "@zeal/common";
import { IAxiosInstance } from "../../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../../AbstractApiClient/IApiResponse";

export const putPaperworkTemplate = (axios: IAxiosInstance) => {
  return async (data: IPostCreatePaperworkTemplateRequest | FormData) => {
    const response = await axios.noRetry.put<
      IApiResponse<IPostCreatePaperworkTemplateResponse>
    >(PAPERWORK_ROUTES.CREATE_PAPERWORK_TEMPLATE, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
