import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import {
  CheckCookieResponse,
  ICheckCookieRequestBody,
} from "./checkCookie.jsonschema";

export const checkCookie = (axios: IAxiosInstance) => {
  return async (body: ICheckCookieRequestBody) => {
    const response = await axios.noRetry.post<CheckCookieResponse>(
      `/api/checkCookie`,
      body
    );

    const { data } = response.data;
    return data;
  };
};
