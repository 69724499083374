import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IEmployeeCheckWebhookRequestBody } from "./employeeCheckWebhook.jsonschema";

export const employeeCheckWebhook = (axios: IAxiosInstance) => {
  return async (
    employeeCheckWebhook: string,
    userType: string,
    test: boolean
  ) => {
    const webhookBody: IEmployeeCheckWebhookRequestBody = {
      employee_check_webhook: employeeCheckWebhook,
      user_type: userType,
      test,
    };
    const response = await axios.noRetry.post<unknown>(
      `/api/testEmployeeCheckWebhook`,
      webhookBody
    );

    const { data } = response;
    return data;
  };
};
