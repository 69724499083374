import { UPDATE_USER_DATA } from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";

export const updateKYCInfo =
  (axios: IAxiosInstance) =>
  async (body: {
    update: {
      odo_user_id: string;
      first_name: string;
      last_name: string;
      birth_date_base: string;
      ssn: string;
      ein: string;
      uuid: string;
      address: string;
      address_line2?: string;
      city: string;
      state: string;
      zip: string;
      phone_number: string;
      contact_email: string;
      partner_id: string;
      company_id: string;
    };
    testMode: boolean;
  }) => {
    const response = await axios.noRetry.post<IApiResponse<any>>(
      UPDATE_USER_DATA,
      body.update
    );
    const { data, success } = response.data;
    if (!success) {
      throw new Error("Failed to update KYC info");
    }
    return data;
  };
