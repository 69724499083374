import {
  EEVerifyCaseClosureReasonsAfterCloseOnTNC,
  EPendingReferralDecision,
  schemes,
} from "@zeal/common";
import { z } from "zod";

export const postResolvePendingReferralSchema = z.discriminatedUnion(
  "decision",
  [
    z.object({
      case_closure_reason_code: z.nativeEnum(
        EEVerifyCaseClosureReasonsAfterCloseOnTNC
      ),
      decision: z.literal(EPendingReferralDecision.CLOSE_CASE),
      workerID: schemes.mongoObjectId(),
      testMode: z.boolean(),
    }),
    z.object({
      decision: z.literal(EPendingReferralDecision.NO_ACTION),
      employee_notified: z.literal(true),
      workerID: schemes.mongoObjectId(),
      testMode: z.boolean(),
    }),
    z.object({
      decision: z.literal(EPendingReferralDecision.REFER),
      employee_notified: z.literal(true),
      workerID: schemes.mongoObjectId(),
      testMode: z.boolean(),
    }),
  ]
);

export type TPostResolvePendingReferral = z.infer<
  typeof postResolvePendingReferralSchema
>;
