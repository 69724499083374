import { zSchema } from "../../../schema-validation";

export const customerAccountAddAchRequestSchema = zSchema.object({
  account_number: zSchema.string(),
  account_type: zSchema.enum(["checking", "savings"]),
  business_name: zSchema.string(),
  odo_user_id: zSchema.string(),
  routing_number: zSchema.string(),
  testMode: zSchema.boolean().optional(),
  zeal_id: zSchema.string(),
});

export type TCustomerAccountAddAchRequestSchema = zSchema.infer<
  typeof customerAccountAddAchRequestSchema
>;

export const customerAccountAddAchResponseSchema = zSchema.object({
  bank_account_id: zSchema.string(),
  odo_user_id: zSchema.string(),
  user_type: zSchema.literal("customer_account_user"),
  zeal_id: zSchema.string(),
});

export type TCustomerAccountAddAchResponseSchema = zSchema.infer<
  typeof customerAccountAddAchResponseSchema
>;
