import {
  TEqualsFilter,
  TGreaterThanFilter,
  TLessThanFilter,
  TGreaterThanOrEqualFilter,
  TLessThanOrEqualFilter,
} from "./filter_types";

export type ArrayOfLength2<T> = readonly [T, T];
// DATE FILTERS (compare) - expects ISO 1806 format (YYYY-MM-DD)
export enum EDateView {
  DAY = "DAY",
  WEEK = "WEEK",
  MONTH = "MONTH",
}

export type TMatchDateFilter = TEqualsFilter<string, "date">;
export type TGreaterThanDateFilter = TGreaterThanFilter<string, "date">;
export type TLessThanDateFilter = TLessThanFilter<string, "date">;
export type TGreaterThanOrEqualDateFilter = TGreaterThanOrEqualFilter<
  string,
  "date"
>;
export type TLessThanOrEqualDateFilter = TLessThanOrEqualFilter<string, "date">;

export type TMultipleDateFilterElement =
  | TGreaterThanDateFilter
  | TGreaterThanOrEqualDateFilter
  | TLessThanDateFilter
  | TLessThanOrEqualDateFilter;

export type TSingleDateFilter = TMultipleDateFilterElement | TMatchDateFilter;

export type TMultipleDateFilter = ArrayOfLength2<TMultipleDateFilterElement>;
export type TDateFilter = TSingleDateFilter | TMultipleDateFilter;
