import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

export const getGovernmentIdUrls = (axios: IAxiosInstance) => {
  return async (params: ISchema["#/definitions/IGetGovernmentIdUrlsArgs"]) => {
    const response = await axios.noRetry.get(`/generate-id-get-url`, {
      params,
    });
    const resData = response.data
      .data as ISchema["#/definitions/IGetGovernmentIdUrlsResponse"];
    return resData;
  };
};
