import { AbstractApiClient } from "../AbstractApiClient/AbstractApiClient";
import { calcTax } from "./routes/calcTax/calcTax";
import { createEmployee } from "./routes/createEmployee/createEmployee";
import { getCompanyLocationMetadata } from "./routes/getCompanyLocationMetadata/getCompanyLocationMetadata";
import { getCompanyLocations } from "./routes/getCompanyLocations/getCompanyLocations";
import { getCompanyTaxParameterSummary } from "./routes/getCompanyTaxParameterSummary/getCompanyTaxParameterSummary";
import { getEmployeeChecks } from "./routes/getEmployeeChecks/getEmployeeChecks";
import { getEmployeeTaxParameterSummary } from "./routes/getEmployeeTaxParameterSummary/getEmployeeTaxParameterSummary";
import { postMigrateEmployeesToNewCompany } from "./routes/migrateEmployeesToNewCompany/migrateEmployeesToNewCompany";
import { resolveTaxableLocation } from "./routes/resolveTaxableLocation/resolveTaxableLocation";
import { setEmployeeTaxParameters } from "./routes/setEmployeeTaxParameters/setEmployeeTaxParameters";
import { updateResidency } from "./routes/updateResidency/updateResidency";
import { voidEmployeeChecks } from "./routes/voidEmployeeChecks/voidEmployeeChecks";
import {
  assertValidQuarkSchema,
  QuarkSchemaAssertionWithParams,
} from "./validation-types/assertValidQuarkSchema";

export class QuarkClient extends AbstractApiClient {
  public static readonly AssertValidSchema: QuarkSchemaAssertionWithParams =
    assertValidQuarkSchema;
  public readonly CalcTax = calcTax(this.Instance);
  public readonly CreateEmployee = createEmployee(this.Instance);
  public readonly GetCompanyLocations = getCompanyLocations(this.Instance);
  public readonly GetCompanyLocationMetadata = getCompanyLocationMetadata(
    this.Instance
  );
  public readonly GetCompanyTaxParameterSummary = getCompanyTaxParameterSummary(
    this.Instance
  );
  public readonly GetEmployeeChecks = getEmployeeChecks(this.Instance);
  public readonly GetEmployeeTaxParameterSummary =
    getEmployeeTaxParameterSummary(this.Instance);
  public readonly MigrateEmployeesToNewCompany =
    postMigrateEmployeesToNewCompany(this.Instance);
  public readonly ResolveTaxableLocation = resolveTaxableLocation(
    this.Instance
  );
  public readonly SetEmployeeTaxParameters = setEmployeeTaxParameters(
    this.Instance
  );
  public readonly UpdateResidency = updateResidency(this.Instance);
  public readonly voidEmployeeChecks = voidEmployeeChecks(this.Instance);
}
