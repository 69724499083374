import { TRANSACTION_UPDATE_STATUS } from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";

export const updateTransactionStatuses =
  (axios: IAxiosInstance) =>
  async (body: { ids: string[]; testMode: boolean }) => {
    const response = await axios.noRetry.post<
      IApiResponse<
        {
          transaction_id: string;
          status: string;
        }[]
      >
    >(TRANSACTION_UPDATE_STATUS, { ids: body.ids });
    const { data, success } = response.data;
    if (!success) {
      throw new Error("Failed to update transaction statuses");
    }
    return data;
  };
