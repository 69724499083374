import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

const URL = `/api/add-payroll-run-checks`;

export const postAddPayrollRunChecks = (axios: IAxiosInstance) => {
  return async (requestBody: {
    employeeIDs: string[];
    payrollRunID: string;
  }) => {
    const response = await axios.noRetry.post<
      ISchema["#/definitions/IPostAddPayrollRunCheckResponse"]
    >(URL, requestBody);
    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
