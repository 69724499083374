import {
  IGetPreviewChecksResponse,
  TGetPreviewChecksRequestSchema,
} from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";

export const getPreviewChecks = (axios: IAxiosInstance) => {
  return async (requestBody: { checks: TGetPreviewChecksRequestSchema[] }) => {
    const response = await axios.noRetry.post<
      IApiResponse<IGetPreviewChecksResponse>
    >("/api/getBulkPreviewChecks", {
      ...requestBody,
      employeeID: requestBody.checks.map((check) => check.employeeID),
    });

    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
