import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

export const getWLInfoById = (axios: IAxiosInstance) => {
  return async ({
    whiteLabelID,
  }: ISchema["#/definitions/IGetWLInfoByIdArgs"]) => {
    const response = await axios.noRetry.get<
      ISchema["#/definitions/IGetWLInfoByIdResponse"]
    >(`/api/getWLInfoById`, {
      params: { usePrimary: true, whiteLabelID },
    });
    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
