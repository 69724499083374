import { z } from "zod";

export const updateCompanyVeriffTokenRequestBodySchema = z.object({
  companyId: z.string(),
  veriffToken: z.string().nullish(),
  veriffSecret: z.string().nullish(),
});

export type TUpdateCompanyVeriffTokenRequestBody = z.infer<
  typeof updateCompanyVeriffTokenRequestBodySchema
>;
