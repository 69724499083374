export const listToString = (
  strings?: string[],
  includeA = true,
  connectingWord = "and"
) => {
  if (!strings || strings.length === 0) {
    return "";
  }
  const a = includeA ? "a " : "";
  if (strings.length === 1) {
    return `${a}${strings[0]}`;
  }

  if (strings.length === 2) {
    return `${a}${strings[0]} ${connectingWord} ${a}${strings[1]}`;
  }
  if (strings.length === 3) {
    return `${a}${strings[0]}, ${a}${strings[1]}, ${connectingWord} ${a}${strings[2]}`;
  }
  const lastString = strings.pop();
  return `${strings.join(", ")}, ${connectingWord} ${a}${lastString}`;
};
