import { zAbstractDeductionTemplate } from "./zAbstractDeductionTemplate";

export const zCreateAbstractDeductionTemplate = zAbstractDeductionTemplate
  .pick({
    companyID: true,
    employeeID: true,
    deduction_type: true,
    custom_name: true,
    effective_start_date: true,
  })
  .required()
  .merge(
    zAbstractDeductionTemplate
      .pick({
        deduction_credit: true,
        effective_end_date: true,
        external_id: true,
        zeal_managed: true,
        webhook_id: true,
      })
      .partial()
  );
