import {
  IPostUpdatePaperworkTemplateRequest,
  IPostUpdatePaperworkTemplateResponse,
  PAPERWORK_ROUTES,
} from "@zeal/common";
import { IAxiosInstance } from "../../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../../AbstractApiClient/IApiResponse";

export const patchPaperworkTemplate = (axios: IAxiosInstance) => {
  return async (
    templateID: string,
    data: IPostUpdatePaperworkTemplateRequest | FormData
  ) => {
    const response = await axios.noRetry.patch<
      IApiResponse<IPostUpdatePaperworkTemplateResponse>
    >(
      `${PAPERWORK_ROUTES.UPDATE_PAPERWORK_TEMPLATE.replace(
        "/:templateID",
        ""
      )}/${templateID}`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
