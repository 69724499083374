import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";

export const validateOnboardingToken = (axios: IAxiosInstance) => {
  return async (body: { accessToken: string }) => {
    const response = await axios.noRetry.post<any>(
      `/api/validateOnboardingToken`,
      body
    );
    return response.data;
  };
};
