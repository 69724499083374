import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import {
  TUpdateResidencyRequestSchema,
  TUpdateResidencyResponseSchema,
} from "./updateResidency.schema";

export const updateResidency = (axios: IAxiosInstance) => {
  return async (body: TUpdateResidencyRequestSchema) => {
    const response = await axios.noRetry.post<TUpdateResidencyResponseSchema>(
      `/api/employee/updateResidency`,
      body
    );

    return response.data;
  };
};
