import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TGetPayCardTransactionsRequestSchema,
  TGetPayCardTransactionsResponseSchema,
} from "./getPayCardTransactions.schema";

export const getPayCardTransactions = (axios: IAxiosInstance) => {
  return async (params: TGetPayCardTransactionsRequestSchema) => {
    const { pay_card_id, ...rest } = params;

    const response = await axios.noRetry.get<
      IApiResponse<TGetPayCardTransactionsResponseSchema>
    >(`/api/pay-cards/${pay_card_id}/transactions`, { params: rest });

    return response.data;
  };
};
