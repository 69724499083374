import { EWorkerVerificationIDType } from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

export interface ISendOnboardingLinkParams {
  readonly employeeID: string;
  readonly stageFlags: {
    readonly createAcct?: boolean;
    readonly paperwork?: boolean;
    readonly idScan?: boolean;
    readonly i9?: boolean;
    readonly paymentMethod?: boolean;
  };
  readonly idScanDocuments?: Record<EWorkerVerificationIDType, boolean>;
}

export const sendOnboardingLink = (axios: IAxiosInstance) => {
  return async (params: ISendOnboardingLinkParams) => {
    const response = await axios.noRetry.post<
      ISchema["#/definitions/ISendOnboardingLinkResponse"]
    >(`/api/sendOnboardingLink`, params);
    const data = response.data;

    if (data.success === false) {
      throw new Error("Error");
    }
    return data;
  };
};
