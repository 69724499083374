export enum ConstraintFieldTypes {
  STRING = "string",
  BOOLEAN = "boolean",
  DOLLARS = "dollars",
  INTEGER = "integer",
  OPTIONS = "options",
  SSN = "ssn",
  DATE = "date",
  PHONE = "phone",
  FILE = "file",
  CUSTOM = "custom",
}
