import { ZealApiResponse } from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IPayrollRun } from "../common/IPayrollRun";

export const getNextRegularRun = (axios: IAxiosInstance) => {
  return async () => {
    const response = await axios.noRetry.get<
      ZealApiResponse<{
        nextRun: IPayrollRun;
        cutOffTime: string;
        payDate: string;
      }>
    >(`/api/payroll-run/next-regular-run`);
    const resData = response.data;
    if (resData.success) {
      return resData.data;
    }
    throw new Error("Something went wrong");
  };
};
