import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TUpdateBankAccountRequestSchema,
  TUpdateBankAccountResponseSchema,
} from "./updateBankAccount.schema";

export const updateBankAccount = (axios: IAxiosInstance) => {
  return async (body: TUpdateBankAccountRequestSchema) => {
    const { odo_user_id, ...rest } = body;

    // TODO(todd - 2024-09-13): Once we migrate the payment destination IDs, we will use that in the URL
    // as the bank account resource.
    const response = await axios.noRetry.patch<
      IApiResponse<TUpdateBankAccountResponseSchema>
    >(`/api/employees/${odo_user_id}/bank-accounts`, rest);

    return response.data;
  };
};
