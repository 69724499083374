import { ZealApiResponse } from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import {
  TPostI9EVerifySummaryRequestBody,
  IPostI9EVerifySummaryResponseData,
} from "./postI9EVerifySummarySchema";

export const postI9EVerifySummary = (axios: IAxiosInstance) => {
  return async (body: TPostI9EVerifySummaryRequestBody) => {
    const response = await axios.noRetry.post<
      ZealApiResponse<IPostI9EVerifySummaryResponseData>
    >("/reports/i9-everify-summary", body);

    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
