/**
 * Indicates the step in the I9 e-verify process.
 * Used to track the progress of the worker through the process,
 * and to restore the session when the worker switches browsers/devices mid-onboarding.
 */
export enum EI9OnboardingStep {
  PERSONAL_INFO = "PERSONAL_INFO",
  CITIZENSHIP_SELECTION = "CITIZENSHIP_SELECTION",
  DOCUMENT_SELECTION = "DOCUMENT_SELECTION",
  SIGNATURE = "SIGNATURE",
  CONTACT_AUTHORIZED_REPRESENTATIVE = "CONTACT_AUTHORIZED_REPRESENTATIVE",
  COMPLETE = "COMPLETE",
}

/**
 * Define an enum for all possible child steps.
 * We will later map the child steps to the main steps.
 * NOTE: Using EI9ChildStep naming convention so that is easier to distinguish.
 */
export enum EI9ChildStep {
  DOCUMENT_INFO_FORM = "DOCUMENT_INFO_FORM",
  DOCUMENT_TYPE_SELECTION = "DOCUMENT_TYPE_SELECTION",
  DOCUMENT_SUBMISSION_PREP = "DOCUMENT_SUBMISSION_PREP",
  DOCUMENT_SUBMISSION_FRONT = "DOCUMENT_SUBMISSION_FRONT",
  DOCUMENT_SUBMISSION_FRONT_REVIEW = "DOCUMENT_SUBMISSION_FRONT_REVIEW",
  DOCUMENT_SUBMISSION_FLIP = "DOCUMENT_SUBMISSION_FLIP",
  DOCUMENT_SUBMISSION_BACK = "DOCUMENT_SUBMISSION_BACK",
  DOCUMENT_SUBMISSION_BACK_REVIEW = "DOCUMENT_SUBMISSION_BACK_REVIEW",
  SIGN = "SIGN",
  AUTHORIZED_REPRESENTATIVE_PREP = "AUTHORIZED_REPRESENTATIVE_PREP",
  PREPARER = "PREPARER",
  VIEW_I9 = "VIEW_I9",
  AUTHORIZED_REPRESENTATIVE_REQUEST = "AUTHORIZED_REPRESENTATIVE_REQUEST",
  AUTHORIZED_REPRESENTATIVE_REQUEST_SENT = "AUTHORIZED_REPRESENTATIVE_REQUEST_SENT",
}

export type EI9OnboardingStepUnion = EI9OnboardingStep | EI9ChildStep;

export const i9OnboardingStepsOrder: EI9OnboardingStep[] = [
  EI9OnboardingStep.PERSONAL_INFO,
  EI9OnboardingStep.CITIZENSHIP_SELECTION,
  EI9OnboardingStep.DOCUMENT_SELECTION,
  EI9OnboardingStep.SIGNATURE,
  EI9OnboardingStep.CONTACT_AUTHORIZED_REPRESENTATIVE,
  EI9OnboardingStep.COMPLETE,
];

/**
 * Map child steps to main steps.
 */
export const stepToMainStepMap: Record<EI9ChildStep, EI9OnboardingStep> = {
  [EI9ChildStep.DOCUMENT_INFO_FORM]: EI9OnboardingStep.DOCUMENT_SELECTION,
  [EI9ChildStep.DOCUMENT_TYPE_SELECTION]: EI9OnboardingStep.DOCUMENT_SELECTION,
  [EI9ChildStep.DOCUMENT_SUBMISSION_PREP]: EI9OnboardingStep.DOCUMENT_SELECTION,
  [EI9ChildStep.DOCUMENT_SUBMISSION_FRONT]:
    EI9OnboardingStep.DOCUMENT_SELECTION,
  [EI9ChildStep.DOCUMENT_SUBMISSION_FRONT_REVIEW]:
    EI9OnboardingStep.DOCUMENT_SELECTION,
  [EI9ChildStep.DOCUMENT_SUBMISSION_FLIP]: EI9OnboardingStep.DOCUMENT_SELECTION,
  [EI9ChildStep.DOCUMENT_SUBMISSION_BACK]: EI9OnboardingStep.DOCUMENT_SELECTION,
  [EI9ChildStep.DOCUMENT_SUBMISSION_BACK_REVIEW]:
    EI9OnboardingStep.DOCUMENT_SELECTION,
  [EI9ChildStep.SIGN]: EI9OnboardingStep.SIGNATURE,
  [EI9ChildStep.VIEW_I9]: EI9OnboardingStep.SIGNATURE,
  [EI9ChildStep.PREPARER]: EI9OnboardingStep.SIGNATURE,
  [EI9ChildStep.AUTHORIZED_REPRESENTATIVE_PREP]:
    EI9OnboardingStep.CONTACT_AUTHORIZED_REPRESENTATIVE,
  [EI9ChildStep.AUTHORIZED_REPRESENTATIVE_REQUEST]:
    EI9OnboardingStep.CONTACT_AUTHORIZED_REPRESENTATIVE,
  [EI9ChildStep.AUTHORIZED_REPRESENTATIVE_REQUEST_SENT]:
    EI9OnboardingStep.CONTACT_AUTHORIZED_REPRESENTATIVE,
};
