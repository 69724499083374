import { z } from "zod";
import { schemes } from "../../schema-validation";

const base = z.object({
  email: schemes.email(),
});

export const userXSchema = base.extend({
  id: schemes.mongoObjectId().readonly(),
  firstName: z.string().nullish().readonly(),
  lastName: z.string().nullish().readonly(),
  hash: z.string().readonly(),
  salt: z.string().readonly(),
  lastLoginAt: z.string().nullish().default(null),
  pendingConfirmation: z.boolean().nullable().default(false),
  loginBlockedUntilDate: z.string().nullish().default(null),
  archived: z.boolean().default(false),
  requiresPasswordReset: z.boolean().default(false),
  createdAt: z.string(),
  updatedAt: z.string(),
});

export const userAccountSchema = base
  .extend({
    category: z.literal("userAccount"),
  })
  .merge(
    userXSchema
      .pick({
        createdAt: true,
        pendingConfirmation: true,
      })
      .partial()
  );

export type UserX = z.infer<typeof userXSchema>;
export type UserAccount = z.infer<typeof userAccountSchema>;
