import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TCreatePayCardRequestSchema,
  TCreatePayCardResponseSchema,
} from "./createPayCard.schema";

export const createPayCard = (axios: IAxiosInstance) => {
  return async (body: TCreatePayCardRequestSchema) => {
    const { odo_user_id, ...rest } = body;
    const response = await axios.noRetry.post<
      IApiResponse<TCreatePayCardResponseSchema>
    >(`/api/employees/${odo_user_id}/pay-cards`, rest);

    return response.data;
  };
};
