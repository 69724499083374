import { ILaForgeCompany, ZealApiResponse } from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { TUpdateCompanyVeriffTokenResponse } from "./updateCompanyVeriffTokenSchema";

export const updateCompanyVeriffToken = (axios: IAxiosInstance) => {
  return async (data: TUpdateCompanyVeriffTokenResponse) => {
    const response = await axios.noRetry.patch<
      ZealApiResponse<ILaForgeCompany>
    >(`/api/updateCompanyVeriffToken`, { ...data });
    const resData = response.data;
    if (resData.success) {
      return resData.data;
    }
    throw new Error("Something went wrong");
  };
};
