import {
  APPLY_ATTRIBUTE_ROUTES,
  CreateOrUpdateApplyAttributesRequest,
} from "@zeal/common";
import { IAxiosInstance } from "../../../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../../../AbstractApiClient/IApiResponse";

export const createOrUpdateApplyAttribute = (axios: IAxiosInstance) => {
  return async (data: CreateOrUpdateApplyAttributesRequest) => {
    const response = await axios.noRetry.post<IApiResponse<any>>(
      APPLY_ATTRIBUTE_ROUTES.CREATE_OR_UPDATE_APPLY_ATTRIBUTES,
      data
    );

    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
