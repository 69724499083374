import { zSchema } from "@zeal/common";

export const getCustomerAccountsRequestSchema = zSchema.object({
  company_id: zSchema.string(),
  partner_id: zSchema.string(),
});

export type TGetCustomerAccountsRequestSchema = zSchema.infer<
  typeof getCustomerAccountsRequestSchema
>;
