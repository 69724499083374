import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

export const getEmployeeChecks = (axios: IAxiosInstance) => {
  return async (params: { employeeID: string }) => {
    const response = await axios.noRetry.get<
      ISchema["#/definitions/IGetEmployeeChecksResponse"]
    >(`/api/getEmployeeChecks`, { params });
    const data = response.data;

    if (data.success) {
      return data;
    }

    throw new Error("Something went wrong");
  };
};
