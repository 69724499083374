import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";
import { IUpdateEmployeeCheckRequest } from "./updateEmployeeCheckByID.jsonschema";

export const updateEmployeeCheckByID = (axios: IAxiosInstance) => {
  return async (params: IUpdateEmployeeCheckRequest) => {
    const response = await axios.noRetry.patch<
      ISchema["#/definitions/updateEmployeeCheckByIDResponse"]
    >(`/api/updateEmployeeCheckByID`, { params });
    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
