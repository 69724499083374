import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

export const updateShift = (axios: IAxiosInstance) => {
  return async ({
    old_shift,
    new_shift,
    isTestMode,
  }: ISchema["#/definitions/IUpdateShiftRequestBody"]) => {
    const response = await axios.noRetry.post(
      `api/updateShift`,
      {
        old_shift,
        new_shift,
      },
      {
        // TestShiftModel is taking a long time to update, so we need to increase the timeout
        // TODO: Danilo Meulens (2024-10-31) - Remove this once TestShiftModel indexes are fixed
        timeout: isTestMode ? 60000 : undefined,
      }
    );

    if (!response?.data?.error) {
      return response;
    }

    throw new Error("Something went wrong");
  };
};
