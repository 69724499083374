import { AxiosError } from "axios";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TGetPaymentDestinationsRequestSchema,
  TGetPaymentDestinationsResponseSchema,
} from "./getPaymentDestinations.schema";

export const getPaymentDestinations =
  (axios: IAxiosInstance) =>
  async (params: TGetPaymentDestinationsRequestSchema) => {
    try {
      const response = await axios.noRetry.get<
        IApiResponse<TGetPaymentDestinationsResponseSchema>
      >(`/api/payment-destinations`, { params });
      return response.data;
    } catch (e) {
      const errorMessage =
        (e as AxiosError)?.response?.data ??
        "Error retrieving payment destinations";
      throw errorMessage;
    }
  };
