import { ISchema } from "../../validation-types/SchemaDefinition";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";

export const UNSAFE__forceExpireCookies = (axios: IAxiosInstance) => {
  return async ({
    isPrimaryCookie,
    isSecondaryCookie,
  }: ISchema["#/definitions/IUNSAFE__forceExpireCookiesBody"]) => {
    const response = await axios.noRetry.post<
      ISchema["#/definitions/IUNSAFE__forceExpireCookiesResponse"]
    >(`/api/UNSAFE__forceExpireCookies`, {
      isPrimaryCookie,
      isSecondaryCookie,
    });
    const resData = response.data;

    if (!resData.success) {
      throw new Error("Something Went Wrong");
    }

    return resData;
  };
};
