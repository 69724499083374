import { schemes } from "@zeal/common";
import { z } from "zod";

export const postAttachEVerifyDocumentToWorkerRequestSchema = z.object({
  companyID: schemes.companyID(),
  workerID: schemes.mongoObjectId(),
  documentType: z.union([
    z.literal("everify_further_action_notice"),
    z.literal("everify_referral_date_confirmation"),
  ]),
  documentS3Key: z.string(),
  testMode: z.boolean(),
});

export type TPostAttachEVerifyDocumentToWorkerRequest = z.infer<
  typeof postAttachEVerifyDocumentToWorkerRequestSchema
>;
