import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";

export type CreateAccountResponse = IApiResponse<null>;
export type CreateAccountInput = {
  readonly email: string;
  readonly password: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly token: string;
};

export const createAccount = (axios: IAxiosInstance) => {
  return async (options: CreateAccountInput) => {
    const response = await axios.noRetry.post<CreateAccountResponse>(
      `/api/createAccount`,
      {
        email: options.email,
        password: options.password,
        first_name: options.firstName,
        last_name: options.lastName,
        token: options.token,
      }
    );

    const { data } = response.data;
    return data;
  };
};
