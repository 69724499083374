import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

export const finishHomeSetup = (axios: IAxiosInstance) => {
  return async (home_setup_mode: boolean) => {
    const response = await axios.noRetry.post<
      ISchema["#/definitions/IFinishHomeSetupResponse"]
    >(`/api/finishHomeSetup`, {
      home_setup_mode,
    });
    const data = response.data;

    if (data.success) {
      return data;
    }

    throw new Error("Something went wrong");
  };
};
