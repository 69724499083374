import { TaxDocument } from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";

export const getCompanyTaxDocument = (axios: IAxiosInstance) => {
  return async (companyID: string, taxDocumentId: string) => {
    const response = await axios.noRetry.get<{ data: TaxDocument }>(
      `/api/${companyID}/documents/tax/${taxDocumentId}`
    );
    return response.data?.data;
  };
};
