import { z } from "zod";
import validator from "validator";

function validateDate(date: string) {
  try {
    return validator.isDate(date, { format: "YYYY-MM-DD", strictMode: true });
  } catch (e) {
    return false;
  }
}

/** validates that string is in YYYY-MM-DD format */
export const isoDate = (
  errMsg = "Invalid date: please format as YYYY-MM-DD."
) => z.string().refine(validateDate, errMsg);
