import { z } from "zod";
import {
  i9eVerifyEmployeeTaskFieldsSchema,
  informationCollectionEmployeeTaskFieldsSchema,
  payrollEmployeeTaskFieldsSchema,
} from "../../employees";
import { Products } from "../../products";
import { schemes } from "../../schema-validation";
import { taskSchema } from "./taskSchema";

export const employeeTasksSchema = taskSchema.extend({
  kind: z.enum(Products),
  employeeID: schemes.mongoObjectId(),
  fields: z.discriminatedUnion("kind", [
    payrollEmployeeTaskFieldsSchema,
    i9eVerifyEmployeeTaskFieldsSchema,
    informationCollectionEmployeeTaskFieldsSchema,
  ]),
});

export type ZEmployeeTask = z.infer<typeof employeeTasksSchema>;

export const parsedEmployeeFields = (task: ZEmployeeTask) => {
  switch (task.kind) {
    case "payroll":
      return payrollEmployeeTaskFieldsSchema
        .omit({ kind: true })
        .parse(task.fields);
    case "i9-eVerify":
      return i9eVerifyEmployeeTaskFieldsSchema
        .omit({ kind: true })
        .parse(task.fields);
    case "information-collection":
      return informationCollectionEmployeeTaskFieldsSchema
        .omit({ kind: true })
        .parse(task.fields);
    default:
      throw new Error("Invalid task");
  }
};
