import { EContributionType } from "../EContributionType";
import { EDeductionType } from "../EDeductionType";
import { EHsaType } from "../EHsaType";
import { EOverrideType } from "../EOverrideType";
import { ZealErrorAlias } from "../../errors";
import { zSchema, schemes } from "../../schema-validation";

const zContributionAmount = zSchema.number().nonnegative();
const zContributionPercentage = zSchema.number().min(0).max(100);

export const zAbstractDeductionTemplate = zSchema.object({
  schema_version: zSchema.enum(["0.0", "1.0"]),
  plan_type: zSchema.enum(["employee", "company"]),
  _id: schemes.mongoObjectId(),
  deductionTemplateID: schemes.uuid(),
  companyID: schemes.companyID(),
  employeeID: schemes
    .mongoObjectId(
      "employeeID",
      ZealErrorAlias.EMPLOYEE_ID_NOT_OBJECTID.message
    )
    .optional(),
  agency_address: schemes
    .nonEmptyString("Agency address must be at least 1 character")
    .optional(),
  agency_name: schemes
    .nonEmptyString("Agency name must be at least 1 character")
    .optional(),
  agency_override_type: zSchema.nativeEnum(EOverrideType).optional(),
  archived: zSchema.boolean(),
  case_id_override_type: zSchema.nativeEnum(EOverrideType).optional(),
  case_id: schemes
    .nonEmptyString("Case ID must be at least 1 character")
    .optional(),
  custom_name: schemes.nonEmptyString(
    "Custom name must be at least 1 character"
  ),
  deduction_credit: zSchema.boolean(),
  deduction_type: zSchema.nativeEnum(EDeductionType),
  effective_end_date: schemes.isoDate().optional(),
  effective_start_date: schemes.isoDate(),
  employee_contribution_amount: zContributionAmount.optional(),
  employee_contribution_override_type: zSchema
    .nativeEnum(EOverrideType)
    .optional(),
  employee_contribution_percentage: zContributionPercentage.optional(),
  employee_contribution_type: zSchema.nativeEnum(EContributionType).optional(),
  employer_contribution_amount: zContributionAmount.optional(),
  employer_contribution_override_type: zSchema
    .nativeEnum(EOverrideType)
    .optional(),
  employer_contribution_percentage: zContributionPercentage.optional(),
  employer_contribution_type: zSchema.nativeEnum(EContributionType).optional(),
  external_id: schemes
    .nonEmptyString("External ID must be at least 1 character")
    .optional(),
  hsa_type: zSchema.nativeEnum(EHsaType).optional(),
  order_number_override_type: zSchema.nativeEnum(EOverrideType).optional(),
  order_number: schemes
    .nonEmptyString("Order number must be at least 1 character")
    .optional(),
  webhook_id: schemes.mongoObjectId().optional(),
  zeal_managed: zSchema.boolean().optional(),
  createdAt: zSchema.date(),
  updatedAt: zSchema.date(),
});
