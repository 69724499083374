import { ZealApiResponse } from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { TGetWorkerCountResponseSchema } from "./getWorkerCountSchema";

export const getWorkerCount = (axios: IAxiosInstance) => {
  return async () => {
    const response = await axios.noRetry.get<
      ZealApiResponse<TGetWorkerCountResponseSchema>
    >("/api/getWorkerCount");
    const resData = response.data;
    if (resData.success) {
      return resData.data;
    }
    throw new Error("Something went wrong");
  };
};
