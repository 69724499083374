import { RECONCILE_BANKS } from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";

export const reconcileBankAccounts = (axios: IAxiosInstance) => {
  return async (
    body: Array<{ id: string; account_number: string; routing_number: string }>
  ) => {
    const response = await axios.noRetry.post<any>(RECONCILE_BANKS, {
      accounts: body,
    });
    if (response.data?.success) {
      return response.data.data;
    }
  };
};
