import { ZealApiErrorResponse } from "@zeal/common";
import { AxiosError } from "axios";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import {
  ICompanyOnboardRequestBody,
  ICompanyOnboardResponseBody,
} from "./companyOnboard.jsonschema";

export const companyOnboard =
  (axios: IAxiosInstance) => async (body: ICompanyOnboardRequestBody) => {
    try {
      const response = await axios.noRetry.post<ICompanyOnboardResponseBody>(
        `/api/company/onboard`,
        body
      );
      return response;
    } catch (e) {
      const errorMessage =
        (e as AxiosError)?.response?.data ??
        (e as ZealApiErrorResponse)?.errors?.[0]?.message ??
        "Error onboarding company";
      throw errorMessage;
    }
  };
