import { zSchema } from "../../../schema-validation";

export const verifyCustomerAccountMicroDepositsRequestSchema = zSchema.object({
  deposits: zSchema.array(zSchema.string()),
  odo_user_id: zSchema.string(),
  testMode: zSchema.boolean().optional(),
});

export type TVerifyCustomerAccountMicroDepositsRequestSchema = zSchema.infer<
  typeof verifyCustomerAccountMicroDepositsRequestSchema
>;

export const verifyCustomerAccountMicroDepositsResponseSchema = zSchema.object({
  bank_account_id: zSchema.string(),
  odo_user_id: zSchema.string(),
  user_type: zSchema.literal("customer_account_user"),
  zeal_id: zSchema.string(),
});

export type TVerifyCustomerAccountMicroDepositsResponseSchema = zSchema.infer<
  typeof verifyCustomerAccountMicroDepositsResponseSchema
>;
