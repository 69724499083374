import { ZealApiResponse } from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IPostResolvePendingReferral } from "./postResolveEVerifyPendingReferralSchema";

export const postResolveEVerifyPendingReferral = (axios: IAxiosInstance) => {
  return async (body: IPostResolvePendingReferral) => {
    const response = await axios.noRetry.post<ZealApiResponse<boolean>>(
      "/api/postResolveEVerifyPendingReferral",
      body
    );

    return response.data;
  };
};
