import { z } from "zod";
import { schemes } from "../../schema-validation";

export const paLocalsResponseSchema = z.object({
  employees: z.array(z.string()),
  taxTypes: z.array(z.string()),
  companies: z.array(z.string()),
  taxCollectionDistrictByPSDCode: z.record(z.string()),
});

export const paLocalsRequestSchema = z.object(
  {
    start_date: schemes.isoDate(),
    end_date: schemes.isoDate(),
    company_ids: z.array(schemes.uuid(), {
      required_error: "company_ids array is required.",
    }),
  },
  { required_error: "Invalid request body." }
);

export type PaLocalsRequest = z.infer<typeof paLocalsRequestSchema>;
export type PaLocalsResponse = z.infer<typeof paLocalsResponseSchema>;
