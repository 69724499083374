import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";

export const getCompanyKybDocuments = (axios: IAxiosInstance) => {
  return async (company_id: string) => {
    const response = await axios.noRetry.get<{ data: string[] }>(
      `/api/${company_id}/documents/kyb`
    );
    return response.data?.data;
  };
};
