import { RemoveRuleRequest, RULE_SET_ROUTES } from "@zeal/common";
import { IAxiosInstance } from "../../../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../../../AbstractApiClient/IApiResponse";

export const removeRule = (axios: IAxiosInstance) => {
  return async (data: RemoveRuleRequest) => {
    const response = await axios.noRetry.delete<IApiResponse<any>>(
      `${RULE_SET_ROUTES.REMOVE_RULE.replace(":companyID", data.companyID)
        .replace(":ruleSetID", data.ruleSetID)
        .replace(":ruleID", data.ruleID)}`,
      { params: { companyID: data.companyID } }
    );

    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
