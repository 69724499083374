export const getNoDirectDepositReason = (
  problems: string[],
  disbursementMethod = "Direct deposit"
): string => {
  const lastLetter = disbursementMethod[disbursementMethod.length - 1];
  let reason = `${disbursementMethod} ${
    lastLetter === "s" ? "are" : "is"
  } unavailable for this worker because they `;

  problems.forEach((problem, index) => {
    reason += problem;

    if (index === problems.length - 1) {
      reason += ".";
    } else {
      reason += ", and they ";
    }
  });

  return reason;
};
