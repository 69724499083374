import { zSchema } from "../../../schema-validation";
import { LEGAL_STRUCTURES } from "../../IOdoCompany";

export const migrateCompanySchema = zSchema.object({
  account_number: zSchema.string(),
  account_type: zSchema.enum(["checking", "savings"]).default("checking"),
  address: zSchema.string(),
  business_name: zSchema.string(),
  business_phone: zSchema.string(),
  city: zSchema.string(),
  contact_email: zSchema.string(),
  dob: zSchema.string().optional(),
  ein: zSchema.string(),
  enable_idempotency: zSchema.boolean().optional(),
  first_name: zSchema.string(),
  last_name: zSchema.string(),
  legal_structure: zSchema.enum(LEGAL_STRUCTURES),
  odo_user_id: zSchema.string(),
  ownership_percentage: zSchema.number(),
  perform_kyc: zSchema.boolean(),
  routing_number: zSchema.string(),
  ssn: zSchema.string(),
  state: zSchema.string(),
  tin: zSchema.string().optional(),
  title: zSchema.string(),
  zeal_id: zSchema.string(),
  zip: zSchema.string(),
});
