import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "clients/LaForgeClient/validation-types/SchemaDefinition";

const URL = "/assert/partnerCanAccessCompany";
export const assertPartnerCanAccessCompany = (axios: IAxiosInstance) => {
  return async (
    requestBody: ISchema["#/definitions/IAssertPartnerCanAccessCompanyRequest"]
  ) => {
    const response = await axios.noRetry.post<
      ISchema["#/definitions/IAssertPartnerCanAccessCompanyResponse"]
    >(URL, requestBody);
    const resData = response.data;

    if (resData.success !== true) {
      throw new Error("Something went wrong");
    }
  };
};
