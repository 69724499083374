import {
  IPostUpdatePaperworkTemplateRequest,
  IPostUpdatePaperworkTemplateResponse,
  PAPERWORK_ROUTES,
} from "@zeal/common";
import { IAxiosInstance } from "../../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../../AbstractApiClient/IApiResponse";

export const archivePaperworkTemplate = (axios: IAxiosInstance) => {
  return async (data: IPostUpdatePaperworkTemplateRequest) => {
    const response = await axios.noRetry.post<
      IApiResponse<IPostUpdatePaperworkTemplateResponse>
    >(PAPERWORK_ROUTES.UPDATE_PAPERWORK_TEMPLATE, data);

    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
