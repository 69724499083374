import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";

export const postParseChecksCSV =
  (axios: IAxiosInstance) => async (csv: string, payrollRunID?: string) => {
    const response = await axios.noRetry.post("/api/preview-check-csv", {
      csv,
      payrollRunID,
    });
    const resData = response.data;
    if (resData.success) {
      return resData.data;
    }
    throw new Error(
      "Error parsing CSV file. Please ensure file matches provided template."
    );
  };
