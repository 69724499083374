import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { Jurisdiction, EffectiveDate } from "@zeal/common";

export interface GetCompanyTaxParameterSummaryRequest {
  companyID: string;
  jurisdiction?: Jurisdiction | Jurisdiction[];
  effectiveDate?: string; // YYYY-MM-DD
}

export interface CompanyTaxParameterSummary {
  code: string;
  value: string;
  valueDescription?: string;
  type: string;
  label?: string;
  isDefaultValue: boolean;
  isUserProvidedValue: boolean;
  additional_scope?: string[];
  scope: string;
}

export interface GetCompanyTaxParameterSummaryResponse {
  companyID: string;
  effectiveDate: EffectiveDate;
  jurisdiction: Jurisdiction;
  params: CompanyTaxParameterSummary[];
}

export const getCompanyTaxParameterSummary = (axios: IAxiosInstance) => {
  return async (body: GetCompanyTaxParameterSummaryRequest) => {
    const response = await axios.noRetry.post<
      GetCompanyTaxParameterSummaryResponse[]
    >(`/api/company/getTaxParameterSummary`, body);

    return response.data;
  };
};
