import { ZealErrorAlias } from "../../errors";
import { uuid } from "./uuid";

export const companyID = ({
  description = "Company ID",
  invalid_pattern_error = ZealErrorAlias.INVALID_COMPANY_ID.message,
  required_error = ZealErrorAlias.NO_COMPANY_ID_BODY.message,
}: Parameters<typeof uuid>[0] = {}) => {
  return uuid({
    description,
    required_error,
    invalid_pattern_error,
  });
};
