import { z } from "zod";

import { schemes } from "../schema-validation";
import { taskDefinitionSchema } from "../tasks";
import { baseEventInput } from "./baseEvent";

export const companyTaskDefinitionEvent = baseEventInput.merge(
  z.object({
    source: z.literal("la-forge.users.task-definitions"),
    companyId: schemes.uuid(),
    payload: z.array(
      taskDefinitionSchema.pick({
        taskDefinitionId: true,
        enabled: true,
        default: true,
      })
    ),
  })
);

export type CompanyTaskDefinitionEvent = z.infer<
  typeof companyTaskDefinitionEvent
>;
