/** Parse input to number then convert to cents.
 * Throws if input can't be parsed to a number.
 */
export const toCents = (input: string | number): number => {
  const isString = typeof input === "string";
  /**
   * Remove "," from the dollar string (like "50,000.00")
   * so Number(parsedInput) can safely convert it to a number.
   */
  const sanitizedInput = isString ? input.replace(/,/g, "") : input;

  const num = Number(sanitizedInput);
  if (Number.isNaN(num))
    throw Error(
      `Could not convert to cents: '${input}' can't be parsed to a number.`
    );
  return Math.round(num * 100);
};
