import { Length } from "./Length";

export class GpsCoordinate {
  static readonly EARTHS_RADIUS = Length.from(6371, "km");

  private lat: number;
  private lon: number;

  constructor(lat: number, lon: number) {
    this.lat = lat;
    this.lon = lon;
  }

  /* Haversine formula taken from https://www.movable-type.co.uk/scripts/latlong.html#distance 
     
     Should we need more robust formula in the future, reference the following:
     - https://github.com/manuelbieh/geolib/blob/master/src/getPreciseDistance.ts
     - https://github.com/UEk/wgs84/blob/main/src/index.ts
  */
  static distanceBetween(pointA: GpsCoordinate, pointB: GpsCoordinate): Length {
    const φ1 = GpsCoordinate.degreeToRadian(pointA.lat);
    const φ2 = GpsCoordinate.degreeToRadian(pointB.lat);
    const Δφ = GpsCoordinate.degreeToRadian(pointB.lat - pointA.lat);
    const Δλ = GpsCoordinate.degreeToRadian(pointB.lon - pointA.lon);

    const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const d = GpsCoordinate.EARTHS_RADIUS.meters * c;
    return Length.from(d, "m");
  }

  static degreeToRadian(degree: number) {
    return (degree * Math.PI) / 180;
  }
}
