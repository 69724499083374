import { IEmployeeCheck } from "../employee-checks/IEmployeeCheck";
import { EDeductionType, DeductionTypeLabel } from "./EDeductionType";
import { toCase } from "../strings/toCase";

export const getDeductionLabel = (
  deduction: IEmployeeCheck["deductions"][0]
) => {
  const label = deduction.deduction_template_name ?? "-";
  const deductionType = deduction.deduction_type as EDeductionType;
  return `${label} (${
    DeductionTypeLabel[deductionType] ??
    toCase(deduction.deduction_type, "title")
  })`;
};
