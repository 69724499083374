import { GET_USER_DATA } from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { returnZealApiErrorResponse } from "../../../ZealApiClient/returnZealApiErrorResponse";

export const getUserData = (axios: IAxiosInstance) => {
  return async (query: { id: string }) => {
    try {
      const response = await axios.noRetry.get<any>(
        `${GET_USER_DATA}?id=${query.id}`
      );
      if (response.data?.success) {
        return response.data.data;
      }
    } catch (error) {
      return returnZealApiErrorResponse(error);
    }
  };
};
