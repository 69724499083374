import { COMPANY_UPDATE_NODE, ICompanyUser } from "@zeal/common";
import { AxiosError } from "axios";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import { TUpdateCompanyNodeRequestSchema } from "./updateCompanyNodeSchema";

export const updateCompanyNode =
  (axios: IAxiosInstance) => async (body: TUpdateCompanyNodeRequestSchema) => {
    try {
      const response = await axios.noRetry.post<IApiResponse<ICompanyUser>>(
        COMPANY_UPDATE_NODE,
        body
      );
      return response;
    } catch (e) {
      const errorMessage =
        (e as AxiosError)?.response?.data ?? "Error updating company node";
      throw errorMessage;
    }
  };
