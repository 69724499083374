import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

export const updateImageAws = (axios: IAxiosInstance) => {
  return async ({
    whiteLabelID,
    contentType,
    usePrimary,
    data,
    putUrl,
    isLogo,
  }: ISchema["#/definitions/IUpdateImageAwsArgs"]) => {
    await axios.noRetry.put<ISchema["#/definitions/IUpdateImageAwsResponse"]>(
      putUrl,
      data,
      {
        params: {
          Key: isLogo ? `${whiteLabelID}-logo` : `${whiteLabelID}-favicon`,
          ContentType: contentType,
          usePrimary,
        },
        headers: {
          "Content-Type": contentType,
        },
        withCredentials: false,
        baseURL: undefined,
      }
    );
  };
};
