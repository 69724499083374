import { ReadAttributeRequest, CUSTOM_ATTRIBUTE_ROUTES } from "@zeal/common";
import { IAxiosInstance } from "../../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../../AbstractApiClient/IApiResponse";

export const readAttribute = (axios: IAxiosInstance) => {
  return async (data: ReadAttributeRequest) => {
    const response = await axios.noRetry.get<IApiResponse<any>>(
      `${CUSTOM_ATTRIBUTE_ROUTES.READ_ATTRIBUTE.replace(
        ":attributeID",
        data.attributeID
      )}`,
      { params: { companyID: data.companyID } }
    );

    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
