import { TaxDocumentUrlResponse } from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";

export const downloadCompanyTaxDocumentUrl = (axios: IAxiosInstance) => {
  return async (companyID: string, taxDocumentId: string) => {
    const response = await axios.noRetry.get<{
      data: TaxDocumentUrlResponse[];
    }>(
      `/documents?companyID=${companyID}&documentID=${taxDocumentId}&onlyVisible=false`
    );
    const [doc] = response.data?.data ?? [];
    if (doc != null) {
      return doc.link;
    }
    return null;
  };
};
