import { EPaymentUserTypes } from "./IOdoEmployee";

export interface IOdoCompany {
  bank_account_id?: string;
  zeal_id?: string;
  odo_user_id: string;
  user_type: EPaymentUserTypes;
  business_name: string;
  business_app_id: string;
  custody_account_app_id: string;
  custody_account_id?: string;
  deductions_account_app_id: string;
  deductions_account_id?: string;
  migrated: boolean;
  primary_person_app_id: string;
  reserve_account_app_id: string;
  reserve_account_id?: string;
  tax_account_app_id: string;
  tax_account_id?: string;
}

export interface ICompanyUser {
  readonly _id: string;
  readonly bank_account_id: string;
  readonly business_app_id: string;
  readonly createdAt: string;
  readonly custody_account_app_id: string;
  readonly custody_account_id: string;
  readonly deductions_account_app_id?: string;
  readonly deductions_account_id?: string;
  readonly migrated: boolean;
  readonly odo_user_id: string;
  readonly primary_person_app_id: string;
  readonly reserve_account_app_id: string;
  readonly reserve_account_id: string;
  readonly tax_account_app_id: string;
  readonly tax_account_id: string;
  readonly updatedAt: string;
  readonly user_type: "company_user";
  readonly zeal_id: string;
}

export interface IOdoCompanyOnboardRequest {
  readonly business_name: string;
  readonly address: string;
  readonly city: string;
  readonly zip: string;
  readonly state: string;
  readonly phone_number: string;
  readonly organization_date_base: string;
  readonly contact_email: string;
  readonly ein: string;
}

export interface IOdoCompanyOnboardResponse {
  readonly success: boolean;
  readonly company?: IOdoCompany;
  readonly error?: string;
}

export type AccountType =
  | "RESERVE"
  | "CUSTODY"
  | "TAX"
  | "BUSINESS"
  | "PERSONAL";

export interface IOdoAddAchRequest {
  first_name?: string;
  last_name?: string;
  company_id?: string;
  user_id: string;
  account_num: string;
  routing_num: string;
  account_type: AccountType;
  account_class: string;
  type_of_node?: string;
}

export interface ICompanyVerifyAchRequest {
  user_id: string;
  deposits: [string, string];
}

export interface IOdoCompanyStatusResponse {
  success: boolean;
  status?: string;
  error?: string;
}

export const LEGAL_STRUCTURES = [
  "ccorp",
  "corp",
  "estate",
  "foreign_entity",
  "llc",
  "llp",
  "lp",
  "nonprofit",
  "partnership",
  "scheme",
  "scorp",
  "soleprop",
  "trust",
] as const;

export type LegalStructureTuple = typeof LEGAL_STRUCTURES;

export type LegalStructure = LegalStructureTuple[number];

export const LegalStructureSelect: Record<LegalStructure, string> = {
  ccorp: "C Corp",
  corp: "Corporation",
  estate: "Estate",
  foreign_entity: "Foreign Entity",
  llc: "LLC",
  llp: "LLP",
  lp: "LP",
  nonprofit: "Non-Profit",
  partnership: "Partnership",
  scheme: "Scheme",
  scorp: "S Corp",
  soleprop: "Sole Proprietorship",
  trust: "Trust",
};
