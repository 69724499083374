import { EKYCStatus } from "./EKYCStatus";
import { EVeriffWatchlistStatus } from "./EVeriffWatchlistStatus";

export const VeriffWatchlistStatusToEKYCStatus: Record<
  EVeriffWatchlistStatus,
  EKYCStatus
> = {
  [EVeriffWatchlistStatus.no_match]: EKYCStatus.APPROVED,
  [EVeriffWatchlistStatus.possible_match]: EKYCStatus.MANUAL_REVIEW,
};
