import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

export const updateWLInfo = (axios: IAxiosInstance) => {
  return async ({
    firstName,
    lastName,
    businessOwnerFirstName,
    businessOwnerLastName,
    ssn,
    jobTitle,
    ownershipPercentage,
    dob,
    ownerEmail,
    ownerAddress,
    ownerAddressLine2,
    ownerCity,
    ownerState,
    ownerZip,
    hasAdditionalOwners,
    businessName,
    businessEin,
    legalStructure,
    businessPhone,
    businessEmail,
    businessAddress,
    businessCity,
    businessState,
    businessZip,
    isMailingAddressDifferent,
    mailAddress,
    mailCity,
    mailState,
    mailZip,
    baseUrl,
    hostedTitle,
    additionalOwner_firstName,
    additionalOwner_lastName,
    additionalOwner_ssn,
    additionalOwner_ownershipPercentage,
    additionalOwner_dob,
    additionalOwner_address,
    additionalOwner_addressLine2,
    additionalOwner_city,
    additionalOwner_state,
    additionalOwner_zip,
  }: ISchema["#/definitions/IUpdateWLInfoArgs"]) => {
    const response = await axios.noRetry.post<
      ISchema["#/definitions/IUpdateWLInfoResponse"]
    >(
      `/api/updateWLInfo`,
      {
        first_name: firstName,
        last_name: lastName,
        business_owner_first_name: businessOwnerFirstName,
        business_owner_last_name: businessOwnerLastName,
        ssn,
        job_title: jobTitle,
        ownership_percentage: ownershipPercentage,
        dob,
        owner_email: ownerEmail,
        owner_address: ownerAddress,
        owner_addressLine2: ownerAddressLine2,
        owner_city: ownerCity,
        owner_state: ownerState,
        owner_zip: ownerZip,
        hasAdditionalOwners,
        additionalOwner_firstName,
        additionalOwner_lastName,
        additionalOwner_ssn,
        additionalOwner_ownershipPercentage,
        additionalOwner_dob,
        additionalOwner_address,
        additionalOwner_addressLine2,
        additionalOwner_city,
        additionalOwner_state,
        additionalOwner_zip,
        business_name: businessName,
        business_ein: businessEin,
        legal_structure: legalStructure,
        business_phone: businessPhone,
        business_email: businessEmail,
        business_address: businessAddress,
        business_city: businessCity,
        business_state: businessState,
        business_zip: businessZip,
        isMailingAddressDifferent,
        mail_address: mailAddress,
        mail_city: mailCity,
        mail_state: mailState,
        mail_zip: mailZip,
        base_url: baseUrl,
        hosted_title: hostedTitle,
      },
      {
        params: {
          usePrimary: true,
        },
      }
    );
    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
