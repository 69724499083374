import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import {
  IGetCompanyLocationMetadataRequest,
  IGetCompanyLocationMetadataResponse,
} from "./getCompanyLocationMetadata.jsonschema";

export const getCompanyLocationMetadata = (axios: IAxiosInstance) => {
  return async (
    body: IGetCompanyLocationMetadataRequest
  ): Promise<IGetCompanyLocationMetadataResponse> => {
    const response =
      await axios.with502Retry.post<IGetCompanyLocationMetadataResponse>(
        `/api/company/getCompanyLocationMetadata`,
        body
      );
    return response.data;
  };
};
