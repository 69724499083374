import { II9OnboardingInfo, ZealApiResponse } from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { TUpdateI9OnboardingInfoRequest } from "./updateI9OnboardingInfoSchema";

export const updateI9OnboardingInfo = (axios: IAxiosInstance) => {
  return async (data: TUpdateI9OnboardingInfoRequest) => {
    const response = await axios.noRetry.patch<
      ZealApiResponse<II9OnboardingInfo>
    >(`/api/updateI9OnboardingInfo`, { ...data });
    const resData = response.data;
    if (resData.success) {
      return resData.data;
    }
    throw new Error("Something went wrong");
  };
};
