import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

export const getCurrentUser = (axios: IAxiosInstance) => {
  return async () => {
    const response = await axios.noRetry.get<{
      success: boolean;
      data: ISchema["#/definitions/IGetCurrentUserResponse"];
    }>(`/api/getCurrentUser`);

    const resData = response.data;

    if (resData.success) {
      return resData.data?.data;
    }

    throw new Error("Something went wrong");
  };
};
