const WORD_REGEX =
  /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g;

const camel = (words: string[]) => {
  const s = words
    .map((w) => w.slice(0, 1).toUpperCase() + w.slice(1).toLowerCase())
    .join("");
  return s.slice(0, 1).toLowerCase() + s.slice(1);
};

const flat = (words: string[]) => {
  return words.join("").toLowerCase();
};

const kebab = (words: string[]) => words.map((w) => w.toLowerCase()).join("-");

const pascal = (words: string[]) =>
  words
    .map((w) => w.slice(0, 1).toUpperCase() + w.slice(1).toLowerCase())
    .join("");

const sentence = (words: string[]) => {
  const s = words.join(" ");
  return s.slice(0, 1).toUpperCase() + s.slice(1).toLowerCase();
};

const snake = (words: string[]) => words.map((w) => w.toLowerCase()).join("_");

const title = (words: string[]) =>
  words
    .map((w) => w.slice(0, 1).toUpperCase() + w.slice(1).toLowerCase())
    .join(" ");

const upper = (words: string[]) => words.join("").toUpperCase();

const constant = (words: string[]) => words.join("_").toUpperCase();

/**
 * Converts a string to the desired case.
 *
 * @param str - The input string to be converted.
 * @param desiredCase - The desired case to convert the string to.
 * @returns The converted string in the desired case.
 * @throws Error if the desired case is invalid.
 */
export const toCase = (
  str: string,
  desiredCase:
    | "camel" // camelCase
    | "constant" // CONSTANT_CASE
    | "flat" // flatcase
    | "kebab" // kebab-case
    | "pascal" // PascalCase
    | "sentence" // Sentence case
    | "snake" // snake_case
    | "title" // Title Case
    | "upper" // UPPERCASE
): string => {
  const words = str.match(WORD_REGEX) ?? [];
  switch (desiredCase) {
    case "camel":
      return camel(words);
    case "constant":
      return constant(words);
    case "flat":
      return flat(words);
    case "kebab":
      return kebab(words);
    case "pascal":
      return pascal(words);
    case "sentence":
      return sentence(words);
    case "snake":
      return snake(words);
    case "title":
      return title(words);
    case "upper":
      return upper(words);
    default:
      throw Error("Invalid case");
  }
};
