import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { getLocalTaxTypesParams, getLocalTaxTypesResponse } from "./schemas";

export const getLocalTaxTypes = (axios: IAxiosInstance) => {
  return async ({ companyID, jurisdiction, q }: getLocalTaxTypesParams) => {
    const response = await axios.noRetry.get<getLocalTaxTypesResponse>(
      "/taxconfig/local/types",
      {
        params: { companyID, jurisdiction, q },
      }
    );
    return response.data;
  };
};
