import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";

export type RemoveAccountMembershipResponse = IApiResponse<null>;

export const removeAccountMembership = (axios: IAxiosInstance) => {
  return async (options: {
    email: string;
    accountType: "admin" | "guest" | "wladmin";
  }) => {
    const response = await axios.noRetry.post<RemoveAccountMembershipResponse>(
      `/api/accounts/remove-account-membership`,
      {
        email: options.email,
        accountType: options.accountType,
      }
    );

    const { data, success } = response.data;
    if (!success) {
      throw new Error("Failed to remove account membership");
    }
    return data;
  };
};
