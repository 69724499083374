import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

export const generateLogoPutUrl = (axios: IAxiosInstance) => {
  return async ({
    whiteLabelID,
    contentType,
    usePrimary = true,
    isLogo,
  }: ISchema["#/definitions/IGenerateLogoPutUrlArgs"]) => {
    const response = await axios.noRetry.get<{
      data: ISchema["#/definitions/IGenerateLogoPutUrlResponse"];
    }>(`/generate-logo-put-url`, {
      params: {
        key: isLogo ? `${whiteLabelID}-logo` : `${whiteLabelID}-favicon`,
        contentType: contentType,
        usePrimary,
        whiteLabelID,
      },
      headers: {
        "Content-Type": contentType,
      },
    });

    const resData = response.data?.data;

    if (resData) {
      return resData;
    }

    throw new Error("Something went wrong");
  };
};
