import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

export const resendFax = (axios: IAxiosInstance) => {
  return async (faxRecordID: string) => {
    const response = await axios.noRetry.post<
      ISchema["#/definitions/TResendFaxResponse"]
    >(`/api/resendFax`, {
      faxRecordID,
    });
    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
