import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";

// TODO use shiraz's type once it's done
export interface IGetCustomPaperwork {
  templateID: string;
  companyID: string;
  form_name?: string;
  description: string;
  effective_date: string;
  archive_date?: string;
  form_fields: unknown[];
  urls: unknown[];
  paperwork_type: string; // TODO type
  worker_type: string; // TODO type
  jurisdictions_filter: {
    type: "all" | "include" | "exclude";
    jurisdictions?: string[];
  };
  jurisdiction_type: string; // TODO type
  status: "Active" | "Ready" | "Draft" | "Archived";
}
export type GetCustomPaperworkResponse = IApiResponse<{
  templates: IGetCustomPaperwork[];
}>;

export const getCustomPaperworkTemplates = (axios: IAxiosInstance) => {
  return async () => {
    const response =
      await axios.noRetry.post<GetCustomPaperworkResponse>(
        `/paperwork/templates`
      );

    const { data, success } = response.data;
    if (!success) {
      throw new Error("Failed to get current user accounts");
    }
    return data;
  };
};
