export enum EI9CountryCode {
  AFG = "AFG",
  AGO = "AGO",
  ALB = "ALB",
  AND = "AND",
  ARE = "ARE",
  ARG = "ARG",
  ARM = "ARM",
  ATG = "ATG",
  AUS = "AUS",
  AUT = "AUT",
  AZE = "AZE",
  BDI = "BDI",
  BEL = "BEL",
  BEN = "BEN",
  BFA = "BFA",
  BGD = "BGD",
  BGR = "BGR",
  BHR = "BHR",
  BHS = "BHS",
  BIH = "BIH",
  BLR = "BLR",
  BLZ = "BLZ",
  BOL = "BOL",
  BRA = "BRA",
  BRB = "BRB",
  BRN = "BRN",
  BTN = "BTN",
  BWA = "BWA",
  CAF = "CAF",
  CAN = "CAN",
  CHE = "CHE",
  CHL = "CHL",
  CHN = "CHN",
  CIV = "CIV",
  CMR = "CMR",
  COD = "COD",
  COG = "COG",
  COL = "COL",
  COM = "COM",
  CPV = "CPV",
  CRI = "CRI",
  CUB = "CUB",
  CYP = "CYP",
  CZE = "CZE",
  DEU = "DEU",
  DJI = "DJI",
  DMA = "DMA",
  DNK = "DNK",
  DOM = "DOM",
  DZA = "DZA",
  ECU = "ECU",
  EGY = "EGY",
  ERI = "ERI",
  ESP = "ESP",
  EST = "EST",
  ETH = "ETH",
  FIN = "FIN",
  FJI = "FJI",
  FRA = "FRA",
  FSM = "FSM",
  GAB = "GAB",
  GBR = "GBR",
  GEO = "GEO",
  GHA = "GHA",
  GIN = "GIN",
  GMB = "GMB",
  GNB = "GNB",
  GNQ = "GNQ",
  GRC = "GRC",
  GRD = "GRD",
  GTM = "GTM",
  GUY = "GUY",
  HND = "HND",
  HRV = "HRV",
  HTI = "HTI",
  HUN = "HUN",
  IDN = "IDN",
  IND = "IND",
  IRL = "IRL",
  IRN = "IRN",
  IRQ = "IRQ",
  ISL = "ISL",
  ISR = "ISR",
  ITA = "ITA",
  JAM = "JAM",
  JOR = "JOR",
  JPN = "JPN",
  KAZ = "KAZ",
  KEN = "KEN",
  KGZ = "KGZ",
  KHM = "KHM",
  KIR = "KIR",
  KNA = "KNA",
  KOR = "KOR",
  KVO = "KVO",
  KWT = "KWT",
  LAO = "LAO",
  LBN = "LBN",
  LBR = "LBR",
  LBY = "LBY",
  LCA = "LCA",
  LIE = "LIE",
  LKA = "LKA",
  LSO = "LSO",
  LTU = "LTU",
  LUX = "LUX",
  LVA = "LVA",
  MAR = "MAR",
  MCO = "MCO",
  MDA = "MDA",
  MDG = "MDG",
  MDV = "MDV",
  MEX = "MEX",
  MHL = "MHL",
  MKD = "MKD",
  MLI = "MLI",
  MLT = "MLT",
  MMR = "MMR",
  MNE = "MNE",
  MNG = "MNG",
  MOZ = "MOZ",
  MRT = "MRT",
  MUS = "MUS",
  MWI = "MWI",
  MYS = "MYS",
  NAM = "NAM",
  NER = "NER",
  NGA = "NGA",
  NIC = "NIC",
  NLD = "NLD",
  NOR = "NOR",
  NPL = "NPL",
  NRU = "NRU",
  NZL = "NZL",
  OMN = "OMN",
  PAK = "PAK",
  PAN = "PAN",
  PER = "PER",
  PHL = "PHL",
  PLW = "PLW",
  PNG = "PNG",
  POL = "POL",
  PRK = "PRK",
  PRT = "PRT",
  PRY = "PRY",
  PSE = "PSE",
  QAT = "QAT",
  ROU = "ROU",
  RUS = "RUS",
  RWA = "RWA",
  SAU = "SAU",
  SDN = "SDN",
  SEN = "SEN",
  SGP = "SGP",
  SLB = "SLB",
  SLE = "SLE",
  SLV = "SLV",
  SMR = "SMR",
  SOM = "SOM",
  SRB = "SRB",
  SSD = "SSD",
  STP = "STP",
  SUR = "SUR",
  SVK = "SVK",
  SVN = "SVN",
  SWE = "SWE",
  SWZ = "SWZ",
  SYC = "SYC",
  SYR = "SYR",
  TCD = "TCD",
  TGO = "TGO",
  THA = "THA",
  TJK = "TJK",
  TKM = "TKM",
  TLS = "TLS",
  TON = "TON",
  TTO = "TTO",
  TUN = "TUN",
  TUR = "TUR",
  TUV = "TUV",
  TWN = "TWN",
  TZA = "TZA",
  UGA = "UGA",
  UKR = "UKR",
  URY = "URY",
  UZB = "UZB",
  VAT = "VAT",
  VCT = "VCT",
  VEN = "VEN",
  VNM = "VNM",
  VUT = "VUT",
  WSM = "WSM",
  YEM = "YEM",
  ZAF = "ZAF",
  ZMB = "ZMB",
  ZWE = "ZWE",
}

/**
 * The labels come from the e-verify web services API,
 * specifically this route: GET /reference/countries
 */
export const i9CountryCodeMenuItems = [
  { label: "Afghanistan", value: EI9CountryCode.AFG },
  { label: "Albania", value: EI9CountryCode.ALB },
  { label: "Algeria", value: EI9CountryCode.DZA },
  { label: "Andorra", value: EI9CountryCode.AND },
  { label: "Angola", value: EI9CountryCode.AGO },
  { label: "Antigua and Barbuda", value: EI9CountryCode.ATG },
  { label: "Argentina", value: EI9CountryCode.ARG },
  { label: "Armenia", value: EI9CountryCode.ARM },
  { label: "Australia", value: EI9CountryCode.AUS },
  { label: "Austria", value: EI9CountryCode.AUT },
  { label: "Azerbaijan", value: EI9CountryCode.AZE },
  { label: "Bahamas", value: EI9CountryCode.BHS },
  { label: "Bahrain", value: EI9CountryCode.BHR },
  { label: "Bangladesh", value: EI9CountryCode.BGD },
  { label: "Barbados", value: EI9CountryCode.BRB },
  { label: "Belarus", value: EI9CountryCode.BLR },
  { label: "Belgium", value: EI9CountryCode.BEL },
  { label: "Belize", value: EI9CountryCode.BLZ },
  { label: "Benin", value: EI9CountryCode.BEN },
  { label: "Bhutan", value: EI9CountryCode.BTN },
  { label: "Bolivia, Plurinational State of", value: EI9CountryCode.BOL },
  { label: "Bosnia and Herzegovina", value: EI9CountryCode.BIH },
  { label: "Botswana", value: EI9CountryCode.BWA },
  { label: "Brazil", value: EI9CountryCode.BRA },
  { label: "Brunei", value: EI9CountryCode.BRN },
  { label: "Bulgaria", value: EI9CountryCode.BGR },
  { label: "Burkina Faso", value: EI9CountryCode.BFA },
  { label: "Burma (Myanmar)", value: EI9CountryCode.MMR },
  { label: "Burundi", value: EI9CountryCode.BDI },
  { label: "Cambodia", value: EI9CountryCode.KHM },
  { label: "Cameroon", value: EI9CountryCode.CMR },
  { label: "Canada", value: EI9CountryCode.CAN },
  { label: "Cape Verde", value: EI9CountryCode.CPV },
  { label: "Central African Republic", value: EI9CountryCode.CAF },
  { label: "Chad", value: EI9CountryCode.TCD },
  { label: "Chile", value: EI9CountryCode.CHL },
  { label: "China", value: EI9CountryCode.CHN },
  { label: "Colombia", value: EI9CountryCode.COL },
  { label: "Comoros", value: EI9CountryCode.COM },
  { label: "Congo, Democratic Republic of the", value: EI9CountryCode.COD },
  { label: "Congo, Republic of the", value: EI9CountryCode.COG },
  { label: "Costa Rica", value: EI9CountryCode.CRI },
  { label: "Cote d'Ivoire (Ivory Coast)", value: EI9CountryCode.CIV },
  { label: "Croatia", value: EI9CountryCode.HRV },
  { label: "Cuba", value: EI9CountryCode.CUB },
  { label: "Cyprus", value: EI9CountryCode.CYP },
  { label: "Czech Republic", value: EI9CountryCode.CZE },
  { label: "Denmark", value: EI9CountryCode.DNK },
  { label: "Djibouti", value: EI9CountryCode.DJI },
  { label: "Dominica", value: EI9CountryCode.DMA },
  { label: "Dominican Republic", value: EI9CountryCode.DOM },
  { label: "Ecuador", value: EI9CountryCode.ECU },
  { label: "Egypt", value: EI9CountryCode.EGY },
  { label: "El Salvador", value: EI9CountryCode.SLV },
  { label: "Equatorial Guinea", value: EI9CountryCode.GNQ },
  { label: "Eritrea", value: EI9CountryCode.ERI },
  { label: "Estonia", value: EI9CountryCode.EST },
  { label: "Eswatini", value: EI9CountryCode.SWZ },
  { label: "Ethiopia", value: EI9CountryCode.ETH },
  { label: "Fiji", value: EI9CountryCode.FJI },
  { label: "Finland", value: EI9CountryCode.FIN },
  { label: "France", value: EI9CountryCode.FRA },
  { label: "Gabon", value: EI9CountryCode.GAB },
  { label: "Gambia", value: EI9CountryCode.GMB },
  { label: "Georgia", value: EI9CountryCode.GEO },
  { label: "Germany", value: EI9CountryCode.DEU },
  { label: "Ghana", value: EI9CountryCode.GHA },
  { label: "Greece", value: EI9CountryCode.GRC },
  { label: "Grenada", value: EI9CountryCode.GRD },
  { label: "Guatemala", value: EI9CountryCode.GTM },
  { label: "Guinea", value: EI9CountryCode.GIN },
  { label: "Guinea-Bissau", value: EI9CountryCode.GNB },
  { label: "Guyana", value: EI9CountryCode.GUY },
  { label: "Haiti", value: EI9CountryCode.HTI },
  { label: "Holy See (Vatican City State)", value: EI9CountryCode.VAT },
  { label: "Honduras", value: EI9CountryCode.HND },
  { label: "Hungary", value: EI9CountryCode.HUN },
  { label: "Iceland", value: EI9CountryCode.ISL },
  { label: "India", value: EI9CountryCode.IND },
  { label: "Indonesia", value: EI9CountryCode.IDN },
  { label: "Iran, Islamic Republic of", value: EI9CountryCode.IRN },
  { label: "Iraq", value: EI9CountryCode.IRQ },
  { label: "Ireland", value: EI9CountryCode.IRL },
  { label: "Israel", value: EI9CountryCode.ISR },
  { label: "Italy", value: EI9CountryCode.ITA },
  { label: "Jamaica", value: EI9CountryCode.JAM },
  { label: "Japan", value: EI9CountryCode.JPN },
  { label: "Jordan", value: EI9CountryCode.JOR },
  { label: "Kazakhstan", value: EI9CountryCode.KAZ },
  { label: "Kenya", value: EI9CountryCode.KEN },
  { label: "Kiribati", value: EI9CountryCode.KIR },
  { label: "Korea, Democratic Republic of (North)", value: EI9CountryCode.PRK },
  { label: "Korea, Republic of (South)", value: EI9CountryCode.KOR },
  { label: "Kosovo", value: EI9CountryCode.KVO },
  { label: "Kuwait", value: EI9CountryCode.KWT },
  { label: "Kyrgyzstan", value: EI9CountryCode.KGZ },
  { label: "Laos", value: EI9CountryCode.LAO },
  { label: "Latvia", value: EI9CountryCode.LVA },
  { label: "Lebanon", value: EI9CountryCode.LBN },
  { label: "Lesotho", value: EI9CountryCode.LSO },
  { label: "Liberia", value: EI9CountryCode.LBR },
  { label: "Libya", value: EI9CountryCode.LBY },
  { label: "Liechtenstein", value: EI9CountryCode.LIE },
  { label: "Lithuania", value: EI9CountryCode.LTU },
  { label: "Luxembourg", value: EI9CountryCode.LUX },
  {
    label: "Macedonia, the Former Yugoslav Republic of",
    value: EI9CountryCode.MKD,
  },
  { label: "Madagascar", value: EI9CountryCode.MDG },
  { label: "Malawi", value: EI9CountryCode.MWI },
  { label: "Malaysia", value: EI9CountryCode.MYS },
  { label: "Maldives", value: EI9CountryCode.MDV },
  { label: "Mali", value: EI9CountryCode.MLI },
  { label: "Malta", value: EI9CountryCode.MLT },
  { label: "Marshall Islands, Republic of the", value: EI9CountryCode.MHL },
  { label: "Mauritania", value: EI9CountryCode.MRT },
  { label: "Mauritius", value: EI9CountryCode.MUS },
  { label: "Mexico", value: EI9CountryCode.MEX },
  { label: "Micronesia, Federated States of", value: EI9CountryCode.FSM },
  { label: "Moldova, Republic of", value: EI9CountryCode.MDA },
  { label: "Monaco", value: EI9CountryCode.MCO },
  { label: "Mongolia", value: EI9CountryCode.MNG },
  { label: "Montenegro", value: EI9CountryCode.MNE },
  { label: "Morocco", value: EI9CountryCode.MAR },
  { label: "Mozambique", value: EI9CountryCode.MOZ },
  { label: "Namibia", value: EI9CountryCode.NAM },
  { label: "Nauru", value: EI9CountryCode.NRU },
  { label: "Nepal", value: EI9CountryCode.NPL },
  { label: "Netherlands", value: EI9CountryCode.NLD },
  { label: "New Zealand", value: EI9CountryCode.NZL },
  { label: "Nicaragua", value: EI9CountryCode.NIC },
  { label: "Niger", value: EI9CountryCode.NER },
  { label: "Nigeria", value: EI9CountryCode.NGA },
  { label: "Norway", value: EI9CountryCode.NOR },
  { label: "Oman", value: EI9CountryCode.OMN },
  { label: "Pakistan", value: EI9CountryCode.PAK },
  { label: "Palau", value: EI9CountryCode.PLW },
  { label: "Palestinian Authority", value: EI9CountryCode.PSE },
  { label: "Panama", value: EI9CountryCode.PAN },
  { label: "Papua New Guinea", value: EI9CountryCode.PNG },
  { label: "Paraguay", value: EI9CountryCode.PRY },
  { label: "Peru", value: EI9CountryCode.PER },
  { label: "Philippines", value: EI9CountryCode.PHL },
  { label: "Poland", value: EI9CountryCode.POL },
  { label: "Portugal", value: EI9CountryCode.PRT },
  { label: "Qatar", value: EI9CountryCode.QAT },
  { label: "Romania", value: EI9CountryCode.ROU },
  { label: "Russia", value: EI9CountryCode.RUS },
  { label: "Rwanda", value: EI9CountryCode.RWA },
  { label: "Saint Kitts and Nevis", value: EI9CountryCode.KNA },
  { label: "Saint Lucia", value: EI9CountryCode.LCA },
  { label: "Saint Vincent and the Grenadines", value: EI9CountryCode.VCT },
  { label: "Samoa", value: EI9CountryCode.WSM },
  { label: "San Marino", value: EI9CountryCode.SMR },
  { label: "Sao Tome and Principe", value: EI9CountryCode.STP },
  { label: "Saudi Arabia", value: EI9CountryCode.SAU },
  { label: "Senegal", value: EI9CountryCode.SEN },
  { label: "Serbia", value: EI9CountryCode.SRB },
  { label: "Seychelles", value: EI9CountryCode.SYC },
  { label: "Sierra Leone", value: EI9CountryCode.SLE },
  { label: "Singapore", value: EI9CountryCode.SGP },
  { label: "Slovakia", value: EI9CountryCode.SVK },
  { label: "Slovenia", value: EI9CountryCode.SVN },
  { label: "Solomon Islands", value: EI9CountryCode.SLB },
  { label: "Somalia", value: EI9CountryCode.SOM },
  { label: "South Africa", value: EI9CountryCode.ZAF },
  { label: "South Sudan", value: EI9CountryCode.SSD },
  { label: "Spain", value: EI9CountryCode.ESP },
  { label: "Sri Lanka", value: EI9CountryCode.LKA },
  { label: "Sudan", value: EI9CountryCode.SDN },
  { label: "Suriname", value: EI9CountryCode.SUR },
  { label: "Sweden", value: EI9CountryCode.SWE },
  { label: "Switzerland", value: EI9CountryCode.CHE },
  { label: "Syria", value: EI9CountryCode.SYR },
  { label: "Taiwan", value: EI9CountryCode.TWN },
  { label: "Tajikistan", value: EI9CountryCode.TJK },
  { label: "Tanzania, United Republic of", value: EI9CountryCode.TZA },
  { label: "Thailand", value: EI9CountryCode.THA },
  { label: "Timor-leste", value: EI9CountryCode.TLS },
  { label: "Togo", value: EI9CountryCode.TGO },
  { label: "Tonga", value: EI9CountryCode.TON },
  { label: "Trinidad and Tobago", value: EI9CountryCode.TTO },
  { label: "Tunisia", value: EI9CountryCode.TUN },
  { label: "Turkey", value: EI9CountryCode.TUR },
  { label: "Turkmenistan", value: EI9CountryCode.TKM },
  { label: "Tuvalu", value: EI9CountryCode.TUV },
  { label: "Uganda", value: EI9CountryCode.UGA },
  { label: "Ukraine", value: EI9CountryCode.UKR },
  { label: "United Arab Emirates", value: EI9CountryCode.ARE },
  { label: "United Kingdom", value: EI9CountryCode.GBR },
  { label: "Uruguay", value: EI9CountryCode.URY },
  { label: "Uzbekistan", value: EI9CountryCode.UZB },
  { label: "Vanuatu", value: EI9CountryCode.VUT },
  { label: "Venezuela, Bolivarian Republic of", value: EI9CountryCode.VEN },
  { label: "Viet Nam", value: EI9CountryCode.VNM },
  { label: "Yemen", value: EI9CountryCode.YEM },
  { label: "Zambia", value: EI9CountryCode.ZMB },
  { label: "Zimbabwe", value: EI9CountryCode.ZWE },
];
