import { AxiosResponse } from "axios";
import {
  buildResponseBase,
  IGeneratedFiles,
  IGetJobStatusRequest,
} from "@zeal/common";
import { IAxiosInstance } from "../../AbstractApiClient/AbstractApiClient";

export const checkJobStatus =
  (axios: IAxiosInstance) => async (obj: IGetJobStatusRequest) => {
    const url = "/private/api/compass/checkJobStatus";

    const res = await axios.noRetry.request<
      IGeneratedFiles,
      AxiosResponse<IGeneratedFiles>,
      IGetJobStatusRequest
    >({
      method: "POST",
      url,
      data: obj,
    });

    return buildResponseBase({ status: res.status, data: res.data });
  };
