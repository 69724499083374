import { schemes, zSchema } from "@zeal/common";

export const getPaymentDestinationsRequestSchema = zSchema.object({
  odo_user_ids: zSchema
    .array(schemes.mongoObjectId().or(schemes.uuid()))
    .optional(),
});

export type TGetPaymentDestinationsRequestSchema = zSchema.infer<
  typeof getPaymentDestinationsRequestSchema
>;

export const getPaymentDestinationsResponseSchema = zSchema.array(
  zSchema.object({
    odo_user_id: schemes.mongoObjectId().or(schemes.uuid()),
    payment_destination_id: schemes.uuid(),
  })
);

export type TGetPaymentDestinationsResponseSchema = zSchema.infer<
  typeof getPaymentDestinationsResponseSchema
>;
