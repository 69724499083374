import { zSchema } from "@zeal/common";

export const postPayrollJournalRequestSchema = zSchema.object({
  companyID: zSchema.string(),
  start_date: zSchema.string(),
  end_date: zSchema.string(),
  media_type: zSchema.string(),
  include_migrated: zSchema.boolean().default(false),
});

export type TPostPayrollJournalRequestSchema = zSchema.TypeOf<
  typeof postPayrollJournalRequestSchema
>;

export const postPayrollJournalResponseSchema = zSchema.object({
  created_at: zSchema.string(),
  job_id: zSchema.string(),
  request_body: postPayrollJournalRequestSchema,
  status: zSchema.string(),
});

export type TPostPayrollJournalResponseSchema = zSchema.TypeOf<
  typeof postPayrollJournalResponseSchema
>;
