import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IShiftWebhookRequestBody } from "./shiftWebhook.jsonschema";

export const shiftWebhook = (axios: IAxiosInstance) => {
  return async (shiftWebhook: string, userType: string, test: boolean) => {
    const webhookBody: IShiftWebhookRequestBody = {
      shift_webhook: shiftWebhook,
      user_type: userType,
      test,
    };
    const response = await axios.noRetry.post<unknown>(
      `/api/testShiftWebhook`,
      webhookBody
    );

    const { data } = response;
    return data;
  };
};
