import { zSchema } from "../../../schema-validation";

export const employeeOnboardSchema = zSchema.object({
  address: zSchema.string(),
  address_line2: zSchema.string().optional().nullable(),
  city: zSchema.string(),
  dob: zSchema.string(),
  email: zSchema.string(),
  first_name: zSchema.string(),
  last_name: zSchema.string(),
  phone_number: zSchema.string().optional().nullable(),
  state: zSchema.string(),
  tin: zSchema.string(),
  zeal_id: zSchema.string(),
  zip: zSchema.string(),
});
