export const fuzzyFilter = <T extends object>(
  search: string,
  data: T[],
  options = { excludedSearchFields: new Set<string>() }
): T[] => {
  if (!search) return data;
  return data.filter(
    (d) =>
      !!Object.entries(d).find(
        ([key, value]) =>
          !options.excludedSearchFields.has(key) &&
          typeof value === "string" &&
          value.toLocaleLowerCase().includes(search.toLocaleLowerCase())
      )
  );
};
