import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { ISchema } from "../../validation-types/SchemaDefinition";

export const submitShift = (axios: IAxiosInstance) => {
  return async (requestBody: ISchema["#/definitions/ISubmitShiftRequest"]) => {
    const response = await axios.noRetry.post(`api/submitShift`, requestBody);
    const resData = response.data;

    if (resData.success) {
      return resData;
    }

    throw new Error("Something went wrong");
  };
};
