import {
  TGetMyRecentlyOnboardedContractorsQuery,
  TGetMyRecentlyOnboardedContractorsResponseData,
} from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";

export const getMyRecentlyOnboardedContractors = (axios: IAxiosInstance) => {
  return async (options: TGetMyRecentlyOnboardedContractorsQuery) => {
    const { query } = options;
    const response = await axios.noRetry.get<
      IApiResponse<TGetMyRecentlyOnboardedContractorsResponseData>
    >("/api/contractors/recently-onboarded", {
      params: { ...query },
    });
    return response.data;
  };
};
