import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TGetPayCardRequestSchema,
  TGetPayCardResponseSchema,
} from "./getPayCard.schema";

export const getPayCard = (axios: IAxiosInstance) => {
  return async (data: TGetPayCardRequestSchema) => {
    const { pay_card_id } = data;
    const response = await axios.noRetry.get<
      IApiResponse<TGetPayCardResponseSchema>
    >(`/api/pay-cards/${pay_card_id}`);

    return response.data;
  };
};
